import React, { useState, useReducer } from "react";
import {
  Box,
  Button,
  makeStyles,
  Typography,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Link
} from "@material-ui/core";
import { CgAttachment } from "react-icons/cg";
import { l1 } from "./local.json";
import { gql, useMutation } from "@apollo/client";

const UPDATE_STUDENTWORK = gql`
  mutation updateStudentWork($id: ID!, $data: editStudentWorkInput!) {
    updateStudentWork(input: { where: { id: $id }, data: $data }) {
      studentWork {
        id
      }
    }
  }
`;
const UPLOAD = gql`
  mutation($file: Upload!) {
    upload(file: $file) {
      name
      id
      url
    }
  }
`;
const useStyles = makeStyles(theme => ({
  container: {
    padding: "20px",
    margin: "20px 0"
  },
  questionBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "baseline"
  },
  questionHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
  },
  questionInput: {
    margin: "20px 0",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  answerView: {
    background: "#eee",
    padding: "10px",
    margin: "20px 0",
    width: "100%"
  }
}));
export default function Assignement({ data, refetch }) {
  const [file, setFile] = useState();
  const [uploadedFile, setUploadedFile] = useState();
  // Confirmation Dialog Logic
  const [confirmationState, reverseConfirmationState] = useReducer(
    state => !state,
    false
  );
  const [uploadFile, { loading }] = useMutation(UPLOAD, {
    onError: error => {
      console.log("error in uploading", error);
    },
    onCompleted: res => {
      setUploadedFile(res);
    }
  });
  const classes = useStyles();
  const [editStudentWorkMutation, { loading: editLoading }] = useMutation(
    UPDATE_STUDENTWORK,
    {
      onError: error => {
        console.log("error in edit student work", error);
      },
      onCompleted: response => {
        console.log("Mutation update student work", response);
        refetch();
      }
    }
  );
  const edtiStudentWork = () => {
    editStudentWorkMutation({
      variables: {
        id: data[0].id,
        data: {
          status: "submitted",
          file: uploadedFile?.upload.url
        }
      }
    });
  };
  return (
    <>
      <Paper
        className={classes.container}
        rounded="true"
        type="outlined"
        elevation={12}
      >
        <Box className={classes.questionBox}>
          <Box className={classes.questionHeader}>
            <Typography variant="body1">{l1.yourAnswer}</Typography>
            <Typography variant="body1" style={{ color: "green" }}>
              {data[0]?.status !== "assigned" ? l1.handedIn : l1.assigned}
            </Typography>
          </Box>
          {data[0]?.status !== "assigned" ? (
            <Box className={classes.answerView}>
              <Typography variant="body1">{data?.[0]?.submission}</Typography>
              <Link href={`${process.env.REACT_APP_STRAPI_URL}${data[0]?.file}`}>
                {l1.downloadTheFile}
              </Link>
            </Box>
          ) : (
            <div style={{ width: "100%" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (file)
                    uploadFile({
                      variables: {
                        file
                      }
                    });
                }}
                startIcon={loading ? <CircularProgress /> : <CgAttachment />}
                type="upload"
                className={classes.questionInput}
              >
                <input
                  accept="image/*,.pdf,text/plain,.docx"
                  type="file"
                  onChange={e => setFile(e.target.files[0])}
                />
                {l1.add}
              </Button>
              <Button
                disabled={!Boolean(uploadedFile)}
                color="primary"
                variant="contained"
                onClick={reverseConfirmationState}
              >
                {l1.handIn}
              </Button>
            </div>
          )}
        </Box>
      </Paper>
      <Dialog open={confirmationState} onClose={reverseConfirmationState}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Typography style={{ fontWeight: "bold" }}>
            {l1.answerConfirmation}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={edtiStudentWork}
            startIcon={editLoading ? <CircularProgress /> : null}
            disabled={editLoading}
          >
            {l1.confirm}
          </Button>
          <Button
            variant="contained"
            onClick={reverseConfirmationState}
            disabled={editLoading}
          >
            {l1.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
