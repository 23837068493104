import React, { useState, useRef, useContext } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, makeStyles } from '@material-ui/core';
import { l1 } from '../../local.json';
import { useMutation, gql } from '@apollo/client';
import Loading from '../../common/Loading';
import { Navigation } from '../../../Context';
const useStyles = makeStyles((theme) => ({}));

function Create({ state, reverse, refetch, alert, failure }) {
  const ADD_CLASS = gql`
    mutation CreateClass($name: String!, $section: String!, $subject: String!, $slug: String!, $id: ID!) {
      createClass(input: { data: { name: $name, section: $section, subject: $subject, slug: $slug, center: $id } }) {
        class {
          name
          id
          section
        }
      }
    }
  `;

  const classes = useStyles();
  const [name, setName] = useState('');
  const nameRef = useRef();
  const [section, setSection] = useState('');
  const sectionRef = useRef();
  const [subject, setSubject] = useState('');
  const subjectRef = useRef();

  const [slug, setSlug] = useState('');
  const slugRef = useRef();
  const { centerId } = useContext(Navigation);

  const [createClass, { loading }] = useMutation(ADD_CLASS, {
    onError: () => failure(l1.failure),
    onCompleted: () => {
      refetch();
      alert(l1.createsuccess);
    },
  });
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Dialog className={classes.CreateClassContainer} open={state} onClose={reverse}>
            <DialogTitle>{l1.createClass}</DialogTitle>
            <DialogContent className={classes.contentContainer}>
              <TextField
                className={classes.textField}
                fullWidth
                label={l1.name}
                required
                inputRef={nameRef}
                onChange={(e) => {
                  setName(nameRef.current.value);
                }}
              />
              <TextField
                className={classes.textField}
                inputRef={slugRef}
                fullWidth
                label={l1.slug}
                required
                onChange={(e) => {
                  setSlug(slugRef.current.value);
                }}
              />
              <TextField
                className={classes.textField}
                fullWidth
                inputRef={sectionRef}
                label={l1.section}
                onChange={(e) => {
                  setSection(sectionRef.current.value);
                }}
              />
              <TextField
                className={classes.textField}
                inputRef={subjectRef}
                fullWidth
                label={l1.subject}
                onChange={(e) => {
                  setSubject(subjectRef.current.value);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  createClass({
                    variables: {
                      name,
                      section,
                      subject,
                      slug,
                      id: centerId,
                    },
                  });

                  reverse();
                }}
              >
                {l1.create}
              </Button>
              <Button variant="contained" onClick={reverse}>
                {l1.cancel}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}

export default Create;
