import React from "react";
// import { Row } from "antd";
import "./Styling.scss";
// import bg1 from './assets/img/bg1.png';
import { useHistory } from 'react-router-dom';
export default function ActivityCard({
  title = "",
  cover = "",
  description = "",
  url = ""
}) {
  const history = useHistory();
  return (
    <article className={"card-course-item activity"}>
      <div
        className="card-course-cover"
        style={{
          backgroundImage: `url(${cover})`
        }}
      ></div>
      <div className="card-course-content">
        <a
          onClick={() => {
            history.push(url);
          }}
          style={{ textDecoration: "none", cursor: "pointer" }}
        >
          <h3 className="title-card-course"> {title}</h3>
        </a>
        <p className="card-course-description"> {description}</p>
      </div>
      <a
        onClick={() => {
          history.push(url);
        }}
        className="card-course-footer act"
      >
        المزيد ...
      </a>
    </article>
  );
}
