import React, { useState, createRef, useContext } from "react";
import { Row, Col } from "antd";
import Carousel from "react-elastic-carousel";
import SmsFailedIcon from "@material-ui/icons/SmsFailed";
import {
    Facebook,
    Instagram,
    YouTube,
    LinkedIn,
    Twitter
} from "@material-ui/icons/";
import ActivityCard from "./rel/Landing/ActivityCard";
import Gallery from "react-grid-gallery";
import ListMembers from "./rel/Landing/ListMembers";
import { gql, useQuery } from "@apollo/client";
import Loading from "./common/Loading";
import { useHistory } from 'react-router-dom';
import { Navigation } from "../Context";
import logo from "./rel/Landing/assets/img/center.default.jpg";
import bg from "./rel/Landing/assets/img/image-slider.png";
import defaultImg from "./rel/Landing/assets/img/default.png";
import {
    Typography,
    Container,
    Button,
    Modal,
    Box,
    Link,
    Chip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { RequireAuthentication, RequireCenterAdmin, RequireCenterMembership } from "../Auth/gates";

const theme = createTheme({
    palette: {
        primary: {
            main: "#002366"
        },
        secondary: {
            main: "#b23434"
        }
    },
    typography: {
        fontFamily: ['"Open Sans"', "Cairo", "Roboto"].join(","),
        h1: {
            fontSize: "40px"
        }
    }
});
const useStyles = makeStyles(theme => ({
    title: {
        fontSize: "50px",
        padding: theme.spacing(8, 0, 6)
    },
    buttonPrimary: {
        color: theme.primary
    },
    buttonSecondary: {
        color: theme.secondary
    },
    login: {
        padding: theme.spacing(2, 4, 2),
        fontSize: "18px;",
        color: theme.primary
    },
    modalBox: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        backgroundColor: "white",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4
    }
}));
const DefaultImages = [
    {
        src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
        thumbnail:
            "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        caption: "عنوان الصورة"
    },
    {
        src: "https://c6.staticflickr.com/9/8890/28897154101_a8f55be225_b.jpg",
        thumbnail:
            "https://c6.staticflickr.com/9/8890/28897154101_a8f55be225_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 183,
        caption: "عنوان الصورة"
    },
    {
        src: "https://c7.staticflickr.com/9/8106/28941228886_86d1450016_b.jpg",
        thumbnail:
            "https://c7.staticflickr.com/9/8106/28941228886_86d1450016_n.jpg",
        thumbnailWidth: 271,
        thumbnailHeight: 320,
        caption: "عنوان الصورة"
    },
    {
        src: "https://c6.staticflickr.com/9/8342/28897193381_800db6419e_b.jpg",
        thumbnail:
            "https://c6.staticflickr.com/9/8342/28897193381_800db6419e_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 213,
        caption: "عنوان الصورة"
    },
    {
        src: "https://c8.staticflickr.com/9/8104/28973555735_ae7c208970_b.jpg",
        thumbnail:
            "https://c8.staticflickr.com/9/8104/28973555735_ae7c208970_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 213,
        caption: "عنوان الصورة"
    },
    {
        src: "https://c1.staticflickr.com/9/8707/28868704912_cba5c6600e_b.jpg",
        thumbnail:
            "https://c1.staticflickr.com/9/8707/28868704912_cba5c6600e_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 213,
        caption: "عنوان الصورة"
    },
    {
        src: "https://c4.staticflickr.com/9/8578/28357117603_97a8233cf5_b.jpg",
        thumbnail:
            "https://c4.staticflickr.com/9/8578/28357117603_97a8233cf5_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 213,
        caption: "عنوان الصورة"
    },
    {
        src: "https://c1.staticflickr.com/9/8056/28354485944_148d6a5fc1_b.jpg",
        thumbnail:
            "https://c1.staticflickr.com/9/8056/28354485944_148d6a5fc1_n.jpg",
        thumbnailWidth: 257,
        thumbnailHeight: 320,
        caption: "عنوان الصورة"
    }
];

const GET_INTERFACE = gql`
  query GetInterface($subdomain: String!) {
    centersLists(where: { subdomain: $subdomain }) {
      events {
        id
        title
        excerpt
        image {
          url
        }
      }
      info {
        openToRegistration
        logo {
          url
        }
        name
        vision
        message
        values {
          name
          content
        }
        gallery {
          name
          caption
          width
          height
          url
        }
        video
        description
        alertSignup
        contact {
          address
          email
          phoneNumbers {
            id
            number
          }
        }
        socialMediaLinks {
          facebook
          twitter
          linkedin
          instagram
          youtube
        }
        teamMember {
          picture {
            url
          }
          fullName
          role
          picture {
            id
          }
          facebook
          instagram
          twitter
        }
      }
    }
  }
`; export default function Landing() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const classes = useStyles(theme);
    const { sub } = useContext(Navigation);
    const history = useHistory();
    const { loading, data } = useQuery(GET_INTERFACE, {
        onError: err => console.log("err get interface", err),
        variables: {
            subdomain: sub
        },
        onCompleted: () => {
            console.log("loading completed");
        }
    });
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 4 }
    ];


    const carouselActivities = createRef();
    const [carouselIndex, setcarouselIndex] = useState(0);
    const center = data?.centersLists[0]?.info;
    const events = data?.centersLists[0]?.events;
    const centerLogo = center?.logo
        ? `url(${process.env.REACT_APP_STRAPI_URL}${center?.logo?.url})`
        : `url(${logo})`;
    const IMAGES = (center?.gallery || DefaultImages).map(image => {
        if (image.url)
            return {
                src: `${process.env.REACT_APP_STRAPI_URL}${image.url}`,
                thumbnail: `${process.env.REACT_APP_STRAPI_URL}${image.url}`,
                width: 'auto',
            };

        return {
            src: image.src,
            thumbnail: image.thumbnail,
            width: 'auto',
        }
    });
    if (!loading)
        return (
            <div>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <main className="center-main">
                        <div
                            className="center-header"
                            style={{
                                backgroundImage: `linear-gradient(transparent 58%, white 100%),
     url(${bg})`
                            }}
                        ></div>
                        <div className="center-content">
                            <Container>
                                <div
                                    className="logo-center"
                                    style={{ backgroundImage: centerLogo }}
                                ></div>
                                <Typography variant="h2" className={classes.title}>
                                    {center?.name}
                                </Typography>

                                <hr />

                                <div className="left">
                                    <RequireAuthentication
                                        rejectBehaviour="message"
                                        message={
                                            <>
                                                {" "}
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    className={classes.login}
                                                    size="large"
                                                    onClick={() =>
                                                        (window.location.href = `${process.env.REACT_APP_ACCOUNTS_URL}/?center&redirect_to=${window.location.href}`)
                                                    }
                                                // style={{ color: "white", cursor: "pointer" }}
                                                >
                                                    دخول
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    className={classes.login}
                                                    onClick={() =>
                                                        (window.location.href = `${process.env.REACT_APP_ACCOUNTS_URL}/register/?center&redirect_to=${window.location.href}`)
                                                    }
                                                // style={{ color: "white", cursor: "pointer" }}
                                                >
                                                    انضم إلينا
                                                </Button>{" "}
                                            </>
                                        }
                                    >
                                        <RequireCenterMembership status="ACTIVE" rejectBehaviour="hide" >
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => history.push("/home")}
                                                className={classes.login}
                                            >
                                                الالتحاق بالصفوف
                                            </Button>
                                        </RequireCenterMembership>
                                        <RequireCenterMembership status="REQUESTED" rejectBehaviour="hide" >
                                            <Chip
                                                color="primary"
                                                variant="outlined"
                                                size="medium"
                                                label="طلب انضمامك قيد المراجعة"
                                                style={{ fontSize: 20 }}
                                            />
                                        </RequireCenterMembership>
                                        <RequireCenterMembership status="BANNED" rejectBehaviour="hide" >
                                            <Chip
                                                color="secondary"
                                                variant="outlined"
                                                size="medium"
                                                label="التسجيلات مغلقة"
                                                style={{ fontSize: 20 }}
                                            />
                                        </RequireCenterMembership>
                                        <RequireCenterMembership status="GUEST" rejectBehaviour="hide" >
                                            {
                                                center.openToRegistration
                                                    ? <Button
                                                        color="primary"
                                                        variant="contained"
                                                        className={classes.login}
                                                        onClick={() => {
                                                            history.push('/request-membership')
                                                        }}
                                                    >
                                                        الالتحاق بالصفوف
                                                    </Button>
                                                    : <Chip
                                                        color="secondary"
                                                        variant="outlined"
                                                        size="medium"
                                                        label="التسجيلات مغلقة"
                                                        style={{ fontSize: 20 }}
                                                    />
                                            }
                                        </RequireCenterMembership>
                                    </RequireAuthentication>
                                </div>
                            </Container>
                            <Row>
                                <Col span={12}>
                                    <Typography variant="h1" className="big-title right u sm">
                                        الرؤية
                                    </Typography>
                                    <Typography variant="body1" className="description">
                                        {center?.vision}
                                    </Typography>
                                    {/* <p className="description">{center.vision}</p> */}
                                </Col>
                                <Col span={12}>
                                    <Typography variant="h1" className="big-title right u sm">
                                        الرسالة
                                    </Typography>
                                    <Typography variant="body1" className="description">
                                        {center?.message}
                                    </Typography>
                                </Col>

                                <Col span={24} style={{ marginTop: "3em" }}>
                                    <Typography variant="h1" className="big-title right u sm">
                                        القيم
                                    </Typography>
                                    {center?.values.map(({ name, content }, index) => (
                                        <p className="description" key={index}>
                                            <b>{name} :</b>
                                            {content}
                                        </p>
                                    ))}
                                </Col>
                            </Row>
                            <Row>
                                <div className="card-gray" xs={12} sm={6} md={4}>
                                    <div>
                                        <div
                                            className="logo-center"
                                            style={{ backgroundImage: centerLogo }}
                                        ></div>
                                    </div>
                                    <div
                                        className="description"
                                        style={{ fontFamily: theme.fontFamily }}
                                    >
                                        {center?.description}
                                    </div>
                                </div>
                            </Row>

                            <div
                                className="video-container"
                                style={{ backgroundImage: `url(${defaultImg})` }}
                            >
                                {
                                    !open
                                        ? <Link component="button" onClick={handleOpen} underline="none">
                                            <svg
                                                className="bi bi-play-fill play"
                                                width="3em"
                                                height="3em"
                                                viewBox="0 0 16 16"
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                                            </svg>
                                        </Link>
                                        : <iframe
                                            width="100%"
                                            height="100%"
                                            src={youtubeEmbedUrl(center?.video)}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                }
                            </div>

                            {
                                center.openToRegistration &&
                                <div className="card-blue">
                                    <div className="title">
                                        <SmsFailedIcon /> تم فتح باب التسجيل{" "}
                                    </div>
                                    <div className="description">{center?.alertSignup}</div>
                                    <div className="left">
                                        <Button variant="outlined" className={classes.Button}>
                                            <Typography>إشترك</Typography>
                                        </Button>
                                    </div>
                                </div>
                            }

                            <div className="section-head">
                                <Typography variant="h1" className="big-title right">
                                    أحدث النشاطات
                                </Typography>
                                <hr className="red" />
                            </div>

                            <div className="nav-arrows">
                                <svg
                                    onClick={() => {
                                        if (carouselIndex.courses + 1 > 4) {
                                            carouselActivities.current.goTo(0);
                                            setcarouselIndex({ ...carouselIndex, courses: 1 });
                                        } else {
                                            carouselActivities.current.slideNext();
                                            setcarouselIndex({
                                                ...carouselIndex,
                                                courses: carouselIndex.courses + 1
                                            });
                                        }
                                    }}
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 16 16"
                                    className="bi bi-chevron-right arrow"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                    />
                                </svg>
                                <svg
                                    onClick={() => {
                                        if (carouselIndex.courses - 1 < 1) {
                                            carouselActivities.current.goTo(4);
                                            setcarouselIndex({ ...carouselIndex, courses: 4 });
                                        } else {
                                            carouselActivities.current.slidePrev();
                                            setcarouselIndex({
                                                ...carouselIndex,
                                                courses: carouselIndex.courses - 1
                                            });
                                        }
                                    }}
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 16 16"
                                    className="bi bi-chevron-left arrow"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                                    />
                                </svg>
                            </div>
                            <Carousel
                                ref={carouselActivities}
                                className="list-articles"
                                pagination={false}
                                // itemsToShow={4}
                                breakPoints={breakPoints}
                                renderArrow={null}
                                showArrows={false}
                                xs={12}
                                sm={6}
                                md={4}
                            >
                                {events &&
                                    events.map((centerEvent, index) => (
                                        <ActivityCard
                                            key={index}
                                            title={centerEvent.title}
                                            cover={`${process.env.REACT_APP_STRAPI_URL}${centerEvent?.image?.url}`}
                                            description={centerEvent.excerpt}
                                            url={"/event/" + centerEvent.id}
                                        />
                                    ))}
                            </Carousel>
                            <button
                                onClick={() => {
                                    history.push("/events");
                                }}
                                className="btn-more no-border no-deplacing"
                                style={{ cursor: "pointer" }}
                            >
                                المزيد من النشاطات
                            </button>

                            <section>
                                <div className="section-head">
                                    <h1 className="big-title right">معرض الصور</h1>
                                    <hr className="red" />
                                </div>
                                <div className="galery-center">
                                    <Gallery
                                        images={IMAGES}
                                        enableLightbox={true}
                                        enableImageSelection={false}
                                        backdropClosesModal={true}
                                        showLightboxThumbnails={true}
                                    />
                                </div>
                            </section>
                            <section className="space-bottom">
                                <div className="section-head">
                                    <h1 className="big-title right"> الفريق</h1>
                                    <hr className="red" />
                                </div>
                                <ListMembers team={center?.teamMember} />
                            </section>

                            <section className="space-bottom space-top">
                                <div className="section-head">
                                    <h1 className="big-title right"> معلومات الإتصال</h1>
                                    <hr className="red" />
                                </div>

                                <Row>
                                    <Col xs={24} sm={15} md={15} lg={15} xl={15}>
                                        <h1 className="big-title right"> العنوان </h1>
                                        <p className="description">{center?.contact?.address}</p>
                                        <h1 className="big-title right"> الايميل </h1>
                                        <p className="description">{center?.contact?.email}</p>

                                        <h1 className="big-title right"> أرقام الهواتف </h1>
                                        {center?.contact?.phoneNumbers?.map(({ id, number }) => {
                                            return (
                                                <p className="description" key={id}>
                                                    {number}
                                                </p>
                                            );
                                        })}
                                    </Col>
                                    {
                                        Object.values(center?.socialMediaLinks).some(Boolean) &&
                                        <Col xs={24} sm={9} md={9} lg={9} xl={9}>
                                            <h1 className="big-title right"> مواقع التواصل </h1>
                                            <div className="social-icons">
                                                {center?.socialMediaLinks?.youtube &&
                                                    <Link
                                                        href={center?.socialMediaLinks?.youtube}
                                                        target="_blank"
                                                        rel="noopener"
                                                    >
                                                        <YouTube style={{ fontSize: 80, color: "red" }} />
                                                    </Link>
                                                }
                                                {center?.socialMediaLinks?.twitter &&
                                                    <Link
                                                        href={center?.socialMediaLinks?.twitter}
                                                        target="_blank"
                                                        rel="noopener"
                                                    >
                                                        <Twitter style={{ fontSize: 80, color: "#50abf1" }} />
                                                    </Link>
                                                }
                                                {center?.socialMediaLinks?.linkedin &&
                                                    <Link
                                                        href={center?.socialMediaLinks?.linkedin}
                                                        target="_blank"
                                                        rel="noopener"
                                                    >
                                                        <LinkedIn style={{ fontSize: 80, color: "#0084b1" }} />
                                                    </Link>
                                                }
                                                {center?.socialMediaLinks?.instagram &&
                                                    <Link
                                                        href={center?.socialMediaLinks?.instagram}
                                                        target="_blank"
                                                        rel="noopener"
                                                    >
                                                        <Instagram style={{ fontSize: 80, color: "#c536a4" }} />
                                                    </Link>
                                                }
                                                {center?.socialMediaLinks?.facebook &&
                                                    <Link
                                                        href={center?.socialMediaLinks?.facebook}
                                                        target="_blank"
                                                        rel="noopener"
                                                    >
                                                        <Facebook style={{ fontSize: 80, color: "#3a559f" }} />
                                                    </Link>
                                                }
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </section>
                        </div>
                    </main >
                </ThemeProvider >
            </div >
        );
    return <Loading />;
}

function youtubeEmbedUrl(input) {
    function parseYoutubeVideoID(input) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = input.match(regExp);
        return (match && match[7].length == 11) ? match[7] : input;
    }
    return `https://www.youtube.com/embed/${parseYoutubeVideoID(input)}`
}