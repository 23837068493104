import { Box, Typography } from "@material-ui/core";
import lineSeparatedValues from "../../../helpers/lineSparatedValues";
import TextField from "./TextField";

export default function TextListField(props) {
  const { edit, label, value } = props;

  if (!edit) {
    const list = lineSeparatedValues(value);
    return (
      <Box style={{ display: "flex", alignItems: "top", gap: 16 }}>
        {label && <Typography color="primary">{label}:</Typography>}
        {list.length > 0 ? (
          <ul style={{ marginTop: 0 }}>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          <Typography>(غير محدد)</Typography>
        )}
      </Box>
    );
  }
  return (
    <TextField
      {...props}
      multiline
      label={"أدخل كل قيمة في سطر منفصل"}
      minRows={3}
    />
  );
}
