import React, { useContext, useReducer, useState } from 'react';
import { Button, Avatar, Box, Grid, makeStyles, Typography } from '@material-ui/core';
import TopicSection from './components/TopicSection';
import { l1 } from '../../local.json';
import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import Loading from '../../common/Loading';
import TopBar from '../../common/TopBar';
import Search from '../../../components/common/Search';
import { Navigation } from '../../../Context';
import { RequireClassAccess } from '../../../Auth/gates';

const QUERY_PEOPLE = gql`
  query QueryClasswork($slug: String!) {
    classes(where: { slug: $slug }) {
      id
      name
      section
      teachers {
        fullname
        id
        avatar
      }
      students {
        fullname
        id
        avatar
      }
    }
  }
`;

const UPDATE_PEOPLE = gql`
  mutation UpdateInterface($data: editClassInput!, $id: ID!) {
    updateClass(input: { data: $data, where: { id: $id } }) {
      class {
        teachers {
          fullname
          id
          avatar
        }
        students {
          fullname
          id
          avatar
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  personContainer: {
    margin: theme.spacing(1),
  },
  nameContainer: {
    gap: theme.spacing(2),
    '$>*': {},
  },
  avatar: {
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
  name: {
    fontWeight: 600,
  },
  optionContainer: {
    marginRight: theme.spacing(4),
  },
}));
function People() {
  const { centerId } = useContext(Navigation);
  const [updatePeople] = useMutation(UPDATE_PEOPLE, {
    onCompleted: (data) => {
      refetch();
    },
  });
  const { slug } = useParams();
  // Apollo
  const [ready, reverseReady] = useReducer((s) => !s, false);
  const { loading, data, refetch } = useQuery(QUERY_PEOPLE, {
    variables: { slug },
    notifyOnNetworkStatusChange: true,
  });

  const [studentMode, setStudentMode] = useState(false);

  const classes = useStyles();
  if (!loading) {
    const { teachers, students, id } = data.classes[0];
    return (
      <>
        <TopBar type="people" />
        <>
          <Grid container>
            <Grid item md={3}></Grid>
            <Grid item xs={12} md={6}>
              <TopicSection
                people
                trigger={reverseReady}
                setStudentMode={setStudentMode}
                studentMode={studentMode}
                text={l1.teachers}
                cid={id}
                teacher
              >
                {teachers.map((i) => (
                  <Box
                    className={classes.personContainer}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    key={i.id}
                  >
                    <Box
                      className={classes.nameContainer}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {console.log('avatar', i?.avatar)}
                      <Avatar
                        src={`${process.env.REACT_APP_STRAPI_URL}${i?.avatar}`}
                        className={classes.avatar}
                      ></Avatar>
                      <Typography className={classes.name}>{i.fullname}</Typography>
                    </Box>
                    <RequireClassAccess only="teacher">
                      <Box className={classes.optionContainer}>
                        <Button
                          size="small"
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            const filter = teachers.filter((item) => item.id !== i.id);
                            const arr = [];
                            filter.forEach((item) => arr.push(item.id));
                            updatePeople({
                              variables: {
                                data: {
                                  teachers: arr,
                                },
                                id: data?.classes[0]?.id,
                              },
                            });
                          }}
                        >
                          {l1.delete}
                        </Button>
                      </Box>
                    </RequireClassAccess>
                  </Box>
                ))}
              </TopicSection>
              <TopicSection
                people
                setStudentMode={setStudentMode}
                studentMode={studentMode}
                trigger={reverseReady}
                text={l1.students}
                cid={data.classes[0].id}
                student
              >
                {students.map((i) => (
                  <Box
                    className={classes.personContainer}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    key={i.id}
                  >
                    <Box className={classes.nameContainer} display="flex" alignItems="center">
                      <Avatar
                        src={`${process.env.REACT_APP_STRAPI_URL}${i?.avatar}`}
                        className={classes.avatar}
                      ></Avatar>
                      <Typography className={classes.name}>{i.fullname}</Typography>
                    </Box>
                    <RequireClassAccess only="teacher">
                      <Box className={classes.optionContainer}>
                        <Button
                          size="small"
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            const filter = students.filter((item) => item.id !== i.id);
                            const arr = [];
                            filter.forEach((item) => arr.push(item.id));
                            updatePeople({
                              variables: {
                                data: {
                                  students: arr,
                                },
                                id: data?.classes[0]?.id,
                              },
                            });
                          }}
                        >
                          {l1.delete}
                        </Button>
                      </Box>
                    </RequireClassAccess>
                  </Box>
                ))}
              </TopicSection>
            </Grid>
            <Grid item sm={3}></Grid>
          </Grid>
          {ready ? (
            <Search
              centerId={centerId}
              classID={data?.classes[0].id}
              students={students}
              teachers={teachers}
              ready={ready}
              reverseReady={reverseReady}
              refetch={refetch}
              studentsMode={studentMode}
            />
          ) : (
            ''
          )}
        </>
      </>
    );
  }

  if (loading) return <Loading />;
}

export default People;
