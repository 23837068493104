import React from 'react'
import {useState, useReducer, useEffect} from 'react'
import {
  makeStyles,
  Box,
  Paper,
  Popper,
  TextField,
  RadioGroup,
  Radio,
  Checkbox,
  FormControlLabel,
  Button,
  IconButton,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Typography,
  Divider,
  Grid,
} from '@material-ui/core'
import {similarityScore} from './common/utils'

import {FaPlus, FaMinus, FaTimes, FaTrashAlt, FaCheck} from  'react-icons/fa';
import {IoText} from 'react-icons/io5';
import {TiSortNumerically} from 'react-icons/ti';
import {BsCheck2Circle, BsCheck2Square} from 'react-icons/bs';

import { l1 } from './local.json';
function makeArrayOf(value, length) {
  var arr = [],
    i = length;
  while (i--) {
    arr[i] = value;
  }
  return arr;
}
const arabicIndex = 'أبجدهوزحطيكلمنسعفصقرشتثخذ';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
  },
  title: {
    fontSize: theme.spacing(5),
    fontWieght: 'bold',
    // color: theme.palette.primary.main,
  },
  description: {
    fontSize: theme.spacing(3),
    lineHeight: '1.5',
    color: '#3c3c3c',
  },
  descriptionContainer: {
    marginTop: theme.spacing(2),
  },
  divider: {
    flexGrow: 1,
    margin: theme.spacing(2, 0),
  },
  alert: {
    fontSize: theme.spacing(1.6),
    color: theme.palette.grey[600],
  },
  iconContainer: {
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(1.4),
    marginLeft: theme.spacing(-7),
  },
  menuItem: {
    paddingRight: theme.spacing(10),
  },
  addQuestionMenu: {
    zIndex: '1',
  },
  questionContainer: {
    marginTop: theme.spacing(2),
    border: 0,
    padding: theme.spacing(2),
    boxShadow: '0 3px 3px gray',
    borderRadius: 4,
    "& .MuiTextField-root":{
      background: "#fff",
      borderRadius: 4,
    },
    '& .MuiFormControlLabel-root .MuiTextField-root': {
      marginTop: 0,
    },
    QuestionText: {
      margin: '0 0 16px 0',
    },
  },
  sectionTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  correctAnswer: {
    color:"green",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  incorrectAnswer: {
    color:"red",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  }
}));

const questionTypes = {
  text: {label:l1.shortAnswer, icon: <IoText/>},
  number: {label:l1.numberInput, icon: <TiSortNumerically/>},
  radio: {label:l1.multipleChoice, icon: <BsCheck2Circle/> },
  checkboxes: {label: l1.checkboxes, icon: <BsCheck2Square/> },
}

function renderWidgetForType(type, id, props, answerUpdateCallback, validity, readOnly, correctAnswer, initialValue ){
  switch (type){
    case 'text':
      return <TextInputAnswer id={id} {...props} initialValue={initialValue} answerUpdateCallback={answerUpdateCallback} readOnly={readOnly} correctAnswer={correctAnswer} />
    case 'number':
      return <NumberInputAnswer id={id} {...props}  initialValue={initialValue} answerUpdateCallback={answerUpdateCallback} readOnly={readOnly} correctAnswer={correctAnswer} />
    case 'radio':
      return <RadioAnswer id={id} {...props} initialValue={initialValue} answerUpdateCallback={answerUpdateCallback} readOnly={readOnly} correctAnswer={correctAnswer} />
    case 'checkboxes':
      return <CheckboxesAnswer id={id} {...props} initialValue={initialValue} answerUpdateCallback={answerUpdateCallback} readOnly={readOnly} correctAnswer={correctAnswer} />
    default:
      return <p>No answer widget of type '{type}' exists</p>;
  }
}

export const QuizModes = {
  EDIT: 0,
  PREVIEW: 1,
  VIEW: 2,
  RESULTS: 3,
};

function EditQuiz({quiz}) {
  // Force update when called

  const c = useStyles();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const deleteQuestion = (index) => {
    delete quiz.correctAnswers[quiz.questions[index].id]
    quiz.questions.splice(index, 1)
  }

  return(
   <Box className={c.container}>
      <TextField inputProps={{className:c.title}} defaultValue={quiz.title} label={l1.quizTitle} placeholder={l1.enterQuizTitle} fullWidth required onChange={(event)=>{quiz.title = event.target.value; forceUpdate()}} error={!Boolean(quiz.title)} helperText={Boolean(quiz.title) ? '' : l1.quizTitleCannotBeEmpty } />
    <TextField inputProps={{className:c.description }} defaultValue={quiz.description} label={l1.quizDescription} placeholder={l1.enterShortDescription}  className={c.description} multiline fullWidth onChange={(event)=>{quiz.description = event.target.value}} />

    <Typography variant="h4" className={c.sectionTitle}>{l1.instructions}</Typography>
    <InstructionIterator itemCollection={quiz.instructions} newItemText={l1.newInstruction} forceUpdate={forceUpdate}/>
    
    <Divider variant="middle" className={c.divider}/>

    <Typography variant="h4" className={c.sectionTitle}>{l1.theQuestions}</Typography>
    <AddQuestionMenu quiz={quiz} forceUpdate={forceUpdate}/>
    <div>
      {quiz.questions.map(
        (question, index)=> <Question key={question.id} index={index} question={question} correctAnswers={quiz.correctAnswers} forceUpdate={forceUpdate} deleteQuestion={deleteQuestion} /> )
      }
    </div>
  </Box>
  )
}

function ViewQuiz({quiz, preview=false, readOnly=false, studentAnswers, submitAction=null }) {
  const c = useStyles()
  var answers = studentAnswers
  const answerUpdateCallback = (id, answer, isValid) => {
    answers[id] = JSON.parse(JSON.stringify(answer))
    validity[id] = isValid
  }
  const showAnswers = readOnly && quiz.correctAnswers
  const totalQuestions = Object.keys(quiz.questions).length
  const totalCorrect = showAnswers ? similarityScore(quiz.correctAnswers, studentAnswers): null

  var validity = {};
  console.log("view quiz answers", answers)
  return (
    <Box className={c.container}>
      <Typography variant="h1" className={c.title}>{quiz.title}</Typography>

    <Typography style={{whiteSpace:'pre'}} className={c.description}>{quiz.description}</Typography>

    { readOnly || (quiz.instructions && !quiz.instructions.join('').length === 0)
      ? null
      : <div>
        <Typography variant="h4" className={c.sectionTitle}>{l1.instructions}</Typography>
        <ul>
          {quiz.instructions.map((instruction, index) => (instruction.length > 0) ? <li key={index}>{instruction}</li> : null)}
        </ul>
      </div>
    }

    <Divider variant="middle" className={c.divider}/>
    <Typography variant="h4" className={c.sectionTitle}>{l1.theQuestions}</Typography>
    {
      showAnswers ? <Typography component="b">{l1.totalCorrectAnswers}: {totalCorrect} {l1.slash} {totalQuestions}</Typography> : null
    }

    <form name="quiz" method="POST" onSubmit={
      (event) => {
        console.log(submitAction)
        event.preventDefault()
        if(!preview && submitAction){
          submitAction(answers)
        }
        else{
          alert(JSON.stringify(answers))
        }
      }
    } >
      {quiz.questions.map((question, index)=>{
        let widget = renderWidgetForType(question.type, question.id, question.widgetProps, answerUpdateCallback, validity, readOnly, quiz?.correctAnswers?.[question.id], studentAnswers[question.id])
        return(
          <Box className={c.questionContainer} key={index}>
            <Typography variant='h5' className={c.questionText}> {index+1}. {question.text} </Typography>
            {question.helpText ? <p className={c.questionHelpText}>{question.helpText}</p> : null}
            {widget}
          </Box>
        )
      })}
      <br/><br/><br/>
      { !readOnly ? <Button type="submit" color='primary' variant="contained">{l1.submit}</Button> : null}
    </form>
    
  </Box>
  )
}

function TextInputAnswer({updateAnswer, id, placeholder=null, initialValue=null, answerUpdateCallback, readOnly=false, correctAnswer=null}){
  const c = useStyles()
  const [valid, setValid] = useState(true)

  const handleChange = (event) => {
    let answer = event.target.value;
    let validity = answer !== '';
    answerUpdateCallback(id, answer, validity);
    setValid(validity);
  };

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" gridGap={8}>
      <TextField label={l1.yourAnswer} InputLabelProps={{shrink: true}} placeholder={placeholder} defaultValue={initialValue || ""} required  onChange={handleChange} error={!valid} helperText={valid ? null : l1.emptyAnswersNotAccepted } inputProps={{disabled:readOnly}} />
      {
        correctAnswer
        ? (correctAnswer === initialValue
          ? <Typography className={c.correctAnswer} >
              <FaCheck /> {l1.answerCorrect}
            </Typography>
          : <Typography className={c.incorrectAnswer} >
              <FaTimes /> {l1.correctAnswer}: {correctAnswer}
            </Typography>)
        : null
      }
    </Box>
  )
}

function NumberInputAnswer({id, min=null, max=null, initialValue=null, answerUpdateCallback, readOnly=false, correctAnswer=null}){
  const c = useStyles()
  const [valid, setValid] = useState(true)

  const handleChange = (event) => {
    let answer =  event.target.value
    let validity = (
      (answer !== '')
      && (min === null || parseInt(answer) >= min)
      && (max === null || parseInt(answer) <= max)
    )
    answerUpdateCallback(id, parseInt(answer), validity)
    setValid(validity)
  }
  return <Box display="flex" justifyContent="flex-start" alignItems="center" gridGap={8}>
    <TextField type="number" label={l1.yourAnswer} InputLabelProps={{shrink: true}} inputProps={{min, max, disabled:readOnly}} defaultValue={initialValue || ""} required onChange={handleChange} />
    {
      correctAnswer
        ? (correctAnswer === initialValue
          ? <Typography className={c.correctAnswer} >
              <FaCheck /> {l1.answerCorrect}
            </Typography>
          : <Typography className={c.incorrectAnswer} >
              <FaTimes /> {l1.correctAnswer}: {correctAnswer}
            </Typography>)
        : null
    }
  </Box>
}

function RadioAnswer({id, choices, initialValue=null, answerUpdateCallback, readOnly=false, correctAnswer=null}){
  const c = useStyles()
  const [value, setValue] = React.useState(initialValue);
  const [valid, setValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const handleChange = (index) => {
    let answer = index;
    let validity = answer !== '';
    answerUpdateCallback(id, parseInt(answer), validity);
    setValue(index);
    setValid(validity);
  };
  return (
    <div style={{marginTop: 16}}>
      {
        correctAnswer && value !== correctAnswer
        ? <Typography className={c.incorrectAnswer} >
              <FaTimes /> {l1.answerIncorrect}
            </Typography>
        : null
      }
      <RadioGroup name={id} value={value} style={{borderBottom: touched && !valid ? 'solid 1px red': null}} >
        {choices.map((choice, index)=>
          <FormControlLabel key={index} control={
              <Radio name={id+'-'+index} value={index} disabled={readOnly}/*onFocus={()=>setTouched(true)}*/ onClick={readOnly ? null : ()=>{handleChange(index)}} color="primary"/>
          } label={
            <Box display="flex" style={{gap: 16}} >
            {arabicIndex[index] + '. ' + choice}
            {
                correctAnswer
                  ? (
                    correctAnswer === index
                    ? <Typography className={c.correctAnswer} >
                        <FaCheck /> {l1.correctAnswer}
                      </Typography>
                    : null
                  )
                  : null
            }
            </Box>}  />
            
          )} 
      </RadioGroup>
      {/* touched && !valid ? <Typography className={c.helperText}>{l1.pleaseChooseOneOption}</Typography> : null */}
    </div>
  );
}

function CheckboxesAnswer({id, choices, min=null, max=null, initialValue=null, answerUpdateCallback, correctAnswer=null, readOnly=false}){

  const c = useStyles();
  // const answer = makeArrayOf(0, choices.length)
  const [answer, updateAnswer] = useReducer(
    (answer, toggleIndex) => {
      let newAnswer = Array.from(answer)
      newAnswer [toggleIndex] = 1 - answer[toggleIndex];
      return newAnswer
    },
    initialValue || makeArrayOf(0, choices.length)
  )
  
  const [valid, setValid] = useState(false);
  const [touched, setTouched] = useState(false);
  const handleChange = (index) => {
  let newAnswer = [...answer]
  newAnswer[index] = 1 - newAnswer[index]
  let totalChecked = newAnswer.reduce((previous, current) => previous + current);
  let validity = (min === null || totalChecked >= min) && (max === null || totalChecked <= max);
  
  answerUpdateCallback(id, newAnswer, validity);
  updateAnswer(index)
  setValid(validity);
  };
  useEffect(()=>{
    console.log('answer', answer)
  })

  return (
    <div style={{marginTop: 16}}>
      {
        correctAnswer && answer !== correctAnswer
        ? <Typography className={c.incorrectAnswer} >
              <FaTimes /> {l1.answerIncorrect}
            </Typography>
        : null
      }
      <RadioGroup aria-label="checkboxes" style={{borderBottom: touched && !valid ? 'solid 1px red': null}} >
        {choices.map((choice, index)=>
          <FormControlLabel key={index} value={index} control={
            <Checkbox name={id+'-'+index} disabled={readOnly}/* onFocus={()=>setTouched(true)}*/ onClick={()=>{ handleChange(index)}} color="primary" checked={answer[index]}/>
        } label={
          <Box display="flex" style={{gap: 16}} >
            {arabicIndex[index] + '. ' + choice}
            {
                correctAnswer ? (
                    correctAnswer[index]
                    ? <Typography className={c.correctAnswer} >
                        <FaCheck /> {l1.answerCorrect}
                      </Typography>
                    : null
                  )
                  : null
            }
          </Box>}  />
        )}
      </RadioGroup>
    </div>
  );
}

export default function Quiz({mode, quiz, submitAction=null, updateAnswers, studentAnswers={}}){
  // console.log("student Answers : ", studentAnswers)
  switch(mode){
    case QuizModes.EDIT: return <EditQuiz quiz={quiz} />;
    case QuizModes.PREVIEW: return <ViewQuiz quiz={quiz} preview />;
    case QuizModes.VIEW: return <ViewQuiz quiz={quiz} studentAnswers={studentAnswers} updateAnswers={updateAnswers} submitAction={submitAction} />;
    case QuizModes.RESULTS: return <ViewQuiz quiz={quiz} readOnly studentAnswers={studentAnswers} />;
    default: return "Unsupported quiz mode";
  }
}

function Question({index, question, correctAnswers={}, forceUpdate, deleteQuestion}){
  const c = useStyles()
  const updateQuestion = (key, value) => {
    question[key] = value;
  };
  const setCorrectAnswer = (event) => {
    correctAnswers[question.id] = event.target.type === 'number' ? parseInt(event.target.value) : event.target.value;
  };
  const setWidgetProp = (propName, value) => {
    question.widgetProps = question.answerProps || {};
    question.widgetProps[propName] = value;
  };

  const [text, setText] = useState(question.text);

  return (
    <Box key={question.id} className={c.questionContainer}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>{questionTypes[question.type].icon}</Box>
        <Box>{questionTypes[question.type].label}</Box>
        <Box flex={1}></Box>
        <Box xs="auto">
          <IconButton
            style={{ fontSize: 20, padding: 4 }}
            onClick={() => {
              deleteQuestion(index);
              forceUpdate();
            }}
          >
            <FaTrashAlt />
          </IconButton>
        </Box>
      </Box>

      <div>
        <TextField
          label={l1.questionText}
          placeholder={l1.enterQuestionText}
          className={c.questionText}
          defaultValue={question.text}
          onChange={(event) => {
            updateQuestion('text', event.target.value);
            setText(event.target.value);
          }}
          error={!Boolean(text)}
          helperText={Boolean(text) ? '' : l1.questionTextCannotBeEmpty}
          fullWidth
        />
      </div>
      <TextField
        label={l1.questionHelpText}
        placeholder={l1.enterAdditionalHelpText}
        className={c.questionHelpText}
        defaultValue={question.helpText}
        onChange={(event) => {
          updateQuestion('helpText', event.target.value);
        }}
        fullWidth
      />

      <Divider variant="middle" className={c.divider} />

      {question.type === 'text' ? (
        <TextField
          label={l1.correctAnswer}
          defaultValue={correctAnswers[question.id]}
          placeholder={l1.enterCorrectAnswer}
          onChange={setCorrectAnswer}
        />
      ) : question.type === 'number' ? (
        <div>
          <TextField
            label={l1.correctAnswer}
            type="number"
            defaultValue={correctAnswers[question.id]}
            onChange={setCorrectAnswer}
            inputProps={{ min: question.min, max: question.max }}
          />
          <br />

          <Typography variant="h6">{l1.answerOptions}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <TextField
                label={l1.minValue}
                type="number"
                defaultValue={question.widgetProps.min}
                onChange={(event) => {
                  setWidgetProp('min', parseInt(event.target.value));
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label={l1.maxValue}
                type="number"
                defaultValue={question.widgetProps.max}
                onChange={(event) => {
                  setWidgetProp('max', parseInt(event.target.value));
                }}
              />
            </Grid>
          </Grid>
        </div>
      ) : question.type === 'radio' ? (
        <RadioOptionIterator
          id={question.id}
          itemCollection={question.widgetProps.choices}
          newItemText={l1.newOption}
          forceUpdate={forceUpdate}
          correctAnswers={correctAnswers}
        />
      ) : question.type === 'checkboxes' ? (
        <div>
          <CheckboxOptionIterator
            id={question.id}
            itemCollection={question.widgetProps.choices}
            newItemText={l1.newOption}
            forceUpdate={forceUpdate}
            correctAnswers={correctAnswers}
          />
          <div>
            <Typography variant="h6">{l1.requiredNumber}</Typography>

            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <TextField
                  label={l1.minNumber}
                  type="number"
                  inputProps={{ min: 0, max: question.widgetProps.choices.length }}
                  defaultValue={question.widgetProps.min}
                  onChange={(event) => {
                    setWidgetProp('min', parseInt(event.target.value));
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  label={l1.maxNumber}
                  type="number"
                  inputProps={{ min: 0, max: question.widgetProps.choices.length }}
                  defaultValue={question.widgetProps.max}
                  onChange={(event) => {
                    setWidgetProp('max', parseInt(event.target.value));
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
        </div>
      ) : null}
    </Box>
  );
}

function AddQuestionMenu({ quiz, forceUpdate }) {
  const c = useStyles();
  const addQuestion = (type) => {
    function getUniqueQuestionId() {
      let total = quiz.questions.length;
      let existingIds = quiz.questions.map((question) => question.id);
      let i = 1;
      while (true) if (!existingIds.includes('q' + (total + i))) return 'q' + (total + i);
    }
    let newId = getUniqueQuestionId();
    quiz.questions.push({
      id: getUniqueQuestionId(),
      type: type,
      text: l1.thequestion + ' ' + (quiz.questions.length + 1),
      helpText: '',
      widgetProps: ['radio', 'checkboxes'].includes(type) ? { choices: [l1.option1] } : {},
    });

    quiz.correctAnswers = quiz.correctAnswers || {};
    if (type === 'checkbox') {
      quiz.correctAnswers[newId] = [];
    }
    forceUpdate();
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we tr  ansitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <Button
        startIcon={<FaPlus />}
        color="primary"
        variant="contained"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {l1.addNewQuestion}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={c.addQuestionMenu}
      >
        {
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                {Object.entries(questionTypes).map((item) => {
                  const [key, type] = item;
                  return (
                    <MenuItem
                      key={key}
                      onClick={(event) => {
                        addQuestion(key);
                        handleClose(event);
                      }}
                    >
                      {type.icon}&nbsp; {type.label}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        }
      </Popper>
    </div>
  );
}

function InstructionIterator({ itemCollection = [], newItemText, forceUpdate }) {
  // const c = useStyles();
  const updateItem = (index, value) => {
    itemCollection[index] = value;
  };
  const addItem = () => {
    itemCollection.push(newItemText);
    forceUpdate();
  };
  const deleteItem = (index) => {
    itemCollection.splice(index, 1);
    forceUpdate();
  };
  return (
    <div>
      {itemCollection.map((item, index) => (
        <div key={index}>
          <TextField
            placeholder={l1.enterNewInstruction}
            defaultValue={item}
            onChange={(event) => {
              updateItem(index, event.target.value);
            }}
          />
          <IconButton onClick={() => deleteItem(index)}>
            <FaMinus />
          </IconButton>
        </div>
      ))}
      <div onClick={addItem}>
        <TextField defaultValue={newItemText} disabled />
        <IconButton color="primary">
          <FaPlus />
        </IconButton>
      </div>
    </div>
  );
}

function RadioOptionIterator({ id, itemCollection = [], newItemText, forceUpdate, correctAnswers }) {
  // const c = useStyles();
  const [choice, setChoice] = React.useState(correctAnswers[id] || 0);
  const setCorrectAnswer = (index) => {
    correctAnswers[id] = index;
  };
  const updateItem = (index, value) => {
    itemCollection[index] = value;
  };
  const addItem = () => {
    itemCollection.push(newItemText);
    forceUpdate();
  };
  const deleteItem = (index) => {
    itemCollection.splice(index, 1);
    forceUpdate();
  };

  return (
    <RadioGroup value={choice}>
      <Typography variant="h6">{l1.answerOptions}</Typography>
      <p>
        {l1.enterListOfAnswers} <br />
        {l1.toAddItems} <br />
        {l1.toDeleteItems} <br />
        {l1.specifyTheCorrectAnswer} <br />
      </p>

      {itemCollection.map((item, index) => (
        <div key={index}>
          <FormControlLabel
            control={
              <Radio
                value={index}
                onClick={() => {
                  setCorrectAnswer(index);
                  setChoice(index);
                }}
                color="primary"
              />
            }
            label={
              <TextField
                defaultValue={item}
                onChange={(event) => {
                  updateItem(index, event.target.value);
                }}
              />
            }
          />
          <IconButton onClick={() => deleteItem(index)}>
            <FaMinus />
          </IconButton>
        </div>
      ))}
      <div onClick={addItem}>
        <FormControlLabel control={<Radio disabled />} label={<TextField disabled defaultValue={newItemText} />} />
        <IconButton color="primary">
          <FaPlus />
        </IconButton>
      </div>
    </RadioGroup>
  );
}

function CheckboxOptionIterator({ id, itemCollection = [], newItemText, forceUpdate, correctAnswers }) {
  const [choice, setChoice] = React.useState(correctAnswers[id]);

  const updateCorrectAnswer = (index) => {
    correctAnswers[id][index] = 1 - correctAnswers[id][index];
  };
  const updateItem = (index, value) => {
    itemCollection[index] = value;
  };
  const addItem = () => {
    itemCollection.push(newItemText);
    correctAnswers[id].push(0);
    forceUpdate();
  };
  const deleteItem = (index) => {
    itemCollection.splice(index, 1);
    correctAnswers[id].splice(index, 1);
    forceUpdate();
  };

  correctAnswers[id] = correctAnswers[id] || makeArrayOf(0, itemCollection.length);

  return (
    <div>
      <RadioGroup value={choice}>
        <Typography variant="h6">{l1.answerOptions}</Typography>
        <p>
          {l1.enterListOfAnswers} <br />
          {l1.toAddItems} <br />
          {l1.toDeleteItems} <br />
          {l1.specifyTheCorrectAnswers} <br />
        </p>
        {itemCollection.map((item, index) => (
          <div key={index}>
            <FormControlLabel
              control={
                <Checkbox
                  value={index}
                  defaultChecked={correctAnswers[id][index]}
                  onClick={(event) => {
                    updateCorrectAnswer(index);
                    setChoice(index);
                  }}
                  color="primary"
                />
              }
              label={
                <TextField
                  defaultValue={item}
                  onChange={(event) => {
                    updateItem(index, event.target.value);
                  }}
                />
              }
            />
            <IconButton onClick={() => deleteItem(index)}>
              <FaMinus />
            </IconButton>
          </div>
        ))}
        <div onClick={addItem}>
          <FormControlLabel control={<Checkbox disabled />} label={<TextField disabled defaultValue={newItemText} />} />
          <IconButton color="primary">
            <FaPlus />
          </IconButton>
        </div>
      </RadioGroup>
    </div>
  );
}

