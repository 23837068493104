import Form from "./Form";

const MeetingSchedule = {
  slug: "meeting-schedule",
  name: "نموذج جدول الزيارات و اللقاءات",
  fields:{
    name:{
      label: 'عنوان الجدول',
      type: 'string',
    },
    schedule:{
      label: "الجدول",
      type:'table',
      columns: [
        {
          label: 'اليوم',
          type: 'date',
        },
        {
          label: 'الزيارات',
          type: 'string',
          multiline: true,
          minRows: 1,
        },
        {
          label: 'اللقاءات',
          type: 'string',
          multiline: true,
          minRows: 1,
        },
      ],
      // aggregate: false if none of the columns has an aggregate function
    },
  },
  layout:[
    'name',
    'schedule',
  ],
  views: {
    list: {
      title: "قائمة الجداول",
      requiredFields: ["name",  'createdAt'],
      columns: [
        {
          name: "العنوان",
          selector: ({ name }) => <span color="red">{name}</span>,
          searchable: true,
          sortable: true,
        },
        {
          name: "بتاريخ",
          selector: ({ createdAt }) => createdAt ,
          searchable: true,
          sortable: true,
        },
      ],
    },
    add: {
      title: "جدول جديد",
    },
    edit: {
      title: "تعديل الجدول",
    },
    view: {
      title: "جدول الزيارات و اللقاءات",
    },
  },
};

export default new Form(MeetingSchedule);
