const aggregations = {
    avg:(result, current, aggregatedCount) => (result*aggregatedCount + current) / (aggregatedCount + 1),
    list: (result, current) => ([ ...result, current]),
    listStrings: (result, current) => ([ ...result, ...(current?.trim()?.length ? [current] : [])]),
    count: (result, current) => ({
      ...result,
      [current]: (result?.[current] ?? 0) + 1
    }),
}

export const aggregationInitialValues = {
    avg: 0,
    list: [],
    listStrings: [],
}

export default function aggregate(evaluations = [], questions){
  const submittedEvaluations = evaluations.filter(Boolean);
  const aggregatedValues = Object.fromEntries(Object.entries(questions).map(([key, {aggregation}]) => ([key, aggregationInitialValues[aggregation] ])))
  console.log(evaluations, submittedEvaluations,  aggregatedValues);
  for(let aggregatedCount = 0; aggregatedCount < submittedEvaluations.length; aggregatedCount++){
    Object.entries(questions).forEach(([key, {aggregation}]) => {
      aggregatedValues[key] = aggregations[aggregation](aggregatedValues[key], evaluations[aggregatedCount]?.[key] ?? (key.type === 'number' ? 0 : ''), aggregatedCount);
    })
  }
  aggregatedValues.expected = evaluations.length;
  aggregatedValues.submitted = submittedEvaluations.length;
  console.log(aggregatedValues)
  return aggregatedValues;
}
  