
import * as Yup from "yup";
import camelCase from "../helpers/camelCase";
import sparseUpdate from "../helpers/sparseUpdate";
import FormLayout from "./components/FormLayout";
import FormList from "./components/FormList";
import FormListActions from "./components/FormListActions";
import FormPageLayout from "./components/FormPageLayout";
import { FormContext, FormContextProvider, FormDefinitionProvider } from "./components/context";
import CheckBoxField from "./components/fields/CheckBoxField";
import TextField from "./components/fields/TextField";
import UserField from "./components/fields/UserField";
import TableField from "./components/fields/TableField";

class Form {
  type = "form";
  static required = ["slug", "name"];
  static basePath = "/forms";
  static inputTypeMap = {
    string: "text",
    number: "number",
    date: "date",
  };
  static routeMap = {
    list: "",
    add: "add",
    edit: ":id/edit",
    view: ":id/view",
  };
  static validationRuleMap = {
    string: Yup.string(),
    select: Yup.string(),
    number: Yup.number().integer().min(0),
    date: Yup.date(),
    user: Yup.object().nullable(),
  };
  static fieldDefaultsByType = {
    number: {
      valueFor: {
        save: (value) => Number(value ?? 0)
      },
      inputProps: {
        min: 0,
      }
    },
    user: {
      valueFor: {
        save: (user) => user ? user.id : null,
      },
      Component: UserField,
    },
    timestamp: {
      valueFor:{
        edit: Boolean,
        save: (value) => value ? new Date() : null,
        view: (value) => value ? `تم بتاريخ ${new Date(value).toISOString()}` : null
      },
      emptyLabel: 'لم يتم بعد',
      Component: CheckBoxField,
    },
    table: {
      Component: TableField,
      emptyLabel: 'لم تدخل أي شيء بعد',
    }
    //Component: CheckBoxField,

  };
  static defaultFieldPermissions = {
    add: {
      show: true,
      write: true,
      autoWrite: false,
      required: false,
    },
    edit: {
      show: true,
      write: true,
      autoWrite: false,
      required: false,
    },
    view: {
      show: true,
      write: false,
    },
  }

  static fieldPermissionProfiles = {
    readOnly: {
      add: { write: false },
      edit: { write: false }
    },
    fillFirst: {
      add: { required: true },
      edit: { write: false },
    },
    fillLater: {
      add: { show: false, write: false},
      edit: { write: true},
    },
    fillOnce: {
      add: { write: false, writeOnce: true},
      edit: { write: true}
    },
    static: {
      add: {write: false},
      edit: {write: false}
    },
    autoFillFirst: {
      add: { required: true, write: false, autoWrite: true },
      edit: { write: false}
    },
    autoFillOnce: {
      add: { required: true, write: false, autoWriteOnce: true },
      edit: { write: false}
    }
  }

  slug = null;
  name = null;
  roles = ["owner", "assistant", "moderator"];
  enabledRoutes = ["list", "add", "edit", "view"];
  availableActions = {
    // callback format (item: ItemType, context: {user, ...}) => Boolean,
    add: () => true,
    edit: () => true,
    view: () => true, // includes showing the item in the listing view.
    delete: () => true,
  }
  postMutation = {
    created: ({ history }) => {
      console.log("item created!");
      history.push(this.routes.list.path);
    },
    updated: ({ history }) => {
      console.log("item updated!");
      history.push(this.routes.list.path);
    },
    deleted: () => {
      console.log("item deleted!");
    },
  };
  /** populate this with custom model fields, no default strapi fields, such as id, createdAt, updatedAt
   * follow a similar schema
   *
   * fields = {
   *   name: {
   *     type: 'string',
   *     label: 'الاسم',
   *     permissions: 'readonly',
   *     nullable: false;
   *   },
   *   start:{
   *     label: 'تاريخ البداية',
   *     type: 'date',
   *   },
   *   end: {
   *     label: 'تاريخ النهاية',
   *   },
   *   budget: {
   *     label: 'الميزانية ($)',
   *     type: 'number',
   *   }
   * }
   *
   * `permissions` can be one of
   * - static: data comes from a `data` attribute, and follows the exact type of the field
   * - read-write [default]: can be filled anytime.
   * - read-only: cannot be filled
   * - fillLater: fillable during creation, then readonly in modification.
   * - fillLater: fillLater: not visible during creation, then fillable in modification.
   *
   * if `nullable` is true:
   * - the field's default value is initially null-like (renders empty string or 0)
   * - when a field's value is null-like it's set to null on save
   *
   */

  fields = {
    id: {
      type: "text",
      label: "الرقم",
      permissions: 'readOnly',
    },
    createdAt: {
      type: "date",
      label: "تاريخ الإنشاء",
      permissions: 'readOnly',
    },
    updatedAt: {
      type: "date",
      label: "تاريخ أخر تحديث",
      permissions: 'readOnly',
    },
  };
  layout = [];
  views = {
    list: {
      title: "قائمة الاستمارات",
      /**
       * list of fields to fetch in order to display the rows
       * no need to add 'id' and 'createdAt',
       */
      fields: [],
      /**
       *
       */
      columns: ["id", "actions"],
      pageSize: 20,
    },
    add: {
      title: "إضافة عنصر جديد",
    },
    edit: {
      title: "استمارة التعديل",
    },
    view: {
      title: "مشاهدة الاستمارة",
    },
  };
  /** Constructor */
  constructor(props = {}) {
    this.constructor.required.forEach((requiredProp) => {
      if (!(requiredProp in props)) {
        throw new TypeError(
          `'${requiredProp}' is a required property to instantiate a Form class`
        );
      }
    });
    // Initialize item and collection names for GraphQL queries and mutations
    this.itemName = camelCase(props.slug);
    this.collectionName = this.itemName + "s";
    // populate object
    this.update(props);
    // Bind instance to component methods
    this.DefinitionProvider = this.DefinitionProvider.bind(this);
    this.ContextProvider = this.ContextProvider.bind(this);
    this.List = this.List.bind(this);
    this.Single = this.Single.bind(this);

    // Add enabled routes
    this.routes = Object.fromEntries(
      this.enabledRoutes.map((routeName) => [
        routeName,
        {
          path: [
            this.constructor.basePath,
            this.slug,
            this.constructor.routeMap[routeName],
          ].join("/"),
          Component:
            routeName === "list"
              ? this.List
              : () => this.Single({ mode: routeName }),
        },
      ])
    );

    // add actions column to data table
    this.views.list.columns = this.views.list.columns.concat([
      { name: "خيارات", cell: FormListActions },
    ]);

    // compile defined layouts to remove string field name references
    this.compileLayouts();
    this.compileValidationSchemas();
  }

  /** Sparse update of the object's attributes */
  update(props) {
    for (let prop in props) {
      if (
        typeof props[prop] === "object" &&
        !Array.isArray(props[prop]) &&
        props[prop] !== null
      ) {
        if (!this.hasOwnProperty(prop)) {
          this[prop] = {};
        }
        sparseUpdate(this[prop], props[prop]);
      } else {
        this[prop] = props[prop];
      }
    }
  }

  getField(name) {
    const definition = this.fields[name];
    if (!definition) {
      throw new TypeError(`No definition found for field '${name}'`);
    }
    
    if(definition.type === 'table'){
      for(let index in definition.columns){
        definition.columns[index] = sparseUpdate(
          this.buildField(`${name}[${index}]`,definition.columns[index]),
          { singleField: true } 
        );
      }
    }

    return this.buildField(name, definition);
  }

  buildField(name, definition){
    const fieldDefaults = {
      valueFor: {
        view: (value) => value,
        edit: (value) => value,
        save: (value) => value,
      },
      Component: TextField,
    };
    let field = sparseUpdate(
      { name, ...fieldDefaults },
      definition,
      this.constructor.fieldDefaultsByType?.[ definition.type] ?? {}
    );
    let permissions = JSON.parse(JSON.stringify(this.constructor.defaultFieldPermissions));
    field.permissions = sparseUpdate(permissions, this.constructor.fieldPermissionProfiles?.[definition.permissions] ?? {});  
    return field
  }

  DefinitionProvider({ children }) {
    return (
      <FormDefinitionProvider form={this}>{children}</FormDefinitionProvider>
    );
  }

  ContextProvider({ mode, children }) {
    return <FormContextProvider mode={mode}>{children}</FormContextProvider>;
  }

  List() {
    const { DefinitionProvider } = this;
    return (
      <DefinitionProvider>
        <FormPageLayout view="list">
          <FormList />
        </FormPageLayout>
      </DefinitionProvider>
    );
  }

  /** @type Single React.Component */
  Single({ mode }) {
    const { DefinitionProvider, ContextProvider} = this;
    return (
      <DefinitionProvider>
        <ContextProvider mode={mode}>
          <FormPageLayout view="single">
            <FormLayout />
            <FormContext.Consumer>
              {({ SubmitButton }) => {
                return SubmitButton && <SubmitButton />;
              }}
            </FormContext.Consumer>
          </FormPageLayout>
        </ContextProvider>
      </DefinitionProvider>
    );
  }

  getSingleQuerySelection(requestedFields) {
    const fields = Array.isArray(requestedFields) ? requestedFields : Object.keys(this.fields);
    let selection = [];
    fields.forEach((fieldName) => {
      const field = this.getField(fieldName);
      const { type, permissions, requiredFields, ...fieldProps } = field;

      // exclude the field if it's static content used in form but not fetched from db
      let subSelection = "";
      if (type === "user") {
        subSelection = "{ id fullname avatar }";
      } else if (type === 'select'){
        const {options, otherOptions} = fieldProps;
        if(otherOptions || options.some(({details})=> details)){
          subSelection = `${fieldName}__details`;
        }
      } else if (type === "calculated") {
        selection.push(this.getSingleQuerySelection(requiredFields ?? []));
        // prevent adding calculated field to selection
        return;
      }
      selection.push(fieldName)
      if(subSelection){
        selection.push(subSelection);
      };
    });
    return selection.join(" ");
  }

  /** Compile Yup validation schemas for add and edit forms */
  compileValidationSchemas() {
    ["add", "edit"].forEach((mode) => {
      //const schemaOverrides = this.views[mode]?.validationSchema ?? {};
      this.views[mode].validationSchema = this.getValidationSchema(mode);
      //sparseUpdate(this.views[mode].validationSchema, schemaOverrides);
    });
  }

  /** Get the default Yup form validation schema for a specific mode */
  getValidationSchema(mode, root = null) {
    let validationSchema = {};
    const layout = root ?? this.views[mode].layout;
    layout.forEach(
      ({
        name,
        type,
        permissions,
        children,
        singleField,
        validationRule,
        ...layoutProps
      }) => {
        // Ensure this field or section should be accessed
        if (!permissions?.[mode] || permissions[mode].write) {
          // recurse validation rule resolving with children for section
          if (type === "section") {
            validationSchema = {
              ...validationSchema,
              ...this.getValidationSchema(
                mode,
                singleField ? [singleField] : children ?? []
              ),
            };
          } else {
            let rule = validationRule ?? this.constructor.validationRuleMap?.[type];
            if (rule) {
              if(type === 'select' ){
                const {options, otherOption} = layoutProps;
                const valuesRequiringDetails = (otherOption ? ['other'] : []).concat(
                  options.map(({details}, index) => details ? String(index+1) : null).filter(Boolean)
                )
                if(valuesRequiringDetails.length > 0){
                    validationSchema[`${name}__details`] = this.constructor.validationRuleMap['string'].when(name, {
                      is: (value) => valuesRequiringDetails.includes(value),
                      then: (rule) => rule.required(),
                      otherwise: (rule) => rule.nullable(),
                  });
                    rule.oneOf((otherOption ? ['other'] : []).concat(
                    options.map((_, index) => String(index+1))
                  ))
                }
              }
              validationSchema[name] = permissions[mode].required ? rule.required() : rule.nullable();
            }
          }
        }
      }
    );
    return validationSchema;
  }

  /** Compile all layouts */
  compileLayouts() {
    this.compileLayout();
    ["add", "edit", "view"].forEach((mode) => {
      if (this.views[mode]?.layout) {
        this.compileLayout(this.views[mode].layout);
      } else {
        this.views[mode].layout = this.layout;
      }
    });
  }

  /** Compile layout specified in root.
   * otherwise compiles default layout
   */
  compileLayout(root = null) {
    root = root ?? this.layout;
    for (let i in root) {
      if (typeof root[i] === "string") {
        root[i] = this.getField(root[i]);
      } else if (typeof root[i]?.singleField === "string") {
        root[i].singleField = this.getField(root[i].singleField);
      } else if (root[i]?.children?.length) {
        this.compileLayout(root[i].children);
      }
    }
  }

  getInputType(fieldType) {
    return this.constructor.inputTypeMap?.[fieldType] ?? "text";
  }

  cleanedFormData(data, mode) {
    const cleanedData = {};
    for (let fieldName in data) {
      if(fieldName.endsWith('__details') && this.getField(fieldName.replace('__details', ''))?.type === 'select'){
        cleanedData[fieldName] = data[fieldName];
      } else {
        const field = this.getField(fieldName);
        if (['write', 'writeOnce', 'autoWrite', 'autoWriteOnce'].some((perm) => field.permissions[mode][perm])) {
          cleanedData[fieldName] = field.valueFor.save(data[fieldName]);
        }
      }
    }
    return cleanedData;
  }

  compiledValues(values, mode, context) {
    const compiledValues = {};
    for (let fieldName in this.fields) {
      const {
        name,
        type,
        permissions,
        value: staticValue,
        valueFor,
        requiredFields,
        calculate,
        ...fieldProps
      } = this.getField(fieldName);
      const fieldPermissions = permissions[mode];
      let value = staticValue;
      if(type !== 'calculated') {
        value = valueFor?.[
          (fieldPermissions.show && fieldPermissions.write)
          || fieldPermissions.autoWrite ? "edit" : "view"
        ](values[name], {values, ...context}) ?? value;
        if (type === "table" && !value){
          value = value ?? [ Array.from(fieldProps.columns, () => null ) ]
        } 
      }
      compiledValues[fieldName] = value;
      if(type === 'select'){
        const {options, otherOptions} = fieldProps;
        if(otherOptions || options.some(({details})=> details)){
          compiledValues[`${fieldName}__details`] = null;
        }

      }
    }
    return compiledValues;
  }
}  
export default Form;
