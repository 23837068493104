import IconButton from "@material-ui/core/IconButton";
import TablePagination from "@material-ui/core/TablePagination";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

function getNumberOfPages(count, rowsPerPage) {
  return Math.ceil(count / rowsPerPage);
}

function TablePaginationActions({ count, page, rowsPerPage, onChangePage }) {
  const handleFirstPageButtonClick = () => {
    onChangePage(1);
  };

  // RDT uses page index starting at 1, MUI starts at 0
  // i.e. page prop will be off by one here
  const handleBackButtonClick = () => {
    onChangePage(page);
  };

  const handleNextButtonClick = () => {
    onChangePage(page + 2);
  };

  const handleLastPageButtonClick = () => {
    onChangePage(getNumberOfPages(count, rowsPerPage));
  };

  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="الصفحة الأولى"
      >
        <LastPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="الصفحة السابقة"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
        aria-label="الصفحة التالية"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
        aria-label="الصفحة الاخيرة"
      >
        <FirstPageIcon />
      </IconButton>
    </>
  );
}

const FormListPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  currentPage,
}) => (
  <TablePagination
    component="nav"
    count={rowCount}
    rowsPerPage={rowsPerPage}
    page={currentPage - 1}
    onPageChange={onChangePage}
    onChangePage={onChangePage}
    onRowsPerPageChange={({ target }) =>
      onChangeRowsPerPage(Number(target.value))
    }
    ActionsComponent={TablePaginationActions}
    labelRowsPerPage={"عدد النتائج في الصفحة"}
    labelDisplayedRows={({ from, to, count, page }) =>
      `${from}${
        from !== to ? ` - ${to}` : ""
      } من ${count} نتيجة \u00A0\u00A0\u00A0\u00A0\u00A0 الصفحة ${page + 1}`
    }
  />
);

export default FormListPagination;
