import { Typography } from "@material-ui/core";
import { useField } from "formik";
import {TextField as MuiTextField} from '@material-ui/core';

export default function EvaluationTextField({id, label, mode = 'fill', long}) {
    console.log(long);
    const [props] = useField(id);
    return (
      mode === 'fill'
      ? <MuiTextField
        name={id}
        label={label}
        multiline={long}
        minRows={3}
        fullWidth
        variant="outlined"
        {...props}
      />
      : <>
       <Typography variant="h6" style={{fontWeight: 'bold'}}>{label}</Typography>
        { mode === 'summary'
          ? (
            !props.value?.length
            ? '(لا توجد)'
            : <ul>
                { props.value?.map(
                  (v) => <Typography component="li" style={{whiteSpace: 'pre-wrap', marginBottom: 15 }}>{v}</Typography>
                )}
              </ul>
          )
          : <Typography style={{whiteSpace: 'pre-wrap'}}>{props?.value?.trim().length ? props.value : '(لا توجد)'}</Typography>
        }
      </>
    )
  }