import React, { useReducer, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Box,
  Typography,
  Divider,
  makeStyles,
  Paper,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from "@material-ui/core";
import { gql, useMutation } from "@apollo/client";

import { MdAssignment, MdAssignmentTurnedIn } from "react-icons/md";
import { BiBookBookmark } from "react-icons/bi";
import { RiQuestionnaireLine } from "react-icons/ri";
import { l1 } from "../../../local.json";
import { Link, useHistory, useLocation } from "react-router-dom";
import Drafter2 from "../../../common/Drafter2";
import { BiDotsVerticalRounded } from "react-icons/bi";

import moment from "moment";
import { RequireClassAccess } from "../../../../Auth/gates";
const DELETE_COMPONENT = gql`
  mutation DeleteComponent($id: ID!) {
    deleteAssignment(input: { where: { id: $id } }) {
      assignment {
        id
      }
    }
  }
`;
function TopicRow({
  id,
  title,
  description,
  type,
  place,
  createdAt,
  statistics,
  link,
  slug,
  refetch,
  scope, evaluationsInfo
}) {
  console.log(statistics);
  const [accordionState, reverseAccordionState] = useReducer(
    currentState => !currentState,
    false
  );

  // Options Logic
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const [anchorComponent, setAnchorComponent] = useState(null);

  const openComponentMenu = event => {
    console.log("open menu", event);
    reverseAccordionState();
    setAnchorComponent(event.currentTarget);
  };
  const closeComponentMenu = () => {
    setAnchorComponent(null);
    reverseAccordionState();
  };
  const [deleteComponent, { loading: loadingDeleteComponent }] = useMutation(
    DELETE_COMPONENT,
    {
      onError: error => {
        console.log("mutationDeleteComponent error: ", error);
        // failure(l1.failure);
      },
      onCompleted: () => {
        refetch();
        alert(l1.deletesuccess);
      }
    }
  );
  const [confirmationState, reverseConfirmationState] = useReducer(
    state => !state,
    false
  );

  const useStyles = makeStyles(theme => ({
    midSection: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },

    topicTable: {
      borderBottom: `1px ${theme.palette.grey[200]} solid`,
      flexGrow: 1,
      cursor: "pointer",
      borderRadius: accordionState ? theme.spacing(1) : "",
      boxShadow: accordionState ? "0px 0px 5px -1px rgba(0,0,0,0.5)" : "",
      "&:hover": {
        borderRadius: theme.spacing(1),
        boxShadow: theme.shadows[1],
        background: "#e8f0fe"
      }
    },

    topicTableStream: {
      padding: theme.spacing(2),
      borderRadius: theme.spacing(2),
      flexGrow: 1,
      cursor: "pointer",
      "&:hover": {
        background: "#e8f0fe"
      }
    },
    iconContainer: {
      backgroundColor: theme.palette.primary.main,
      marginRight: theme.spacing(2)
    },
    row: {
      fontWeight: 600,
      color: theme.palette.grey[600]
    },
    accordionDetails: {},
    divider: {
      margin: theme.spacing(2, 0)
    },
    date: {
      color: theme.palette.grey[500],
      fontSize: theme.spacing(1.4)
    },
    instructions: {
      color: theme.palette.grey[800]
    },
    stats: {
      width: "max-content",
      borderLeft: `1px ${theme.palette.grey[400]} solid`,
      padding: theme.spacing(0, 2)
    },
    statsNumber: {
      textAlign: "center",
      fontWeigh: 900,
      fontSize: theme.spacing(5)
    },
    statsText: {
      color: theme.palette.grey[600]
    },
    viewText: {
      margin: theme.spacing(2.5, 0, 1, 2),
      color: theme.palette.primary.main,
      fontWeight: 600
    },
    options: {
      color: theme.palette.primary.dark,
      fontSize: theme.spacing(3)
      // paddingRight: theme.spacing(4)
    }
  }));
  const classes = useStyles();
  const iconChanger = () => {
    if (type === "material") {
      return (
        <Avatar className={classes.iconContainer}>
          <BiBookBookmark color="white" />
        </Avatar>
      );
    }
    if (['assignment', 'quiz'].includes(type)) {
      return (
        <Avatar className={classes.iconContainer}>
          <MdAssignment color="white" />
        </Avatar>
      );
    }
    if (type === "question") {
      return (
        <Avatar className={classes.iconContainer}>
          <RiQuestionnaireLine color="white" />
        </Avatar>
      );
    }
    if (type === "evaluations") {
      return (
        <Avatar className={classes.iconContainer}>
          <MdAssignmentTurnedIn color="white" />
        </Avatar>
      );
    }
  };
  const { pathname } = useLocation();
  const history = useHistory();
  let redirect = link
    ? link
    : pathname +
      (pathname[pathname.length - 1] === "/" ? "" : "/") +
      id +
      "/details";
  if (place === "stream"){
    const renderedRow = (
      <Box
        className={classes.midSection}
        display="flex"
        justifyContent="space-between"
      >
        <Paper className={classes.topicTableStream} variant="outlined">
          <Box
            className={classes.accordionSummary}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              {iconChanger()}
              <Typography variant="body1" className={classes.row}>
                {type === 'evaluations' && (l1.evaluations + ': ')}{title}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    );
    return (
      ['assignment', 'question','quiz', 'material', 'evaluations'].includes(type) ? <Link to={link} style={{textDecoration: 'none'}} >{renderedRow}</Link> : renderedRow 
      );
    }
  return (
    <Box
      className={classes.midSection}
      display="flex"
      justifyContent="space-between"
    >
      <Accordion
        className={classes.topicTable}
        expanded={accordionState}
        onChange={reverseAccordionState}
        square
        elevation={0}
      >
        <AccordionSummary>
          <Box
            className={classes.accordionSummary}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {iconChanger()}
              <Typography variant="h6" className={classes.row}>
                {type === 'evaluations' && (l1.evaluations + ': ')}{title}
              </Typography>
            </div>
            {scope === "review"? "" : 
            <RequireClassAccess only="teacher">
            <BiDotsVerticalRounded
              className={classes.options}
              onClick={openComponentMenu}
            />
            </RequireClassAccess>
            }
            
            <Menu
              open={Boolean(anchorComponent)}
              onClose={closeComponentMenu}
              elevation={1}
              anchorEl={anchorComponent}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  // setEditCommentState(true);
                  // console.log("edit");
                  // closeComponentMenu();
                  history.push(redirect);
                }}
              >
                <Typography>{l1.edit}</Typography>
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  reverseConfirmationState();
                  closeComponentMenu();
                  // reverseAccordionState();
                }}
              >
                {l1.delete}
              </MenuItem>
            </Menu>
            <Dialog
              open={confirmationState}
              onClose={() => {
                reverseConfirmationState();
                reverseAccordionState();
              }}
            >
              <DialogTitle></DialogTitle>
              <DialogContent>
                <Typography style={{ fontWeight: "bold" }}>
                  {l1.confirmation}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    deleteComponent({
                      variables: {
                        id
                      }
                    });
                    reverseAccordionState();
                    window.location.reload(false);
                  }}
                >
                  {l1.confirm}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    reverseConfirmationState();
                    reverseAccordionState();
                  }}
                >
                  {l1.cancel}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Box flexGrow={1}>
            <Divider variant="fullWidth" className={classes.divider} />
            { type === 'evaluations' && <>
              <Typography
                variant="h6"
              >
                <b>{l1.evaluationsCoach}:</b> {evaluationsInfo.coach}
              </Typography>
              <Typography
                variant="h6"
              >
                <b>{l1.evaluationsDate}:</b> {evaluationsInfo.date}
              </Typography>
              <Typography
                variant="h6"
              >
                <b>{l1.evaluationsLocation}:</b> {evaluationsInfo.location}
              </Typography>
              </>
            }
            <Typography
              variant="body2"
              className={classes.date}
              component="span"
            >
              {moment(createdAt).fromNow()}
            </Typography>
            <Box className={classes.midContentContainer} display="flex">
              <Box flexGrow={1}>
                <Drafter2 preview raw={description} />
              </Box>
              {type === "material" ? (
                ""
              ) : (
                <Box className={classes.statsContainer} display="flex">
                  <RequireClassAccess only="teacher" slug={slug}>
                    { type === 'evaluations'
                      ? <>
                        <Box className={classes.stats}>
                          <Typography
                            variant="body1"
                            className={classes.statsNumber}
                          >
                            {statistics ? statistics.all || '' : 0}
                          </Typography>
                          <Typography variant="body1" className={classes.statsText}>
                            {l1.studentCount}
                          </Typography>
                        </Box>
                        <Box className={classes.stats}>
                          <Typography
                            variant="body1"
                            className={classes.statsNumber}
                          >
                            {statistics ? statistics.trainingEvaluations : 0}
                          </Typography>
                          <Typography variant="body1" className={classes.statsText}>
                            {l1.trainingEvaluations}
                          </Typography>
                        </Box>
                        <Box className={classes.stats}>
                          <Typography
                            className={classes.statsNumber}
                            variant="body1"
                          >
                            {statistics ? statistics.coachEvaluations : 0}
                          </Typography>
                          <Typography className={classes.statsText} variant="body1">
                            {l1.coachEvaluations}
                          </Typography>
                        </Box>
                        <Box className={classes.stats}>
                          <Typography
                            variant="body1"
                            className={classes.statsNumber}
                          >
                            {statistics ? statistics.studentEvaluations : 0}
                          </Typography>
                          <Typography variant="body1" className={classes.statsText}>
                            {l1.studentEvaluations}
                          </Typography>
                        </Box>
                      </>
                      : <>
                        <Box className={classes.stats}>
                          <Typography
                            variant="body1"
                            className={classes.statsNumber}
                          >
                            {statistics ? statistics.assigned : 0}
                          </Typography>
                          <Typography variant="body1" className={classes.statsText}>
                            {l1.assigned}
                          </Typography>
                        </Box>
                        <Box className={classes.stats}>
                          <Typography
                            className={classes.statsNumber}
                            variant="body1"
                          >
                            {statistics ? statistics.submitted : 0}
                          </Typography>
                          <Typography className={classes.statsText} variant="body1">
                            {l1.handedIn}
                          </Typography>
                        </Box>
                        <Box className={classes.stats}>
                          <Typography
                            variant="body1"
                            className={classes.statsNumber}
                          >
                            {statistics ? statistics.graded : 0}
                          </Typography>
                          <Typography variant="body1" className={classes.statsText}>
                            {l1.workgraded}
                          </Typography>
                        </Box>
                      </>
                    }
                  </RequireClassAccess>
                </Box>
              )}
            </Box>
            <Divider className={classes.divider} />
            <Typography
              variant="body1"
              className={classes.viewText}
              onClick={() => {
                history.push(redirect);
              }}
            >
              {l1.moredetails}
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default TopicRow;
