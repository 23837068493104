import React, { useRef, useState } from "react";

import {
  Avatar,
  Box,
  CircularProgress,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import TopBar from "../../common/TopBar";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Loading from "../../common/Loading";
import l1 from "../../local.json";
import { StudentWorkGradeEditor } from '../../Answers'
const useStyles = makeStyles(theme => ({
  container: {},
  tablesContainer: {
    width: "100vw"
  },
  headCell: {
    width: theme.spacing(18),
    height: theme.spacing(12),
    borderColor: theme.palette.grey[300],
    padding: theme.spacing(2)
  },
  bodyCell: {
    width: theme.spacing(18),
    height: theme.spacing(8),
    borderColor: theme.palette.grey[300]
  },
  headCellRight: {
    width: theme.spacing(24),
    height: theme.spacing(12),
    borderColor: theme.palette.grey[300]
  },
  bodyCellRight: {
    width: theme.spacing(24),
    height: theme.spacing(8),
    borderColor: theme.palette.grey[300]
  },
  avatar: {
    margin: theme.spacing(0, 2),
    height: theme.spacing(4),
    width: theme.spacing(4)
  },
  fullname: {
    fontWeight: 600
  },
  TextField: {
    margin: theme.spacing(0, 5)
  },
  input: {
    fontWeight: 600
  },
  title: {
    fontWeight: 600,
    color: theme.palette.primary.main
  }
}));

const QUERY_MARKS = gql`
  query QueryClasswork($slug: String!) {
    classes(where: { slug: $slug }) {
      students(sort: "fullname:asc") {
        fullname
        id
      }
      assignments(sort: "createdAt:desc") {
        id
        title
        description
        type
        grade
        studentWorks (sort:"student:asc"){
          id
          status
          student {
            id
          }
          grade
        }
      }
    }
  }
`;
// const RETURN_MARK = gql`
//   mutation updateAssignment($id: ID!, $points: Int, $student: ID!) {
//     updateAssignment(
//       input: {
//         where: { id: $id }
//         data: { marks: [{ points: $points, student: $student }] }
//       }
//     ) {
//       assignment {
//         marks {
//           points
//           student {
//             id
//             fullname
//           }
//         }
//         title
//         description
//       }
//     }
//   }
// `;

function Marks() {
  const { slug } = useParams();
  // Apollo
  const { loading, data } = useQuery(QUERY_MARKS, {
    variables: { slug },
    onError: error => console.log("QueryMarksError: ", error),
    onCompleted: data => console.log("QueryMarksSuccess: ", data)
  });
  // const [returnMark, { loading: loadingReturnMark }] = useMutation(
  //   RETURN_MARK,
  //   {
  //     onError: error => console.log("MutationMarkError: ", error),
  //     onCompleted: data => console.log("MutationMarkSuccess: ", data),
  //     refetchQueries: [
  //       {
  //         query: QUERY_MARKS,
  //         variables: {
  //           slug
  //         }
  //       }
  //     ]
  //   }
  // );
  const [targetField, setTargetField] = useState(null);
  const targetRef = useRef();
  const classes = useStyles();
  if (!loading) {
    const assignments = data.classes[0].assignments;
    const students = data.classes[0].students;

    return (
      <Box className={classes.container}>
        <TopBar type="marks" />
        {assignments.length === 0 ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h1" style={{ color: "grey" }}>
              فارغ
            </Typography>
          </Box>
        ) : (
            <Box className={classes.tablesContainer} display="flex">
              <Box className={classes.table}>
                <Box className={classes.headRight} display="flex">
                  <Box
                    className={classes.headCellRight}
                    borderBottom={1}
                    borderRight={1}
                  ></Box>
                </Box>
                <Box
                  className={classes.bodyRight}
                  display="flex"
                  style={{ flexDirection: "column" }}
                >
                  {students.map(({ fullname, id }) => (
                    <Box
                      className={classes.bodyCellRight}
                      borderBottom={1}
                      borderRight={1}
                      key={id}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Avatar className={classes.avatar} />
                      <Typography className={classes.fullname}>
                        {fullname}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              {assignments.map(({ id, title, grade, type, studentWorks }) => {
                if (type != "material") {
                  return (
                    <Box className={classes.table}>
                      {/* {console.log(type)} */}
                      <Box className={classes.head} display="flex">
                        <Box
                          className={classes.headCell}
                          borderBottom={1}
                          borderRight={1}
                        >
                          <Typography className={classes.title}>
                            {title}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        className={classes.body}
                        display="flex"
                        style={{ flexDirection: "column" }}
                      >
                        {grade
                          ? studentWorks.map((work) => {
                            return (

                              <Box
                                className={classes.bodyCell}
                                borderBottom={1}
                                borderRight={1}
                                key={id}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <StudentWorkGradeEditor maxGrade={100} studentWork={work} />
                              </Box>
                            );
                          })
                          : studentWorks.map(() => {
                            return (
                              <Box
                                className={classes.bodyCell}
                                borderBottom={1}
                                borderRight={1}
                                key={id}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              ></Box>
                            );
                          })}
                      </Box>
                    </Box>
                  );
                }
              })}
              <Box className={classes.table} flexGrow={1}>
                <Box className={classes.head} display="flex">
                  <Box
                    className={classes.headCell}
                    borderBottom={1}
                    borderRight={1}
                    flexGrow={1}
                  ></Box>
                </Box>
                <Box className={classes.body} display="flex">
                  <Box
                    className={classes.bodyCell}
                    borderBottom={1}
                    borderRight={1}
                    flexGrow={1}
                  ></Box>
                </Box>
              </Box>
            </Box>
          )}
      </Box>
    );
  }
  return <Loading />;
}

export default Marks;
//  <Box className={classes.table}>
//    <Box className={classes.head} display="flex">
//      <Box className={classes.headCell} borderBottom={1} borderRight={1}>
//        <Typography>T</Typography>
//      </Box>
//    </Box>
//    <Box className={classes.body} display="flex">
//      <Box className={classes.bodyCell} borderBottom={1} borderRight={1}>
//        <Typography>B</Typography>
//      </Box>
//    </Box>
//  </Box>;
