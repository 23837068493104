import {
  Avatar,
  Box,
  Button,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { Navigation } from "../../../Context";
import SearchMember from "./SearchMember";
import TextField from "./TextField";

export default function UserField(props) {
  const { name, label, edit, value, handleChange } = props;
  const { centerId, user } = useContext(Navigation);
  const [ready, setReady] = useState(false);

  if (!edit) {
    return (
      <Box style={{ display: "flex", alignItems: "top", gap: 16 }}>
        {label && <Typography color="primary">{label}:</Typography>}
        <Typography>{value?.fullname ?? value?.username ?? "(غير محدد)"}</Typography>
      </Box>
    );
  }
  
  return (
    <>
      <TextField
        {...props}
        readOnly
        disabled
        value={value ? value.fullname : "(غير محدد)"}
        InputProps={{
          startAdornment: Boolean(value) && (
            <InputAdornment position="start">
              <Avatar
                src={`${process.env.REACT_APP_STRAPI_URL}${value.avatar}`}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setReady(true);
                }}
              >
                اختيار
              </Button>
            </InputAdornment>
          ),
        }}
      />
      <SearchMember
        centerId={centerId}
        ready={ready}
        setReady={setReady}
        name={name}
        initialValue={value}
        handleChange={(value) => {
          handleChange({
            target: {
              name,
              value,
            },
          });
        }}
      />
    </>
  );
}
