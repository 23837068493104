import React, {useState, useReducer} from 'react'
import Quiz, {QuizModes} from './Quiz';
import {
  Grid, Box, Button, Typography,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Divider,
} from '@material-ui/core';
import TopBar from './common/TopBar';
import {useParams} from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import {l1} from './local.json';
import Loading from './common/Loading';

const GET_QUIZ = gql`
  query Quiz($id: ID!) {
    quiz(id: $id) {
      title
      description
      instructions
      questions
      correctAnswers
    }
  }
`;

const UPDATE_QUIZ = gql`
  mutation UpdateQuiz($id: ID!, $title: String!, $description: String, $instructions: JSON, $questions: JSON, $correctAnswers: JSON) {
    updateQuiz(
      input: {
        where: { id: $id }
        data: {
          title: $title
          description: $description
          instructions: $instructions
          questions: $questions
          correctAnswers: $correctAnswers
        }
      }) 
      {
      quiz {
        title,
        description,
        instructions
        questions
        correctAnswers
      }
    }
  }
`;

const DELETE_QUIZ = gql`
  mutation DeleteQuiz($id: ID!) {
    deleteQuiz(input: { where: { id: $id } }) {
      quiz {
        id
        title
      }
    }
  }
`;



export default function QuizEditor() {
  const {id} = useParams()
  const [ready, setReady] = useState(false)
  const [quiz, setQuiz] = useState({})
  const [loadingError, setLoadingError] = useState(null)
  const { loading: loadingQuiz} = useQuery(
    GET_QUIZ, 
    {
      variables:{
        id
      },
      onError: (error) => console.log('GetQuizError: ', error),
      onCompleted: (data) => {
        console.log('QuizData: ', data)
        setQuiz(data.quiz)
        setSavedQuiz(JSON.parse(JSON.stringify(data.quiz)))
        setReady(true)
      },
      fetchPolicy: 'no-cache',
    }
  );
  
  const [savedQuiz, setSavedQuiz] = useState(JSON.parse(JSON.stringify(quiz)));
  const [close, setClose] = useState(false)


  const [saveQuiz, { loading: savingQuiz }] = useMutation(
    UPDATE_QUIZ,
    {
      onError: (error) => {
        console.warn('MutationSaveQuiz error: ', error);
        setLoadingError(error)
      },
      onCompleted: (data) => {
        console.log('MutationSaveQuiz data: ', data);
        setSavedQuiz(data.updateQuiz.quiz)
        if(close){
          window.close();
        }
      },
    }
  );

  const saveQuizIfModified = ({closeOnFinish}) => {
    console.log(quiz, savedQuiz)
    if (JSON.stringify(quiz) !== JSON.stringify(savedQuiz)){
      saveQuiz(
        {
          variables:{
            id,
            ...quiz
          },

        })
    }
    else if (closeOnFinish){
      window.close()
    }
  }

  const [deleteConfirmationVisible, toggleDeleteConfirmationVisible] = useReducer((state) => !state, false);
  const [deleteQuiz, { loading: deletingQuiz }] = useMutation(
    DELETE_QUIZ,
    {
      onError: (error) => {
        console.warn('MutationDeleteQuiz error: ', error);
      },
      onCompleted: (data) => {
        console.log('MutationDeleteQuiz data: ', data);
        alert(l1.quizSuccessfullyDeleted)
        window.close()
      },
    }
  );

  // TODO: do api calls needed to load the quiz.
  let [quizMode, setQuizMode] = useState("EDIT")
  return (
      ready
      ? <div>
          <TopBar type="Quiz" currentQuizMode={quizMode} setQuizMode={setQuizMode} sticky/>
          <Grid container style={{marginTop: 100}} >
            <Grid item xs={1} md={3} />
            <Grid item xs={10} md={6}>
              <Quiz mode={QuizModes[quizMode]} quiz={quiz} />
              <Divider variant="middle" style={{margin:[16, 0]}}/>
              <Box display="flex" alignItems="center" justifyContent="flex-end" style={{gap: 8, padding: "0 16px", marginBottom: 16}}>
                <Typography>
                  {savingQuiz ? l1.saving + "..." : null}
                </Typography>
                <Button variant="contained" color="primary" onClick={saveQuizIfModified} disabled={savingQuiz}>{l1.save}</Button>
                <Button variant="contained" color="primary" onClick={()=>{ setClose(true); saveQuizIfModified({closeOnFinish:true});}} disabled={savingQuiz}>{l1.saveAndClose}</Button>
                <Button variant="contained" onClick ={window.close} disabled={savingQuiz}>{l1.close}</Button>
                <Button variant="contained" color="secondary" onClick={toggleDeleteConfirmationVisible} disabled={savingQuiz | deletingQuiz}>{l1.delete}</Button>
              </Box>
            </Grid>
            <Grid item xs={1} md={3} />
          </Grid>
          {
            true ?
            <Dialog open={deleteConfirmationVisible} onClose={toggleDeleteConfirmationVisible}>
            <DialogTitle></DialogTitle>
            <DialogContent>
              <Typography style={{ fontWeight: 'bold' }}>{l1.confirmation}</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  toggleDeleteConfirmationVisible();
                  deleteQuiz({
                    variables: {
                      id
                    }
                  })
                }}
              >
                {l1.confirm}
              </Button>
              <Button variant="contained" onClick={toggleDeleteConfirmationVisible}>
                {l1.cancel}
              </Button>
            </DialogActions>
          </Dialog>
        
            : null
          }
        </div>
      : loadingQuiz
        ? <Loading />
      : loadingError
        ? <Typography>{l1.anErrorHappened}.{l1.refreshToRetry}</Typography>
        : null
  )
}
