import React, { useReducer, useRef, useState } from "react";

import {
  Avatar,
  Box,
  makeStyles,
  Paper,
  Typography,
  IconButton,
  Button,
  TextField,
  Snackbar
} from "@material-ui/core";
// Icons
import { FiMessageSquare } from "react-icons/fi";
import { MdCropFree, MdMessage } from "react-icons/md";
import { CgAttachment } from "react-icons/cg";
import { l1 } from "../../local.json";
import Due from "../../common/Due";
import TopicRow from "./components/TopicRow";
import Announcement from "./components/Announcement";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Loading from "../../common/Loading";
import TopBar from "../../common/TopBar";
import MuiAlert from "@material-ui/lab/Alert";
import Drafter from "../../common/Editor";
import Drafter2 from "../../common/Drafter2";
import moment from "moment";
import useLayout from "../../common/useLayout";
import { RequireClassAccess } from "../../../Auth/gates";

// Styles
const useStyles = makeStyles(theme => ({
  head: {
    height: theme.spacing(30),
    width: "90vw",
    [theme.breakpoints.up("lg")]: {
      width: "62vw"
    },
    backgroundImage:
      "url(https://gstatic.com/classroom/themes/img_backtoschool.jpg)",
    backgroundColor: "black",
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1)
  },
  name: {
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: theme.spacing(3.4)
  },
  section: {
    color: theme.palette.common.white,
    fontSize: theme.spacing(3)
  },
  codeContainer: {
    "& > *": {
      color: theme.palette.common.white,
      marginRight: theme.spacing(1)
    }
  },
  codePrefix: {
    fontWeight: 600,
    fontSize: theme.spacing(1.7)
  },
  code: {
    fontSize: theme.spacing(1.7)
  },
  scanCodeIconContainer: {
    marginLeft: theme.spacing(-1)
  },
  scanCodeIcon: {
    color: theme.palette.common.white,
    fontSize: theme.spacing(2.5),
    fontWeight: 700
  },
  themeContainer: {
    gap: theme.spacing(0.5)
  },
  uploadContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: "50%",
    "& > *": {
      color: theme.palette.common.white,
      fontSize: theme.spacing(1.5),
      cursor: "pointer"
    }
  },
  main: {
    [theme.breakpoints.up("lg")]: {
      width: "62vw"
    },
    width: "90vw"
  },
  mainSubContainer: {
    flexGrow: 1
  },

  announcementContainer: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    height: "min-content"
    // width: "60%",
  },
  noAnnouncements: {
    margin: theme.spacing(2, 0, 2, 2),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    height: "min-content",
    "& > *": {
      margin: theme.spacing(1, 0, 0, 2)
    }
  },
  noAnnouncementsSpecialText: {
    fontSize: theme.spacing(3),
    color: theme.palette.success.dark
  },
  noAnnouncementsBlock: {
    alignItems: "center",
    "& :first-child": {
      fontSize: theme.spacing(3),
      margin: theme.spacing(1)
    },
    "& > *": {
      fontWeight: 600,
      color: theme.palette.grey[700]
    }
  },
  upComingContainer: {
    margin: theme.spacing(2, 2, 2, 0),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    height: "max-content",
    minWidth: "max-content"
  },
  upComing: {
    fontWeight: 600
  },
  due: {
    margin: theme.spacing(0, 1),
    width: "max-content"
  },
  upComingContainerBottom: {
    margin: theme.spacing(1, 1, 0, 1)
  },
  announcementAvatar: {
    margin: theme.spacing(0, 2)
  },
  announcementText: {
    flexGrow: 1,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  select: {
    minWidth: theme.spacing(15),
    marginRight: theme.spacing(2)
  },
  postUpperContainer: {
    marginBottom: theme.spacing(3)
  },
  postMiddleContainer: {
    marginBottom: theme.spacing(3)
  },
  postBottomContainer: {
    marginTop: theme.spacing(2)
  }
}));
const UPLOAD = gql`
  mutation($file: Upload!) {
    upload(file: $file) {
      name
    }
  }
`;
const QUERY_STREAM = gql`
  query QueryStream($slug: String!) {
    classes(where: { slug: $slug }) {
      id
      name
      section
      slug

      assignments(sort: "createdAt:desc") {
        id
        title
        description
        type
        createdAt
      }

      posts(sort: "createdAt:desc") {
        id
        writer {
          id
          fullname
          email
        }
        content
        createdAt
        attachments {
          url
        }
        comments {
          writer {
            id
            fullname
            email
          }
          id
          content
          createdAt
        }
      }
    }
  }
`;

const ADD_POST = gql`
  mutation CreatePost(
    $content: String!
    $class: ID!
    $writer: ID!
    $attachments: ID
  ) {
    createPost(
      input: {
        data: {
          content: $content
          class: $class
          writer: $writer
          attachments: $attachments
        }
      }
    ) {
      post {
        id
        content
        class {
          id
          name
        }
        attachments {
          url
        }
        comments {
          id
          writer {
            id
            fullname
            email
          }
          content
          createdAt
        }
      }
    }
  }
`;

const Stream = () => {
  const { slug } = useParams();
  // Apollo
  const { loading: loadingQueryStream, data: queryData, refetch } = useQuery(
    QUERY_STREAM,
    {
      variables: { slug },
      notifyOnNetworkStatusChange: true,
      onError: error => console.log("QueryStreamError: ", error)
    }
  );
  const [createPost, { loading: loadingMutationCreatePost }] = useMutation(
    ADD_POST,
    {
      onError: error => console.log("mutationCreatePost error: ", error),
      onCompleted: () => {
        setAlertSuccess(l1.postsuccess);
        refetch();
      }
    }
  );

  const [postState, reversePostState] = useReducer(
    currentState => !currentState,
    false
  );
  // Alert Logic
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFailure, setAlertFailure] = useState(false);

  const classes = useStyles();
  const layout = useLayout();
  const streamItems = (queryData?.classes[0]?.posts ?? []).map(
        ({
          writer,
          content,
          comments,
          createdAt,
          id,
          attachments
        }) => ([
          new Date(createdAt).getTime(), 
          <Announcement
            refetch={refetch}
            key={id}
            fullname={writer.fullname}
            content={content}
            comments={comments}
            createdAt={createdAt}
            id={id}
            query={QUERY_STREAM}
            alert={content => setAlertSuccess(content)}
            failure={content => setAlertFailure(content)}
            attachments={attachments}
            writerEmail={writer.email}
          />])
      ).concat(
        (queryData?.classes[0]?.assignments ?? []).map(
          ({ title, description, type, id, createdAt }) => ([
            new Date(createdAt).getTime(),
            <TopicRow
              title={title}
              description={description}
              type={type}
              key={id}
              place="stream"
              link={`/class/${slug}/classwork/${id}/details`}
            />
          ])
        )
      ).sort((a, b) => b[0] - a[0]).map(([_,item])=>item)
  return (
    <>
      {loadingQueryStream || loadingMutationCreatePost ? (
        <Loading />
      ) : (
        <>
          {layout
            ? console.log("layout desktop")
            : console.log("layout mobile")}
          <TopBar type="stream" />
          <Box
            className={classes.root}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              className={classes.head}
              onClick={() => {
                const sortedArray = queryData?.classes[0]?.sort(
                  (a, b) => -moment(b.posts.createdAt).format("YYYYMMDD")
                );
                console.log(sortedArray);
              }}
            >
              <Typography className={classes.name}>
                {queryData.classes[0].name}
              </Typography>
              <Typography className={classes.section}>
                {queryData.classes[0].section}
              </Typography>

            </Box>

            <Box
              display="flex"
              flexDirection={layout ? "row" : "column"}
              className={classes.main}
            >
              <Paper className={classes.upComingContainer} variant="outlined">
                <Typography className={classes.upComing}>
                  {l1.upComing}
                </Typography>
                <Due className={classes.due} />
              </Paper>
              <Box
                display="flex"
                flexDirection="column"
                className={classes.mainSubContainer}
              >
                {/* <RequireClassAccess only="teacher"> */}
                <Paper variant="outlined" className={classes.noAnnouncements}>
                  <Typography className={classes.noAnnouncementsSpecialText}>
                    {l1.communicateWithYourClassHere}
                  </Typography>
                  <Box display="flex" className={classes.noAnnouncementsBlock}>
                    <FiMessageSquare />
                    <Typography>{l1.createYourAnnouncements}</Typography>
                  </Box>
                  <Box display="flex" className={classes.noAnnouncementsBlock}>
                    <MdMessage />
                    <Typography>{l1.RespondToStudentPost}</Typography>
                  </Box>
                </Paper>
                {/* </RequireClassAccess> */}
                <Paper className={classes.announcementContainer} elevation={3}>
                  {postState ? (
                    <>
                      <Drafter2
                        mutation={createPost}
                        reverse={reversePostState}
                        id={queryData.classes[0].id}
                      />
                    </>
                  ) : (
                    // <RequireClassAccess only="teacher">
                    <Box display="flex" alignItems="center">
                      <Avatar className={classes.announcementAvatar} />
                      <Typography
                        className={classes.announcementText}
                        variant="body2"
                        onClick={reversePostState}
                      >
                        {l1.announceSomethingToClass}
                      </Typography>
                      {/* <IconButton onClick={reverseClassTableState}>
                  <IoMdRepeat />
                </IconButton> */}
                    </Box>
                    // </RequireClassAccess>
                  )}
                </Paper>
                {streamItems}
              </Box>
            </Box>
          </Box>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={Boolean(alertSuccess)}
            onClose={() => {
              setAlertSuccess(false);
            }}
            autoHideDuration={3000}
          >
            <MuiAlert
              className={classes.muiAlert}
              elevation={6}
              variant="filled"
              severity="success"
            >
              {alertSuccess}
            </MuiAlert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={alertFailure}
            autoHideDuration={3000}
            onClose={() => {
              setAlertFailure(false);
            }}
          >
            <MuiAlert
              className={classes.muiAlert}
              elevation={6}
              variant="filled"
              severity="error"
            >
              {alertFailure}
            </MuiAlert>
          </Snackbar>
        </>
      )}
    </>
  );
};

export default Stream;
