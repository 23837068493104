import React from "react";
import { makeStyles, Button, Typography, Container } from "@material-ui/core";
import { l1 } from "../local.json";
import { BiCalendarAlt } from "react-icons/bi";
import { BsCardChecklist } from "react-icons/bs";
import { useHistory } from 'react-router-dom';
import { RequireTeacherAccess } from "../../../src/Auth/gates";
const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 0)
  },
  topButtonIcon: {
    color: theme.palette.grey[700]
  },
  topButtonTypography: {
    color: theme.palette.primary.light,
    fontWeight: 600
  }
}));
function TopSection() {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Button
        startIcon={<BiCalendarAlt className={classes.topButtonIcon} />}
        style={{ textTransform: "none" }}
        onClick={() => history.push("/calendar")}
      >
        <Typography className={classes.topButtonTypography}>
          {l1.calendar}
        </Typography>
      </Button>
      <RequireTeacherAccess>
        <Button
          startIcon={<BsCardChecklist className={classes.topButtonIcon} />}
          style={{ textTransform: "none" }}
          onClick={() => history.push("/review")}
        >
          <Typography className={classes.topButtonTypography}>
            {l1.toReview}
          </Typography>
        </Button>
      </RequireTeacherAccess>
    </Container>
  );
}

export default TopSection;
