import CoachEvaluation from "./subjects/coach";
import StudentEvaluation from "./subjects/student";
import TrainingEvaluation from "./subjects/training";

const subjects = {
    coach: CoachEvaluation,
    student: StudentEvaluation,
    training: TrainingEvaluation,
}

export default function useEvaluationSubject(name){
  return subjects[name];
}