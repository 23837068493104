import React, { useContext, useRef, useState } from 'react';

import { Avatar, Box, Divider, InputBase, makeStyles } from '@material-ui/core';
import { AiOutlineSend } from 'react-icons/ai';
import { gql, useMutation } from '@apollo/client';
import { l1 } from '../local.json';
import Loading from './Loading';
import { Navigation } from '../../Context';

const useStyles = makeStyles((theme) => ({
  botContainer: {
    minWidth: '100%',
  },
  avatar: {
    margin: theme.spacing(2),
  },

  inputContainer: {
    border: `1px solid lightgrey`,
    borderRadius: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  input: {
    marginLeft: theme.spacing(2),
  },
  inputIcon: {
    transform: 'scaleX(-1)',
    margin: theme.spacing(0, 1),
    fontSize: theme.spacing(3),
    cursor: 'pointer',
    color: theme.palette.grey[500],
  },
  options: {
    marginRight: theme.spacing(2),
  },
  menuItem: {
    paddingRight: theme.spacing(10),
  },
  announcement: {
    fontSize: theme.spacing(3),
    // margin: theme.spacing(2),
    cursor: 'pointer',
    color: 'grey',
  },
  editPostContainer: {
    margin: theme.spacing(0, 5),
  },
  editPostTextField: {
    background: theme.palette.grey[100],
    marginBottom: theme.spacing(2),
  },
  editPostButtons: {
    gap: theme.spacing(2),
  },
}));
const ADD_COMMENT = gql`
  mutation CreatComment($content: String!, $writer: ID!, $post: ID!) {
    createComment(input: { data: { content: $content, writer: $writer, post: $post } }) {
      comment {
        id
        content
      }
    }
  }
`;
const ADD_COMMENT_ASSIGNMENT = gql`
  mutation CreatCommentAssignment($content: String!, $writer: ID!, $assignment: ID!) {
    createComment(input: { data: { content: $content, writer: $writer, assignment: $assignment } }) {
      comment {
        id
        content
      }
    }
  }
`;
function CreateComment({ query, id, assignment, refetch, alert, failure }) {
  const { user } = useContext(Navigation);
  // Apollo
  const [createComment, { loading: loadingCreateComment }] = useMutation(ADD_COMMENT, {
    onError: (error) => {
      console.log('mutationCreateComment error: ', error);
      failure(l1.failure);
    },
    onCompleted: (data) => {
      refetch();
      alert(l1.commentsuccess);
      setComment('');
    },
    // refetchQueries: [
    //   {
    //     query: query,
    //     variables: {
    //       slug,
    //     },
    //   },
    // ],
  });
  const [createCommentAssignment, { loading: loadingCreateCommentAssignment }] = useMutation(ADD_COMMENT_ASSIGNMENT, {
    onError: (error) => {
      console.log('mutationCreateCommentAssignment error: ', error);
      failure(l1.failure);
    },
    onCompleted: (data) => {
      refetch();
      alert(l1.commentsuccess);
      setComment('');
    },
    // refetchQueries: [
    //   {
    //     query: query,
    //     variables: {
    //       id,
    //     },
    //   },
    // ],
  });
  // Inputs Logic
  const [comment, setComment] = useState('');
  const commentRef = useRef();

  const classes = useStyles();
  return (
    <>
      {loadingCreateComment || loadingCreateCommentAssignment ? (
        <Loading />
      ) : (
        <>
          <Divider className={classes.divider} />
          <Box className={classes.botContainer} display="flex" alignItems="center">
            <Avatar className={classes.avatar}></Avatar>
            <Box
              className={classes.inputContainer}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexGrow="1"
            >
              <InputBase
                className={classes.input}
                fullWidth
                inputRef={commentRef}
                value={comment}
                onChange={(e) => {
                  setComment(commentRef.current.value);
                }}
              />
              <AiOutlineSend
                className={classes.inputIcon}
                onClick={() => {
                  assignment
                    ? createCommentAssignment({
                        variables: {
                          writer: user.id,
                          content: comment,
                          assignment: id,
                        },
                      })
                    : createComment({
                        variables: {
                          writer: user.id,
                          content: comment,
                          post: id,
                        },
                      });
                  setComment('');
                }}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default CreateComment;
