import React, { useState, useEffect } from "react";
import {
  Avatar,
  Typography,
  Box,
  Dialog,
  DialogContent,
  LinearProgress,
  makeStyles,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { gql, useQuery } from "@apollo/client";
import { l1 } from "../../../components/local.json";

const GET_CENTER_MEMBERS = gql`
  query QueryCenterMembers($id: ID!) {
    centersLists(where: { id: $id }) {
      memberships(where: { status: "ACTIVE" }) {
        id
        user {
          id
          fullname
          avatar
        }
        role
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    margin: theme.spacing(2),
  },
  search: {
    margin: theme.spacing(2, 30),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  nameContainer: {
    marginBottom: theme.spacing(1),
    border: "1px solid lightgrey",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    "&:not(.selected):hover": {
      backgroundColor: "#ddd",
      cursor: "pointer",
    },
    "&.selected": {
      backgroundColor: "#eee",
      border: "solid 1px" + theme.palette.primary.main,
    },
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

function SearchMember({
  name,
  ready,
  setReady,
  centerId,
  handleChange,
  initialSelected = null,
}) {
  const [search, setSearch] = useState("");
  const [result, setResult] = useState([]);
  const [selected, setSelected] = useState(initialSelected);

  const { loading, data } = useQuery(GET_CENTER_MEMBERS, {
    onError: (error) => console.error("QueryMembershipError: ", error),
    variables: {
      id: centerId,
    },
    onCompleted: () => {
      setResult(
        data?.centersLists[0]?.memberships?.filter(({ user }) =>
          user.fullname?.includes(search)
        )
      );
    },
  });

  useEffect(() => {
    setResult(
      data?.centersLists[0]?.memberships?.filter(({ user }) =>
        user.fullname?.includes(search)
      )
    );
  }, [search]);

  const choices =
    result?.filter(({ user }) => !selected || selected.id !== user.id) ?? [];
  const classes = useStyles();
  return (
    <Dialog
      className={classes.dialog}
      open={ready}
      onClose={() => {
        setReady(false);
      }}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.search}>
          {loading ? (
            <LinearProgress />
          ) : (
            <TextField
              fullWidth
              label="Search"
              value={search}
              variant="outlined"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          )}
        </Box>

        {selected && (
          <Box
            className={classes.nameContainer + " selected"}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            key={selected.id}
          >
            <Box display="flex" alignItems="center">
              <Avatar
                src={`${process.env.REACT_APP_STRAPI_URL}${selected.avatar}`}
                className={classes.avatar}
              ></Avatar>
              <Typography className={classes.name}>
                {selected.fullname}
              </Typography>
            </Box>
            <Button
              variant="outlined"
              size="medium"
              color="secondary"
              onClick={() => setSelected(null)}
            >
              {l1.delete}
            </Button>
          </Box>
        )}
        <Box
          style={{
            width: "100%",
            height: 1,
            background: "lightgrey",
            marginBottom: 32,
          }}
        ></Box>
        {!choices?.length ? (
          <Box textAlign={"center"} p={4}>
            {l1.noMember}
          </Box>
        ) : (
          choices?.map((membership) => (
            <Box
              className={classes.nameContainer}
              display="flex"
              alignItems="center"
              key={membership.id}
              onClick={() => {
                setSelected(membership.user);
              }}
            >
              <Avatar
                src={`${process.env.REACT_APP_STRAPI_URL}${membership.user.avatar}`}
                className={classes.avatar}
              ></Avatar>
              <Typography className={classes.name}>
                {membership.user.fullname}
              </Typography>
            </Box>
          ))
        )}
        {selected ? (
          <Box
            style={{
              width: "100%",
              height: 1,
              background: "lightgrey",
              marginBottom: 32,
            }}
          ></Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleChange(selected);
            setReady(false);
          }}
        >
          {l1.confirm}
        </Button>
        <Button
          onClick={() => {
            setSelected(initialSelected ?? null);
            setReady(false);
          }}
        >
          {l1.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default SearchMember;
