import React, { useState, useContext } from "react";
import ClassCard from "./common/ClassCard";
import TopSection from "./common/TopSection";
import { Grid, makeStyles, Snackbar } from "@material-ui/core";
import TopBar from "./common/TopBar";
import { gql, useQuery } from "@apollo/client";
import Loading from "./common/Loading";
import MuiAlert from "@material-ui/lab/Alert";
import { Navigation } from "../Context";
import { RequireClassAccess } from "../../src/Auth/gates";
const useStyles = makeStyles(theme => ({}));
const QUERY_CLASSES = gql`
  query QueryClasses($centerId: ID!) {
    centersList(id: $centerId) {
      classes {
        id
        name
        section
        slug
        subject
        room
        isArchived
      }
    }
  }
`;

function Home() {
  const { centerId } = useContext(Navigation);

  //QUERY CLASS
  const { loading, data: dataQueryClass, refetch } = useQuery(QUERY_CLASSES, {
    onError: error => console.log("QueryClassError: ", error),
    notifyOnNetworkStatusChange: true,
    variables: {
      centerId
    }
  });
  const classes = useStyles();
  // Alert Logic
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFailure, setAlertFailure] = useState(false);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <TopBar
            location="home"
            refetch={refetch}
            alert={content => setAlertSuccess(content)}
            failure={content => setAlertFailure(content)}
          />
          <TopSection />
          <Grid container direction="row" className={classes.grid}>
            {dataQueryClass.centersList.classes.map(item => {
              if (item.isArchived === true) return null;
              return (
                <RequireClassAccess slug={item.slug} rejectBehaviour="hide">
                  <Grid key={item.id} item xs={12} sm={6} md={3}>
                    <ClassCard
                      key={item.id}
                      classData={item}
                      refetch={refetch}
                      alert={c => setAlertSuccess(c)}
                      failure={c => setAlertFailure(c)}
                    />
                  </Grid>
                </RequireClassAccess>
              );
            })}
          </Grid>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={Boolean(alertSuccess)}
            onClose={() => {
              setAlertSuccess(false);
            }}
            autoHideDuration={3000}
          >
            <MuiAlert
              className={classes.muiAlert}
              elevation={6}
              variant="filled"
              severity="success"
            >
              {alertSuccess}
            </MuiAlert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={alertFailure}
            autoHideDuration={3000}
            onClose={() => {
              setAlertFailure(false);
            }}
          >
            <MuiAlert
              className={classes.muiAlert}
              elevation={6}
              variant="filled"
              severity="error"
            >
              {alertFailure}
            </MuiAlert>
          </Snackbar>
        </>
      )}
    </>
  );
}

export default Home;
