import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";

export default function EvaluationResult({total, max, labels}){
  return (
    <Box mt={2}>
      <Box display='flex' alignItems="center" style={{gap: 16}}>
        <Typography variant="h5">المجموع النهائي:</Typography>
        <Box>
          <Typography variant="h4" component="span" color="secondary">
            <b>{Math.round(total)}{' '}</b>
          </Typography>
          <Typography variant="h5" component="span">
            <b>\ {max}</b>
          </Typography>
        </Box> 
      </Box>
      <Box display='flex' alignItems="center" style={{gap: 16}}>
        <Typography variant="h5">التقييم العام:</Typography>
        <Typography variant="h4" color="secondary">{Object.entries(labels).reverse().find(([min]) => total >= min)?.[1]}</Typography>
      </Box>
    </Box>
  )
}