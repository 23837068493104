import React, { useState, useReducer } from "react";
import {
  Box,
  Button,
  makeStyles,
  Typography,
  TextField,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress
} from "@material-ui/core";
import { l1 } from "./local.json";
import { gql, useMutation } from "@apollo/client";

const UPDATE_STUDENTWORK = gql`
  mutation updateStudentWork($id: ID!, $data: editStudentWorkInput!) {
    updateStudentWork(input: { where: { id: $id }, data: $data }) {
      studentWork {
        id
      }
    }
  }
`;
const useStyles = makeStyles(theme => ({
  container: {
    padding: "20px",
    margin: "20px 0"
  },
  questionBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "baseline"
  },
  questionHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
  },
  questionInput: {
    margin: "20px 0",
    width: "100%"
  },
  answerView: {
    background: "#eee",
    padding: "10px",
    margin: "20px 0",
    width: "100%"
  }
}));
export default function Question({ data, refetch }) {
  const [answer, setAnswer] = useState(null);
  // Confirmation Dialog Logic
  const [confirmationState, reverseConfirmationState] = useReducer(
    state => !state,
    false
  );
  const classes = useStyles();
  const [editStudentWorkMutation, { loading }] = useMutation(
    UPDATE_STUDENTWORK,
    {
      onError: error => {
        console.log("error in editing student work", error);
      },
      onCompleted: response => {
        refetch();
      }
    }
  );
  const edtiStudentWork = () => {
    editStudentWorkMutation({
      variables: {
        id: data[0].id,
        data: {
          submission: answer,
          status: "submitted"
        }
      }
    });
  };
  return (
    <>
      <Paper
        className={classes.container}
        rounded="true"
        type="outlined"
        elevation={12}
      >
        <Box className={classes.questionBox}>
          <Box className={classes.questionHeader}>
            <Typography variant="body1">{l1.yourAnswer}</Typography>
            <Typography variant="body1" style={{ color: "green" }}>
              {data[0].status !== "assigned" ? l1.handedIn : l1.assigned}
            </Typography>
          </Box>
          {data[0].status !== "assigned" ? (
            <Box className={classes.answerView}>
              <Typography variant="body1">{data[0].submission}</Typography>
            </Box>
          ) : (
            <div style={{ width: "100%" }}>
              <TextField
                fullWidth
                value={answer || ""}
                className={classes.questionInput}
                variant="outlined"
                placeholder={l1.typeYourAnswer}
                onChange={e => {
                  setAnswer(e.target.value);
                }}
              />
              <Button
                disabled={!Boolean(answer)}
                color="primary"
                variant="contained"
                onClick={reverseConfirmationState}
              >
                {l1.handIn}
              </Button>
            </div>
          )}
        </Box>
      </Paper>
      <Dialog open={confirmationState} onClose={reverseConfirmationState}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Typography style={{ fontWeight: "bold" }}>
            {l1.answerConfirmation}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={edtiStudentWork}
            disabled={loading}
            startIcon={loading ? <CircularProgress /> : null}
          >
            {l1.confirm}
          </Button>
          <Button
            variant="contained"
            onClick={reverseConfirmationState}
            disabled={loading}
          >
            {l1.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
