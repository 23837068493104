import React from 'react';

import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
import { onError } from 'apollo-link-error';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'universal-cookie/es6';
import { createUploadLink } from 'apollo-upload-client';

function Apollo({ children }) {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const cookies = new Cookies();
  const authLink = setContext((_, { headers }) => {
    const jwt = cookies.get('token');
    const token = jwt ? `Bearer ${jwt}` : '';
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  });
  const httpLink = createUploadLink({
    //  uri: 'https://strapi.rwad.tk/graphql',
    uri: process.env.REACT_APP_GRAPHQL_END_POINT,
  });
  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };
  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions,
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default Apollo;
