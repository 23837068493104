import { Button, TextField } from "@material-ui/core";
import React, { useState } from "react";
import PhoneNumber from "./PhoneNumber";
export default function PhoneNumbers({ phoneNumbers, setPhoneNumbers }) {
  const [state, setstate] = useState();
  const handleAdd = () => {
    const newPhoneNumber = { number: state };
    if (phoneNumbers && phoneNumbers.length > 0) {
      setPhoneNumbers([...phoneNumbers, newPhoneNumber]);
    } else setPhoneNumbers([newPhoneNumber]);
    setstate("");
  };
  const handleDelete = index => {
    const remainingNumbers = phoneNumbers?.filter(
      (number, idx) => index !== idx
    );
    setPhoneNumbers(remainingNumbers);
  };
  const editNumber = (index, newNumber) => {
    const editedPhoneNumbers = phoneNumbers?.map((phoneNumber, idx) => {
      // if this task has the same ID as the edited task
      if (index === idx) {
        //
        return { number: newNumber };
      }
      return phoneNumber;
    });
    setPhoneNumbers(editedPhoneNumbers);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start"
      }}
    >
      <div style={{ marginBottom: "30px" }}>
        <TextField
          value={state}
          placeholder="رقم جديد هنا"
          onChange={e => setstate(e.target.value)}
          style={{ width: "300px", marginLeft: "10px" }}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleAdd}
        >
          {" "}
          اضافة رقم هاتف
        </Button>
      </div>
      {phoneNumbers &&
        phoneNumbers?.map(({ id, number }, index) => (
          <PhoneNumber
            id={id}
            number={number}
            key={index}
            index={index}
            handleDelete={handleDelete}
            editNumber={editNumber}
          />
        ))}
    </div>
  );
}
