/* eslint-disable react-hooks/rules-of-hooks */
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Navigation } from "../../../Context";
import sparseUpdate from "../../../helpers/sparseUpdate";
import { useStudentWork } from "../context/studentWork";
import { useContext } from "react";

// possible permissions :
//  - actors: student, teacher
//  - actions: fill, view
//  targets for each action
//  - fill: own, others
//  - view: own, others, summary

const DEFAULT_PERMISSIONS = {
  student: {
    fill: ['own'],
    view: ['own'],
  },
  teacher: {
    fill: [],
    view: ['summary'],
  },
}

class EvaluationSubject{
  constructor(props = {}) {
    // Initialize item and collection names for GraphQL queries and mutations
    this.update(props);
    // Bind instance to component methods
    this.getPageTitle = this.getPageTitle.bind(this);
    this.useCheckPermissions = this.useCheckPermissions.bind(this);
    this.usePostSubmit = this.usePostSubmit.bind(this);
    this.EvaluationTable.Head = this.EvaluationTable.Head.bind(this);
    this.EvaluationTable.Footer = this.EvaluationTable.Footer.bind(this);
    this.EvaluationTable.Row = this.EvaluationTable.Row.bind(this);
  }
  
  key;
  questions ={};
  layout = [];
  resultLabels = null;
  permissions = DEFAULT_PERMISSIONS;
  listStudents = false;

  usePostSubmit(){
    console.log('default post submit');
    return () => { console.log('no action')}
  };

  EvaluationTable = {
    Head(){
      return null;
    },
    Footer(){
      return null;
    },
    Row(){
      return null;
    }
  }
  /** Sparse update of the object's attributes */
  update(props) {
    for (let prop in props) {
      if ([null, undefined].includes(this?.[prop])){
        this[prop] = props[prop];
      } else
      if (
        typeof props[prop] === "object" &&
        !Array.isArray(props[prop]) &&
        props[prop] !== null
      ) {
        if (!this.hasOwnProperty(prop)) {
          this[prop] = {};
        }
        sparseUpdate(this[prop], props[prop]);
      } else {
        this[prop] = props[prop];
      }
    }
  }

  getPageTitle(mode){
    return `${
      mode === 'summary'
      ?  "ملخص تقييمات"
      :mode === 'view'
      ? 'مشاهدة تقييم'
      : 'تقييم'
      } ${
        this.key === 'student'
        ? (mode === 'summary' ? 'الطلاب' : 'الطالب')
        : this.key === 'coach' ? 'المدرب'
        : 'الدورة التدريبية'
      }`
  }

  useCheckPermissions(mode){
    const {id, slug} = useParams();
    const history = useHistory();
    const {user, isCenterAdmin} = useContext(Navigation);
    const isStudent = user.classes.some(c => c.slug === slug);
    const isTeacher = isCenterAdmin || user.teachingClasses.some(c => c.slug === slug);
    const roles = [isStudent && 'student', isTeacher && 'teacher'].filter(Boolean);
    const {studentWork} = useStudentWork();
    const action = mode === 'summary' || studentWork?.submission?.[this.key] ? 'view' : 'fill';
    const isOwnWork = studentWork?.student?.id === user?.id;
    const target = mode === 'summary' ? 'summary' : (isOwnWork ? 'own' : 'others');
    
    for(let role of roles){
      if(this.permissions?.[role]?.[action].includes(target)){
        return;
      }
    }
    history.push(`/class/${slug}/classwork/${id}/details`);
  }


  
}
    
export default EvaluationSubject