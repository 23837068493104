import React, {useState} from 'react'
import { TextField, Button, Typography } from '@material-ui/core';
import { l1 } from "../../../../components/local.json";

export default function PhoneNumber({id,number,handleDelete,editNumber,index}){
    const [isEditing, setEditing] = useState();
    const [numberState,setNumber] = useState();
    const viewTemplate = (number)=>(
        <div style={{
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            height:"40px"
        }}>
        <Typography variant="h6" style={{width:"300px", marginLeft:"10px"}}>{number}</Typography>
        <div>
        <Button variant="contained" color="primary" size="small" style={{marginLeft:"5px"}} onClick={()=>{
            setEditing(true)
        }}>
        {l1.edit}
          </Button>
          
          <Button variant="contained" color="secondary" size="small" onClick={()=>{
              handleDelete(index)
              }}>
          {l1.delete}
          </Button>
          </div>
        </div>
    )
    const editingTemplate = (number)=>(
        <div style={{
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            height:"40px"
        }}>
        <TextField
            fullWidth
            style={{ width: "300px", marginLeft:"10px" }}
            value={numberState || number}
            onChange={(e) => setNumber(e.target.value)}
          />
          <div style={{marginRight:"30px"}}>
          <Button variant="contained" color="primary" size="small" style={{marginLeft:"5px"}} onClick={()=>{
              editNumber(index,numberState)
              setEditing(false)
              }}>
            {l1.submit}
          </Button>
          
          <Button variant="contained" color="secondary" size="small" onClick={()=>{
              setEditing(false)
          }}>
          {l1.cancel}
          </Button>
          </div>
          </div>
    )
    return (
        isEditing ? editingTemplate(number) : viewTemplate(number)
    )
}