import Form from "./Form";

const ComplaintAboutSuperior = {
  slug: "complaint-about-superior",
  name: "نموذج تقديم الموظف شكوى عن مديره",
  availableActions:{
    edit: () => false,
    view: (item, {user, membershipRole}) => membershipRole === 'owner' || item.employee.id === user.id,
    delete: (_, {membershipRole}) => membershipRole === 'owner',
  },
  fields:{
    employee:{
      label: 'الموظف',
      type: 'user',
      permissions: 'autoFillFirst',
      valueFor: {
        view: (value, context) => value ?? context.user,
        edit: (value, context) => value ?? context.user,
      },
    },
    job:{
      type: 'string',
      label: 'الوظيفة',
      permissions: 'fillFirst',
    },
    unit: {
      type: 'string',
      label: 'الوحدة التنظيمية',
      permissions: 'fillFirst',
    },
    employedSince: {
      type: 'date',
      label: 'تم التوظيف بتاريخ',
      permissions: 'fillFirst',
    },
    superior:{
      label: 'المدير المشتكى منه',
      type: 'string',
      permissions: 'fillFirst',
    },
    behavior:{
      label: 'تصرف المدير',
      type: 'string',
      multiline: true,
      minRows: 5,
      permissions: 'fillFirst',
    },
    reasons:{
      label: 'أسباب الشكوى',
      type: 'string',
      permissions: 'fillFirst',
      multiline: true,
      minRows: 5,
    }
  },
  layout:[
    {
      type: 'section',
      label: 'معلومات المشتكي',
      children: [
        'employee',
        'job',
        'unit',
        'employedSince'
      ]
    },
    {
      type: 'section',
      label: 'تفاصيل الشكوى',
      children: [
        'superior',
        'behavior',
        'reasons',
      ]
    },
  ],
  views:{
    list: {
      title: 'قائمة الشكاوى',
      requiredFields: ['employee', 'createdAt', 'superior'],
      columns: [
        {
          name: "الموظف",
          selector: ({ employee }) => employee ? employee?.fullname : '(غير محدد)',
          searchable: true,
          sortable: true,
        },
        {
          name: "تاريخ الشكوى",
          selector: ({ createdAt }) => createdAt,
          searchable: true,
          sortable: true,
        },
        {
          name: "المشتكى منه",
          selector: ({ superior }) => superior,
          searchable: true,
          sortable: true,
        },
      ],
    },
    add: {
      title: 'شكوى جديدة',
    },
    view: {
      title: 'تفاصيل الشكوى'
    }
  }
};
export default new Form(ComplaintAboutSuperior);
