import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { BiPrinter } from "react-icons/bi";
import { Link } from "react-router-dom";
import TopBar from "../../components/common/TopBar";
import { Navigation } from "../../Context";
import { FormContext, FormDefinition } from "./context";

const useStyles = makeStyles({
  paper: {
    border: "solid 1px black",
    boxShadow: "2px 3px 4px rgba(20,20,20,0.4);",
    borderRadius: 8,
    padding: 24,
    marginTop: 24,
  },
  noPadding: {
    paddingTop: 0,
  },
  noShadow: {
    boxShadow: "unset !important",
  },
});
function FormPageLayout({ view, children }) {
  const classes = useStyles();
  const form = useContext(FormDefinition);
  const formContext = useContext(FormContext);
  const { info } = useContext(Navigation);
  const printing = window.location.search.includes("print");
  useEffect(() => {
    if (printing) {
      window.addEventListener("afterprint", (e) => {
        window.close();
      });
      window.print();
    }
  }, []);
  return (
    <>
      {!printing && <TopBar />}
      <Box my={4}>
        <Container maxWidth="lg">
          {!printing && (
            <Box mb={4}>
              <Typography variant="h3" style={{ flexGrow: 1 }}>
                {form.name}
              </Typography>
            </Box>
          )}
          <Paper className={printing ? classes.noShadow : classes.paper}>
            {!printing && (
              <Box display="flex">
                <Breadcrumbs style={{ marginBottom: 16, flexGrow: 1 }}>
                  <Link to="/home">{info.centerName}</Link>
                  <Link to="/files">الملفات</Link>
                  {view === "list" ? (
                    <Typography>{form.name}</Typography>
                  ) : (
                    <Link to={view === "list" ? "" : form.routes.list.path}>
                      {form.name}
                    </Link>
                  )}
                  <Typography>
                    {
                      form.views[view === "list" ? "list" : formContext.mode]
                        ?.title
                    }
                  </Typography>
                </Breadcrumbs>
                {formContext?.mode === "view" && (
                  <Button
                    startIcon={<BiPrinter />}
                    onClick={() => {
                      window.open(`${window.location.href}?print`);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    طباعة
                  </Button>
                )}
              </Box>
            )}
            <Typography variant="h4" className={classes.noPadding} style={{marginBottom: 30}}>
              {form.views[view === "list" ? "list" : formContext.mode]?.title}
            </Typography>
            {children}
          </Paper>
        </Container>
      </Box>
    </>
  );
}

FormPageLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  form: PropTypes.object,
};
FormPageLayout.defaultProps = {};

export default FormPageLayout;
