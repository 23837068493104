import { Box, Button, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from "@material-ui/core";
import React from "react";
import { FaMinus, FaPlus } from 'react-icons/fa';
import FormField from "../FormField";
import { useField } from "formik";
import sparseUpdate from "../../../helpers/sparseUpdate";
export default function TableField({
  // field properties
  name,
  type,
  label,
  // data properties
  edit,
  columns,
  value: rows,
  emptyLabel,
  singleField,
  ...other
}) {
  const aggregate = columns?.some(({aggregate}) => typeof aggregate === 'function');
  const [props, meta, helpers] = useField(name);
  return ( <Box mt={3}>
    { label && !singleField &&  
      <Typography variant="h5">
        {label}
      </Typography>
    }
    <Table style={{ paddingBottom: 20 }}>
        <TableHead>
          <TableRow>
            {edit && <TableCell size="small"/>}
            { columns.map((col, index) => (
              <TableCell size="small" key={index}>
                <Typography color="secondary" variant="h6">{col.label}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0
            ? <TableRow>
              <TableCell size="small" colSpan={columns.length + 1}>
                <Box display="flex" alignItems="center" justifyContent="center" py={4} px={2}>
                  <Typography variant="h5">{emptyLabel}</Typography>
                </Box>
              </TableCell>
            </TableRow>
            : rows.map((_, rowIndex) => (
              <TableRow key={rowIndex}>
                {edit && (
                  <TableCell size="small" width={40} style={{ verticalAlign: "top" }}>
                    <IconButton color="secondary" onClick={() => { 
                      const remainingRows = rows;
                      remainingRows.splice(rowIndex, 1);
                      helpers.setValue(remainingRows);
                    } }>
                      <FaMinus />
                    </IconButton>
                  </TableCell>
                )}
                {columns.map((column, columnIndex) => (
                  <TableCell size="small" key={columnIndex} style={{ verticalAlign: "top" }}>
                    {column.type === 'calculated'
                      ? <Typography variant="h6"><b>{column.calculate({ rowIndex, rows })}</b></Typography>
                      : <TableCellFormField
                        tableName={name}
                        rowIndex={rowIndex}
                        columnIndex={columnIndex}
                        definition={column} />}
                  </TableCell>
                ))}
              </TableRow>
            ))}

        </TableBody>
        <TableFooter>

          {aggregate && (
            <TableRow>
              {edit && <TableCell size="small" />}
              {columns.map((column, columnIndex) => {
                return (
                  <TableCell size="small" key={columnIndex}>
                    <Typography variant="h6" color="secondary">
                      {typeof column.aggregate === 'function' ? column.aggregate({ rows, rowIndex: column.rowIndex }) : null}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          )}
          {edit && <TableRow>
            <TableCell size="small" colSpan={columns.length + 1}>
              <Button
                startIcon={<FaPlus />}
                variant="contained"
                color="primary"
                style={{ width: "100%", textAlign: 'center' }}
                onClick={() => { helpers.setValue(rows.concat([Array.from(columns, () => null)])); } }
              >
                إضافة سطر
              </Button>
            </TableCell>
          </TableRow>}
        </TableFooter>
      </Table>
  </Box> )
}

function TableCellFormField({tableName, rowIndex, columnIndex, definition}){
  const fieldName = `${tableName}[${rowIndex}][${columnIndex}]`;
  const [props] = useField(fieldName);

  
  return  <FormField
    {...definition}
    {...props}
    size='small'
    style={{marginBottom:0}}
  />;
}