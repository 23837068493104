import Form from "./Form";

const EventPlan = {
  slug: "event-plan",
  name: "نموذج تخطيط فعالية / نشاط",
  fields: {
    name: {
      label: "اسم النشاط",
      type: "string",
    },
    guests: {
      label: "الضيوف",
      type: "string",
      multiline: true,
    },
    date: {
      label: "التاريخ",
      type: "string",
    },
    time: {
      label: "الوقت",
      type: "string",
    },
    location: {
      label: "تفاصيل المكان",
      type: "string",
      multiline: true,
    },
    manager: {
      label: "مدير المشروع",
      type: "string",
    },
    whatTheyShouldKnow: {
      label: "ماذا تريد لهم أن يعرفوا",
      type: "string",
      multiline: true,
    },
    revenueTarget: {
      label: "العائد من المشروع لصالح من؟",
      type: "string",
      multiline: true,
    },
    programme: {
      label: "شرح عام لما سيحدث",
      type: "string",
      multiline: true,
    },
    funding : {
      label: "التمويل",
      helperText: "اكتب بالتفصيل ما هي تكاليف النشاط و كيف تقترح تعطيتها (اشتراكات، ميزانية مخصصة، من الرعاه، النشاط سيغطي نفسه بنفسه) ",
      type: "string",
      multiline: true,
    },
  },
  layout: [
    {
      type: "section",
      singleField: "name",
    },
    {
      type: "section",
      singleField: "guests",
    },
    {
      label: "الإطار الزماني و المكاني",
      type: "section",
      children: ['date', 'time', 'location']
    },
    {
      type: "section",
      singleField: "manager",
    },
    {
      type: "section",
      singleField: "whatTheyShouldKnow",
    },
    {
      type: "section",
      singleField: "programme",
    },
    {
      type: "section",
      singleField: "revenueTarget",
    },
    {
      type: "section",
      singleField: "funding",
    },




  ],

  views: {
    list: {
      title: "قائمة الأنشطة",
      requiredFields: ["name", "date"],
      columns: [
        {
          name: "اسم النشاط",
          selector: ({ name }) => <span color="red">{name}</span>,
          searchable: true,
          sortable: true,
        },
        {
          name: "تاريخ النشاط",
          selector: ({ date }) => date,
          searchable: true,
          sortable: true,
        },
      ],
    },
    add: {
      title: "نشاط جديد",
    },
    edit: {
      title: "تعديل ورقة تخطيط النشاط",
    },
    view: {
      title: "ورقة تخطيط نشاط",
    },
  },
};


export default new Form(EventPlan);
