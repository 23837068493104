import React, { useState, createRef, useReducer } from "react";
import MemberCard from "./MemberCard";
import Carousel from "react-elastic-carousel";
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  TextField,
} from "@material-ui/core";
import "./Styling.scss";
export default function ListMembers({ team, setTeam, ...props }) {
  const Memberscarousel = createRef();
  const [carouselIndex, setcarouselIndex] = useState({
    members: 1,
  });
  const history = useHistory();
  const [state, reverse] = useReducer((s) => !s, false);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];
  const deleteMember = (index) => {
    const remainingTeamMembers = team.filter(
      (teamMember, idx) => index !== idx
    );
    let newTeam = remainingTeamMembers.map((teamMember) => {
      return {
        fullName: teamMember.fullName,
        role: teamMember.role,
        picture: teamMember.picture?.id,
        facebook: teamMember.facebook,
        instagram: teamMember.instagram,
        twitter: teamMember.twitter,
      };
    });
    console.log(newTeam);
    setTeam(newTeam);
  };
  return (
    <>
      <div className="nav-arrows only-desktop" data-aos="fade-up">
        <svg
          onClick={() => {
            //inactive
            if (carouselIndex.members + 1 > 4) {
              Memberscarousel.current.goTo(0);
              setcarouselIndex({ ...carouselIndex, members: 1 });
            } else {
              Memberscarousel.current.slideNext();
              setcarouselIndex({
                ...carouselIndex,
                members: carouselIndex.members + 1,
              });
            }
          }}
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          className="bi bi-chevron-right arrow"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
        <svg
          onClick={() => Memberscarousel.current.slidePrev()}
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          className="bi bi-chevron-left arrow"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          />
        </svg>
      </div>

      <Carousel
        breakPoints={breakPoints}
        showArrows={false}
        renderArrow={null}
        ref={Memberscarousel}
        className="list-members"
        pagination={false}
        xs={12}
        sm={6}
        md={4}
      >
        {team?.map(
          (
            { fullName, role, twitter, instagram, facebook, picture },
            index
          ) => (
            <MemberCard
              key={index}
              deleteMember={deleteMember}
              index={index}
              picture={picture}
              name={fullName}
              role={role}
              instagram={instagram}
              facebook={facebook}
              twitter={twitter}
            />
          )
        )}
      </Carousel>
      {history.location.pathname === "/" ? null : (
        <div style={{ marginTop: "50px" }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={reverse}
          >
            إضافة عضو جديد
          </Button>
          {/* <label
            type="primary"
            style={{
              color: "white",
              padding: `10px 20px`,
              background: "grey",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={reverse}
            //htmlFor="uploadToGallery"
          >
            إضافة عضو جديد
          </label> */}
        </div>
      )}

      <Dialog open={state} onClose={reverse}>
        <Box display="flex" flexDirection="column" style={{ padding: 50 }}>
          <TextField
            label="الاسم"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ marginBottom: 10 }}
            variant="outlined"
          />
          <TextField
            label="الصفة"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            style={{ marginBottom: 10 }}
            variant="outlined"
          />
          <TextField
            label="رابط الانستاجرام"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
            style={{ marginBottom: 10 }}
            variant="outlined"
          />
          <TextField
            label="رابط الفيسبيوك"
            value={facebook}
            onChange={(e) => setFacebook(e.target.value)}
            style={{ marginBottom: 10 }}
            variant="outlined"
          />
          <TextField
            label="رابط التويتر"
            value={twitter}
            onChange={(e) => setTwitter(e.target.value)}
            style={{ marginBottom: 10 }}
            variant="outlined"
          />
          <TextField
            type="file"
            helperText="الصورة"
            id="uploadToGallery"
            onChange={(e) => {
              props.addPicture({
                variables: {
                  file: e.target.files[0],
                },
              });
            }}
            variant="outlined"
          />
        </Box>
        <DialogActions>
          <Button
            variant="contained"
            disabled={props.loading}
            color="primary"
            style={{ marginLeft: "5px" }}
            onClick={() => {
              const newTeamMemeber = {
                fullName: name,
                role: role,
                picture: props.pictureID,
                instagram: instagram,
                facebook: facebook,
                twitter: twitter,
              };
              let newTeam = team.map((teamMember) => {
                return {
                  fullName: teamMember.fullName,
                  role: teamMember.role,
                  picture: teamMember.picture?.id,
                  facebook: teamMember.facebook,
                  instagram: teamMember.instagram,
                  twitter: teamMember.twitter,
                };
              });
              setTeam([...newTeam, newTeamMemeber]);
              // props.add(newTeam);
              setName("");
              setRole("");
              setInstagram("");
              setFacebook("");
              setTwitter("");
              reverse();
            }}
          >
            أضف
          </Button>
          <Button variant="contained" color="secondary" onClick={reverse}>
            الغاء
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
