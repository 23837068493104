import React, { useContext, useReducer, useState } from "react";
import { l1 } from "../components/local.json";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  Paper,
  TextField
} from "@material-ui/core";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import TopBar from "./common/TopBar";
import { gql, useQuery, useMutation } from "@apollo/client";
import Loading from "./common/Loading";
import { Navigation } from "../Context";
const useStyles = makeStyles(theme => ({
  highOrder: {
    margin: theme.spacing(10, 30)
  },
  title: {
    fontSize: theme.spacing(3),
    fontWeight: 600,
    color: theme.palette.grey[700]
  },
  label: {
    marginLeft: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(4)
  },
  headContainer: {
    //margin: theme.spacing(0, 10),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[100]}`,
    background: `${theme.palette.grey["50"]}30`,
    borderRadius: theme.spacing(2)
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  iconHead: {
    color: "#44566c",
    margin: theme.spacing(0, 1)
  },
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    background: "white",
    marginBottom: theme.spacing(1),
    cursor: "pointer"
  },
  avatar: {
    margin: theme.spacing(0, 1)
  },
  nameText: {},
  nameName: {
    color: "#44566c"
  },
  menuItem: {
    paddingRight: theme.spacing(10)
  },
  nameTitle: {
    color: "#44566c",
    fontSize: theme.spacing(1.4)
  },
  idId: {
    color: "#8697a8"
  },
  address: {
    color: "#44566c"
  },
  icon: {
    fontSize: theme.spacing(3),
    color: "#8697a8",
    marginLeft: theme.spacing(3)
  }
}));

const GET_STUDENTS = gql`
  query GetStudent($id: ID!) {
    centersList(id: $id) {
      memberships(sort: "createdAt:desc", where: { status: "ACTIVE" }) {
        fullname
        user {
          id
        }
        email
        id
        fullname
        school
        specialty
        email
        field
        afterGraduate
        homeTel
        mobileTel
        hobbies
        birthDate
        object
        college
        address
      }
    }
  }
`;
const GET_INFO = gql`
  query GetInfo($student: ID!, $id: ID!) {
    centersList(id: $id) {
      memberships(where: { student: $student }) {
        id
        fullname
        school
        specialty
        email
        field
        afterGraduate
        homeTel
        mobileTel
        hobbies
        birthDate
        object
        college
        address
      }
    }
  }
`;
const DELETE_STUDENT = gql`
  mutation DeleteStudent($id: ID!) {
    deleteMembership(input: { where: { id: $id } }) {
      membership {
        id
      }
    }
  }
`;
const BAN_STUDENT = gql`
  mutation BanStudent($id: ID!) {
    updateMembership(input: { where: { id: $id }, data: { status: BANNED } }) {
      membership {
        id
      }
    }
  }
`;

function AdminPanel() {
  const { centerId } = useContext(Navigation);

  //Apollo
  const { loading, data, refetch } = useQuery(GET_STUDENTS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: centerId
    }
  });
  const [userInfoId, setUserInfoId] = useState(null);
  const [skipState, reverseSkipState] = useReducer(s => !s, true);
  const [state, reverseState] = useReducer(s => !s, false);
  // const { loading: loadingUserInfo, data: userInfo } = useQuery(GET_INFO, {
  //   skip: skipState,
  //   onCompleted: () => reverseState(),
  //   variables: {
  //     student: userInfoId,
  //     id: centerId
  //   }
  // });
  const [deleteStudent, { loading: loadingDelete }] = useMutation(
    DELETE_STUDENT,
    {
      onError: err => console.log("dase errris", err),
      onCompleted: () => {
        console.log("student has been deleted");
        refetch();
      }
    }
  );
  const [BanStudent, { loading: loadingBan }] = useMutation(BAN_STUDENT, {
    onError: err => console.log("dase errris", err),
    onCompleted: () => {
      console.log("student has been banned");
      refetch();
    }
  });
  const getUserInfo = () => {
    if (state) {
      const {
        fullname,
        school,
        specialty,
        email,
        field,
        afterGraduate,
        homeTel,
        mobileTel,
        hobbies,
        birthDate,
        object,
        college,
        address
      } = data.centersList.memberships;
      return (
        <Dialog
          maxWidth="md"
          open={state}
          onClose={() => {
            reverseState();
            reverseSkipState();
          }}
        >
          <Paper className={classes.paper} variant="outlined">
            <Box display="flex" justifyContent="center">
              <Typography className={classes.title}>{l1.formTitle}</Typography>
            </Box>

            <TextField
              InputProps={{
                readOnly: true
              }}
              variant="outlined"
              margin="normal"
              fullWidth
              label={l1.fullName}
              name="fullname"
              autoFocus
              value={fullname}
            />
            <TextField
              InputProps={{
                readOnly: true
              }}
              variant="outlined"
              margin="normal"
              fullWidth
              label={l1.birthDate}
              name="birthDate"
              autoFocus
              value={birthDate}
              InputLabelProps={{ className: classes.label }}
            />
            <TextField
              InputProps={{
                readOnly: true
              }}
              variant="outlined"
              margin="normal"
              fullWidth
              label={l1.school}
              name="school"
              autoFocus
              value={school}
            />
            <TextField
              InputProps={{
                readOnly: true
              }}
              variant="outlined"
              margin="normal"
              fullWidth
              label={l1.college}
              name="college"
              autoFocus
              value={college}
            />
            <TextField
              InputProps={{
                readOnly: true
              }}
              variant="outlined"
              margin="normal"
              fullWidth
              label={l1.specialities}
              name="specialty"
              autoFocus
              value={specialty}
            />
            <TextField
              InputProps={{
                readOnly: true
              }}
              variant="outlined"
              margin="normal"
              fullWidth
              label={l1.address}
              name="address"
              autoFocus
              value={address}
            />
            <TextField
              InputProps={{
                readOnly: true
              }}
              variant="outlined"
              margin="normal"
              fullWidth
              label={l1.homeTel}
              name="homeTel"
              autoFocus
              value={homeTel}
            />
            <TextField
              InputProps={{
                readOnly: true
              }}
              variant="outlined"
              margin="normal"
              fullWidth
              label={l1.tel}
              name="mobileTel"
              autoFocus
              value={mobileTel}
            />
            <TextField
              InputProps={{
                readOnly: true
              }}
              variant="outlined"
              margin="normal"
              fullWidth
              label={l1.email}
              name="email"
              autoFocus
              value={email}
            />
            <TextField
              InputProps={{
                readOnly: true
              }}
              variant="outlined"
              margin="normal"
              fullWidth
              label={l1.q1}
              name="object"
              autoFocus
              rows={6}
              multiline
              value={object}
            />
            <TextField
              InputProps={{
                readOnly: true
              }}
              variant="outlined"
              margin="normal"
              fullWidth
              label={l1.q2}
              name="field"
              autoFocus
              rows={6}
              multiline
              value={field}
            />
            <TextField
              InputProps={{
                readOnly: true
              }}
              variant="outlined"
              margin="normal"
              fullWidth
              label={l1.q3}
              name="hobbies"
              autoFocus
              rows={6}
              multiline
              value={hobbies}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label={l1.q4}
              name="afterGraduate"
              autoFocus
              rows={6}
              multiline
              value={afterGraduate}
              InputProps={{
                readOnly: true
              }}
            />
            <Box
              display="flex"
              justifyContent="center"
              className={classes.button}
            ></Box>
          </Paper>
        </Dialog>
      );
    }
    return "";
  };
  const classes = useStyles();
  if (loading || loadingDelete || loadingBan) return <Loading />;
  else {
    const students = data.centersList.memberships;
    return (
      <Box>
        <TopBar type="admin" tab="students" />
        <Box className={classes.highOrder}>
          <Grid
            container
            className={classes.headContainer}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Box display="flex" alignItems="center" flexDirection="row">
                <Typography className={classes.text}>{l1.username}</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                //style={{ right: -70, position: 'relative' }}
              >
                <Typography className={classes.text}>{l1.id}</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" alignItems="center" flexDirection="row">
                <Typography className={classes.text}>{l1.email}</Typography>
              </Box>
            </Grid>

            <Grid item xs={1}></Grid>
          </Grid>
          {students.map(({ fullname, email, id, user }) => (
            <>
              <TableRow
                name={fullname}
                email={email}
                id={id}
                key={user.id}
                reverse={() => {
                  reverseSkipState();
                  setUserInfoId(user);
                }}
              />
              {getUserInfo()}
            </>
          ))}
        </Box>
      </Box>
    );
  }

  function TableRow({ name, email, id, reverse }) {
    // Options Logic
    const [anchorPost, setAnchorPost] = useState(null);
    const openPostMenu = event => {
      setAnchorPost(event.currentTarget);
    };
    const closePostMenu = () => {
      setAnchorPost(null);
    };
    // Confirmation Dialog Logic
    const [confirmationState, reverseConfirmationState] = useReducer(
      state => !state,
      false
    );
    const [confirmationBanState, reverseConfirmationBanState] = useReducer(
      state => !state,
      false
    );
    const classes = useStyles();
    return (
      <Box className={classes.container}>
        <Grid container alignItems="center">
          <Grid item xs={4} onClick={() => reverse()}>
            <Box
              className={classes.nameContainer}
              display="flex"
              alignItems="center"
              flexDirection="row"
            >
              <Avatar className={classes.avatar} />
              <Box className={classes.nameText}>
                <Typography className={classes.nameName}>{name}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} onClick={() => reverse()}>
            <Typography className={classes.idId}>{id}</Typography>
          </Grid>
          <Grid item xs={4} onClick={() => reverse()}>
            <Typography className={classes.address}>{email}</Typography>
          </Grid>

          <Grid item xs={1}>
            <BiDotsHorizontalRounded
              className={classes.icon}
              onClick={openPostMenu}
            />
          </Grid>
        </Grid>
        <Menu
          open={Boolean(anchorPost)}
          onClose={closePostMenu}
          elevation={1}
          anchorEl={anchorPost}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={reverseConfirmationBanState}
          >
            <Typography>{l1.ban}</Typography>
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={reverseConfirmationState}
          >
            <Typography>{l1.delete}</Typography>
          </MenuItem>
        </Menu>
        <Dialog open={confirmationState} onClose={reverseConfirmationState}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <Typography style={{ fontWeight: "bold" }}>
              {l1.confirmation}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                deleteStudent({
                  variables: {
                    id
                  }
                });
              }}
            >
              {l1.confirm}
            </Button>
            <Button variant="contained" onClick={reverseConfirmationState}>
              {l1.cancel}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={confirmationBanState}
          onClose={reverseConfirmationBanState}
        >
          <DialogTitle></DialogTitle>
          <DialogContent>
            <Typography style={{ fontWeight: "bold" }}>
              {l1.confirmation}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                BanStudent({
                  variables: {
                    id
                  }
                });
              }}
            >
              {l1.confirm}
            </Button>
            <Button variant="contained" onClick={reverseConfirmationBanState}>
              {l1.cancel}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}
export default AdminPanel;
