import { createContext, useContext } from "react";
import Loading from "../../common/Loading";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { gql, useQuery } from "@apollo/client";

export const StudentWorksContext = createContext();

const QUERY_STUDENTWORKS = gql`
  query QueryStudentWorks($id: ID!) {
    studentWorks( where: { assignment: {id: $id}}) {
      id
      student {
        id
        fullname
        avatar
      }
      submission
    }
  }
`;

export function StudentWorksProvider({type, children}){
  const {id} = useParams();
  const {data, loading, error} = useQuery(QUERY_STUDENTWORKS, {variables: {id}});
  return (
    loading
    ? <Loading />
    : error || !data?.studentWorks
    ? 'ERROR'
    : <StudentWorksContext.Provider value={data.studentWorks}>
      {children}
    </StudentWorksContext.Provider>
  )
}

export function useStudentWorks(){
  return useContext(StudentWorksContext) ?? [];
}