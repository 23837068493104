import React, { useContext, useReducer, useState } from "react";
import { l1 } from "../../local.json";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Devider,
  IconButton,
  Divider,
  Container,
  TextField,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  CircularProgress,
  Snackbar
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import CloseIcon from "@material-ui/icons/Close";
import TopBar from "../../common/TopBar";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";

import Loading from "../../common/Loading";
import { Navigation } from "../../../Context";
import TopicSection from "../class/components/TopicSection";
import EditEvent from "./EditEvent";

const useStyles = makeStyles(theme => ({
  highOrder: {
    margin: theme.spacing(5, 30)
  },
  headContainer: {
    //margin: theme.spacing(0, 10),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[100]}`,
    background: `${theme.palette.grey["50"]}30`,
    borderRadius: theme.spacing(2)
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  iconHead: {
    color: "#44566c",
    margin: theme.spacing(0, 1)
  },
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    background: "white",
    marginBottom: theme.spacing(1),
    cursor: "pointer"
  },
  avatar: {
    margin: theme.spacing(0, 1)
  },
  nameText: {},
  nameName: {
    color: "#44566c"
  },
  menuItem: {
    paddingRight: theme.spacing(10)
  },
  nameTitle: {
    color: "#44566c",
    fontSize: theme.spacing(1.4)
  },
  idId: {
    color: "#8697a8"
  },
  address: {
    color: "#44566c"
  },
  icon: {
    fontSize: theme.spacing(3),
    color: "#8697a8",
    marginLeft: theme.spacing(3)
  },
  create: {
    marginLeft: "240px",
    marginTop: "30px",
    width: "150px"
  },
  createDialogContent: {
    marginTop: "100px",
    display: "flex",
    flexDirection: "column"
  },
  eventImage: {
    width: "100px",
    marginTop: "20px"
  }
}));

const CREATE_EVENT = gql`
  mutation createEvent($data: CenterEventInput!) {
    createCenterEvent(input: { data: $data }) {
      centerEvent {
        id
      }
    }
  }
`;
const GET_EVENTS = gql`
  query getEvents($subdomain: String!) {
    centersLists(where: { subdomain: $subdomain }) {
      events {
        id
        title
        content
        image {
          url
        }
        excerpt
        createdAt
        updatedAt
      }
    }
  }
`;
const GET_EVENT = gql`
  query getEvent($id: ID!) {
    centerEvent(id: $id) {
      id
      title
      content
      excerpt
      image {
        id
        url
      }
    }
  }
`;
const UPDATE_EVENT = gql`
  mutation updateEvent($id: ID!, $data: editCenterEventInput!) {
    updateCenterEvent(input: { where: { id: $id }, data: $data }) {
      centerEvent {
        id
      }
    }
  }
`;
const DELETE_EVENT = gql`
  mutation DeleteEvent($id: ID!) {
    deleteCenterEvent(input: { where: { id: $id } }) {
      centerEvent {
        id
      }
    }
  }
`;
function Events() {
  const [create, setCreate] = useState(null);
  const [edit, setEdit] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [eventTitle, setTitle] = useState(null);
  const [eventContent, setContent] = useState(null);
  const [eventExcerpt, setExcerpt] = useState(null);
  const [saveLoading, setSaveLoading] = useState(null);
  const [eventImageId, setEventImageId] = useState(null);
  const [eventImage, setEventImage] = useState(null);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFailure, setAlertFailure] = useState(false);

  const reverseCreate = () => {
    setCreate(!create);
  };
  const { centerId, sub } = useContext(Navigation);
  //Apollo
  const { loading, data, refetch } = useQuery(GET_EVENTS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: centerId,
      subdomain: sub
    }
  });
  const [getEvent, { loading: getEventLoading }] = useLazyQuery(GET_EVENT, {
    onError: err => {
      console.log("error when getting one event", err);
    },
    onCompleted: data => {
      console.log("data", data);
      setTitle(data.centerEvent.title);
      setContent(data.centerEvent.content);
      setExcerpt(data.centerEvent.excerpt);
      setEventImage(data.centerEvent.image.url);
      setEventId(data.centerEvent.id);
      setEventImageId(data.centerEvent.image.id);
      setEdit(true);
      reverseCreate();
    }
  });
  const [deleteEvent, { loading: loadingDelete }] = useMutation(DELETE_EVENT, {
    onError: err => {
      console.log("error when deleting event", err);
      setAlertFailure(l1.failure);
    },
    onCompleted: () => {
      setAlertSuccess(l1.editsuccess);
      refetch();
    }
  });
  const [createEvent, { loading: loadingCreate }] = useMutation(CREATE_EVENT, {
    onError: err => {
      console.log("error on creating event", err);
      setAlertFailure(l1.failure);
    },
    onCompleted: data => {
      console.log("created with success", data);
      setAlertSuccess(l1.editsuccess);
      reverseCreate();
      refetch();
    }
  });
  const [updateEvent, { loading: loadingUpdate }] = useMutation(UPDATE_EVENT, {
    onError: err => {
      console.log("error when updating event", err);
      setAlertFailure(l1.failure);
    },
    onCompleted: data => {
      console.log("updated with sucess", data);
      setAlertSuccess(l1.editsuccess);
      reverseCreate();
      refetch();
    }
  });
  const saveAllChanges = () => {
    console.log("save changes");
    if (edit) {
      updateEvent({
        variables: {
          id: eventId,
          data: {
            title: eventTitle,
            content: eventContent,
            excerpt: eventExcerpt,
            image: eventImageId
          }
        }
      });
    } else {
      createEvent({
        variables: {
          data: {
            title: eventTitle,
            content: eventContent,
            excerpt: eventExcerpt,
            image: eventImageId,
            center: centerId
          }
        }
      });
    }
  };
  const editEvent = id => {
    getEvent({
      variables: { id: id }
    });
  };
  const classes = useStyles();
  if (loading || loadingDelete || getEventLoading || loadingUpdate)
    return <Loading />;
  else {
    const events = data?.centersLists[0]?.events;
    return (
      <Box>
        <TopBar type="admin" tab="events" />
        <Button
          color="primary"
          variant="contained"
          size="large"
          className={classes.create}
          onClick={reverseCreate}
        >
          {l1.add}
        </Button>
        <Box className={classes.highOrder}>
          <Grid container className={classes.headContainer} display="flex">
            <Grid item xs={4}>
              <Box display="flex" alignItems="center" flexDirection="row">
                <Typography className={classes.text}>{l1.image}</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                //style={{ right: -70, position: 'relative' }}
              >
                <Typography className={classes.text}>{l1.title}</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" alignItems="center" flexDirection="row">
                <Typography className={classes.text}>{l1.excerpt}</Typography>
              </Box>
            </Grid>

            <Grid item xs={1}></Grid>
          </Grid>
          {events &&
            events?.map(event => (
              <TableRow
                key={event.id}
                id={event.id}
                title={event.title}
                image={event.image?.url}
                excerpt={event.excerpt}
              />
            ))}
        </Box>
        <EditEvent
          classes={classes}
          create={create}
          eventTitle={eventTitle}
          eventContent={eventContent}
          eventImage={eventImage}
          eventExcerpt={eventExcerpt}
          setEventImageId={setEventImageId}
          setEventImage={setEventImage}
          setTitle={setTitle}
          setContent={setContent}
          setExcerpt={setExcerpt}
          saveLoading={loadingCreate}
          reverseCreate={reverseCreate}
          saveAllChanges={saveAllChanges}
          edit={edit}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={Boolean(alertSuccess)}
          onClose={() => {
            setAlertSuccess(false);
          }}
          autoHideDuration={3000}
        >
          <MuiAlert
            className={classes.muiAlert}
            elevation={6}
            variant="filled"
            severity="success"
          >
            {alertSuccess}
          </MuiAlert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={Boolean(alertFailure)}
          onClose={() => {
            setAlertFailure(false);
          }}
          autoHideDuration={3000}
        >
          <MuiAlert
            className={classes.muiAlert}
            elevation={6}
            variant="filled"
            severity="error"
          >
            {alertFailure}
          </MuiAlert>
        </Snackbar>
      </Box>
    );
  }

  function TableRow({ id, title, image, excerpt }) {
    // Options Logic

    // Confirmation Dialog Logic
    const [confirmationState, reverseConfirmationState] = useReducer(
      state => !state,
      false
    );
    const [confirmationBanState, reverseConfirmationBanState] = useReducer(
      state => !state,
      false
    );
    const classes = useStyles();
    return (
      <Box className={classes.container}>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Box
              className={classes.nameContainer}
              display="flex"
              alignItems="center"
              flexDirection="row"
            >
              <Box className={classes.nameText}>
                {/* <Typography className={classes.nameName}>{id}</Typography> */}
                <img
                  src={`${process.env.REACT_APP_STRAPI_URL}${image}`}
                  width="100"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.idId}>{title}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.address}>{excerpt}</Typography>
          </Grid>

          <Grid item xs={1} alignItems="center" justifyContent="center">
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                editEvent(id);
              }}
            >
              {l1.edit}
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              onClick={reverseConfirmationState}
            >
              {l1.delete}
            </Button>
          </Grid>
        </Grid>

        <Dialog open={confirmationState} onClose={reverseConfirmationState}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <Typography style={{ fontWeight: "bold" }}>
              {l1.confirmation}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                deleteEvent({
                  variables: {
                    id: id
                  }
                });
              }}
            >
              {l1.confirm}
            </Button>
            <Button variant="contained" onClick={reverseConfirmationState}>
              {l1.cancel}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}
export default Events;
