import React from "react";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { BiCalendarAlt } from "react-icons/bi";
import TopicRow from "./components/TopicRow";
import TopicSection from "./components/TopicSection";
import Create from "./components/Create";
import { l1 } from "../../local.json";
import { gql, useQuery } from "@apollo/client";
import Loading from "../../common/Loading";
import { useParams, useHistory } from "react-router-dom";
import TopBar from "../../common/TopBar";
import { RequireClassAccess, RequireTeacherAccess } from "../../../Auth/gates";
import { BiDotsVerticalRounded } from "react-icons/bi";
import SchoolIcon from "@material-ui/icons/School";

const useStyles = makeStyles(theme => ({
  rightContainer: {
    marginTop: theme.spacing(15)
  },
  topicContainer: {
    alignSelf: "center",
    width: theme.spacing(15),
    textAlign: "center",
    padding: theme.spacing(1.5, 2),
    "&:hover": {
      backgroundColor: "#E8F0FE",
      cursor: "pointer",
      borderRadius: theme.spacing(1)
    },
    "&>*": {
      fontSize: theme.spacing(1.6),
      color: theme.palette.grey[500]
    }
  },
  topicTitle: {
    fontWeight: 600,
    color: "#6785F4"
  },
  topicTitleContainer: {
    marginBottom: theme.spacing(1)
  },

  mainContainer: {
    marginTop: theme.spacing(3)
  },

  calendar: {
    color: theme.palette.grey[600],
    padding: theme.spacing(1, 2),
    "&:hover": {
      color: "#6785F4"
    }
  }
}));
const QUERY_CLASSWORK = gql`
  query QueryClasswork($slug: String!) {
    classes(where: { slug: $slug }) {
      id
      name
      section
      topics(sort: "createdAt:desc") {
        name
        id
        assignments(sort: "createdAt:desc") {
          id
          title
          description
          type
          createdAt
          evaluationsInfo {
            coach
            date
            location
          }
          studentWorks {
            status
            submission
          }
        }
      }
    }
  }
`;
const QUERY_NULL_TOPIC = gql`
  query QueryClasswork($slug: String!) {
    classes(where: { slug: $slug }) {
      assignments(where: { topic_null: true }, sort: "createdAt:desc") {
        id
        type
        title
        description
        createdAt
        evaluationsInfo {
          coach
          date
          location
        }
        studentWorks {
          status
          submission
        }
      }
    }
  }
`;
function Classwork() {
  const history = useHistory();
  const { slug } = useParams();
  // Apollo
  const { loading, data, refetch } = useQuery(QUERY_CLASSWORK, {
    variables: { slug },
    onError: error => console.log("QueryClassworkError: ", error),
    notifyOnNetworkStatusChange: true
  });
  const { loading: loadingNullTopic, data: nullTopicData } = useQuery(
    QUERY_NULL_TOPIC,
    {
      variables: { slug },
      onError: error => console.log("QueryNullError: ", error),
      onCompleted: data => console.log("QueryNullSuccess: ", data)
    }
  );
  const classes = useStyles();
  const evaluationStatistics = works => ({
    all: works.length,
    trainingEvaluations: works.filter(work => Boolean(work?.submission?.trainingEvaluation)).length,
    coachEvaluations: works.filter(work => Boolean(work?.submission?.coachEvaluation)).length,
    studentEvaluations: works.filter(work => Boolean(work?.submission?.studentEvaluation)).length,
  });
  const groupStudentWorks = works => {
    const groups = {
      all: works,
      assigned: works.filter(work => work.status === "assigned"),
      submitted: works.filter(work => work.status === "submitted"),
      graded: works.filter(work => work.status === "graded")
    };
    return {
      statistics: {
        all: works.length,
        assigned: groups.assigned.length,
        submitted: groups.submitted.length,
        graded: groups.graded.length
      },
      groups
    };
  };
  if (loading || loadingNullTopic) return <Loading />;
  else {
    const { name, topics, id } = data.classes[0];
    const nullTopics = nullTopicData.classes[0].assignments;

    return (
      <>
        <TopBar type="classwork" />
        <Grid container>
          <Grid item container xs={12} sm={3} justifyContent="center">
            <Box
              className={classes.rightContainer}
              display="flex"
              flexDirection="column"
            >
              <Box className={classes.topicContainer}>
                <Typography className={classes.topicTitle}>
                  {l1.allTopics}
                </Typography>
              </Box>

              {!topics.length ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography>{l1.empty}</Typography>
                </Box>
              ) : (
                topics.map(({ id, name }) => (
                  <Box className={classes.topicContainer} key={id}>
                    <Typography key={id}>{name}</Typography>
                  </Box>
                ))
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.mainContainer}>
              <Box
                className={classes.topSection}
                display="flex"
                justifyContent="space-between"
              >
                <RequireClassAccess only="teacher">
                  <Create
                    currentClass={name}
                    topics={topics}
                    classID={id}
                    query={QUERY_CLASSWORK}
                    refetch={refetch}
                  />
                </RequireClassAccess>
                <div>
                  <Button
                    className={classes.calendar}
                    endIcon={<BiCalendarAlt />}
                    onClick={() => history.push("/calendar")}
                  >
                    {l1.calendar}
                  </Button>
                  <RequireTeacherAccess>
                    <Button
                      className={classes.calendar}
                      endIcon={<SchoolIcon />}
                      onClick={() => history.push("/home")}
                    >
                      {l1.classes}
                    </Button>
                  </RequireTeacherAccess>
                </div>
              </Box>
              {console.log('nullTopics',nullTopics)}
              {nullTopics.length
                ? nullTopics.map(
                    ({ title, description, type, id, createdAt, studentWorks, evaluationsInfo }) => {
                      console.log('works', studentWorks.filter(work => work));
                      let statistics = type === 'evaluations' ? evaluationStatistics(studentWorks) : groupStudentWorks(studentWorks)
                          .statistics;
                      return (
                      <TopicRow
                        title={title}
                        description={description}
                        type={type}
                        key={id}
                        id={id}
                        createdAt={createdAt}
                        statistics={statistics}
                        refetch={refetch}
                        evaluationsInfo={evaluationsInfo}
                      />
                    )}
                  )
                : ""}
              {console.log("topics", topics)}
              {!topics.length ? (
                <Box
                  className={classes.topicTitleContainer}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h2" style={{ color: "grey" }}>
                    {l1.empty}
                  </Typography>
                </Box>
              ) : (
                topics.map(({ id, name, assignments }) => (
                  <TopicSection key={id} text={name} id={id} refetch={refetch}>
                    {assignments.map(
                      ({
                        title,
                        description,
                        type,
                        id,
                        createdAt,
                        studentWorks,
                        evaluationsInfo
                      }) => {
                        let statistics = groupStudentWorks(studentWorks)
                          .statistics;
                        return (
                          <TopicRow
                            title={title}
                            description={description}
                            type={type}
                            key={id}
                            id={id}
                            createdAt={createdAt}
                            statistics={statistics}
                            refetch={refetch}
                            evaluationsInfo={evaluationsInfo}
                          />
                        );
                      }
                    )}
                  </TopicSection>
                ))
              )}
            </Box>
          </Grid>
          <Grid item sm={3}></Grid>
        </Grid>
      </>
    );
  }
  return <Loading />;
}

export default Classwork;
