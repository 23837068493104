import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";

export default function EvaluationStatistics({submitted, expected}){
    return <Box mt={2}>
      <Box display='flex' alignItems="center" style={{ gap: 16 }}>
        <Typography variant="h5">عدد التقييمات:</Typography>
        <Box>
          <Typography variant="h4" component="span">
            <b>{submitted}</b>{' '}
          </Typography>
          { Boolean (expected) && (
            <Typography variant="h5" component="span">
              ({Math.round(submitted / expected * 100)}&rlm;%)
            </Typography>
          )}
        </Box>
      </Box>
      <Box display='flex' alignItems="center" style={{ gap: 16 }}>
        <Typography variant="h5">عدد الطلبة:</Typography>
        <Box>
          <Typography variant="h4" component="span">
            <b>{expected}</b>
          </Typography>
        </Box>
      </Box>
    </Box>
}