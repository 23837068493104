import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { l1 } from "../local.json";
import { Box } from "@material-ui/core";
import {
  ArrowDropDownCircleSharp,
  ArrowDropUpSharp,
  Edit,
} from "@material-ui/icons";
import { BiChevronDown } from "react-icons/bi";

export default function EditableChoice({ choices, initialChoice, onChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState(initialChoice);

  const handleClickListItem = (event) => {
    setSelectedOption(selectedOption);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (value) => {
    setAnchorEl(null);
    onChange(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" justifyContent="center">
      <List component="span" aria-label="Role">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="choice-menu"
          onClick={handleClickListItem}
        >
          {choices.find(({ value }) => selectedOption === value)?.label ?? '؟؟؟'}
          &nbsp;&nbsp;
          <ArrowDropDownCircleSharp />
        </ListItem>
      </List>
      <Menu
        id="choice-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {choices.map(({ value, label }, index) => (
          <MenuItem
            key={value}
            selected={value === selectedOption}
            onClick={(event) => {
              if (value !== selectedOption) {
                handleMenuItemClick(value);
              } else {
                setAnchorEl(null);
              }
            }}
          >
            <Box textAlign="center">{label}</Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
