import React, { useContext, useState } from "react";
import TopBar from "../common/TopBar";
import {
  Avatar,
  Box,
  makeStyles,
  TextField,
  Button,
  Typography,
  Snackbar
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { FaEdit, FaTrash, FaSave } from "react-icons/fa";
import { Navigation } from "../../Context";
import { l1 } from "../../components/local.json";
import { gql, useMutation } from "@apollo/client";
// import { InMemoryCache } from "@apollo/client";
// import { createUploadLink } from "apollo-upload-client";
// import { setContext } from "@apollo/client/link/context";
// import Cookies from "universal-cookie";
import useLayout from "../common/useLayout";
import CropeImage from "./ImageCrop";
// const cache = new InMemoryCache();
// const cookies = new Cookies();
// const token = cookies.get("token");
// const uploadLink = createUploadLink({
//   uri: process.env.REACT_APP_GRAPHQL_END_POINT
// });

// const authLink = setContext((_, { headers }) => {
//     // get the authentication token from local storage if it exists
//     // return the headers to the context so httpLink can read them
//     return {
//       headers: {
//         ...headers,
//         authorization: token ? `Bearer ${token}` : ""
//       }
//     };
//   }),
//   client = new ApolloClient({
//     cache,
//     link: authLink.concat(uploadLink)
//   });

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(10),
    maxWidth: "100vw",
    gap: theme.spacing(5)
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(4),
    marginRight: theme.spacing(1)
  },

  formSectionPassword: {
    padding: theme.spacing(4),
    marginBottom: 20,
    border: "solid 1px lightgray",
    borderRadius: 18,
    boxShadow: "-5px 5px 5px lightgray",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    flexGrow: 1
  },
  formSectionAvatar: {
    padding: theme.spacing(4),
    marginBottom: 20,
    border: "solid 1px lightgray",
    borderRadius: 18,
    boxShadow: "-5px 5px 5px lightgray",
    width: "min-content"
  },
  avatarLarge: {
    width: 150,
    height: 150
  }
}));
// const UPLOAD = gql`
//   mutation($file: Upload!) {
//     upload(file: $file) {
//       name
//       id
//       url
//     }
//   }
// `;

const UPDATE_USER = gql`
  mutation UpdateUser($data: editUserInput!, $id: ID!) {
    updateUser(input: { data: $data, where: { id: $id } }) {
      user {
        id
        avatar
      }
    }
  }
`;

export default function EditProfile() {
  const { user } = useContext(Navigation);
  // console.log(`${process.env.REACT_APP_STRAPI_URL}${user?.avatar}`);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFailure, setAlertFailure] = useState(false);
  const [updateUser, { data }] = useMutation(UPDATE_USER, {
    onCompleted: e => {
      console.log("updateUser completed", e);
      setAlertSuccess(l1.editsuccess);
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    },
    onError: err => {
      setAlertFailure(l1.failure);
      console.log("updateUser error", err);
    }
  });
  const layout = useLayout();
  const classes = useStyles();
  // const [uploadAvatar, { loading: loadingAvatar }] = useMutation(UPLOAD, {
  //   onError: err => {
  //     console.log("upload err", err);
  //   },
  //   onCompleted: data => console.log("data upload", data)
  // });

  const [fullname, setFullname] = useState(user.fullname);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const handleEssentials = () => {
    const fn = fullname === user.fullname;
    const em = email === user.email;
    if (fn && em) return null;
    else updateUser({ variables: { data: { fullname, email }, id: user.id } });
  };
  const handlePassword = () => {
    const pw = password === passwordConfirm;
    if (pw) {
      updateUser({ variables: { data: { password }, id: user.id } });
    } else setError("كلمة السر غير متطابقة");
  };
  // const [avatar, setAvatar] = useState(null);
  // useEffect(() => {
  //   uploadAvatar({ variables: { file: avatar } });
  //   console.log(avatar);
  // }, [avatar]);
  // const onSubmit = e => {
  //   e.preventDefault();
  //   console.log(avatar);
  //   client
  //     .mutate({
  //       mutation: UPLOAD,
  //       variables: {
  //         file: avatar
  //       }
  //     })
  //     .then(res => {
  //       updateUser({
  //         variables: { data: { avatar: res.data.upload.url }, id: user.id }
  //       });
  //     })
  //     .catch(err => {
  //       console.error(err);
  //     });
  // };
  return (
    <>
      <TopBar />
      <Box
        className={classes.container}
        display="flex"
        justifyContent="center"
        flexWrap={layout ? "no-wrap" : "wrap"}
      >
        <Box className={classes.formSectionAvatar}>
          <h2>{l1.profilePicture}</h2>
          <Box>
            <Avatar
              className={classes.avatarLarge}
              src={`${process.env.REACT_APP_STRAPI_URL}${user?.avatar}`}
            />
            <br></br>
            {user.avatarURL ? (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<FaEdit />}
                  style={{ height: "auto" }}
                >
                  {l1.edit}
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<FaTrash />}
                  style={{ height: "auto" }}
                  onClick={e => {}}
                >
                  {l1.delete}
                </Button>
              </div>
            ) : (
              <div>
                <Typography variant="body2">{l1.updateAvatar}</Typography>
                {/* <ApolloProvider client={client}>
                  <form onSubmit={onSubmit}>
                    <input
                      onChange={e => {
                        setAvatar(e.target.files[0]);
                      }}
                      label="hello"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                    />
                    <Button variant="contained" color="primary" type="submit">
                      {l1.submit}
                    </Button>{" "}
                  </form>{" "}
                </ApolloProvider> */}
                <CropeImage destination="userProfile" />
              </div>
            )}
          </Box>
        </Box>
        <Box
          className={classes.formSectionPassword}
          style={{ minWidth: "max-content" }}
        >
          <h2>{l1.changeEssentials}</h2>
          <TextField
            fullWidth
            label={l1.fullName}
            value={fullname}
            onChange={e => setFullname(e.target.value)}
            required
            variant="outlined"
          />

          <TextField
            fullWidth
            label={l1.email}
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
            variant="outlined"
          />

          <Button
            onClick={handleEssentials}
            variant="contained"
            color="primary"
            startIcon={<FaSave />}
            style={{ width: "fit-content" }}
          >
            {l1.save}
          </Button>
        </Box>

        <Box
          className={classes.formSectionPassword}
          style={{ minWidth: "max-content" }}
        >
          <h2>{l1.changePassword}</h2>
          <TextField
            type="password"
            fullWidth
            label={l1.newPassword}
            variant="outlined"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />

          <TextField
            type="password"
            helperText={error}
            error={Boolean(error)}
            fullWidth
            label={l1.confirmNewPassword}
            variant="outlined"
            value={passwordConfirm}
            onChange={e => setPasswordConfirm(e.target.value)}
          />

          <Button
            onClick={handlePassword}
            variant="contained"
            color="primary"
            startIcon={<FaSave />}
            style={{ width: "fit-content" }}
          >
            {l1.save}
          </Button>
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={Boolean(alertSuccess)}
          onClose={() => {
            setAlertSuccess(false);
          }}
          autoHideDuration={3000}
        >
          <MuiAlert
            className={classes.muiAlert}
            elevation={6}
            variant="filled"
            severity="success"
          >
            {alertSuccess}
          </MuiAlert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={Boolean(alertFailure)}
          onClose={() => {
            setAlertFailure(false);
          }}
          autoHideDuration={3000}
        >
          <MuiAlert
            className={classes.muiAlert}
            elevation={6}
            variant="filled"
            severity="error"
          >
            {alertSuccess}
          </MuiAlert>
        </Snackbar>
      </Box>
    </>
  );
}
