import Form from "./Form";

const EmployeeAbsence = {
  slug: "employee-absence",
  name: "سجل غياب الموظفين",
  fields:{
    employee:{
      label: 'الموظف',
      type: 'user',
      permissions: 'fillFirst',
    },
    job:{
      type: 'string',
      label: 'الوظيفة',
      permissions: 'fillFirst',

    },
    unit: {
      type: 'string',
      label: 'الوحدة التنظيمية',
      permissions: 'fillFirst',
    },
    absences:{
      label: "الجدول",
      type:'table',
      columns: [
        {
          label: '#',
          type: 'calculated',
          calculate: ({rowIndex}) => rowIndex + 1,
        },
        {
          label: 'عدد أيام الغياب',
          type: 'number',
          min: 1,
        },
        {
          label: 'تاريخ الغياب',
          type: 'date',
        },
        {
          label: 'الأسباب',
          type: 'string',
          multiline: true,
          minRows: 1,
        },
        {
          label: 'الإجراء الإداري',
          type: 'string',
          multiline: true,
          minRows: 1,
        },
      ],
      
      // aggregate: false if none of the columns has an aggregate function
    },
  },
  layout:[
    {
      type: 'section',
      label: 'معلومات السجل',
      children: [
        'employee',
        'job',
        'unit'
      ]
    },
    {
      type: 'section',
      singleField:'absences',
    }
  ],
  views:{
    list: {
      title: 'قائمة السجلات',
      requiredFields: ['employee'],
      columns: [

        {
          name: "الموظف",
          selector: ({ employee }) => employee ? employee?.fullname : '(غير محدد)',
          searchable: true,
          sortable: true,
        },
        {
          name: "الوظيفة",
          selector: ({ employee }) => employee ? employee?.fullname : '(غير محدد)',
          searchable: true,
          sortable: true,
        },
        {
          name: "آخر تحديث",
          selector: ({ updatedAt, createdAt }) => updatedAt ?? createdAt,
          searchable: true,
          sortable: true,
        },
      ],
    },
    add: {
      title: 'إنشاء سجل غياب جديد',
    },
    edit: {
      title: 'تعديل سجل الغياب',
    },
    view: {
      title: 'سجل غيابات الموظف'
    }
  }
};

export default new Form(EmployeeAbsence);
