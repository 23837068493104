
import Form from "./Form";
import SelectField from "./components/fields/SelectField";

const WarningLetter = {
  slug: "warning-letter",
  name: "خطاب إنذار",
  availableActions:{
    edit: () => false,
    view: (item, {user, membershipRole}) => membershipRole === 'owner' || item.employee.id === user.id,
    delete: (_, {membershipRole}) => membershipRole === 'owner',
  },
  fields:{
    employee:{
      label: 'الموظف',
      type: 'user',
      permissions: 'fillFirst',
    },
    job:{
      type: 'string',
      label: 'الوظيفة',
      permissions: 'fillFirst',
    },
    unit: {
      type: 'string',
      label: 'الوحدة التنظيمية',
      permissions: 'fillFirst',
    },
    date:{
      type: 'date',
      label: 'تاريخ المخالفة',
      permissions: 'fillFirst',
    },
    infraction:{
      type: 'select',
      label: 'المخالفة التي ارتكبها',
      permissions: 'fillFirst',
      Component: SelectField,
      options: [
        {
          label: 'التأخر في الحضور للعمل حسب الأوقات الرسمية المقررة لمدة',
          details: {
            label: 'حدد المدة',
          }
        },
        {
          label: 'الخروج من مكان العمل بدون إذن لمدة',
          details: {
            label: 'حدد المدة',
          }
        },
        {
          label: 'مغادرة مكان العمل قبل نهاية الدوام الرسمي بمدة',
          details: {
            label: 'حدد المدة',
          }
        },
        { label: 'الغياب عن العمل بدون موافقة المسؤول' },
        { label: 'ادعاء المرض بقصد الغياب عن العمل' },
        { label: 'العودة إلى مكان العمل بعد انتهاء الدوام بدون الحصول على موافقة' },
        { label: 'التسكع في المكاتب بقصد إضاعة الوقت' },
        { label: 'استقبال الزوار خلال ساعات العمل الرسمية مخالفاً أنظمة وتعليمات الشركة' },
        { label: 'الأكل في مكان العمل خلال الدوام' },
        { label: 'مطالعة الجرائد والمجلات أثناء الدوام الرسمي' },
        { label: 'التدخين في الأماكن المحظورة' },
        { label: 'استخدام هاتف الشركة للأغراض الشخصية' },
        { label: 'التوقيع نيابة عن الآخرين في سجل الدوام' },
        { label: 'التزوير في سجل الدوام لإثبات مواعيد حضورك' },
        { label: 'الإهمال المتعمد في استعمال المواد بغرض إيقاع الضرر' },
        { label: 'تعريض مكان العمل وممتلكات الشركة للخطر نتيجة للإهمال المتعمد في العمل' },
        { label: 'استخدامك ممتلكات الشركة لأغراض خاصة' },
        { label: 'عدم إطاعة تعليمات وتوجيهات المدير المسؤول' },
        { label: 'الامتناع عن العمل بدون مبرر' },
        { label: 'اختلاق المشاجرات والنزاعات مع الموظفين خلال أوقات العمل' },
        { label: 'تجاوزك للصلاحيات الممنوحة لك' },
        { label: 'إخفاء معلومات خاصة بالشركة بقصد الإضرار بمصلحة العمل' },
      ],
      otherOption:{
        label: "سبب أخر"
      }
    },
    recidiveConsequence:{
      type: 'string',
      label: 'الإجراء المتخذ في حال تكرار المخالفة',
      permissions: 'fillFirst',
    },
  },
  layout:[
    {
      type: 'section',
      label: 'معلومات الموظف',
      children: [
        'employee',
        'job',
        'unit'
      ]
    },
    {
      type: 'section',
      label: 'معلومات المخالفة',
      children:[
        'date',
        'infraction',
        'recidiveConsequence',
      ]
    },
  ],
  views:{
    list: {
      title: 'قائمة الإنذارات',
      requiredFields: ['employee', 'date', 'createdAt'],
      columns: [

        {
          name: "الموظف",
          selector: ({ employee }) => employee ? employee?.fullname : '(غير محدد)',
          searchable: true,
          sortable: true,
        },
        {
          name: "تاريخ المخالفة",
          selector: ({ date }) => date,
          searchable: true,
          sortable: true,
        },
        {
          name: "تاريخ الإرسال",
          selector: ({ createdAt }) => createdAt,
          searchable: true,
          sortable: true,
        },
      ],
    },
    add: {
      title: 'خطاب إنذار جديد',
    },  
    view: {
      title: 'خطاب إنذار'
    }
  }
};

export default new Form(WarningLetter);
