import sparseUpdate from "../helpers/sparseUpdate";
import TextListField from "./components/fields/TextListField";
import Form from "./Form";

const staticFieldPermissions = sparseUpdate(
  JSON.parse(JSON.stringify(Form.defaultFieldPermissions)),
  Form.fieldPermissionProfiles.static,
)
const ProjectSheet = {
  slug: "project-sheet",
  name: "نموذج استمارة مشروع",
  fields: {
    manager: {
      label: "مدير المشروع",
      type: "user",
    },
    name: {
      label: "اسم المشروع",
      type: "string",
    },
    shortDescription: {
      label: "وصف مختصر",
      type: "string",
    },
    budget: {
      label: "الميزانية التقديرية للمشروع ($)",
      type: "number",
    },
    start: {
      label: "تاريخ البداية",
      type: "date",
    },
    end: {
      label: "تاريخ النهاية",
      type: "date",
    },
    duration: {
      label: "المدة التقديرية (أيام)",
      type: "calculated",
      requiredFields: ["start", "end"],
      // calculate date difference in days
      calculate: ({ start, end }) =>
        (new Date(end) - new Date(start)) / 86400000,
      permissions: 'readOnly',
    },
    goals: {
      label: "الاهداف",
      type: "string",
      Component: TextListField,
    },
    successCriteria: {
      label: "معايير النجاح الخاصة بالمشروع",
      type: "string",
      Component: TextListField,
    },
    managerPrivileges: {
      label: "صلاحيات مدير المشروع",
      type: "string",
      Component: TextListField,
    },
  },
  layout: [
    {
      label: "معلومات عامة",
      type: "section",
      children: [
        "manager",
        "name",
        "shortDescription",
        "budget",
        "start",
        "end",
        "duration",
      ],
    },
    {
      type: "section",
      singleField: "goals",
    },
    {
      label: "معايير نجاح المشروع",
      type: "section",
      children: [
        {
          type: "section",
          singleField: {
            name: "commonSuccessCriteria",
            label: "معايير النجاح العامة لكل المشاريع",
            type: "string",
            permissions: staticFieldPermissions,
            value: [
              "تحقيق أهداف المشروع كاملة",
              "إنهاء المشروع في الوقت المحدد",
            ],
            Component: TextListField,
          },
        },
        {
          type: "section",
          singleField: "successCriteria",
        },
      ],
    },
    {
      type: "section",
      singleField: "managerPrivileges",
    },
  ],

  views: {
    list: {
      title: "قائمة المشاريع",
      requiredFields: ["name", "start", "end", "budget"],
      columns: [
        {
          name: "اسم المشروع",
          selector: ({ name }) => <span color="red">{name}</span>,
          searchable: true,
          sortable: true,
        },
        {
          name: "تاريخ البداية",
          selector: ({ start }) => start,
          searchable: true,
          sortable: true,
        },
        {
          name: "تاريخ النهاية",
          selector: ({ end }) => end,
          searchable: true,
          sortable: true,
        },
        {
          name: "الميزانية",
          selector: ({ budget }) => budget,
          searchable: true,
          sortable: true,
        },
      ],
    },
    add: {
      title: "استمارة مشروع جديد",
    },
    edit: {
      title: "تعديل استمارة مشروع",
    },
    view: {
      title: "استمارة مشروع",
    },
  },
};

export default new Form(ProjectSheet);
