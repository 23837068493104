import { useContext } from "react";
import { FormContext, FormDefinition } from "./context";
import { Navigation } from "../../Context";

export default function FormField({
  // common properties
  name,
  type,
  label: originalLabel,
  permissions,
  Component,
  // Whether it's a single field
  singleField = false,
  helperText,
  valueFor,
  aggregate,
  // calculated field props
  requiredFields,
  calculate,
  ...otherProps
}) {
  const form = useContext(FormDefinition);
  const {user} = useContext(Navigation)
  const {
    mode,
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    initialValues,

  } = useContext(FormContext);

  let fieldPermissions = permissions[mode]
  let label = `${singleField ? (fieldPermissions.read && !fieldPermissions.write ? helperText ?? '' : '') : originalLabel}${fieldPermissions.write && fieldPermissions.required ? " (مطلوب)" : ""}`;
  let calculatedValue = (type !== 'calculated')
        ? null 
        : ((requiredFields ?? []).every((fieldName) => values[fieldName] !== null)
          ? valueFor.view(calculate(values), {values, user})
          : null);

  // do not render if it's a field that should be filled later (in edit)
  if(!fieldPermissions.show){
    return null;
  }
  return (
    <Component
      {...{
        name,
        type: form.getInputType(type),
        label,
        edit: (!initialValues[name] && fieldPermissions.writeOnce) || (fieldPermissions.show && fieldPermissions.write),
        readOnly: (initialValues[name] && fieldPermissions.writeOnce) || (fieldPermissions.show && !fieldPermissions.write),
        required: fieldPermissions.required,
        value: type === 'calculated' ? calculatedValue : valueFor ? valueFor[(!initialValues[name] && fieldPermissions.writeOnce) || (fieldPermissions.show && fieldPermissions.write) ? 'edit' : 'view'](values[name]) : values[name] ,
        handleChange,
        handleBlur,
        valueFor,
        touched: touched && touched[name],
        error: errors && errors[name],
        ...otherProps,
      }}
    />
  );
}
