import React from 'react';

// Material-UI
import { makeStyles, Typography, Box, Link } from '@material-ui/core';
import { l1 } from '../local.json';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
// Styles
const useStyles = makeStyles((theme) => ({
  contentContainer: {
    margin: theme.spacing(2),
  },
  contentLink: {
    fontSize: theme.spacing(1.4),
    cursor: 'pointer',
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  contentTitle: {
    color: theme.palette.grey[600],
  },
}));
const QUERY_TOMORROW_DUE = gql`
  query TomorrowDue($id: ID!, $due: Date!) {
    classes(where: { id: $id }) {
      assignments(where: { due: $due }) {
        id
        due
        title
      }
    }
  }
`;

function Due({ id, slug }) {
  const { data } = useQuery(QUERY_TOMORROW_DUE, {
    variables: { id, due: moment().add(1, 'days').format('YYYY-MM-DD') },
  });
  const classes = useStyles();
  const due = data?.classes[0]?.assignments;
  return due?.length ? (
    <Box className={classes.contentContainer}>
      <Typography variant="body2" className={classes.contentTitle}>
        {l1.dueTomorrow}
      </Typography>
      {due.map(({ id, title }) => (
        <Link href={`/class/${slug}/classwork/${id}`}>
          <Typography className={classes.contentLink}>{title}</Typography>
        </Link>
      ))}
    </Box>
  ) : null;
}

export default Due;
