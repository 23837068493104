import { TableCell, TableFooter, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import { useHistory, useParams } from "react-router-dom";
import EvaluationSubject from "./EvaluationSubject";

const number = (props) => ({
  type: 'number',
  format: 'inputNumber',
  aggregation: 'avg',
  ...props
})

const longText= (props) => ({
  type: 'text',
  long: true,
  aggregation: 'listStrings',
  ...props,
})

const CoachEvaluation = {
  key: 'coach',
  questions:{
    q1: number({
      label: "هل بدت عليه الثقة و البشاشة؟",
      max: 20,
    }),
    q2: number({
      label: "هل كانت البداية مشوقة و مثيرة؟",
      max: 20,
    }),
    q3: number({
      label: "هل شرح الأهداف و قسم الموضوع في البداية؟",
      max: 20,
    }),
    q4: number({
      label: "هل كانت مدة البداية مناسبة؟",
      max: 20,
    }),
    q5: number({
      label: "هل كان المظهر العام مناسبا للموضوع و الحضور؟",
      max: 20,
    }),
    q6: number({
      label: 'هل كان ارتفاع الصوت مناسبا؟',
      max: 20,
    }),
    q7: number({
      label: 'هل كان تغيير نبرات الصوت مناسبا؟',
      max: 20,
    }),
    q8: number({
      label: 'هل كانت الوقفات مناسبة؟',
      max: 20,
    }),
    q9: number({
      label: 'هل كانت سرعة الحديث مناسبة؟',
      max: 20,
    }),
    q10: number({
      label: 'هل خلا الحديث من اللزمات المكررة Tic؟',
      max: 20,
    }),
    q11: number({
      label: 'هل كانت تعابير وجهه مريحة ودودة دون اصطناع؟',
      max: 10,
    }),
    q12: number({
      label: 'هل كانت تعابير جسده تشجع الانتباه و المشاركة؟',
      max: 20,
    }),
    q13: number({
      label: 'هل كانت حركات يديه مناسبة للموضوع ؟',
      max: 20,
    }),
    q14: number({
      label: 'هل كانت حركات جسده ومشيه ووقفته مناسبة؟',
      max: 15,
    }),
    q15: number({
      label: 'هل خلت حركاته من Tic ( حركة مكررة غير مناسبة )؟',
      max: 10,
    }),
    q16: number({
      label: 'هل كانت نظرات عينيه مناسبة وموزعة بشكل سليم؟',
      max: 15,
    }),
    q17: number({
      label: 'هل خلت تعابيره من الخوف أو التوتر أو الانفعال؟',
      max: 10,
    }),
    q18: number({
      label: 'هل كان المدرب متمكناً من مادته؟',
      max: 50,
    }),
    q19: number({
      label: 'هل كان واضحاً في تقسيم الموضوع إلى نقاط أساسية وفرعية؟',
      max: 15,
    }),
    q20: number({
      label: 'هل كان يربط الجانب النظري بأمثله واقعية',
      max: 15,
    }),
    q21: number({
      label: 'هل كان هناك تذكير مستمر أين وصلنا؟',
      max: 20,
    }),
    q22: number({
      label: 'هل كان يغير الشرائح بسرعة مناسبة؟',
      max: 20,
    }),
    q23: number({
      label: '2.هل التزم بقانون 5 × 5 ( 5 سطور، 5 كلمات )؟',
      max: 20,
    }),
    q24: number({
      label: '3.هل كانت الخطوط وألوانها مناسبة؟',
      max: 20,
    }),
    q25: number({
      label: '4.هل كانت الصور جميلة ومناسبة؟',
      max: 20,
    }),
    q26: number({
      label: '5.هل كانت الشرائح بشكل عام مناسبة وجميلة؟',
      max: 20,
    }),
    q27: number({
      label: '.هل كانت التمارين مناسبة وفعالة لموضوع الدورة؟',
      max: 20,
    }),
    q28: number({
      label: '.هل كانت التمارين مبدعة وجميلة وغير مألوفة؟',
      max: 20,
    }),
    q29: number({
      label: 'هل بذل المدرب جهداً واضحاً في إعدادها؟',
      max: 20,
    }),
    q30: number({
      label: 'هل كانت الإرشادات لاستعمال التمارين جيدة؟',
      max: 20,
    }),
    q31: number({
      label: 'هل كانت الدروس المستفادة جيدة واضحة و فعالة؟',
      max: 20,
    }),
    q32: number({
      label: 'هل كان هناك حوار مع المتدربين؟',
      max: 20,
    }),
    q33: number({
      label: 'هل كان هناك أنشطة جماعية؟',
      max: 20,
    }),
    q34: number({
      label: 'هل كان الجو العام وديا؟',
      max: 20,
    }),
    q35: number({
      label: '.هل أجاب المدرب على الأسئلة بفاعلية؟',
      max: 20,
    }),
    q36: number({
      label: 'هل كان الختام فعالا ومؤثرا؟',
      max: 20,
    }),
    q37: number({
      label: 'هل كانت المادة واضحة التقسيم؟',
      max: 20,
    }),
    q38: number({
      label: 'هل هي مادة جديدة ومبدعة؟',
      max: 20,
    }),
    q39: number({
      label: 'هل يوجد مراجع واستشهادات حديثة؟',
      max: 20,
    }),
    q40: number({
      label: 'هل المادة حسنة الإخراج؟',
      max: 20,
    }),
    q41: number({
      label: 'هل تحوي المادة معاني وعبر ورسائل واضحة؟',
      max: 20,
    }),
    q42: number({
      label: 'التقييم العام لتناسق الأداء',
      max: 100,
    }),
    pros:longText({ label: "أبرز الإيجابيات" }),
    cons:longText({ label: "أبرز السلبيات" }),
    comment:longText({ label:"ملاحظات" }),
  },

  layout: [
    {
      label: 'تقييم البداية',
      questions: ['q1', 'q2', 'q3', 'q4', 'q5'],
    },
    {
      label: 'تقييم لغة الحديث',
      questions: ['q6', 'q7', 'q8', 'q9', 'q10'],
    },
    {
      label: 'لغة الجسد',
      questions: ['q11', 'q12', 'q13', 'q14', 'q15', 'q16', 'q17'],
    },
    {
      label: 'الثقة و التمكن',
      questions: ['q18', 'q19', 'q20', 'q21'],
    },
    {
      label: 'شرائح العرض PowerPoint',
      questions: ['q22', 'q23', 'q24', 'q25', 'q26'],
    },
    {
      label: 'التمارين و الوسائل المساعدة',
      questions: ['q27', 'q28', 'q29', 'q30', 'q31'],
    },
    {
      label: 'الجو العام و الختام',
      questions: ['q32', 'q33', 'q34', 'q35', 'q36'],
    },
    {
      label: 'المادة العلمية',
      questions: ['q37', 'q38', 'q39', 'q40', 'q41'],
    },
    {
      label: 'التقييم العام لتناسق الأداء',
      questions: ['q42'],
    },
    /* ... other sections */
    {
      label: 'في الختام',
      questions: ['pros', 'cons', 'comment'],
      table: false,
    },
  ],

  resultLabels:{
    0: 'سيء',
    600: 'مقبول', 
    700: 'جيد',
    800: 'ممتاز',
  },

  usePostSubmit(){
    const {id, slug} = useParams();
    const history = useHistory();
    return function postSubmitAction(){
      history.push(`/class/${slug}/classwork/${id}/details`);
    }
  },

  EvaluationTable: {
    Head(){
      return (
        <TableHead>
          <TableRow>
            <TableCell component="th"><Typography color="secondary">م</Typography></TableCell>
            <TableCell component="th"><Typography color="secondary">عنصر التقييم</Typography></TableCell>
            <TableCell component="th"><Typography color="secondary">الدرجة</Typography></TableCell>
            <TableCell component="th"><Typography color="secondary">التقييم</Typography></TableCell>
          </TableRow>
        </TableHead>
      )
    },
    Footer({section}){
      const {values} = useFormikContext();
        return (
          <TableFooter>
            <TableRow>
              <TableCell><Typography color="secondary"></Typography></TableCell>
              <TableCell><Typography color="secondary">مجموع {section.label}</Typography></TableCell>
              <TableCell><Typography color="secondary">{section.questions.reduce((prev, cur) => prev + this.questions[cur].max, 0)}</Typography></TableCell>
              <TableCell><Typography color="secondary"><b>{Math.round(section.questions.reduce((prev, cur) => prev + values[cur],  0))}</b></Typography></TableCell>
            </TableRow>
          </TableFooter>
        );
    },
    Row({id, index, mode = 'fill'}){
      const {label, max} = this.questions[id];
      const [props, meta] = useField(id);
      return <TableRow>
        <TableCell width={100}><Typography><b>{index}</b></Typography></TableCell>
        <TableCell><Typography>{label}</Typography></TableCell>
        <TableCell width={100}><Typography>{max}</Typography></TableCell>
        <TableCell width={100}>
          {mode === 'fill' 
            ? <TextField
              color="primary"
              error={meta.error}
              type="number"
              label="" 
              InputLabelProps={{
                shrink: false,
              }}
              inputProps={{
                min: 0,
                max: max,
                style: {
                  padding: 10,
                }
              }}
              fullWidth
              variant="outlined"
              {...props} 
            />
            : <Typography color="secondary">{Math.round(props.value)}</Typography>
          }
        </TableCell>
      </TableRow>
    }
  }
}

export default new EvaluationSubject(CoachEvaluation);