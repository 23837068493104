import React, { useState }  from 'react'
import {TextField, Button,Typography} from '@material-ui/core'
import { l1 } from "../../../../components/local.json";


export default function Value(props){
    const [isEditing, setEditing] = useState(false);
    const [newName, setNewName] = useState(props.name);
    const [newContent, setnewContent] = useState(props.content);
    const handleNewNameChange = (e)=>{
        setNewName(e.target.value)
    }
    const handleNewContentChange = (e)=>{
        setnewContent(e.target.value)
    }
    const handleSubmit =(e)=>{
        e.preventDefault();
        props.editValue(props.index, {newName,newContent});
        setNewName("");
        setnewContent("");
        setEditing(false);
      }
    const editingTemplate = (
        <div>
            <p className="description">
                <TextField value={newName} onChange={handleNewNameChange} />
                <TextField fullWidth value={newContent} onChange={handleNewContentChange} />
            </p>
            <div className="btn-group">
                <Button color="primary" variant='contained' size="large" onClick={handleSubmit} style={{marginLeft:"10px"}}>
                    {l1.submit}
                </Button>
                <Button color="secondary" variant='contained' size="large" onClick={()=> setEditing(false)}>
                    {l1.cancel}
                </Button>
            </div>    
        </div>
    )
    const viewTemplate = (
        <div >
            <div className='description'>
                {/* <TextField value={props.name} onChange={(e) => setValues({value: e.target.value})} />
                <TextField fullWidth value={props.content} onChange={(e) => setValues({value: e.target.value})} /> */}
                <Typography variant="h5">{props.name}</Typography>
                <Typography variant="body1" >{props.content}</Typography>
            </div>
            <div className="btn-group">
                <Button color="primary" variant='contained' size="large" onClick={()=> setEditing(true)} style={{marginLeft:"10px"}}>
                    {l1.edit}
                </Button>
                <Button color="secondary" variant='contained' size="large" onClick={() => props.deleteValue(props.index)}>
                    {l1.delete}
                </Button>
            </div>    
        </div>
    )
    return (
        isEditing ? editingTemplate : viewTemplate
        
    )
}