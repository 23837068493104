import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { createContext, useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import Loading from "./components/common/Loading";
export const Navigation = createContext();
export const CreateClass = createContext();
export const LoadingState = createContext();
export const Class = createContext();
export const ClassAnchor = createContext();
export const GetName = createContext();
export const Auth = createContext();

export function ClassWrapper({ children }) {
  const { slug } = useParams();

  return (
    <Class.Provider value={{ classSlug: slug }}>{children}</Class.Provider>
  );
}
var currentUrl = "aymen.rwadleadership.tk";
if (process.env.REACT_APP_DEBUG_URL == "False") {
  currentUrl = window.location.host;
}
var parts = currentUrl.split(".");
var sub = parts[0];
const cookies = new Cookies();
const token = cookies.get("token");

const QUERY_MEMBERSHIP = gql`
  query getUserMembership($userId: ID!, $centerSlug: String!) {
    memberships(where: { user: $userId, center: { subdomain: $centerSlug } }) {
      center {
        id
        info {
          name
        }
        classes {
          slug
          name
          section
          isArchived
          students(where: { id: $userId }) {
            id
          }
          teachers(where: { id: $userId }) {
            id
          }
        }
      }
      status
      role
    }
  }
`;
const queryInfo = gql`
  query QueryInfo($subdomain: String!) {
    centersLists(where: { subdomain: $subdomain }) {
      owner {
        id
      }
      info {
        name
      }
      id
      classes(where: { isArchived: false }) {
        name
        section
        slug
      }
    }
  }
`;
const GET_NAME = gql`
  query GetName($subdomain: String!) {
    centersLists(where: { subdomain: $subdomain }) {
      info {
        name
      }
    }
  }
`;

function Context({ children }) {
  // Auth
  const [user, setUser] = useState(null);
  const [membership, setMembership] = useState(null);
  const [loadingState, reverseLoadingState] = useReducer(
    (state) => !state,
    true
  );
  const logout = (callBack) => {
    callBack();
    cookies.remove("token");
    setUser(null);
    // sessionStorage.clear();
  };
  const [skip, setSkip] = useState(true);

  const [
    getUserMembership,
    {
      loading: membershipLoading,
      error: membershipError,
      data: membershipData,
    },
  ] = useLazyQuery(QUERY_MEMBERSHIP, {
    onCompleted: (data) => {
      setMembership(data.memberships?.[0]);
      reverseLoadingState();
    },
    onError: (err) => {
      console.log("membership error : ", err);
      // TODO handle error
    },
  });

  useEffect(() => {
    async function triggerEffect() {
      if (!token) {
        reverseLoadingState();
        return;
      }
      const userInfo = !token
        ? null
        : await fetch(`${process.env.REACT_APP_STRAPI_URL}/users/me`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => res.json())
            .then((res) => {
              setUser(res);
              setSkip(false);
              return res;
            })
            .catch((err) => {
              console.log("me err", err);
            });

      if (!userInfo) {
        reverseLoadingState();
        return;
      }
      getUserMembership({
        variables: {
          userId: userInfo.id,
          centerSlug: sub,
        },
      });
    }
    triggerEffect();
  }, []);
  const [isLoading, reverseLoading] = useReducer((s) => !s, true);
  //
  const { loading: loadingQueryInfo, data: dataQueryInfo } = useQuery(
    queryInfo,
    {
      onError: (error) => {
        console.log("errorQueryInfo: ", error);
      },
      onSuccess: (data) => {
        console.log("successQueryInfo: ", data);
      },
      variables: {
        subdomain: sub,
      },
    }
  );
  const { loading: loadingName, data: centerName } = useQuery(GET_NAME, {
    onError: (error) => {
      console.log("errorGetName: ", error);
    },
    variables: {
      subdomain: sub,
    },
  });
  // LOADING

  // CREATE CLASS
  const [createState, reverseCreateState] = useReducer(
    (state) => !state,
    false
  );
  const [classContent, setClassContent] = useState({
    name: "",
    section: "",
    subject: "",
    room: "",
    slug: "",
  });
  const handleClassContent = (newState) => {
    setClassContent(newState);
  };
  // ClassAnchor
  const [classAnchor, setClassAnchor] = useState(null);

  // TABS
  const [tabState, setTabState] = useState("Stream");

  const handleTabsChange = (newState) => {
    setTabState(newState);
  };
  if (loadingQueryInfo) return <Loading />;
  if (!loadingQueryInfo) {
    if (loadingState || membershipLoading) return <Loading />;
    // const centerId = membership?.center?.id;
    const centerId = dataQueryInfo?.centersLists[0]?.id;
    let info = {
      centerName: membership?.center?.info?.name,
      classes: membership?.center?.classes,
      students: membership?.center?.students,
    };
    if (info?.centerName) {
      document.title = info.centerName;
    }
    // const isCenterOwner = user?.ownedCenters?.some(
    //   center => center.subdomain === sub
    // );
    const isCenterOwner =
      dataQueryInfo?.centersLists[0]?.owner.id === user?.id ? true : false;
    const isCenterAdmin = ["moderator", "assistant", "owner"].includes(
      membership?.role
    );
    const membershipRole = membership?.role;
    const membershipStatus = membership?.status;
    const joinedClasses = membership?.center?.classes?.filter(
      ({ students }) => students?.length
    );
    const teachedClasses = membership?.center?.classes?.filter(
      ({ teachers }) => teachers?.length
    );
    return (
      <Auth.Provider value={{ isLoading, reverseLoading }}>
        <ClassAnchor.Provider value={{ classAnchor, setClassAnchor }}>
          <LoadingState.Provider value={{ loadingState, reverseLoadingState }}>
            <Navigation.Provider
              value={{
                tabState,
                handleTabsChange,
                info,
                sub,
                centerId,
                user,
                skip,
                setSkip,
                isCenterOwner,
                isCenterAdmin,
                membershipRole,
                membershipStatus,
                joinedClasses,
                teachedClasses,
                logout,
              }}
            >
              <Class.Provider
                value={{
                  createState,
                  reverseCreateState,
                  classContent,
                  handleClassContent,
                }}
              >
                <GetName.Provider value={{ loadingName, centerName }}>
                  {children}
                </GetName.Provider>
              </Class.Provider>
            </Navigation.Provider>
          </LoadingState.Provider>
        </ClassAnchor.Provider>
      </Auth.Provider>
    );
  }
}

export default Context;
