import React, { useState, useReducer, useRef } from "react";
import {
  Box,
  Divider,
  IconButton,
  makeStyles,
  Typography,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@material-ui/core";
import { MdAdd } from "react-icons/md";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { RequireClassAccess, RequireTeacherAccess } from "../../../../Auth/gates";
import { l1 } from "../../../local.json";
import { gql, useMutation } from "@apollo/client";
import Loading from "../../../common/Loading";

const DELETE_TOPIC = gql`
  mutation DeleteTopic($id: ID!) {
    deleteTopic(input: { where: { id: $id } }) {
      topic {
        id
      }
    }
  }
`;
const UPDATE_TOPIC_NAME = gql`
  mutation updateTopicName($id: ID!, $name: String!) {
    updateTopic(input: { where: { id: $id }, data: { name: $name } }) {
      topic {
        id
        name
      }
    }
  }
`;
const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(2),
    "&:hover": {
      borderRadius: theme.spacing(1),
      boxShadow: theme.shadows[1]
    },
    cursor: "pointer"
  },
  title: {
    fontSize: theme.spacing(5),
    color: theme.palette.primary.dark,
    paddingLeft: theme.spacing(4)
  },
  options: {
    color: theme.palette.primary.dark,
    fontSize: theme.spacing(3)
    // paddingRight: theme.spacing(4)
  },
  divider: {
    background: theme.palette.primary.main,
    flexGrow: 1,
    margin: theme.spacing(2, 0)
  },
  alert: {
    fontSize: theme.spacing(1.6),
    color: theme.palette.grey[600]
  },
  topicTextFieldContainer: {
    minWidth: "400px",
    height: "150px"
  }
}));

function TopicSection({
  text,
  children,
  people,
  trigger,
  cid,
  setStudentMode,
  student,
  teacher,
  id,
  scope,
  refetch
}) {
  const classes = useStyles();
  const [anchorTopic, setAnchorTopic] = useState(null);
  const openDialogTopic = () => {
    console.log("open dialog topic");
    reverseTopicDialogState();
    closeTopicMenu();
  };
  const openTopicMenu = event => {
    console.log("open topic menu", dialogTopicState);
    setAnchorTopic(event.currentTarget);
  };
  const closeTopicMenu = () => {
    console.log("close topic menu", dialogTopicState);
    setAnchorTopic(false);
  };
  const [topicContent, setTopicContent] = useState(null);
  // const topicContentRef = useRef();
  const [updateTopicName, { loading: loadingUpdateTopic }] = useMutation(
    UPDATE_TOPIC_NAME,
    {
      onError: error => {
        console.log("update topic error", error);
      },
      onCompleted: () => {
        console.log("Topic updated successfuly");
        refetch();
      }
    }
  );
  const [dialogTopicState, reverseTopicDialogState] = useReducer(
    state => !state,
    false
  );

  const [confirmationState, reverseConfirmationState] = useReducer(
    state => !state,
    false
  );
  const [deleteTopic, { loading: loadingDeleteTopic }] = useMutation(
    DELETE_TOPIC,
    {
      onError: error => {
        console.log("mutationDeleteComponent error: ", error);
        // failure(l1.failure);
      },
      onCompleted: () => {
        refetch();
        console.log("deleted with success");
      }
    }
  );
  if (!children)
    return (
      <Box className={classes.container}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.title}>{text}</Typography>
          <RequireClassAccess only="teacher">
            <BiDotsVerticalRounded className={classes.options} />
          </RequireClassAccess>
        </Box>
        <Divider classes={{ root: classes.divider }} />
        <Typography className={classes.alert}>Empty</Typography>
      </Box>
    );
  if (people)
    return (
      <Box className={classes.container}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.title}>{text}</Typography>
          <RequireClassAccess only="teacher">
            <IconButton
              onClick={() => {
                if (teacher) setStudentMode(false);
                if (student) setStudentMode(true);
                trigger();
              }}
            >
              <MdAdd />
            </IconButton>
          </RequireClassAccess>
        </Box>
        <Divider classes={{ root: classes.divider }} />
        <Typography className={classes.alert}>{children}</Typography>
      </Box>
    );
  return (
    <Box className={classes.container}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography className={classes.title}>{text}</Typography>
        {scope === "review" ? (
          ""
        ) : (
          <RequireClassAccess only="teacher">
          <BiDotsVerticalRounded
            className={classes.options}
            onClick={openTopicMenu}
          />
          </RequireClassAccess>
        )}

        <Menu
          open={Boolean(anchorTopic)}
          onClose={closeTopicMenu}
          elevation={1}
          anchorEl={anchorTopic}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem className={classes.menuItem} onClick={openDialogTopic}>
            <Typography>{l1.editTopic}</Typography>
          </MenuItem>

          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              reverseConfirmationState();
              closeTopicMenu();
            }}
          >
            {l1.deleteTopic}
          </MenuItem>
        </Menu>
        <Dialog
          open={confirmationState}
          onClose={() => {
            reverseConfirmationState();
          }}
        >
          <DialogTitle></DialogTitle>
          <DialogContent>
            <Typography style={{ fontWeight: "bold" }}>
              {l1.confirmation}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                deleteTopic({
                  variables: {
                    id
                  }
                });
                window.location.reload(false);
                reverseConfirmationState();
              }}
            >
              {l1.confirm}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                reverseConfirmationState();
              }}
            >
              {l1.cancel}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Dialog
        className={classes.topicDialogContainer}
        open={dialogTopicState}
        onClose={reverseTopicDialogState}
      >
        <DialogContent className={classes.topicTextFieldContainer}>
          <TextField
            variant="outlined"
            className={classes.topicTextField}
            label={l1.topic}
            fullWidth
            // inputRef={topicContentRef}
            onChange={e => {
              setTopicContent(e.target.value);
            }}
            defaultValue={text}
          />
          <Box
            className={classes.topicButtons}
            display="flex"
            justifyContent="space-evenly"
            style={{ marginTop: "15px" }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                updateTopicName({
                  variables: {
                    id: id,
                    name: topicContent
                  }
                });
              }}
            >
              {l1.edit}
            </Button>
            <Button variant="contained" onClick={reverseTopicDialogState}>
              {l1.cancel}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Divider classes={{ root: classes.divider }} />
      <Typography className={classes.alert}>{children}</Typography>
    </Box>
  );
}

export default TopicSection;
