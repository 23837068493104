import Form from "./Form";

const BusinessPlan = {
  slug: "business-plan",
  name: "نموذج كتابة تقرير لمشروع إعداد قيادي",
  availableActions:{
    view: (item, {membershipRole, user}) => item.submittedAt && membershipRole === 'owner' || item.author.id === user.id,
    edit: (item, {user}) => item.author.id === user.id && !item.submittedAt,
    delete: (item, {membershipRole, user}) => item.submittedAt ? membershipRole === 'owner' : item.author.id === user.id,
  },
  fields:{
    name:{
      label: 'اسم المشروع',
      type: 'string',
    },
    introduction:{
      label: 'تعريف',
      helperText: 'ابدأ بتعريف بسسيط عن التقرير',
      type: 'string',
      multiline: true,
    },
    team:{
      label: 'فريق المشروع',
      helperText: 'يتألف الفريق من عدة تخصصات\nاكتب عن هيكلة الفريق',
      type: 'string',
      multiline: true,
    },
    management:{
      label: 'إدارة المشروع المشروع',
      helperText: 'اكتب عن عملية إدارة المشروع، و دور كل أعضاء الفريق في العملية الإدارية',
      type: 'string',
      multiline: true,
    },
    time:{
      label: 'المدة و الأطر الزمنية',
      helperText: 'اكتب عن مدة تميد المشروع مع الإشارة لأي تأجيل في التنفيذ، او على العكس أي أعمال أنجزت في وقت قصير',
      type: 'string',
      multiline: true,
    },
    risks:{
      label: 'مخاطر المشروع',
      helperText: 'اكتب عن أي مخاطر واجهتكم أثناء تنفيذ المشروع',
      type: 'string',
      multiline: true,
    },
    expenses:{
      label: "المصاريف",
      type:'table',
      columns: [
        {
          label: 'البيان',
          type: 'string',
          multiline: true,
          minRows: 1,
          aggregate: ()=> "الإجمالي",
        },
        {
          label: 'تكلفة الفرد',
          type: 'number',
        },
        {
          label: 'العدد',
          type: 'number',
        },
        {
          label: '$',
          type: 'calculated',
          calculate: ({rows, rowIndex}) => {
            const [value,quantity] = rows[rowIndex].slice(1,3).map(Number);
            return ([value, quantity].includes(NaN) ? 0 : value * quantity)
          },
          aggregate: ({rows}) => rows.reduce((sum, row) => {
            const [value,quantity] = row.slice(1,3).map(Number);
            return sum + ([value, quantity].includes(NaN) ? 0 : value * quantity)
          }, 0),
        },
        {
          label: 'ملاحظات',
          type: 'string',
          multiline: true,
          minRows: 1,
        },
      ],
      // aggregate: false if none of the columns has an aggregate function
    },
    revenue:{
      label: "الإيرادات",
      type:'table',
      columns: [
        {
          label: 'البيان',
          type: 'string',
          multiline: true,
          minRows: 1,
          aggregate: ()=> "الإجمالي",
        },
        {
          label: 'تكلفة الفرد',
          type: 'number',
        },
        {
          label: 'العدد',
          type: 'number',
        },
        {
          label: '$',
          type: 'calculated',
          calculate: ({rows, rowIndex}) => {
            const [value,quantity] = rows[rowIndex].slice(1,3).map(Number);
            return ([value, quantity].includes(NaN) ? 0 : value * quantity)
          },
          aggregate: ({rows}) => rows.reduce((sum, row) => {
            const [value,quantity] = row.slice(1,3).map(Number);
            return sum + ([value, quantity].includes(NaN) ? 0 : value * quantity)
          }, 0),
        },
        {
          label: 'ملاحظات',
          type: 'string',
          multiline: true,
          minRows: 1,
        },
      ],
      // aggregate: false if none of the columns has an aggregate function
    },
    totalRevenue:{
      label: 'إجمالي الإيرادات',
      type: 'calculated',
      requiredFields: ['revenue'],
      calculate: ({revenue}) => revenue.reduce((sum, row) => {
        const [value,quantity] = row.slice(1,3).map(Number);
        return sum + ([value, quantity].includes(NaN) ? 0 : value * quantity)
      }, 0),
      permissions: 'readOnly',
    },
    totalExpenses: {
      label: 'إجمالي المصاريف',
      type: 'calculated',
      requiredFields: ['expenses'],
      calculate: ({expenses}) => expenses.reduce((sum, row) => {
        const [value,quantity] = row.slice(1,3).map(Number);
        return sum + ([value, quantity].includes(NaN) ? 0 : value * quantity)
      }, 0),
      permissions: 'readOnly',
    },
    netProfit: {
      label: 'صافي الربح',
      type: 'calculated',
      requiredFields: ['revenue', 'expenses'],
      calculate: ({expenses, revenue}) => (
        revenue.reduce((sum, row) => {
          const [value,quantity] = row.slice(1,3).map(Number);
          return sum + ([value, quantity].includes(NaN) ? 0 : value * quantity)
        }, 0)
        - expenses.reduce((sum, row) => {
          const [value,quantity] = row.slice(1,3).map(Number);
          return sum + ([value, quantity].includes(NaN) ? 0 : value * quantity)
        }, 0)
      ),
      permissions: 'readOnly',
    },
    author: {
      label: "قام بإعداد التقرير",
      type: "user",
      permissions: 'autoFillFirst',
      valueFor: {
        view: (value, context) => value ?? context.user,
        edit: (value, context) => value ?? context.user,
      },
    },
    submittedAt: {
      label: "رفع التقرير للمسؤول",
      type: "timestamp",
      permissions: 'fillOnce',
    }
  },
  layout:[
    {
      type: 'section',
      singleField: 'name'
    },
    {
      type: 'section',
      singleField: 'introduction'
    },
    {
      type: 'section',
      singleField: 'team'
    },
    {
      type: 'section',
      singleField: 'management'
    },
    {
      type: 'section',
      singleField: 'time'
    },
    {
      type: 'section',
      singleField: 'risks'
    },
    {
      type: 'section',
      label: 'المالية',
      children: [
        'revenue',
        'expenses',
        'totalRevenue',
        'totalExpenses',
        'netProfit'
      ],
    },
    {
      type: "section",
      label: "معلومات عن التقرير",
      children:[
        'author',
        'submittedAt',
      ]
    },
  ],
  views:{
    list: {
      title: 'قائمة التقارير',
      requiredFields: ['name', 'createdAt', 'submittedAt', 'author'],
      columns: [
        {
          name: "العنوان",
          selector: ({ name }) => name ?? '(غير محدد)',
          searchable: true,
          sortable: true,
        },
        {
          name: "انشئ بتاريخ",
          selector: ({ createdAt }) => createdAt,
          searchable: true,
          sortable: true,
        },
        {
          name: "كاتب التقرير",
          selector: ({ author }) => author ? author?.fullname : '(غير محدد)' ,
          searchable: true,
          sortable: true,
        },
        {
          name: "تم رفعه بتاريخ",
          selector: ({ submittedAt }) => submittedAt ?? 'ليس بعد',
          searchable: true,
          sortable: true,
        },
      ],
    },
    add: {
      title: 'تقرير جديد',
    },
    edit: {
      title: 'تعديل التقرير',
    },
    view: {
      title: 'تقرير المشروع'
    }
  }
};

export default new Form(BusinessPlan);
