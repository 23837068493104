import React, {
  useState,
  createRef,
  useRef,
  useContext,
  useEffect,
  useReducer
} from "react";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import { Row, Col } from "antd";
import SmsFailedIcon from "@material-ui/icons/SmsFailed";
import {
  Facebook,
  Instagram,
  YouTube,
  LinkedIn,
  Twitter
} from "@material-ui/icons/";
import Gallery from "react-grid-gallery";
import ListMembers from "../../rel/Landing/ListMembers";
import "../../rel/Landing/Styling.scss";
import { gql, useMutation, useQuery } from "@apollo/client";
import Loading from "../../common/Loading";
import { Navigation } from "../../../Context";
import logo from "../../rel/Landing/assets/img/center.default.jpg";
import bg from "../../rel/Landing/assets/img/image-slider.png";
import defaultImg from "../../rel/Landing/assets/img/default.png";
import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  InputAdornment,
  TextField,
  Typography,
  CircularProgress,
  Button,
  Snackbar,
  Checkbox,
  FormControlLabel,
  FormGroup
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import TopBar from "../../common/TopBar";
import { l1 } from "../../../components/local.json";
import CropeImage from "../../UserPages/ImageCrop";
import Values from "./LandingComponents/Values";
import PhoneNumbers from "./LandingComponents/PhoneNumbers";

const UPLOAD = gql`
  mutation($file: Upload!) {
    upload(file: $file) {
      name
      id
      url
    }
  }
`;

const GET_INTERFACE = gql`
  query GetInterface($subdomain: String!) {
    centersLists(where: { subdomain: $subdomain }) {
      info {
        openToRegistration
        logo {
          url
        }
        id
        name
        vision
        message
        values {
          id
          name
          content
        }
        gallery {
          id
          name
          caption
          width
          height
          url
        }
        video
        description
        alertSignup
        contact {
          address
          email
          phoneNumbers {
            id
            number
          }
        }
        socialMediaLinks {
          facebook
          twitter
          linkedin
          instagram
          youtube
        }
        teamMember {
          id
          picture {
            id
            url
          }
          fullName
          role
          facebook
          instagram
          twitter
        }
      }
    }
  }
`;
const UPDATE_INTERFACE = gql`
  mutation updateCenterInfo($id: ID!, $data: editCenterInput!) {
    updateCenter(input: { where: { id: $id }, data: $data }) {
      center {
        id
      }
    }
  }
`;
const UPDATE_VALUES = gql`
  mutation updateCenterInfo(
    $id: ID!
    $data: [editComponentCenterValueInput!]!
  ) {
    updateCenter(input: { where: { id: $id }, data: { values: $data } }) {
      center {
        values {
          name
          content
        }
      }
    }
  }
`;
const UPDATE_PHONE_NUMBERS = gql`
  mutation updateCenterInfo(
    $id: ID!
    $data: [editComponentPhonePhoneNumberInput!]!
  ) {
    updateCenter(
      input: { where: { id: $id }, data: { contact: { phoneNumbers: $data } } }
    ) {
      center {
        id
      }
    }
  }
`;
const UPDATE_TEAM = gql`
  mutation updateCenterInfo($data: [editComponentCenterTeamInput]!, $id: ID!) {
    updateCenter(input: { data: { teamMember: $data }, where: { id: $id } }) {
      center {
        id
      }
    }
  }
`;

export default function EditLanding() {
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFailure, setAlertFailure] = useState(false);
  const { centerId, sub } = useContext(Navigation);
  const [saveLoading, setSaveLoading] = useState(null);

  const { loading, data, refetch } = useQuery(GET_INTERFACE, {
    onError: err => console.log("err get interface", err),
    onCompleted: data => {
      setGallery(IMAGES);
      setGalleryId(ids);
      setOpenToRegistration(Boolean(data?.centersLists[0]?.info?.openToRegistration))
    },
    variables: {
      subdomain: sub
    },
    notifyOnNetworkStatusChange: true
  });
  const center = data?.centersLists[0]?.info;
  const centerLogo = center?.logo
    ? `url(${process.env.REACT_APP_STRAPI_URL}${center?.logo?.url})`
    : `url(${logo})`;
  // const centerLogo =  `url(${process.env.REACT_APP_STRAPI_URL}${center?.logo?.url})`
  // const arr = [];
  const ids = (center?.gallery || []).map(image => {
    return { id: image.id };
  });
  const toggleImageSelected = index => {
    const updatedImages = gallery.map((image, imageIdx) => {
      if (index === imageIdx) {
        return { ...image, isSelected: !image.isSelected };
      }
      return image;
    });
    setGallery(updatedImages);
  };
  const IMAGES = (center?.gallery || []).map(image => {
    if (image.url)
      return {
        id: image.id,
        src: `${process.env.REACT_APP_STRAPI_URL}${image.url}`,
        thumbnail: `${process.env.REACT_APP_STRAPI_URL}${image.url}`,
        isSelected: false
      };
  });
  const [uploadToGallery] = useMutation(UPLOAD, {
    onError: err => console.log("error upload to gallery", err),
    onCompleted: data => {
      const ids = [...galleryId, { id: data.upload.id }];
      postUploadToGallery({
        variables: {
          data: {
            gallery: [...ids].map(id => id.id)
          },
          id: center.id
        }
      });
      const arr = [
        ...gallery,
        {
          src: `${process.env.REACT_APP_STRAPI_URL}${data.upload.url}`,
          thumbnail: `${process.env.REACT_APP_STRAPI_URL}${data.upload.url}`
        }
      ];
      setGallery(arr);
      setUploadGalleryLoading(null);
    }
  });
  const [updateValuesMutation] = useMutation(UPDATE_VALUES, {
    onError: err => {
      console.log("update values error", err);
    },
    onCompleted: () => {
      console.log("update values completed");
      refetch();
    }
  });
  const [updatePhoneNumbersMutation] = useMutation(UPDATE_PHONE_NUMBERS, {
    onError: err => {
      console.log("error update phone numbers", err);
    },
    onCompleted: () => {
      console.log("updated phone numbers");
      refetch();
    }
  });
  const [addPicture, { loading: loadingAddPicture }] = useMutation(UPLOAD, {
    onError: err => console.log("error add picture to team member", err),
    onCompleted: data => {
      setPictureID(data.upload.id);
    }
  });
  const [postUploadToGallery] = useMutation(UPDATE_INTERFACE, {
    onError: err => {
      console.log("update gallery", err);
      setAlertFailure(l1.failure);
    },
    onCompleted: data => {
      setAlertSuccess(l1.editsuccess);
      setDeleteGalleryLoading(false);
      const arr = gallery;
      arr.push({
        src: `${process.env.REACT_APP_STRAPI_URL}${data?.updateCentersList?.centersList?.gallery?.url}`,
        thumbnail: `${process.env.REACT_APP_STRAPI_URL}${data?.updateCentersList?.centersList?.gallery?.url}`
      });
    }
  });
  const [updateInterface] = useMutation(UPDATE_INTERFACE, {
    onError: err => console.log("error update center info", err),
    onCompleted: data => {
      setSaveLoading(false);
      refetch();
    }
  });
  const [updateTeamMembers] = useMutation(UPDATE_TEAM, {
    onError: err => console.log("error update team members", err),
    onCompleted: data => {
      console.log("update team members completed", data);
      refetch();
    }
  });
  const [team, setTeam] = useState(center?.teamMember);

  const handleDeleteImages = () => {
    setDeleteGalleryLoading(true);
    let newGallery = gallery.filter(image => image.isSelected !== true);
    postUploadToGallery({
      variables: {
        data: {
          gallery: [...newGallery].map(id => id.id)
        },
        id: center.id
      }
    });
    setGallery(newGallery);
  };
  // const carouselActivities = createRef();
  // const [carouselIndex, setcarouselIndex] = useState(0);
  const [uploadGalleryLoading, setUploadGalleryLoading] = useState();
  const [deleteGalleryLoading, setDeleteGalleryLoading] = useState(false);
  const [gallery, setGallery] = useState();
  const [galleryId, setGalleryId] = useState([]);
  const [name, setName] = useState(center?.name);
  const [vision, setVision] = useState(center?.vision);
  const [message, setMessage] = useState(center?.message);
  const [description, setDescription] = useState(center?.description);
  const [alertSignup, setAlertSignup] = useState(center?.alertSignup);
  const [values, setValues] = useState(null);

  const [facebook, setFacebook] = useState(center?.socialMediaLinks?.facebook);
  const [twitter, setTwitter] = useState(center?.socialMediaLinks?.twitter);
  const [instagram, setInstagram] = useState(
    center?.socialMediaLinks?.instagram
  );
  const [youtube, setYoutube] = useState(center?.socialMediaLinks?.youtube);
  const [linkedin, setLinkedin] = useState(center?.socialMediaLinks?.linkedin);

  const [video, setVideo] = useState(center?.video);

  const [phoneNumbers, setPhoneNumbers] = useState(
    center?.contact?.phoneNumbers
  );
  const [address, setAddress] = useState(center?.contact?.address);
  const [email, setEmail] = useState(center?.contact?.email);
  const [pictureID, setPictureID] = useState(null);
  useEffect(() => { returnGallery() }, [gallery]);
  const [openToRegistration, setOpenToRegistration] = useState(false)
  const returnGallery = () => (
    <Gallery
      images={gallery}
      enableLightbox={true}
      enableImageSelection={true}
      backdropClosesModal={true}
      showLightboxThumbnails={true}
      onSelectImage={(index, image) => {
        toggleImageSelected(index);
      }}
    />
  );
  const inputRef = useRef();
  const saveAllChanges = () => {
    if (
      !name &&
      !vision &&
      !message &&
      !description &&
      !video &&
      !address &&
      !email &&
      !alertSignup &&
      !values &&
      !phoneNumbers &&
      !team &&
      !facebook &&
      !youtube &&
      !twitter &&
      !instagram &&
      !linkedin
      && Boolean(openToRegistration) === Boolean(center?.openToRegistration)
    ) {
      console.log("nothing to be saved");
      setSaveLoading(false);
      return;
    }
    setSaveLoading(true);
    if (!phoneNumbers && !values && !team) {
      updateInterface({
        variables: {
          data: {
            name,
            openToRegistration,
            vision,
            message,
            description,
            video,
            contact: { address, email },
            alertSignup,
            socialMediaLinks: {
              facebook,
              instagram,
              linkedin,
              twitter,
              youtube
            }
          },
          id: center.id
        }
      });
    }
    if (values) {
      updateValuesMutation({
        variables: {
          id: center.id,
          data: [...values]
        }
      });
    }

    if (phoneNumbers) {
      console.log("phonenumbers", phoneNumbers);
      updatePhoneNumbersMutation({
        variables: {
          id: center.id,
          data: [...phoneNumbers]
        }
      });
    }
    if (team) {
      updateTeamMembers({
        variables: {
          id: center.id,
          data: [...team]
        }
      });
    }
  };
  if (!loading)
    return (
      <div>
        <TopBar type="admin" tab="editLanding" />
        <main className="center-main">
          <div
            className="center-header"
            style={{
              backgroundImage: `linear-gradient(transparent 58%, white 100%),
     url(${bg})`
            }}
          ></div>
          <div className="center-content">
            <div
              className="logo-center"
              style={{ backgroundImage: centerLogo }}
            ></div>
            <CropeImage destination="centerLogo" centerId={center?.id} />
            <TextField
              value={name || center?.name || ''}
              onChange={e => setName(e.target.value)}
            />

            <hr />

            <div className="left">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={saveAllChanges}
                // style={{ color: 'white', cursor: 'pointer' }}
                disabled={saveLoading}
              >
                {saveLoading && <CircularProgress size={20} />}
                {!saveLoading && l1.saveChanges}
              </Button>
            </div>
            <Row>
              <Col span={12} align="initial">
                <h1 className="big-title right u sm">التسجيل في المركز</h1>
                <FormControlLabel control={<Checkbox checked={openToRegistration} onChange={e => setOpenToRegistration(e.target.checked)}
                />} label={l1.centerIsOpenToRegistration} />
              </Col>

              <Col span={12} mb={4}>
                <h1 className="big-title right u sm">الرؤية</h1>
                <TextField
                  multiline
                  fullWidth
                  value={vision || center?.vision || ''}
                  onChange={e => setVision(e.target.value)}
                />
              </Col>
              <Col span={12}>
                <h1 className="big-title right u sm">الرسالة</h1>
                <TextField
                  multiline
                  fullWidth
                  value={message || center?.message || ''}
                  onChange={e => setMessage(e.target.value)}
                />
              </Col>

              <Col span={24} style={{ marginTop: "3em" }}>
                <h1 className="big-title right u sm">القيم</h1>
                <Values
                  values={values || center?.values}
                  setValues={setValues}
                />
              </Col>
            </Row>

            <div className="card-gray">
              <div>
                <div
                  className="logo-center"
                  style={{ backgroundImage: centerLogo }}
                ></div>
              </div>
              <TextField
                multiline
                fullWidth
                value={description || center?.description || ''}
                onChange={e => setDescription(e.target.value)}
              />
            </div>
            <Typography variant="h4" style={{ marginTop: "30px" }}>
              رابط الفيديو
            </Typography>
            <TextField
              fullWidth
              value={video || center?.video || ''}
              onChange={e => setVideo(e.target.value)}
            />
            <div
              className="video-container"
              style={{ backgroundImage: `url(${defaultImg})` }}
            >
              <svg
                className="bi bi-play-fill play"
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
              </svg>
            </div>
            <div className="card-blue">
              <div className="title">
                <SmsFailedIcon /> تم فتح باب التسجيل
              </div>
              <TextField
                multiline
                fullWidth
                value={alertSignup || center?.alertSignup || ''}
                onChange={e => setAlertSignup(e.target.value)}
              />
              <div className="left">
                <Button
                  variant="contained"
                  style={{ background: "#002366" }}
                  size="large"
                >
                  <Typography style={{ color: "white" }}>إشترك</Typography>
                </Button>
              </div>
            </div>
            {/* 
            <div className="section-head">
              <h1 className="big-title right">أحدث النشاطات</h1>
              <hr className="red" />
            </div> */}
            {/* 
            <div className="nav-arrows">
              <svg
                onClick={() => {
                  if (carouselIndex.courses + 1 > 4) {
                    carouselActivities.current.goTo(0);
                    setcarouselIndex({ ...carouselIndex, courses: 1 });
                  } else {
                    carouselActivities.current.slideNext();
                    setcarouselIndex({
                      ...carouselIndex,
                      courses: carouselIndex.courses + 1,
                    });
                  }
                }}
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-chevron-right arrow"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
              <svg
                onClick={() => {
                  if (carouselIndex.courses - 1 < 1) {
                    carouselActivities.current.goTo(4);
                    setcarouselIndex({ ...carouselIndex, courses: 4 });
                  } else {
                    carouselActivities.current.slidePrev();
                    setcarouselIndex({
                      ...carouselIndex,
                      courses: carouselIndex.courses - 1,
                    });
                  }
                }}
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-chevron-left arrow"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </div> */}
            {/* <Carousel
              ref={carouselActivities}
              className="list-articles"
              pagination={false}
              itemsToShow={4}
              renderArrow={null}
              showArrows={false}
            >
              <ActivityCard
                title=" تطوير  المهارات القيادية"
                cover={`${bg1}`}
                description="لوريم ايبسوم دولار سيت أميت  ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور  أنكايديديونتيوت لابوري ات دولار ماجنا أليكيوا"
              />
              <ActivityCard
                title=" تطوير  المهارات القيادية"
                cover={`${bg1}`}
                description="لوريم ايبسوم دولار سيت أميت  ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور  أنكايديديونتيوت لابوري ات دولار ماجنا أليكيوا"
              />
              <ActivityCard
                title=" تطوير  المهارات القيادية"
                cover={`${bg1}`}
                description="لوريم ايبسوم دولار سيت أميت  ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور  أنكايديديونتيوت لابوري ات دولار ماجنا أليكيوا"
              />
              <ActivityCard
                title=" تطوير  المهارات القيادية"
                cover={`${bg1}`}
                description="لوريم ايبسوم دولار سيت أميت  ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور  أنكايديديونتيوت لابوري ات دولار ماجنا أليكيوا"
              />
              <ActivityCard
                title=" تطوير  
                 القيادية"
                cover={`${bg1}`}
                description="لوريم ايبسوم دولار سيت أميت  ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور  أنكايديديونتيوت لابوري ات دولار ماجنا أليكيوا"
              />
            </Carousel> */}
            {/* <a href="/courses" className="btn-more no-border no-deplacing">
              المزيد من النشاطات
            </a> */}

            <section>
              <div className="section-head">
                <h1 className="big-title right">معرض الصور</h1>
                <hr className="red" />
              </div>
              <div className="galery-center">
                {gallery ? returnGallery() : null}
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  inputRef.current.click();
                }}
                startIcon={<CloudUploadIcon />}
                disabled={uploadGalleryLoading}
                style={{ marginLeft: "10px" }}
              >
                {uploadGalleryLoading && <CircularProgress size={14} />}
                {!uploadGalleryLoading && l1.chooseFromComputer}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleDeleteImages();
                }}
                startIcon={<DeleteIcon />}
                disabled={
                  deleteGalleryLoading ||
                  gallery?.filter(image => image.isSelected === true).length ===
                  0
                }
              >
                {deleteGalleryLoading && <CircularProgress size={14} />}
                {!deleteGalleryLoading && l1.deleteImages}
              </Button>
              <input
                type="file"
                accept="image/*"
                ref={inputRef}
                style={{ display: "none" }}
                onChange={e => {
                  setUploadGalleryLoading(true);
                  uploadToGallery({
                    variables: {
                      file: e.target.files[0]
                    }
                  });
                }}
              />
            </section>
            <section className="space-bottom">
              <div className="section-head">
                <h1 className="big-title right"> الفريق</h1>
                <hr className="red" />
              </div>
              <ListMembers
                team={team || center?.teamMember}
                setTeam={setTeam}
                id={centerId}
                // add={addTeamMember}
                pictureID={pictureID}
                addPicture={addPicture}
                loading={loadingAddPicture}
              />
            </section>

            <section className="space-bottom space-top">
              <div className="section-head">
                <h1 className="big-title right"> معلومات الإتصال</h1>
                <hr className="red" />
              </div>

              <Row>
                <Box display="flex" flexDirection="column">
                  <h1 className="big-title right"> العنوان </h1>
                  <TextField
                    fullWidth
                    style={{ width: 300, float: "right" }}
                    value={address || center?.contact?.address || ''}
                    onChange={e => setAddress(e.target.value)}
                  />

                  <h1 className="big-title right"> الايميل </h1>
                  <TextField
                    fullWidth
                    style={{ width: 300, float: "right" }}
                    value={email || center?.contact?.email || ''}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <h1 className="big-title right"> أرقام الهواتف </h1>
                  <PhoneNumbers
                    phoneNumbers={phoneNumbers || center?.contact?.phoneNumbers}
                    setPhoneNumbers={setPhoneNumbers}
                  />
                </Box>
                <Col xs={24} sm={9} md={9} lg={9} xl={9}>
                  <h1 className="big-title right"> مواقع التواصل </h1>
                  <Box display="flex" flexDirection="column">
                    <TextField
                      style={{ marginBottom: 50, width: "300px" }}
                      value={youtube || center?.socialMediaLinks?.youtube || ''}
                      onChange={e => setYoutube(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <YouTube style={{ fontSize: 80, color: "red" }} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <TextField
                      style={{ marginBottom: 50, width: "300px" }}
                      value={twitter || center?.socialMediaLinks?.twitter || ''}
                      onChange={e => setTwitter(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Twitter
                              style={{ fontSize: 80, color: "#50abf1" }}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                    <TextField
                      style={{ marginBottom: 50, width: "300px" }}
                      value={linkedin || center?.socialMediaLinks?.linkedin || ''}
                      onChange={e => setLinkedin(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LinkedIn
                              style={{ fontSize: 80, color: "#0084b1" }}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                    <TextField
                      style={{ marginBottom: 50, width: "300px" }}
                      value={instagram || center?.socialMediaLinks?.instagram || ''}
                      onChange={e => setInstagram(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Instagram
                              style={{ fontSize: 80, color: "#c536a4" }}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                    <TextField
                      style={{ marginBottom: 50, width: "300px" }}
                      value={facebook || center?.socialMediaLinks?.facebook || ''}
                      onChange={e => setFacebook(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Facebook
                              style={{ fontSize: 80, color: "#3a559f" }}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                </Col>
              </Row>
            </section>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={Boolean(alertSuccess)}
            onClose={() => {
              setAlertSuccess(false);
            }}
            autoHideDuration={3000}
          >
            <MuiAlert
              // className={classes.muiAlert}
              elevation={6}
              variant="filled"
              severity="success"
            >
              {alertSuccess}
            </MuiAlert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={Boolean(alertFailure)}
            onClose={() => {
              setAlertFailure(false);
            }}
            autoHideDuration={3000}
          >
            <MuiAlert
              // className={classes.muiAlert}
              elevation={6}
              variant="filled"
              severity="error"
            >
              {alertFailure}
            </MuiAlert>
          </Snackbar>
        </main>
        <Paper
          style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels={false}
          // value={value}
          // onChange={(event, newValue) => {
          //   setValue(newValue);
          // }}
          >
            {/* <Button variant="contained" label={l1.saveAllChanges}>{l1.saveAllChanges}</Button> */}
            <Button
              component={Button}
              variant="contained"
              color="primary"
              size="large"
              onClick={saveAllChanges}
              // style={{ color: 'white', cursor: 'pointer' }}
              disabled={saveLoading}
              style={{ width: "100%" }}
            >
              {saveLoading ? <CircularProgress size={20} /> : l1.saveChanges}
            </Button>
          </BottomNavigation>
        </Paper>
      </div>
    );
  return <Loading />;
}
