import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import { useField } from "formik";
import TextField from "./TextField";

export default function SelectField({
  // field properties
  name,
  label,
  edit,
  required,
  options,
  otherOption,
  // data properties
  touched,
  error,
  value,
  handleChange,
}) {
  const selectedOption = value === 'other' ? otherOption : options.find((_, index) => value === String(index+1));
    const [props] = useField(`${name}__details`);
  if (!edit) {
    return (
      <Box style={{ display: "flex", alignItems: "top", gap: 16 }}>
        {label && <Typography color="primary">{label}:</Typography>}
        <Typography style={{whiteSpace:"pre-wrap"}}>
           {selectedOption ? selectedOption.label : '(غير محدد)'}
           <b>{props.value}</b>
        </Typography>
      </Box>
    );
  } else {
    return (

      <Box display="flex" justifyContent="space-between" gap={3}>

        <FormControl fullWidth variant="outlined" style={{marginBottom: 20}}>
          <InputLabel id="demo-simple-select-label" >{label}</InputLabel>
          <Select
            id={name}
            labelId="demo-simple-select-label"
            name={name}
            label={label}
            value={value ?? ''}
            onChange={handleChange}
            required={required}
          >
            { options.map((option, index) => <MenuItem key={index+1} value={String(index+1)}>{option.label}</MenuItem>) }
            { otherOption && <MenuItem key="other" value="other">{otherOption.label}</MenuItem> }
          </Select>
        </FormControl>
        { selectedOption && selectedOption.details && <TextField edit label={selectedOption.details.label} {...props}/> }
      </Box>
    );
  }
}
