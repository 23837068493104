import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    width: '100vw',
    background: 'white',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'fixed',
    zIndex: 9999999,
    flexGrow: 1,
    overflow: 'auto',
  },
}));

function Loading() {
  const classes = useStyles();
  return (
    <Box
      className={classes.container}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box style={{ position: 'sticky' }}>
        <CircularProgress />
      </Box>
    </Box>
  );
}

export default Loading;
