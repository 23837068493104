import BusinessPlan from "./BusinessPlan";
import ComplaintAboutSuperior from "./ComplaintAboutSuperior";
import EmployeeAbsence from "./EmployeeAbsence";
import EventPlan from "./EventPlan";
import MeetingSchedule from "./MeetingSchedule";
import ProjectSheet from "./ProjectSheet";
import Suggestion from "./Suggestion";
import TrainingProgram from "./TrainingProgram";
import TripFinancialEstimation from "./TripFinancialEstimation";
import TripOrMeetingReport from "./TripOrMeetingReport";
import WarningLetter from "./WarningLetter";

export {
  EmployeeAbsence,
  WarningLetter,
  Suggestion,
  ComplaintAboutSuperior,
  BusinessPlan,
  TrainingProgram,
  EventPlan,
  TripFinancialEstimation,
  MeetingSchedule,
  TripOrMeetingReport,
  ProjectSheet,
};

const forms = {
  EmployeeAbsence,
  WarningLetter,
  Suggestion,
  ComplaintAboutSuperior,
  BusinessPlan,
  TrainingProgram,
  EventPlan,
  TripFinancialEstimation,
  MeetingSchedule,
  TripOrMeetingReport,
  ProjectSheet,
};

export default forms;
