import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import { FaRegQuestionCircle } from "react-icons/fa";
import { IoAdd } from "react-icons/io5";
import { MdAssignment, MdAssignmentTurnedIn } from "react-icons/md";
import { RiQuestionnaireLine } from "react-icons/ri";
import { IoMdArrowDropdown } from "react-icons/io";
import { GrClose } from "react-icons/gr";
import { BiBookBookmark } from "react-icons/bi";
import { BsListUl } from "react-icons/bs";
import { HiMenuAlt3 } from "react-icons/hi";
import { l1 } from "../../../local.json";
import { gql, useMutation } from "@apollo/client";
import { LoadingState, Navigation } from "../../../../Context";
import { useParams } from "react-router-dom";
import Drafter2 from "../../../common/Drafter2";
import QuizCard from "./QuizCard";

const useStyles = makeStyles(theme => ({
  create: {
    padding: theme.spacing(1.4, 3.8),
    borderRadius: theme.spacing(4)
  },
  menu: {
    marginTop: theme.spacing(7.5)
  },
  menuTopics: {
    marginTop: theme.spacing(1),
    height: "auto",
    "&>*": {
      width: "inherit"
    }
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(4),
    marginRight: theme.spacing(1)
  },
  menuText: {
    paddingRight: theme.spacing(4)
  },
  // Dialog
  titleContainer: {
    maxHeight: theme.spacing(3)
  },
  title: {
    fontSize: theme.spacing(2.4),
    fontWeight: 600,
    marginLeft: theme.spacing(0.4)
  },
  dialogTitle: {
    borderBottom: `1px ${theme.palette.grey[300]} solid`
  },
  closeContainer: {
    marginTop: theme.spacing(0.7),
    "&>*": {
      fontSize: theme.spacing(2)
    }
  },
  dialogContent: {
    padding: 0,
    marginRight: theme.spacing(4)
  },
  dialogContentSubContainer: {
    margin: theme.spacing(2, 0)
  },
  rightContainer: {
    height: "92vh",
    borderLeft: `1px ${theme.palette.grey[300]} solid`
  },
  partTitle: {
    textAlign: "left",
    // marginLeft: theme.spacing(8),
    color: theme.palette.grey[700],
    fontSize: theme.spacing(2),
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  select: {
    width: "100%",
    padding: theme.spacing(2, 2),
    background: theme.palette.grey[100],

    borderRadius: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.grey[200]
    },
    color: theme.palette.grey[700]
  },
  marginRight: {
    marginLeft: theme.spacing(8)
  },
  selectFullWidth: {
    margin: theme.spacing(0, 8)
  },
  boxDivider: {
    width: theme.spacing(2)
  },

  dialogIcon: {
    fontSize: theme.spacing(4),
    color: theme.palette.grey[700],
    padding: theme.spacing(2)
  },

  titleTextField: {
    // padding: theme.spacing(2, 0),
    background: theme.palette.background.default,
    minWidth: 288,
    flexShrink: 0,
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    "&>*": {
      padding: theme.spacing(0, 2, 2, 2)
    }
  },
  textField: {
    // padding: theme.spacing(2, 0),
    background: theme.palette.background.default,
    borderRadius: theme.spacing(2),
    "&>*": {
      padding: theme.spacing(0, 2, 2, 2)
    }
  },
  contentTextField: {
    background: theme.palette.background.default,
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    "&>*": {
      padding: theme.spacing(0, 2, 2, 2)
    },
    margin: theme.spacing(2, 0)
  },
  partContainer: {
    padding: theme.spacing(1, 2)
  },
  optionsContainer: {
    marginTop: theme.spacing(2),
    "&>*": {
      fontSize: theme.spacing(3),
      fontWeight: 600,
      color: theme.palette.grey[700]
    }
  },
  topicButtons: {
    margin: theme.spacing(1),
    gap: theme.spacing(2)
  },
  topicTextFieldContainer: {
    minWidth: "400px",
    height: "150px"
  }
}));
const ADD_TOPIC = gql`
  mutation CreateTopic($name: String!, $class: ID!) {
    createTopic(input: { data: { name: $name, class: $class } }) {
      topic {
        name
      }
    }
  }
`;

const CREATE_ASSIGNMENT = gql`
  mutation CreateAssignment(
    $title: String!
    $description: String!
    $topic: ID
    $type: ENUM_ASSIGNMENT_TYPE!
    $class: ID!
    $grade: Float
    $due: Date
    $center: ID!
    $quiz: ID
    $writer: ID!
    $evaluationsInfo: ComponentClassworkEvaluationsInfoInput
  ) {
    createAssignment(
      input: {
        data: {
          center: $center
          title: $title
          description: $description
          type: $type
          topic: $topic
          class: $class
          grade: $grade
          due: $due
          quiz: $quiz
          writer: $writer
          evaluationsInfo: $evaluationsInfo
        }
      }
    ) {
      assignment {
        id
        title
        type
        description
      }
    }
  }
`;
function Create({ currentClass, topics, query, classID, refetch }) {
  const { reverseLoadingState } = useContext(LoadingState);
  const { slug } = useParams();
  const { user, centerId } = useContext(Navigation);
  const [
    createAssignment,
    { loading: loadingCreateAssignmentMutation }
  ] = useMutation(CREATE_ASSIGNMENT, {
    onError: error => console.log("mutationCreateMutation error: ", error),
    onCompleted: data => {
      console.log("mutationCreateMutation success: ", data);
      reverseLoadingState();
      reverseDialogState();
    },
    refetchQueries: [
      {
        query: query,
        variables: {
          slug
        }
      }
    ]
  });
  const [createTopic] = useMutation(ADD_TOPIC, {
    onError: error => console.log("mutationCreateTopic error: ", error),
    onCompleted: data => {
      console.log("mutationCreateTopic success: ", data);
      reverseTopicDialogState();
      refetch();
    }
  });
  const [type, setType] = useState(null);
  const [topic, setTopic] = useState(null);
  const [topicContent, setTopicContent] = useState(null);
  const topicContentRef = useRef();
  const [title, setTitle] = useState(null);
  const titleRef = useRef();
  const [due, setDue] = useState(null);
  const dueRef = useRef();
  const [description, setDescription] = useState("");
  const [evaluationsInfo, setEvaluationsInfo] = useReducer(
    (prev, update) => ({...prev, ...update}),
    {coach: '', date: '', location:''}
  )
  useEffect(()=>{
    console.log(evaluationsInfo);
  }
  ,[evaluationsInfo])
  //////
  const [dialogState, reverseDialogState] = useReducer(state => !state, false);
  const [dialogTopicState, reverseTopicDialogState] = useReducer(
    state => !state,
    false
  );

  const [anchor, setAnchor] = useState(null);
  const openMenu = event => {
    setAnchor(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchor(null);
  };
  const [anchorTopic, setAnchorTopic] = useState(null);
  const openTopicMenu = event => {
    setAnchorTopic(event.currentTarget);
  };
  const closeTopicMenu = () => {
    setAnchorTopic(null);
  };
  const [anchorPoints, setAnchorPoints] = useState(null);
  const openPointsMenu = event => {
    setAnchorPoints(event.currentTarget);
  };
  const closePointsMenu = () => {
    setAnchorPoints(null);
  };
  const [topicMenuLabel, setTopicMenuLabel] = useState(l1.choosetopics);
  const [pointsMenuLabel, setPointsMenuLabel] = useState(l1.noPoints);
  ///////
  const [grade, setGrade] = useState(null);
  if (loadingCreateAssignmentMutation) {
    reverseLoadingState();
  }

  const [quiz, setQuiz] = useState("");
  const classes = useStyles();
  return (
    <>
      <Button
        className={classes.create}
        color="primary"
        variant="contained"
        startIcon={<IoAdd />}
        onClick={openMenu}
      >
        {l1.create}
      </Button>

      {/* Create Menu */}
      <Menu
        className={classes.menu}
        open={Boolean(anchor)}
        onClose={closeMenu}
        anchorEl={anchor}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            reverseDialogState();
            setType("assignment");
          }}
        >
          <Box display="flex" justifyContent="space-around" alignItems="center">
            <MdAssignment className={classes.icon} />
            <Typography className={classes.menuText}>
              {l1.assignment}
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            reverseDialogState();
            setType("quiz");
          }}
        >
          <Box display="flex" justifyContent="space-around" alignItems="center">
            <MdAssignment className={classes.icon} />
            <Typography className={classes.menuText}>{l1.quiz}</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            reverseDialogState();
            setType("question");
          }}
        >
          <Box display="flex" justifyContent="space-around" alignItems="center">
            <RiQuestionnaireLine className={classes.icon} />
            <Typography className={classes.menuText}>{l1.question}</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            reverseDialogState();
            setType("material");
          }}
        >
          <Box display="flex" justifyContent="space-around" alignItems="center">
            <BiBookBookmark className={classes.icon} />
            <Typography className={classes.menuText}>{l1.material}</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            reverseDialogState();
            setType("evaluations");
          }}
        >
          <Box display="flex" justifyContent="space-around" alignItems="center">
            <MdAssignmentTurnedIn className={classes.icon} />
            <Typography className={classes.menuText}>
              {l1.evaluations}
            </Typography>
          </Box>
        </MenuItem>

        <Divider className={classes.divider} />
        <MenuItem
          className={classes.menuItem}
          onClick={reverseTopicDialogState}
        >
          <Box display="flex" justifyContent="space-around" alignItems="center">
            <BsListUl className={classes.icon} />
            <Typography className={classes.menuText}>{l1.topic}</Typography>
          </Box>
        </MenuItem>
      </Menu>
      {/* ///////////// */}
      <Dialog
        className={classes.dialog}
        open={dialogState}
        onClose={reverseDialogState}
        fullScreen
      >
        <DialogTitle className={classes.dialogTitle}>
          <Box
            className={classes.titleContainer}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <Box onClick={reverseDialogState}>
                <IconButton className={classes.closeContainer}>
                  <GrClose />
                </IconButton>
              </Box>
              <Typography className={classes.title}>{l1[type]}</Typography>
            </Box>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                createAssignment({
                  variables: {
                    title,
                    description,
                    type,
                    topic,
                    due,
                    class: classID,
                    grade,
                    center: centerId,
                    quiz: quiz.length > 0 ? quiz : null,
                    writer: user.id,
                    evaluationsInfo,
                  }
                });
              }}
            >
              {l1.create}
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Grid container>
            <Grid item xs={12} sm={5} md={3}>
              <Box
                className={classes.optionsContainer}
                display="flex"
                justifyContent="center"
              >
                <Typography className={classes.optionText}>
                  {l1.options}
                </Typography>
              </Box>
              {/* <Box
                className={classes.partContainer}
                display="flex"
                flexDirection="column"
                alignItems="space-between"
              >
                <Box display="flex" flexDirection="column">
                  <Typography className={classes.partTitle}>
                    {l1.for}
                  </Typography>
                </Box>
                <Box
                  className={classes.partSelectContainer}
                  display="flex"
                  justifyContent="center"
                >
                  <Box
                    className={classes.select}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography>{currentClass}</Typography>
                    <IoMdArrowDropdown />
                  </Box>
                  <span className={classes.boxDivider} />
                  <Box
                    className={classes.select}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography>{l1.allstudents}</Typography>
                    <IoMdArrowDropdown />
                  </Box>
                </Box>
              </Box> */}
              {/* Part 2 */}
              {type === "material" ? (
                ""
              ) : (
                <Box
                  className={classes.partContainer}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Box display="flex" flexDirection="column">
                    <Typography className={classes.partTitle}>
                      {l1.points}
                    </Typography>
                    <Menu
                      className={classes.menuTopics}
                      open={Boolean(anchorPoints)}
                      onClose={closePointsMenu}
                      elevation={1}
                      anchorEl={anchorPoints}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          closePointsMenu();
                          setGrade(100);
                          setPointsMenuLabel(100 + " نقطة");
                        }}
                      >
                        <Typography className={classes.menuText}>
                          100
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          closePointsMenu();
                          setGrade(null);
                          setPointsMenuLabel(l1.noPoints);
                        }}
                      >
                        <Typography className={classes.menuText}>
                          {l1.noPoints}
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Box
                    className={classes.partSelectContainer}
                    display="flex"
                    justifyContent="center"
                  >
                    <Box
                      className={classes.select}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      onClick={openPointsMenu}
                    >
                      <Typography>{pointsMenuLabel}</Typography>
                      <IoMdArrowDropdown />
                    </Box>
                  </Box>
                </Box>
              )}
              {/* Part 4 */}
              <Box
                className={classes.partContainer}
                display="flex"
                flexDirection="column"
                alignItems="space-between"
              >
                <Box display="flex" flexDirection="column">
                  <Typography className={classes.partTitle}>
                    {l1.due}
                  </Typography>
                </Box>
                <Box
                  className={classes.partSelectContainer}
                  display="flex"
                  justifyContent="center"
                >
                  <Box
                    className={classes.select}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <TextField
                      id="date"
                      type="date"
                      className={classes.textField}
                      inputRef={dueRef}
                      onBlur={e => {
                        setDue(dueRef.current.value);
                      }}
                    />
                    <IoMdArrowDropdown />
                  </Box>
                </Box>
              </Box>
              {/* Part 5 */}
              <Box
                className={classes.partContainer}
                display="flex"
                flexDirection="column"
                alignItems="space-between"
              >
                <Box display="flex" flexDirection="column">
                  <Typography className={classes.partTitle}>
                    {l1.topic}
                  </Typography>
                  {/* Topic Menu */}
                  <Menu
                    className={classes.menuTopics}
                    open={Boolean(anchorTopic)}
                    onClose={closeTopicMenu}
                    elevation={1}
                    anchorEl={anchorTopic}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    {topics.map(({ name, id }) => (
                      <MenuItem
                        key={id}
                        onClick={() => {
                          setTopicMenuLabel(name);
                          closeTopicMenu();
                          setTopic(id);
                        }}
                      >
                        <Typography className={classes.menuText}>
                          {name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
                <Box
                  className={classes.partSelectContainer}
                  display="flex"
                  justifyContent="center"
                >
                  <Box
                    className={classes.select}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={openTopicMenu}
                  >
                    <Typography>{topicMenuLabel}</Typography>
                    <IoMdArrowDropdown />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid className={classes.rightContainer} item xs={12} sm={7} md={9}>
              <Box className={classes.dialogContentSubContainer} display="flex">
                <MdAssignment className={classes.dialogIcon} />
                <TextField
                  className={classes.titleTextField}
                  label={l1.title}
                  fullWidth
                  autoFocus
                  inputRef={titleRef}
                  onChange={e => {
                    setTitle(titleRef.current.value);
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <HiMenuAlt3 className={classes.dialogIcon} />
                <Drafter2 assignment setContent={setDescription} />
              </Box>
              {type === "quiz" && (
                <Box
                  className={classes.dialogContentSubContainer}
                  display="flex"
                  justifyContent="flex-start"
                >
                  <MdAssignment className={classes.dialogIcon} />
                  <QuizCard mode="edit" quiz={quiz} setQuiz={setQuiz} />
                </Box>
              )}
              {type === "evaluations" ? (
                <Box
                  className={classes.dialogContentSubContainer}
                  display="flex"
                  justifyContent="flex-start"
                  style={{gap: 8}}
                >
                  <MdAssignmentTurnedIn className={classes.dialogIcon} />
                  
                  <TextField
                    className={classes.textField}
                    label={l1.evaluationsCoach}
                    onChange={e => {
                      setEvaluationsInfo({coach: e.target.value});
                    }}
                    style={{flexGrow:1}}
                  />
                  <TextField
                    className={classes.textField}
                    label={l1.evaluationsDate}
                    onChange={e => {
                      setEvaluationsInfo({date: e.target.value});
                    }}
                    style={{flexGrow:1}}

                  />
                  <TextField
                    className={classes.textField}
                    label={l1.evaluationsLocation}
                    onChange={e => {
                      setEvaluationsInfo({location: e.target.value});
                    }}
                    style={{flexGrow:1}}

                  />

                </Box>
              ) : null}

            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        className={classes.topicDialogContainer}
        open={dialogTopicState}
        onClose={reverseTopicDialogState}
      >
        <DialogContent className={classes.topicTextFieldContainer}>
          <TextField
            className={classes.topicTextField}
            label={l1.topic}
            fullWidth
            inputRef={topicContentRef}
            onChange={e => {
              setTopicContent(topicContentRef.current.value);
            }}
          />
          <Box
            className={classes.topicButtons}
            display="flex"
            justifyContent="center"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                createTopic({
                  variables: {
                    class: classID,
                    name: topicContent
                  }
                });
              }}
            >
              {l1.create}
            </Button>
            <Button variant="contained" onClick={reverseTopicDialogState}>
              {l1.cancel}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Create;
