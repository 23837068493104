import React from 'react';
import {
  Box, IconButton,
  FormControl, InputLabel, Select, MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';

import {FaPlus, FaTimes, FaPen} from 'react-icons/fa';
import {l1} from '../../../local.json';

import moment from 'moment'
import 'moment/locale/ar-dz';
moment.locale('ar-dz');

const useStyles = makeStyles(theme => ({
  quizSelector:{
    padding: theme.spacing(2)
  },
  quizSelectorItem:{
    borderTop: "solid 1px lightgray",
    display:"flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    textAlign: "left",
    '& > *':{
      width: "100%",
    },
    '& > p':{
      fontSize: 14,
    },
    "&.add":{
      display:"flex",
      justifyContent: "center",
      flexDirection:"row",
      gap: theme.spacing(1),
      padding: theme.spacing(2),
      fontWeight: "bold",
      '& > *':{
        width: "auto",
      }, 
    }
  }
}))




export default function QuizSelector({quizzes, selectedQuiz, onQuizSelected, newQuiz}) {
  const c = useStyles()
  return (
       <Box display="flex" alignItems="center" justifyContent="flex-start" className={c.quizSelector}>
         <Typography>{l1.chooseOrCreateQuiz}</Typography>
         <FormControl>
          <InputLabel id="quiz-select-label">{l1.selectQuiz}</InputLabel>
          <Select
            labelId="quiz-select-helper-label"

            id="quiz-select-helper"
            value={selectedQuiz}
            onChange={onQuizSelected}
            style={{minWidth: 200}}
            >
            {
              <MenuItem className={c.quizSelectorItem + " add"} value="+">
                <FaPlus />
                <Typography variant="b">{l1.createNewQuiz}</Typography>
              </MenuItem>
            }
            {
              quizzes.map((quiz, index) =>
                <MenuItem key={index} value={quiz.id} className={c.quizSelectorItem}>
                  <Typography variant="h5">{quiz.title}</Typography>
                  <Typography component="p">{l1.createdBy} @{"moatezz"/*quiz.createdBy.username*/}</Typography>
                  <Typography component="p">{moment(quiz.createdAt).fromNow()}</Typography>
                </MenuItem>
                )
            }
          </Select>
        </FormControl>
      </Box> 
  )
}