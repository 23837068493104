import React, { useReducer, useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  makeStyles,
  MenuItem,
  Typography,
  Select,
  InputAdornment,
  Input,
  Link,
  Button
} from "@material-ui/core";
import TopBar from "./common/TopBar";
import { l1 } from "./local.json";

import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Loading from "./common/Loading";
import { staticUrl } from "./common/utils";

const useStyles = makeStyles(theme => ({
  answersHeader: {
    height: theme.spacing(8),
    borderBottom: "solid 1px lightgray"
  },
  sidebar: {
    width: "28rem",
    borderRight: "solid 1px lightgray"
  },
  noWorksFound: {
    width: "100%",
    textAlign: "center",
    padding: theme.spacing(4, 2)
  },
  mainContent: {
    padding: theme.spacing(2),
    flexGrow: 1
  },
  searchFilter: {
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    borderBottom: "solid 1px lightgray"
  },
  filterMenu: {
    minWidth: 100
  },
  studentWorkCard: {
    gap: theme.spacing(2),
    padding: theme.spacing(1, 0, 1, 2),
    borderBottom: "solid 1px lightgray",
    height: 64,
    "&:hover": {
      cursor: "pointer",
      studentWorkName: {
        color: "green",
        textDecoration: "underline"
      }
    },
    "&:hover, &:focus-within, &.active": {
      backgroundColor: "#eee"
    }
  },
  studentWorkAvatar: {
    width: 32,
    height: 32
  },
  studentWorkName: {
    fontWeight: "bold",
    flexGrow: 1
  },
  studentWorkGradeContainer: {
    fontSize: theme.spacing(2),
    fontWeight: 700,
    height: "100%",
    // borderLeft: "solid 1px lightgray",
    maxWidth: "8.25rem",
    minWidth: "8.25rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    "&:hover": {
      cursor: "text"
    }
  },
  studentWorkGrade: {
    color: "green",
    fontWeight: 700,
    fontSize: theme.spacing(2)
  },
  stats: {
    marginTop: theme.spacing(3),
    width: "max-content",
    borderRight: `1px ${theme.palette.grey[400]} solid`,
    padding: theme.spacing(0, 2),
    "&:last-child": {
      borderRight: 0
    }
  },
  statsNumber: {
    textAlign: "left",
    fontWeigh: 900,
    fontSize: theme.spacing(5)
  },
  statsText: {
    color: theme.palette.grey[600],
    textAlign: "left"
  },
  submittedWorks: {
    marginTop: theme.spacing(4)
  },
  studentWorkSubmission: {
    borderBottom: "solid 1px lightgray",
    padding: theme.spacing(2, 0, 1, 0.5)
  },
  studentWorkSubmissionHeader: {
    gap: theme.spacing(2)
  },
  studentWorkSubmissionDetails: {
    paddingLeft: theme.spacing(6)
  },
  clickable: {
    color: "green",
    cursor: "pointer"
  }
}));

const QUERY_ASSIGNMENT = gql`
  query QueryAssignment($id: ID!) {
    assignment(id: $id) {
      title
      type
      description
      grade
      studentWorks {
        id
        student {
          id
          fullname
          #avatar {
          #  url
          #}
        }
        file
        status
        submission
        submittedAt
        grade
        gradedBy {
          id
        }
        gradedAt
      }
    }
  }
`;

const UPDATE_STUDENT_WORK = gql`
  mutation SubmitStudentWork($id: ID!, $fields: editStudentWorkInput!) {
    updateStudentWork(input: { where: { id: $id }, data: $fields }) {
      studentWork {
        id
        status
        grade
      }
    }
  }
`;

function AnswersRoot({ data, handlers }) {
  const c = useStyles();
  const { id } = useParams();
  const [assignment, setAssignment] = useState(null);

  const { loading } = useQuery(QUERY_ASSIGNMENT, {
    variables: { id },
    onError: error => {
      console.log("QueryAssignmentError: ", error);
      setError(true);
    },
    onCompleted: response => {
      console.log("QueryAssignmentSuccess: ", response);
      data.studentWorks = groupStudentWorks(response.assignment.studentWorks);
      data.assignment = response.assignment;
      setAssignment(data.assignment);
      setReady(true);
    },
    notifyOnNetworkStatusChange: true
  });

  const groupStudentWorks = works => {
    const groups = {
      all: works,
      assigned: works.filter(work => work.status === "assigned"),
      submitted: works.filter(work => work.status === "submitted"),
      graded: works.filter(work => work.status === "graded")
    };
    return {
      statistics: {
        all: works.length,
        assigned: groups.assigned.length,
        submitted: groups.submitted.length,
        graded: groups.graded.length
      },
      groups
    };
  };
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);

  return loading ? (
    <Loading />
  ) : ready ? (
    <Box
      style={{ height: "100vh" }}
      display="flex"
      flexDirection="column"
      justifyContent="stretch"
    >
      <TopBar
        type={
          assignment.type === "question"
            ? "QuestionAnswers"
            : "AssignmentAnswers"
        }
      />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        className={c.answersHeader}
      ></Box>

      <Box
        display="flex"
        aligntItems="stretch"
        justifyContent="flex-start"
        flexGrow="1"
      >
        <AnswersSidebar data={data} handlers={handlers} />
        <AnswersContent data={data} handlers={handlers} />
      </Box>
    </Box>
  ) : error ? (
    l1.anErrorHappened + ". " + l1.refreshToRetry
  ) : null;
}

function AnswersSidebar({ data, handlers }) {
  const c = useStyles();
  const [, forceUpdate] = useReducer(x => !x, false);
  handlers.updateSidebar = forceUpdate;
  const [statusFilter, setStatusFilter] = useState("all");
  handlers.setStatusFilter = setStatusFilter;

  return !data.studentWorks?.statistics?.all ? (
    <Box className={c.sidebar}>
      <Typography className={c.noWorksFound}>{l1.noWorksFound}</Typography>
    </Box>
  ) : (
    <Box className={c.sidebar} display="flex" flexDirection="column">
      <Box
        className={c.searchFilter}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Typography>{l1.filterWorksByStatus}</Typography>
        <Select
          className={c.filterMenu}
          value={statusFilter}
          onChange={event => {
            handlers.setStatusFilter(event.target.value);
            data.selectedWork = null;
            handlers.updateSidebar();
          }}
        >
          {["all", "assigned", "submitted", "graded"].map(status =>
            !data.studentWorks.statistics[status] ? null : (
              <MenuItem className={c.statusSelectorItem} value={status}>
                <Typography>
                  {l1[`work${status}`]} ({data.studentWorks.statistics[status]})
                </Typography>
              </MenuItem>
            )
          )}
        </Select>
      </Box>
      {data.studentWorks.groups[statusFilter].map((work, index) => (
        <Box
          key={index}
          className={
            c.studentWorkCard + (work.id === data.selectedWork ? " active" : "")
          }
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          onClick={e => {
            data.selectedWork = work.id;
            handlers.updateContent();
            handlers.updateSidebar();
          }}
        >
          <Avatar
            className={c.studentWorkAvatar}
            alt={work.student.fullname}
            src={
              work.student?.avatar?.url
                ? staticUrl(work.student?.avatar?.url)
                : null
            }
          />
          <Typography g className={c.studentWorkName}>
            {work.student.fullname}
          </Typography>
          {
            <StudentWorkGradeEditor
              studentWork={work}
              maxGrade={data.assignment.grade}
              updateSidebar={handlers.updateSidebar}
              //parentRef={workRefs.current[index]}
            />
          }
        </Box>
      ))}
    </Box>
  );
}

function AnswersContent({ data, handlers }) {
  const c = useStyles();
  const [, forceUpdate] = useReducer(x => !x, false);
  handlers.updateContent = forceUpdate;
  const submittedWorks = data.studentWorks
    ? data.studentWorks.groups.all.filter(work => work.submission !== null)
    : [];
  return (
    <Box className={c.mainContent}>
      <Typography component="h1" variant="4">
        {data.assignment.title}
      </Typography>
      <Typography
        component="p"
        variant="p"
        dangerouslySetInnerHTML={{ __html: data.assignment.description }}
      />
      {data.selectedWork ? (
        data.studentWorks?.groups.all.map(work =>
          work.id !== data.selectedWork ? null : (
            <StudentWorkSubmission
              key={work.id}
              studentWork={work}
              maxGrade={data.assignment.grade || null}
              assignmentType={data.assignment.type}
            />
          )
        )
      ) : (
        <Box>
          <Box className={c.statsContainer} display="flex">
            {["all", "assigned", "submitted", "graded"].map(status => {
              let count = data.studentWorks.statistics[status];
              return (
                <Box
                  className={c.stats + " " + (count ? c.clickable : "")}
                  onClick={
                    count
                      ? () => {
                          handlers.setStatusFilter(status);
                        }
                      : null
                  }
                >
                  <Typography className={c.statsNumber}>{count}</Typography>
                  <Typography className={c.statsText}>
                    {l1["work" + status]}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          {submittedWorks.length > 0 ? (
            <Box className={c.submittedWorks}>
              <Typography component="h2" variant="h5" gutterBottom>
                {l1.submittedWorks}
              </Typography>
              {submittedWorks.map((work, index) => (
                <Box key={index} className={c.studentWorkSubmission}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    className={c.studentWorkSubmissionHeader}
                  >
                    <Avatar
                      className={c.studentWorkAvatar}
                      alt={work.student.fullname}
                      src={
                        work.student?.avatar?.url
                          ? staticUrl(work.student?.avatar?.url)
                          : null
                      }
                    />
                    <Typography className={c.studentWorkName}>
                      {work.student.fullname}
                    </Typography>
                  </Box>
                  {["question", "assignment"].includes(data.assignment.type) ? (
                    <Typography
                      className={c.studentWorkSubmissionDetails}
                      component="p"
                      dangerouslySetInnerHTML={{ __html: work.submission }}
                    />
                  ) : data.assignment.type === "quiz" ? (
                    <Typography className={c.studentWorkSubmissionDetails}>
                      {work.submission.totalQuestions}
                      {l1.slash}
                      {work.submission.totalCorrect}
                      {l1.correctAnswers}
                    </Typography>
                  ) : null}
                </Box>
              ))}
            </Box>
          ) : null}
        </Box>
      )}
    </Box>
  );
}
export default function Answers() {
  let handlers = {};
  let data = {
    assignment: null,
    studentWorks: {},
    selectedWork: null
  };
  return <AnswersRoot data={data} handlers={handlers} />;
}

export function StudentWorkGradeEditor({ studentWork, maxGrade }) {
  const c = useStyles();
  const [grade, setGrade] = useState(studentWork.grade);
  const [editMode, setEditMode] = useState(false);
  const [errorUpdating, setErrorUpdating] = useState(false);

  const [updateStudentWork, { loading: updatingStudentWork }] = useMutation(
    UPDATE_STUDENT_WORK,
    {
      onError: error => {
        console.log("mutationUpdateStudentWork Error", error);
        setErrorUpdating(true);
      },
      onCompleted: response => {
        console.log("mutationUpdateStudentWork Success", response);
        studentWork.status = response.updateStudentWork.studentWork.status;
        studentWork.grade = response.updateStudentWork.studentWork.grade;
      }
    }
  );

  // const {}
  const processGrade = e => {
    const newGrade = grade === "" ? null : parseInt(grade);
    setGrade(newGrade);
    console.log(studentWork, newGrade);
    if (studentWork.grade !== newGrade) {
      updateStudentWork({
        variables: {
          id: studentWork.id,
          fields: {
            grade: newGrade,
            status:
              newGrade !== null
                ? "graded"
                : studentWork.submittedAt
                ? "submitted"
                : "assigned"
            // gradedBy: new !== null ? currentUser.id : null
            // gradedAt: calculate in strapi's lifecycle.beforeUpdate
          }
        }
      });
    } else {
      console.log("Same grade");
    }
    setEditMode(false);
  };

  return editMode ? (
    <Box class={c.studentWorkGradeContainer}>
      <Input
        className={c.studentWorkGrade}
        autoFocus
        type="tel"
        defaultValue={grade !== null ? grade : ""}
        onChange={e => {
          setGrade(e.target.value);
        }}
        endAdornment={
          <InputAdornment
            style={{ color: "green", fontWeight: 700 }}
            position="end"
          >
            {l1.slash}
            {maxGrade}
          </InputAdornment>
        }
        onKeyPress={e => {
          if (e.charCode === 13) {
            processGrade(e);
          }
        }}
        onBlur={processGrade}
      />
    </Box>
  ) : (
    <Box
      class={c.studentWorkGradeContainer}
      onClick={event => {
        setEditMode(true);
        event.stopPropagation();
      }}
      tabIndex="1"
    >
      <Typography className={c.studentWorkGrade}>
        {grade || (
          <span
            style={{
              display: "inline-block",
              minWidth: 24,
              borderBottom: "solid 1px green"
            }}
          ></span>
        )}
        <span className={c.maxGrade}>
          {l1.slash}
          {maxGrade}
        </span>
      </Typography>
      <Typography
        variant="p"
        style={{ fontWeight: "normal", whiteSpace: "nowrap" }}
      >
        {updatingStudentWork
          ? l1.saving + "..."
          : errorUpdating
          ? l1.error
          : l1["work" + studentWork.status]}
      </Typography>
    </Box>
  );
}

function StudentWorkSubmission({ studentWork, maxGrade, assignmentType }) {
  const c = useStyles();
  const [feedback] = useState(studentWork.feedback);
  const [editMode, setEditMode] = useState(false);
  const [, setErrorUpdating] = useState(false);
  const [updateStudentWork /*{ loading: updatingStudentWork }*/] = useMutation(
    UPDATE_STUDENT_WORK,
    {
      onError: error => {
        console.log("mutationUpdateStudentWork Error", error);
        setErrorUpdating(true);
      },
      onCompleted: response => {
        console
          .log("mutationUpdateStudentWork Success", response)
          [("status", "feedback", "grade", "gradedAt", "gradedBy")].forEach(
            field => {
              studentWork[field] =
                response.updateStudentWork.studentWork[field];
            }
          );
      }
    }
  );

  // const {}
  const processFeedback = () => {
    if (editMode) {
      const newFeedback = feedback === "" ? null : feedback;
      console.log(studentWork, feedback);
      if (studentWork.feedback !== newFeedback) {
        updateStudentWork({
          variables: {
            id: studentWork.id,
            fields: {
              feedback: newFeedback
            }
          }
        });
      } else {
        console.log("Same feedback, do nothing.");
      }
      setEditMode(false);
    }
  };
  return (
    <Box>
      <Box className={c.studentWorkSubmission}>
        <Box
          display="flex"
          alignItems="center"
          className={c.studentWorkSubmissionHeader}
        >
          <Avatar
            className={c.studentWorkAvatar}
            alt={studentWork.student.fullname}
            src={
              studentWork.student?.avatar?.url
                ? staticUrl(studentWork.student?.avatar?.url)
                : null
            }
          />
          <Box flexGrow="1">
            <Typography gutterBottom className={c.studentWorkName}>
              {studentWork.student.fullname}
            </Typography>
            {assignmentType === "assignment" && studentWork.file ? (
              <Button variant="outlined" color="primary">
                <Link
                  href={`${process.env.REACT_APP_STRAPI_URL}${studentWork.file}`}
                >
                  {l1.downloadTheFile}
                </Link>
              </Button>
            ) : null}

            {assignmentType === "question" ? (
              <Typography
                component="p"
                dangerouslySetInnerHTML={{ __html: studentWork.submission }}
              />
            ) : null}
            {assignmentType === "quiz" ? (
              <Typography>
                {studentWork.submission?.totalQuestions}
                {l1.slash}
                {studentWork.submission?.totalCorrect}
                {l1.correctAnswers}
              </Typography>
            ) : null}
          </Box>
        </Box>
      </Box>

      <Divider />

      {/* <Box>
        <Typography gutterBottom variant="h5">{l1.teacherFeedback}</Typography>
        <br/>
        <br/>

        {
          editMode
          ? <Box class={c.teacherFeedbackContainer}>
              <Input
                className={c.teacherFeedback}
                autoFocus
                multiline
                fullWidth
                defaultValue={feedback !== null ? feedback : ""} 
                onChange={(e)=>{setFeedback(e.target.value)}}
                onKeyPress={(e)=>{
                  if(e.charCode === 13){
                  processFeedback(e)
                  }
                }}
                onBlur={processFeedback}/>
            </Box>
          : <Box>
              <Box display="flex" alignItems="center" style={{gap: 8}}>
                <Button variant="contained" color="default" onClick={()=>setEditMode(true)}>{studentWork?.feedback?.length ? l1.edit : l1.addFeedback}</Button>
                {feedback?.length ? <Button onClick={alert("delete element using mutation")}>{l1.delete}</Button> : null}
              </Box>
              {
                feedback?.length
                ? <Typography component="p" dangerouslySetInnerHTML={{__html: studentWork.feedback}}></Typography>
                : null
              }
            </Box>
        }
      </Box> */}
    </Box>
  );
}
