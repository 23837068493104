import { Box, Button, CircularProgress } from "@material-ui/core";
import { useContext } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { l1 } from "../../components/local.json";
import { FormActionsContext } from "./FormList";
import { Navigation } from "../../Context";

function FormListActions({ id }) {
  const { form, confirmationDialog, deleteItem, deleting, data } =
    useContext(FormActionsContext);
  const { membershipRole, user } = useContext(Navigation);
  const item = data.find(item => item.id === id)
  const history = useHistory();
  return (
    <Box style={{ display: "flex", gap: 4 }}>
      {form.enabledRoutes.includes("view") && form.availableActions.view(item, {user, membershipRole}) && ( 
        <Button
          color="default"
          variant="outlined"
          style={{ fontWeight: 700 }}
          onClick={() => {
            history.push(generatePath(form.routes.view.path, { id }));
          }}
          disabled={deleting}
        >
          {l1.view}
        </Button>
      )}
      {form.enabledRoutes.includes("edit") && form.availableActions.edit(item, {user, membershipRole}) && (
        <Button
          color="primary"
          variant="outlined"
          style={{ fontWeight: 700 }}
          onClick={() => {
            history.push(generatePath(form.routes.edit.path, { id }));
          }}
          disabled={deleting}
        >
          {l1.edit}
        </Button>
      )}
      {
        form.availableActions.delete(item, {user, membershipRole}) && (
        <Button
          startIcon={deleting && <CircularProgress color="secondary" size={16} />}
          color="secondary"
          variant="outlined"
          style={{ fontWeight: 700 }}
          onClick={() => {
            confirmationDialog({
              title: l1.deleteItem,
              content: l1.confirmation,
              onConfirm: () => {
                deleteItem({
                  variables: {
                    id,
                  },
                });
              },
            });
          }}
          disabled={deleting}
        >
          {l1.delete}
        </Button>
      )}
    </Box>
  );
}

FormListActions.propTypes = {};

export default FormListActions;
