import Apollo from "./Apollo";
import { FloatingAlertProvider } from "./components/common/FloatingAlert";
import Context from "./Context";
import Router from "./Router";
import RTL from "./RTL";

function App() {
  return (
    // <ErrorBoundary>
    <Apollo>
      <RTL>
        <Context>
          <FloatingAlertProvider>
            <Router />
          </FloatingAlertProvider>
        </Context>
      </RTL>
    </Apollo>
    // </ErrorBoundary>
  );
}

export default App;
