import { gql, useMutation, useQuery } from "@apollo/client";
import { createContext, useContext } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loading from "../../common/Loading";
import useEvaluationSubject from "../useEvaluationSubject";

export const StudentworkContext = createContext();

const QUERY_STUDENTWORK = gql`
  query QueryStudentWork($studentWorkId: ID!) {
    studentWork(id: $studentWorkId) {
      student {
        id
        fullname
        avatar
      }
      submission
    }
  }
`;

const UPDATE_STUDENT_WORK = gql`
mutation UpdateStudentWork(
  $studentWorkId: ID!
  $submission: JSON!
) {
  updateStudentWork(
    input: { where: { id: $studentWorkId }, data: { submission: $submission } }
  ) {
    studentWork {
      id
    }
  }
}
`;


export function StudentWorkProvider({subject, children}){
  const {studentWorkId} = useParams();
  const {data, loading, error} = useQuery(QUERY_STUDENTWORK, {variables: {studentWorkId}});
  const {usePostSubmit} = useEvaluationSubject(subject);
  const postUpdateCallback = usePostSubmit();
  const [updateStudentWork, { loading: updatingStudentWork, error: studentWorkUpdateError }] = useMutation(
    UPDATE_STUDENT_WORK,
    {
      onCompleted(){
        postUpdateCallback();
      },
      onError(error){console.log("mutationUpdateStudentWork Error", error);},
    }
  );
  return (
    loading || updatingStudentWork
    ? <Loading />
    : error || !data?.studentWork || studentWorkUpdateError
    ? 'ERROR'
    : <StudentworkContext.Provider value={{studentWork: data.studentWork, updateStudentWork }}>
      {children}
    </StudentworkContext.Provider>
  )
}

export function useStudentWork(){
  return useContext(StudentworkContext) ?? {};
}