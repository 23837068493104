import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

export const FloatingAlertContext = createContext();

export function useFloatingAlert() {
  const [floatingAlertProps, dispatch] = useReducer(
    (current, action) => {
      let next = { ...current };
      Object.entries(action).forEach(([key, value]) => {
        next[key] = value;
      });
      return next;
    },
    {
      open: false,
      message: null,
      onClose: () => {
        dispatch({ open: false, message: null });
      },
    }
  );

  const floatingAlert = ({message, severity, ...props} ) => {
    dispatch({
      open: true,
      message,
      severity,
      ...props
    });
  };
  return { floatingAlertProps, floatingAlert };
}

export default function FloatingAlert(props) {
  const { open, onClose, message, severity = 'info', autoHideDuration = 6000, alertProps = {}, snackbarProps = {} } = props;

  return (
  <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} {...snackbarProps}>
    <Alert severity={severity} {...alertProps}>
      {message}
    </Alert>
  </Snackbar>
  );
}

FloatingAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.node,
  severity: PropTypes.oneOf(['info', 'success', 'warning', 'error'])
};

export function FloatingAlertProvider({children}){
  const { floatingAlertProps, floatingAlert } = useFloatingAlert();
  return <>
  <FloatingAlertContext.Provider value={floatingAlert}>
    {children}
  </FloatingAlertContext.Provider>
  <FloatingAlert {...floatingAlertProps} />
  </>

}
