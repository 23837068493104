import React, { useContext, useReducer, useState } from "react";
import {
  AppBar,
  Avatar,
  Typography,
  makeStyles,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  Divider,
  ListItemIcon,
  MenuItem,
  Menu
} from "@material-ui/core";
import { IoAddOutline } from "react-icons/io5";
import { HiOutlineMenu } from "react-icons/hi";
import { ClassAnchor, Navigation } from "../../Context";
import { BiHome, BiCalendarAlt, BiFolder } from "react-icons/bi";
import { BsCardText } from "react-icons/bs";
import Create from "../rel/home/Create";
// @ts-ignore
import { l1 } from "../local.json";
import { Link, useParams, useHistory } from "react-router-dom";
import { RiAdminLine } from "react-icons/ri";
import Cookies from "universal-cookie";
import useLayout from "./useLayout";
import {
  RequireCenterAdmin,
  RequireClassAccess,
  RequireTeacherAccess
} from "../../Auth/gates";
const useStyles = makeStyles(theme => ({
  root: {},
  iconHome: { fontSize: theme.spacing(3), marginLeft: theme.spacing(2) },

  menuButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.black
  },
  title: {
    fontSize: "1.38rem",
    color: "#5f6368",
    marginLeft: "5px",
    cursor: "pointer",
    padding: theme.spacing(1)
  },
  appBar: {
    //  height: theme.spacing(8.2),
  },
  toolbar: {},

  icon: {
    color: theme.palette.grey[700]
  },

  iconAdd: {
    color: theme.palette.grey[700]
  },
  leftContainer: {
    padding: theme.spacing(1),
    gap: theme.spacing(1)
  },
  contentContainer: {
    "&>*": {
      marginBottom: theme.spacing(2)
    }
  },
  // Drawer

  list: {
    width: theme.spacing(35)
  },

  DrawerIcon: {
    fontSize: theme.spacing(3),
    marginLeft: theme.spacing(2)
  },
  listItem: {
    borderTopRightRadius: theme.spacing(4),
    borderBottomRightRadius: theme.spacing(4),
    padding: theme.spacing(3, 0),
    width: theme.spacing(30)
  },
  listItemClass: {
    borderTopRightRadius: theme.spacing(4),
    borderBottomRightRadius: theme.spacing(4),
    padding: theme.spacing(2, 0),
    width: theme.spacing(30)
  },
  listItemText: {
    fontWeight: "bold",
    fontSize: theme.spacing(2),
    color: theme.palette.grey[700]
  },
  classAvatar: { margin: theme.spacing(0, 2) },
  link: {
    textDecoration: "none"
  },
  tabs: {
    flexGrow: 1
  },
  tabContainer: {
    width: theme.spacing(13),
    height: theme.spacing(8.2),
    cursor: "pointer",
    "&:focus": {
      "&:hover": {
        background: "#e8f0fe"
      },
      "& span": {
        width: theme.spacing(13),
        height: theme.spacing(0.5),
        background: theme.palette.primary.main,
        position: "relative",
        bottom: theme.spacing(-2.5),
        borderTopRightRadius: theme.spacing(3),
        borderTopLeftRadius: theme.spacing(3)
      }
    },
    "&>*": {
      fontSize: theme.spacing(1.8),
      fontWeight: 600,
      color: theme.palette.grey[700]
    }
  },
  tabContainerCurrent: {
    width: theme.spacing(13),
    height: theme.spacing(8.2),
    cursor: "pointer",
    "&:hover": {
      background: "#e8f0fe"
    },
    "& span": {
      width: theme.spacing(13),
      height: theme.spacing(0.5),
      background: theme.palette.primary.main,
      position: "relative",
      bottom: theme.spacing(-2.5),
      borderTopRightRadius: theme.spacing(3),
      borderTopLeftRadius: theme.spacing(3)
    },

    "&>*": {
      fontSize: theme.spacing(1.8),
      fontWeight: 600,
      color: theme.palette.grey[700]
    }
  }
}));

const TopBar = ({ type, alert, refetch, failure, tab, ...moreProps }) => {
  const { slug, id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [createClassState, reverseCreateClassState] = useReducer(
    state => !state,
    false
  );
  const [drawerState, reverseDrawerState] = useReducer(state => !state, false);
  const { handleTabsChange, info, logout } = useContext(Navigation);
  // Menu Logic
  const { classAnchor } = useContext(ClassAnchor);
  const [anchor, setAnchor] = useState(null);
  const openMenu = event => {
    setAnchor(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchor(null);
  };
  const cookies = new Cookies();
  const { user } = useContext(Navigation);
  const layout = useLayout();
  const types = () => {
    if (type === "stream")
      return (
        <Box
          className={classes.tabs}
          flexWrap={layout ? "no-wrap" : "wrap"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Link to={`${slug}`} className={classes.link}>
            <Box
              className={classes.tabContainerCurrent}
              tabIndex="1"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography>{l1.stream}</Typography>
              <span className={classes.indicator} />
            </Box>
          </Link>

          <Link to={`/class/${slug}/classwork`} className={classes.link}>
            <Box
              className={classes.tabContainer}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography>{l1.classwork}</Typography>
              <span className={classes.indicator} />
            </Box>
          </Link>
          <Link to={`/class/${slug}/people`} className={classes.link}>
            <Box
              className={classes.tabContainer}
              tabIndex="3"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography>{l1.people}</Typography>
              <span className={classes.indicator} />
            </Box>
          </Link>
          <RequireClassAccess only="teacher">
            <Link to={`/class/${slug}/marks`} className={classes.link}>
              <Box
                className={classes.tabContainer}
                tabIndex="4"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Typography>{l1.marks}</Typography>
                <span className={classes.indicator} />
              </Box>
            </Link>
          </RequireClassAccess>
        </Box>
      );
    if (type === "classwork")
      return (
        <Box
          className={classes.tabs}
          flexWrap={layout ? "no-wrap" : "wrap"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Link to={`/class/${slug}`} className={classes.link}>
            <Box
              className={classes.tabContainer}
              tabIndex="1"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography>{l1.stream}</Typography>
              <span className={classes.indicator} />
            </Box>
          </Link>

          <Link to={`/class/${slug}/classwork`} className={classes.link}>
            <Box
              className={classes.tabContainerCurrent}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography>{l1.classwork}</Typography>
              <span className={classes.indicator} />
            </Box>
          </Link>
          <Link to={`/class/${slug}/people`} className={classes.link}>
            <Box
              className={classes.tabContainer}
              tabIndex="3"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography>{l1.people}</Typography>
              <span className={classes.indicator} />
            </Box>
          </Link>
          <RequireClassAccess only="teacher">
            <Link to={`/class/${slug}/marks`} className={classes.link}>
              <Box
                className={classes.tabContainer}
                tabIndex="4"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Typography>{l1.marks}</Typography>
                <span className={classes.indicator} />
              </Box>
            </Link>
          </RequireClassAccess>
        </Box>
      );
    if (type === "people")
      return (
        <Box
          className={classes.tabs}
          flexWrap={layout ? "no-wrap" : "wrap"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Link to={`/class/${slug}`} className={classes.link}>
            <Box
              className={classes.tabContainer}
              tabIndex="1"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography>{l1.stream}</Typography>
              <span className={classes.indicator} />
            </Box>
          </Link>

          <Link to={`/class/${slug}/classwork`} className={classes.link}>
            <Box
              className={classes.tabContainer}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography>{l1.classwork}</Typography>
              <span className={classes.indicator} />
            </Box>
          </Link>
          <Link to={`/class/${slug}/people`} className={classes.link}>
            <Box
              className={classes.tabContainerCurrent}
              tabIndex="3"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography>{l1.people}</Typography>
              <span className={classes.indicator} />
            </Box>
          </Link>

          <RequireClassAccess only="teacher">
            {" "}
            <Link to={`/class/${slug}/marks`} className={classes.link}>
              <Box
                className={classes.tabContainer}
                tabIndex="4"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                onClick={() => {
                  handleTabsChange("Marks");
                }}
              >
                <Typography>{l1.marks}</Typography>
                <span className={classes.indicator} />
              </Box>
            </Link>
          </RequireClassAccess>
        </Box>
      );
    if (type === "marks")
      return (
        <Box
          className={classes.tabs}
          flexWrap={layout ? "no-wrap" : "wrap"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Link to={`/class/${slug}`} className={classes.link}>
            <Box
              className={classes.tabContainer}
              tabIndex="1"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography>{l1.stream}</Typography>
              <span className={classes.indicator} />
            </Box>
          </Link>

          <Link to={`/class/${slug}/classwork`} className={classes.link}>
            <Box
              className={classes.tabContainer}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography>{l1.classwork}</Typography>
              <span className={classes.indicator} />
            </Box>
          </Link>
          <Link to={`/class/${slug}/people`} className={classes.link}>
            <Box
              className={classes.tabContainer}
              tabIndex="3"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography>{l1.people}</Typography>
              <span className={classes.indicator} />
            </Box>
          </Link>
          <RequireClassAccess only="teacher">
            <Link to={`/class/${slug}/marks`} className={classes.link}>
              <Box
                className={classes.tabContainerCurrent}
                tabIndex="4"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Typography>{l1.marks}</Typography>
                <span className={classes.indicator} />
              </Box>
            </Link>
          </RequireClassAccess>
        </Box>
      );
    if (type === "Quiz")
      return (
        <Box
          className={classes.tabs}
          flexWrap={layout ? "no-wrap" : "wrap"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            className={
              moreProps.currentQuizMode === "EDIT"
                ? classes.tabContainerCurrent
                : classes.tabContainer
            }
            tabIndex="3"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            onClick={() => {
              moreProps.setQuizMode("EDIT");
            }}
          >
            <Typography>{l1.edit}</Typography>
            <span className={classes.indicator} />
          </Box>

          <Box
            className={
              moreProps.currentQuizMode === "PREVIEW"
                ? classes.tabContainerCurrent
                : classes.tabContainer
            }
            tabIndex="2"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            onClick={() => {
              moreProps.setQuizMode("PREVIEW");
            }}
          >
            <Typography>{l1.preview}</Typography>
            <span className={classes.indicator} />
          </Box>
        </Box>
      );
    if (["ViewAssignment", "ViewQuestion"].includes(type))
      return (
        <Box
          className={classes.tabs}
          flexWrap={layout ? "no-wrap" : "wrap"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            className={classes.tabContainerCurrent}
            tabIndex="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            autoFocus
          >
            <Typography>
              {type === "ViewQuestion" ? l1.thequestion : l1.theAssignment}
            </Typography>
            <span className={classes.indicator} />
          </Box>

          <RequireClassAccess only="teacher">
            <Box
              className={classes.tabContainer}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              onClick={() =>
                history.push(`/class/${slug}/classwork/${id}/answers`)
              }
            >
              <Typography>{l1.answers}</Typography>
              <span className={classes.indicator} />
            </Box>
          </RequireClassAccess>
        </Box>
      );

    if (["QuestionAnswers", "AssignmentAnswers"].includes(type))
      return (
        <Box
          className={classes.tabs}
          flexWrap={layout ? "no-wrap" : "wrap"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            className={classes.tabContainer}
            tabIndex="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            autoFocus
            onClick={() =>
              history.push(`/class/${slug}/classwork/${id}/details`)
            }
          >
            <Typography>
              {type === "QuestionAnswers" ? l1.thequestion : l1.theAssignment}
            </Typography>
            <span className={classes.indicator} />
          </Box>

          <RequireClassAccess only="teacher">
            <Box
              className={classes.tabContainerCurrent}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography>{l1.answers}</Typography>
              <span className={classes.indicator} />
            </Box>
          </RequireClassAccess>
        </Box>
      );
    if (type === "ViewMaterial")
      return (
        <Box
          className={classes.tabs}
          flexWrap={layout ? "no-wrap" : "wrap"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            className={classes.tabContainerCurrent}
            tabIndex="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            autoFocus
          >
            <Typography>{l1.material}</Typography>
            <span className={classes.indicator} />
          </Box>
        </Box>
      );
    if (type === "admin")
      return (
        <Box
          className={classes.tabs}
          flexWrap={layout ? "no-wrap" : "wrap"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {tab === "editLanding" ? (
            <Box
              className={classes.tabContainerCurrent}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              onClick={() => history.push("/admin-panel/center-info")}
            >
              <Typography>{l1.editLanding}</Typography>
              <span className={classes.indicator} />
            </Box>
          ) : (
            <Box
              className={classes.tabContainer}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              onClick={() => history.push("/admin-panel/center-info")}
            >
              <Typography>{l1.editLanding}</Typography>
              <span className={classes.indicator} />
            </Box>
          )}
          {tab === "events" ? (
            <Box
              className={classes.tabContainerCurrent}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              onClick={() => history.push("/admin-panel/events")}
            >
              <Typography>{l1.events}</Typography>
              <span className={classes.indicator} />
            </Box>
          ) : (
            <Box
              className={classes.tabContainer}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              onClick={() => history.push("/admin-panel/events")}
            >
              <Typography>{l1.events}</Typography>
              <span className={classes.indicator} />
            </Box>
          )}
          {tab === "students" ? (
            <Box
              className={classes.tabContainerCurrent}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              onClick={() => history.push("/admin-panel")}
            >
              <Typography>{l1.students}</Typography>
              <span className={classes.indicator} />
            </Box>
          ) : (
            <Box
              className={classes.tabContainer}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              onClick={() => history.push("/admin-panel")}
            >
              <Typography>{l1.students}</Typography>
              <span className={classes.indicator} />
            </Box>
          )}
          {tab === "banned" ? (
            <Box
              className={classes.tabContainerCurrent}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              onClick={() => history.push("/admin-panel/banned")}
            >
              <Typography>{l1.banned}</Typography>
              <span className={classes.indicator} />
            </Box>
          ) : (
            <Box
              className={classes.tabContainer}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              onClick={() => history.push("/admin-panel/banned")}
            >
              <Typography>{l1.banned}</Typography>
              <span className={classes.indicator} />
            </Box>
          )}
          {tab === "teachers" ? (
            <Box
              className={classes.tabContainerCurrent}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              onClick={() => history.push("/admin-panel/teachers")}
            >
              <Typography>{l1.teachers}</Typography>
              <span className={classes.indicator} />
            </Box>
          ) : (
            <Box
              className={classes.tabContainer}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              onClick={() => history.push("/admin-panel/teachers")}
            >
              <Typography>{l1.teachers}</Typography>
              <span className={classes.indicator} />
            </Box>
          )}

          {tab === "waiting" ? (
            <Box
              className={classes.tabContainerCurrent}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              onClick={() => history.push("/admin-panel/waiting")}
            >
              <Typography>{l1.waiting}</Typography>
              <span className={classes.indicator} />
            </Box>
          ) : (
            <Box
              className={classes.tabContainer}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              onClick={() => history.push("/admin-panel/waiting")}
            >
              <Typography>{l1.waiting}</Typography>
              <span className={classes.indicator} />
            </Box>
          )}
          <Box
              className={tab === 'staff' ? classes.tabContainerCurrent : classes.tabContainer}
              tabIndex="2"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              onClick={() => history.push("/admin-panel/staff")}
            >
              <Typography>{l1.staff}</Typography>
              <span className={classes.indicator} />
            </Box>
        </Box>
      );
    return <Box display="flex" flexGrow={1}></Box>;
  };
  return (
    <Box
      className={classes.root}
      style={
        moreProps.sticky
          ? { position: "fixed", top: 0, width: "100%", zIndex: "10" }
          : {}
      }
    >
      <AppBar
        className={classes.appBar}
        position="static"
        variant="outlined"
        color={moreProps.sticky ? "inherit" : "transparent"}
      >
        <Box
          className={classes.toolbar}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton onClick={reverseDrawerState}>
              <HiOutlineMenu />
            </IconButton>
            <Link className={classes.link} to="/home">
              <Typography variant="h6" className={classes.title}>
                {info.centerName}
              </Typography>
            </Link>
          </Box>
          {layout ? types() : null}
          <Box
            display="flex"
            alignItems="center"
            className={classes.leftContainer}
          >
            <RequireCenterAdmin>
              <IconButton onClick={reverseCreateClassState}>
                <IoAddOutline className={classes.icon} />
              </IconButton>
            </RequireCenterAdmin>

            <Avatar
              className={classes.avatar}
              style={{ cursor: "pointer" }}
              onClick={openMenu}
              src={`${process.env.REACT_APP_STRAPI_URL}${user?.avatar}`}
            />
            <Menu
              open={Boolean(anchor)}
              onClose={closeMenu}
              elevation={1}
              anchorEl={anchor}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  history.push("/profile/edit");
                }}
              >
                <Typography>{l1.editProfile}</Typography>
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  logout(() => {
                    history.push("/");
                  });
                }}
              >
                <Typography>{l1.logout}</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </AppBar>
      {/* Create Class */}
      <RequireCenterAdmin>
        <Create
          state={createClassState}
          reverse={reverseCreateClassState}
          refetch={refetch}
          alert={alert}
          failure={failure}
        />
      </RequireCenterAdmin>
      {/* Drawer */}
      <Drawer
        classes={{
          root: classes.drawerRoot,

          paper: classes.drawer
        }}
        open={drawerState}
        onClose={reverseDrawerState}
      >
        <List className={classes.list}>
          {layout ? null : types()}
          <RequireCenterAdmin>
            <ListItem
              button
              className={classes.listItem}
              onClick={() => history.push("/admin-panel")}
            >
              <ListItemIcon>
                <RiAdminLine className={classes.DrawerIcon} />
              </ListItemIcon>
              <Typography className={classes.listItemText}>
                {l1.administration}
              </Typography>
            </ListItem>
          </RequireCenterAdmin>
          <ListItem
            button
            className={classes.listItem}
            onClick={() => history.push("/home")}

          >
            <ListItemIcon>
              <BiHome className={classes.iconHome} />
            </ListItemIcon>

            <Typography className={classes.listItemText} variant="body2">
              {l1.classes}
            </Typography>
          </ListItem>
          <ListItem
            button
            className={classes.listItem}
            onClick={() => history.push("/calendar")}
          >
            <ListItemIcon>
              <BiCalendarAlt className={classes.DrawerIcon} />
            </ListItemIcon>
            <Typography className={classes.listItemText}>
              {l1.calendar}
            </Typography>
          </ListItem>
          <RequireCenterAdmin>
            <ListItem
                button
                className={classes.listItem}
                onClick={() => history.push("/files")}
              >
                <ListItemIcon>
                  <BiFolder className={classes.DrawerIcon} />
                </ListItemIcon>
                <Typography className={classes.listItemText}>
                  {l1.files}
                </Typography>
            </ListItem>
          </RequireCenterAdmin>
          
          <Divider />
          <RequireTeacherAccess>
            <ListItem
              button
              className={classes.listItem}
              onClick={() => history.push("/review")}
            >
              <ListItemIcon>
                <BsCardText className={classes.DrawerIcon} />
              </ListItemIcon>
              <Typography className={classes.listItemText} variant="body2">
                {l1.toReview}
              </Typography>
            </ListItem>
          </RequireTeacherAccess>
          {info.classes.map(({ name, slug, isArchived }) => {
            if (isArchived) {
              return (
                <RequireClassAccess only="teacher" slug={slug} key={slug}>
                  <Link to={`/class/${slug}`} className={classes.link}>
                    <ListItem button className={classes.listItemClass}>
                      <Avatar className={classes.classAvatar}>
                        {name.charAt(0)}
                      </Avatar>
                      <Typography className={classes.listItemText}>
                        {name}
                      </Typography>
                    </ListItem>
                  </Link>
                </RequireClassAccess>
              );
            } else {
              return (
                <RequireClassAccess slug={slug} key={slug}>
                  <Link to={`/class/${slug}`} className={classes.link}>
                    <ListItem button className={classes.listItemClass}>
                      <Avatar className={classes.classAvatar}>
                        {name.charAt(0)}
                      </Avatar>
                      <Typography className={classes.listItemText}>
                        {name}
                      </Typography>
                    </ListItem>
                  </Link>
                </RequireClassAccess>
              );
            }
          })}
        </List>
      </Drawer>
    </Box>
  );
};

export default TopBar;
