import React, { useState, useContext, useEffect } from "react";
import {
  Facebook,
  Instagram,
  YouTube,
  LinkedIn,
  Twitter
} from "@material-ui/icons/";
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ActivityCard from "./rel/Landing/ActivityCard";
import { gql, useLazyQuery } from "@apollo/client";
import Loading from "./common/Loading";
import { useHistory } from 'react-router-dom';
import { Navigation } from "../Context";
import logo from "./rel/Landing/assets/img/ONTWNC1-11.png";
import bg from "./rel/Landing/assets/img/image-slider.png";
import bg1 from "./rel/Landing/assets/img/bg1.png";
import defasult from "./rel/Landing/assets/img/default.png";
import { Typography, Container, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { RequireAuthentication } from "../Auth/gates";

const theme = createTheme({
  palette: {
    primary: {
      main: "#002366"
    },
    secondary: {
      main: "#b23434"
    }
  },
  typography: {
    fontFamily: ['"Open Sans"', "Cairo", "Roboto"].join(","),
    h1: {
      fontSize: "40px"
    }
  },
});
const useStyles = makeStyles(theme => ({
  title: {
    fontSize: "50px",
    padding: theme.spacing(4, 0, 6)
    // fontFamily: "Helvetica"
  },
  buttonPrimary: {
    color: theme.primary
  },
  buttonSecondary: {
    color: theme.secondary
  },
  login: {
    padding: theme.spacing(2, 4, 2),
    fontSize: "18px;",
    color: theme.primary
  },
  item: {
    marginBottom: theme.spacing(3)
  },
  eventTitle: {
    margin: theme.spacing(4, 0),
    textAlign: "left"
  },
  eventsContainer: {
    marginBottom: theme.spacing(20)
  }
}));

const GET_INTERFACE = gql`
  query GetInterface($subdomain: String!, $limit: Int!) {
    centersLists(where: { subdomain: $subdomain }) {
      events(sort: "createdAt:desc", limit: $limit) {
        id
        title
        excerpt
        image {
          url
        }
      }
      info {
        logo {
          url
        }
        name
      }
    }
  }
`;
export default function FrontEvents() {
  const { sub } = useContext(Navigation);
  const classes = useStyles(theme);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState(() => []);
  const [limit, setLimit] = useState(4);
  const history = useHistory();

  const [getEvents, { loading: loadingEvents, data }] = useLazyQuery(
    GET_INTERFACE,
    {
      onError: err => console.log("err get interface", err),
      onCompleted: data => {
        console.log("get Events completed");
        setEvents(data.centersLists[0]?.events);
        setLoading(false);
      }
    }
  );

  const center = data?.centersLists[0]?.info;
  const centerLogo = center?.logo
    ? `url(${process.env.REACT_APP_STRAPI_URL}${center?.logo?.url})`
    : `url(${logo})`;
  const loadMore = () => {
    setLoading(true);
    getEvents({ variables: { subdomain: sub, limit: limit } });
  };

  useEffect(() => {
    loadMore();
  }, [limit]);
  // if (firstLoading) return <Loading />;
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <main className="center-main">
          <div
            className="center-header"
            style={{
              backgroundImage: `linear-gradient(transparent 58%, white 100%),
     url(${bg})`
            }}
          ></div>
          <div className="center-content">
            <Container className={classes.eventsContainer}>
              <div
                className="logo-center"
                style={{ backgroundImage: centerLogo }}
              ></div>
              <Typography variant="h2" className={classes.title}>
                {center?.name}
              </Typography>

              <hr />
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  history.push('/')
                }}
                endIcon={<ArrowBackIcon />}
                style={{ float: "left" }}
              >
                العودة الى الرئيسية
              </Button>
              <Typography variant="h5" className={classes.eventTitle}>
                {" "}
                نشاطات المركز
              </Typography>
              <Grid container>
                {console.log("events", events)}
                {events &&
                  events.map((centerEvent, index) => {
                    return (
                      <Grid item sm={3} className={classes.item} key={index}>
                        <ActivityCard
                          title={centerEvent?.title}
                          cover={`${process.env.REACT_APP_STRAPI_URL}${centerEvent?.image?.url}`}
                          description={centerEvent?.excerpt}
                          url={"/event/" + centerEvent.id}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
              <Button
                variant="outlined"
                className={classes.login}
                size="large"
                disabled={loading}
                onClick={() => {
                  setLimit(limit + 4);
                }}
              >
                {loading ? "تحميل..." : "حمل المزيد"}
              </Button>
            </Container>
          </div>
        </main>
      </ThemeProvider>
    </>
  );
}
