import React, { useReducer, useState, useContext, useEffect } from "react";

import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  Snackbar,
  Container,
  TextField,
  Paper,
  Chip
} from "@material-ui/core";
import TopBar from "./common/TopBar";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MdAssignment, MdPeople } from "react-icons/md";
import { l1 } from "./local.json";
import MuiAlert from "@material-ui/lab/Alert";

import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useHistory, useParams, Link } from "react-router-dom";
import Loading from "./common/Loading";
import CreateComment from "./common/CreateComment";
import Comment from "./common/Comment";
import Drafter2 from "./common/Drafter2";
import Quiz, { QuizModes } from "./Quiz";
import moment from "moment";
import { Navigation } from "../Context";
import { RequireClassAccess, RequireForTheMemberOnly } from "../Auth/gates";
import Question from "./Question";
import Assignement from "./AssignmentSubmission";
const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(2, 0)
    // padding: theme.spacing(2),
  },
  title: {
    fontSize: theme.spacing(5),
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(2)
  },
  options: {
    color: theme.palette.primary.dark,
    fontSize: theme.spacing(3)
  },
  divider: {
    background: theme.palette.primary.main,
    flexGrow: 1,
    margin: theme.spacing(2, 0)
  },
  alert: {
    fontSize: theme.spacing(1.6),
    color: theme.palette.grey[600]
  },

  iconContainer: {
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(1.4),
    marginLeft: theme.spacing(-7)
  },
  teacherContainer: {
    "&>*": {
      color: theme.palette.grey[600],
      marginRight: theme.spacing(0.3)
    }
  },
  exDivider: {
    flexGrow: 1,
    height: "2px",
    background: theme.palette.grey[200],
    marginTop: theme.spacing(2)
  },

  midSection: {
    marginBottom: theme.spacing(2)
  },
  stats: {
    width: "max-content",
    padding: theme.spacing(2), 
    display: 'inline-block',
  },
  statsNumber: {
    textAlign: "center",
    fontWeigh: 900,
    fontSize: theme.spacing(5),
  },
  statsIcon: {
    color: theme.palette.grey[700],
    fontSize: theme.spacing(3),
    margin: theme.spacing(0, 1)
  },
  statsText: {
    fontWeight: 600,
    color: theme.palette.grey[700],
    marginTop: theme.spacing(2),
  },
  midNameBox: {
    gap: theme.spacing(1.4)
  },
  avatar: {
    margin: theme.spacing(2)
  },
  name: {
    fontWeight: 600
  },
  date: {
    fontSize: theme.spacing(1.6),
    color: theme.palette.grey[600]
  },
  post: {
    marginLeft: theme.spacing(2),
    "&>*": {}
  },
  inputContainer: {
    border: `1px solid lightgrey`,
    borderRadius: theme.spacing(4),
    marginRight: theme.spacing(4)
  },
  input: {
    marginLeft: theme.spacing(2)
  },
  inputIcon: {
    transform: "scaleX(-1)",
    margin: theme.spacing(0, 1),
    fontSize: theme.spacing(3),
    cursor: "pointer",
    color: theme.palette.grey[500]
  },
  points: {
    fontWeight: 600,
    marginTop: theme.spacing(1)
  },
  studentGrade: {
    fontSize: theme.spacing(2.5)
  },
  menuItem: {
    paddingRight: theme.spacing(10)
  },
  quizContainer: {
    border: "solid 1px lightgray",
    borderRadius: theme.spacing(2)
  }
}));
const QUERY_ASSIGNMENT = gql`
  query QueryAssignment($id: ID!, $userId: ID!) {
    assignment(id: $id) {
      title
      class {
        id
      }
      writer {
        id
        fullname
      }
      createdAt
      type
      description
      grade
      comments {
        writer {
          id
          fullname
          email
        }
        id
        content
        createdAt
      }
      quiz {
        id
      }
      evaluationsInfo{
        coach
        date
        location
      }
      studentWorks(where: { student: { id: $userId } }) {
        id
        student {
          id
        }
        file
        status
        submission
        submittedAt
        grade
        gradedBy {
          id
        }
        gradedAt
      }
    }
  }
`;
const EDIT_ASSIGNMENT = gql`
  mutation EditAssignment($id: ID!, $description: String!) {
    updateAssignment(
      input: { where: { id: $id }, data: { description: $description } }
    ) {
      assignment {
        description
      }
    }
  }
`;
const DELETE_ASSIGNMENT = gql`
  mutation DeleteAssignment($id: ID!) {
    deleteAssignment(input: { where: { id: $id } }) {
      assignment {
        description
      }
    }
  }
`;

const QUERY_QUIZ = gql`
  query QueryQuiz($id: ID!) {
    quiz(id: $id) {
      id
      title
      description
      instructions
      questions
    }
  }
`;

const QUERY_QUIZ_WITH_ANSWERS = gql`
  query QueryQuizWithAnswers($id: ID!) {
    quiz(id: $id) {
      id
      title
      description
      questions
      correctAnswers
    }
  }
`;
const SUBMIT_STUDENT_WORK = gql`
  mutation SubmitStudentWork($id: ID!, $fields: editStudentWorkInput!) {
    updateStudentWork(input: { where: { id: $id }, data: $fields }) {
      studentWork {
        id
        status
      }
    }
  }
`;
const QUERY_STUDENT_WORKS = gql`
  query StudentWorks($assignmentId: ID!){
    studentWorks(where: { assignment: { id: $assignmentId } }) {
      id
      student {
        id
      }
      file
      status
      submission
      submittedAt
      grade
      gradedBy {
        id
      }
      gradedAt
    }
  }
`;

function Assignment({ state, reverse }) {
  const { id, slug } = useParams();
  const history = useHistory();
  const { user } = useContext(Navigation);
  // Apollo
  const { loading, data, refetch } = useQuery(QUERY_ASSIGNMENT, {
    variables: { id, userId: user.id },
    onError: error => console.log("QueryAssignmentError: ", error),
    onCompleted: data => {
      console.log("QueryAssignmentSuccess: ", data);
      if (data.assignment.type === "quiz") {
        if (data.assignment.studentWorks?.[0]?.status === "assigned") {
          loadQuiz({ variables: { id: data.assignment.quiz.id } });
        } else {
          loadQuizWithAnswers({ variables: { id: data.assignment.quiz.id } });
        }
        updateStudentAnswers(
          data.assignment.studentWorks?.[0]?.submission || {}
        );
      } else if(data.assignment.type = 'evaluations'){
        loadStudentWorks({variables:{
          assignmentId: id
        }})
      }
    },
    notifyOnNetworkStatusChange: true
  });
  const [loadStudentWorks, { loading: loadingStudentWorks, data: worksData }] = useLazyQuery(QUERY_STUDENT_WORKS, {
    onError: error => {
      console.log("QueryQuizError: ", error);
      return [];
    },
  });


  const [editAssignment, { loading: loadingEdit }] = useMutation(
    EDIT_ASSIGNMENT,
    {
      onError: () => setAlertFailure(l1.failure),
      onCompleted: () => {
        setAlertSuccess(l1.editsuccess);
        reverseEditState();
        refetch();
      }
    }
  );

  const [deleteAssignment, { loading: loadingDelete }] = useMutation(
    DELETE_ASSIGNMENT,
    {
      onError: () => setAlertFailure(l1.failure),
      onCompleted: () => history.push(`/class/${slug}/classwork`)
    }
  );

  const [
    submitStudentWork /*{ loading: loadingSubmitStudentWork }*/
  ] = useMutation(SUBMIT_STUDENT_WORK, {
    onError: () => {
      setAlertFailure(l1.failure);
    },
    onCompleted: response => {
      let studentWork = data.assignment.studentWorks[0];
      studentWork.status = response.updateStudentWork.studentWork.status;
      studentWork.submission =
        response.updateStudentWork.studentWork.submission;
      setAlertSuccess(l1.submitsuccess);
      updateStudentAnswers(studentWork.submission);
      console.log("existing quiz", quiz);
      loadQuizWithAnswers({ variables: { id: quiz.id } });
    }
  });

  const [quiz, setQuiz] = useState(null);
  const [studentAnswers, updateStudentAnswers] = useReducer((prev, answers) => {
    console.log(
      "prev: ",
      JSON.stringify(prev),
      "next:",
      JSON.stringify(answers)
    );
    return { ...prev, ...answers };
  }, {});
  const [loadQuiz, { loading: loadingQuiz }] = useLazyQuery(QUERY_QUIZ, {
    onError: error => {
      console.log("QueryQuizError: ", error);
    },
    onCompleted: response => {
      console.log("QueryQuizSuccess: ", response);
      setQuiz(response.quiz);
    }
  });

  const [
    loadQuizWithAnswers,
    { loading: loadingQuizWithAnswers }
  ] = useLazyQuery(QUERY_QUIZ_WITH_ANSWERS, {
    onError: error => {
      console.log("QueryQuizWithAnswersError: ", error);
    },
    onCompleted: response => {
      console.log("QueryQuizWithAnswersSuccess: ", response);
      setQuiz(response.quiz);
    }
  });

  // Edit State Indicator
  const [editState, reverseEditState] = useReducer(s => !s, false);

  // Options Logic
  const [anchor, setAnchor] = useState(null);
  const openMenu = event => {
    setAnchor(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchor(null);
  };
  // Confirmation Dialog Logic
  const [confirmationState, reverseConfirmationState] = useReducer(
    state => !state,
    false
  );

  // Alert Logic
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFailure, setAlertFailure] = useState(false);

  const classes = useStyles();
  // useEffect(() => {
  //   console.log("student answers = ", studentAnswers);
  // });
  if (
    loadingEdit ||
    loading ||
    loadingDelete ||
    loadingQuiz ||
    loadingQuizWithAnswers ||
    loadingStudentWorks
  ) {
    return <Loading />;
  } else {
    return (
      <>
        {" "}
        <TopBar
          type={
            data.assignment.type === "material"
              ? "ViewMaterial"
              : data.assignment.type === "question"
              ? "ViewQuestion"
              : data.assignment.type === "evaluations"
              ? 'evaluations'
              : "ViewAssignment"
          }
        />
        <Grid container>
          <Grid item xs={1} md={3}></Grid>
          <Grid item xs={10} md={6}>
            <Box className={classes.container}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center">
                  <Avatar className={classes.iconContainer}>
                    <MdAssignment color="white" />
                  </Avatar>
                  <Typography className={classes.title}>
                    {data.assignment.title}
                  </Typography>
                </Box>
                <RequireClassAccess only="teacher">
                  <IconButton onClick={openMenu}>
                    <BiDotsVerticalRounded className={classes.options} />
                  </IconButton>
                </RequireClassAccess>
                <Menu
                  open={Boolean(anchor)}
                  onClose={closeMenu}
                  elevation={1}
                  anchorEl={anchor}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                >
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                      reverseEditState();
                    }}
                  >
                    <Typography>{l1.edit}</Typography>
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={reverseConfirmationState}
                  >
                    <Typography>{l1.delete}</Typography>
                  </MenuItem>
                </Menu>
              </Box>
              {/* <Box className={classes.teacherContainer} display="flex">
                <Typography>{data.assignment.writer.fullname}</Typography>
                <Typography>•</Typography>
                <Typography>{moment(data.assignment.sub).fromNow()}</Typography>
              </Box> */}
              {data?.assignment?.type === 'material' ? null
              : data?.assignment?.type === 'evaluations' ?(
                <>
                  <Divider classes={{ root: classes.divider }} /><Box>
                    <Typography
                      variant="h6"
                    >
                      <b>{l1.evaluationsCoach}:</b> {data.assignment?.evaluationsInfo.coach}
                    </Typography>
                    <Typography
                      variant="h6"
                    >
                      <b>{l1.evaluationsDate}:</b> {data.assignment?.evaluationsInfo?.date}
                    </Typography>
                    <Typography
                      variant="h6"
                    >
                      <b>{l1.evaluationsLocation}:</b> {data.assignment.evaluationsInfo.location}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Typography className={classes.points}>
                    {data?.assignment?.studentWorks?.[0]?.grade &&
                    data?.assignment?.grade !== null &&
                    data?.assignment?.studentWorks?.[0]?.grade !== null ? (
                      <>
                        <span className={classes.studentGrade}>
                          {data.assignment.studentWorks[0].grade *
                            data.assignment.grade}
                        </span>{" "}
                        {l1.slash}{" "}
                      </>
                    ) : null}
                    {data.assignment.grade
                      ? data.assignment.grade + " " + l1.points2
                      : l1.noPoints}{" "}
                  </Typography>
                </>
              )}
              <Divider classes={{ root: classes.divider }} />

              {editState ? (
                <Box className={classes.editPostContainer}>
                  <Drafter2
                    edit
                    assignment
                    mutation={editAssignment}
                    reverse={reverseEditState}
                    id={id}
                    raw={data.assignment.description}
                  />
                </Box>
              ) : (
                <Drafter2 preview raw={data.assignment.description} />
              )}
              {data.assignment.type !== "question" ? (
                ""
              ) : (
                <Question
                  data={data?.assignment?.studentWorks}
                  refetch={refetch}
                />
              )}
              {data.assignment.type !== "assignment" ? (
                ""
              ) : (
                <Assignement
                  data={data?.assignment?.studentWorks}
                  refetch={refetch}
                />
              )}

            </Box>
              {
                data.assignment.type === 'quiz'
                ? (
                  quiz ? (
                    <Box className={classes.quizContainer}>
                      {data.assignment.studentWorks?.[0]?.status &&
                      data.assignment.studentWorks?.[0].status === "assigned" ? (
                        <Quiz
                          quiz={quiz}
                          mode={QuizModes.VIEW}
                          studentAnswers={studentAnswers}
                          submitAction={answers => {
                            submitStudentWork({
                              variables: {
                                id: data.assignment.studentWorks[0].id,
                                fields: {
                                  submission: answers,
                                  status: "submitted"
                                }
                              }
                            });
                            refetch();
                          }}
                        />
                      ) : (
                        <Quiz
                          quiz={quiz}
                          mode={QuizModes.RESULTS}
                          studentAnswers={studentAnswers}
                          correctAnswers={quiz.correctAnswers}
                        />
                      )}
                    </Box>
                  ) : (
                    "Quiz not loaded correctly"
                  )
                )
                : data.assignment.type === 'evaluations'
                ? (<>
                  
                  <RequireClassAccess only="teacher" slug={slug}>
                    <Box display="flex" style={{gap: 24, marginTop: 32}}>
                      <Box className={classes.stats}>
                        <Typography
                          variant="body1"
                          className={classes.statsNumber}
                          >
                          {worksData?.studentWorks?.length ?? 0}
                        </Typography>
                        <Typography variant="body1" className={classes.statsText}>
                          {l1.studentCount}
                        </Typography>
                      </Box>
                      <Link to={`/class/${slug}/classwork/${id}/training-evaluation`} >
                        <Button className={classes.stats} variant="outlined" color="primary">
                          <Typography
                            variant="body1"
                            className={classes.statsNumber}
                            >
                            {worksData?.studentWorks?.filter(work => work?.submission?.training)?.length ?? 0}
                          </Typography>
                          <Typography variant="body1" className={classes.statsText}>
                            {l1.trainingEvaluations}
                          </Typography>
                        </Button>
                      </Link>
                      <Link to={`/class/${slug}/classwork/${id}/coach-evaluation`}>
                        <Button className={classes.stats} variant="outlined" color="primary">
                          <Typography
                            className={classes.statsNumber}
                            variant="body1"
                            >
                            {worksData?.studentWorks?.filter(work => work?.submission?.coach)?.length ?? 0}
                          </Typography>
                          <Typography className={classes.statsText} variant="body1">
                            {l1.coachEvaluations}
                          </Typography>
                        </Button>
                      </Link>
                      <Link to={`/class/${slug}/classwork/${id}/student-evaluation`} >
                        <Button className={classes.stats} variant="outlined" color="primary">
                          <Typography
                            variant="body1"
                            className={classes.statsNumber}
                            >
                            {worksData?.studentWorks?.filter(work => work?.submission?.student)?.length ?? 0}
                          </Typography>
                          <Typography variant="body1" className={classes.statsText}>
                            {l1.studentEvaluations}
                          </Typography>
                        </Button>
                      </Link>
                    </Box>
                  </RequireClassAccess>
                  <RequireClassAccess only="student" slug={slug}>
                    <Box marginTop={4}>
                      <Box display="flex" alignItems="center" style={{gap: 24}} marginBottom={2}>
                        <Typography variant="h6">{l1.myEvaluation}</Typography> 
                        <Chip component={'div'}
                          variant={data?.assignment?.studentWorks?.[0]?.submission?.student ? "default" : 'outlined' }
                          label={<b>{data?.assignment?.studentWorks?.[0]?.submission?.student ? l1.ready : l1.notReady}</b>}
                          color='secondary'
                        /> 
                        { data?.assignment?.studentWorks?.[0]?.submission?.student && <Link to={`/class/${slug}/classwork/${id}/student-evaluation/${data?.assignment?.studentWorks?.[0]?.id}`} ><Button color="primary" variant="contained">{l1.view}</Button></Link>} 
                      </Box>
                      <Box display="flex" alignItems="center" style={{gap: 24}}  marginBottom={2}>
                        <Typography variant="h6">{l1.myCoachEvaluation}</Typography> 
                        <Chip component={'div'}
                          variant={data?.assignment?.studentWorks?.[0]?.submission?.coach ? "default" : 'outlined' }
                          label={<b>{data?.assignment?.studentWorks?.[0]?.submission?.coach ? l1.done : l1.notYet}</b>}
                          color='secondary'
                        /> 
                        <Link to={`/class/${slug}/classwork/${id}/coach-evaluation/${data?.assignment?.studentWorks?.[0]?.id}`} >
                          <Button color="primary" variant="contained">{l1.view}</Button>
                        </Link>
                      </Box>
                      <Box display="flex" alignItems="center" style={{gap: 24}}  marginBottom={2}>
                        <Typography variant="h6">{l1.myTrainingEvaluation}</Typography> 
                        <Chip component={'div'}
                          variant={data?.assignment?.studentWorks?.[0]?.submission?.training ? "default" : 'outlined' }
                          label={<b>{data?.assignment?.studentWorks?.[0]?.submission?.training ? l1.done : l1.notYet}</b>}
                          color='secondary'
                        /> 
                        <Link to={`/class/${slug}/classwork/${id}/training-evaluation/${data?.assignment?.studentWorks?.[0]?.id}`} >
                          <Button color="primary" variant="contained">{l1.view}</Button>
                        </Link>
                      </Box>
                    </Box>
                  </RequireClassAccess>
                </>)
                : (<>
                  <Box
                      className={classes.stats}
                      display="flex"
                      alignItems="center"
                    >
                    <MdPeople className={classes.statsIcon} />
                    <Typography className={classes.statsText}>
                      {data.assignment.comments.length} {l1.comment}
                    </Typography>
                  </Box>
                  {data.assignment.comments.map(item => (
                    <Comment
                      assignment
                      fullname={item.writer.fullname}
                      content={item.content}
                      id={item.id}
                      key={item.id}
                      refetch={refetch}
                      alert={c => setAlertSuccess(c)}
                      failure={c => setAlertFailure(c)}
                      createdAt={item.createdAt}
                      writerEmail={item.writer.email}
                    />
                  ))}
                  <CreateComment
                    assignment
                    refetch={refetch}
                    alert={c => setAlertSuccess(c)}
                    failure={c => setAlertFailure(c)}
                    query={QUERY_ASSIGNMENT}
                    id={id}
                  />
                </>)
            }
          </Grid>
          <Grid item xs={1} md={3}></Grid>
        </Grid>
        <Dialog open={confirmationState} onClose={reverseConfirmationState}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <Typography style={{ fontWeight: "bold" }}>
              {l1.confirmation}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                deleteAssignment({
                  variables: {
                    id
                  }
                });
              }}
            >
              {l1.confirm}
            </Button>
            <Button variant="contained" onClick={reverseConfirmationState}>
              {l1.cancel}
            </Button>
          </DialogActions>
        </Dialog>
        \
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={Boolean(alertSuccess)}
          onClose={() => {
            setAlertSuccess(false);
          }}
          autoHideDuration={3000}
        >
          <MuiAlert
            className={classes.muiAlert}
            elevation={6}
            variant="filled"
            severity="success"
          >
            {alertSuccess}
          </MuiAlert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={alertFailure}
          autoHideDuration={3000}
          onClose={() => {
            setAlertFailure(false);
          }}
        >
          <MuiAlert
            className={classes.muiAlert}
            elevation={6}
            variant="filled"
            severity="error"
          >
            {alertFailure}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

export default Assignment;
