import React, { useState, useContext } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Box, makeStyles, Button, CircularProgress } from "@material-ui/core";
import { EditorState } from "draft-js";
import "./Drafter2.css";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import { l1 } from "../local.json";
import { CgAttachment } from "react-icons/cg";
import { stateFromHTML } from "draft-js-import-html";
import { gql, useMutation } from "@apollo/client";
import { Navigation } from "../../Context";

const UPLOAD = gql`
  mutation($file: Upload!) {
    upload(file: $file) {
      name
      id
    }
  }
`;
const useStyles = makeStyles(theme => ({
  container: {
    width: "100%"
  },
  postBottomContainer: {
    marginTop: theme.spacing(2)
  }
}));
function Drafter2({
  mutation,
  preview,
  noUpload,
  reverse,
  id,
  raw,
  edit,
  setContent,
  assignment,
  centerEvent = false
}) {
  const { user } = useContext(Navigation);

  /// Edit Mode
  const [editorEditState, setEditorEditState] = useState(() =>
    EditorState.createWithContent(stateFromHTML(raw))
  );
  const handleEditorEditChange = state => {
    convertEditContentToHTML(state);
    setEditorEditState(state);
  };
  const [convertedEditContent, setConvertedEditContent] = useState();

  const convertEditContentToHTML = state => {
    let currentContentAsHTML = convertToHTML(state.getCurrentContent());
    if (centerEvent) {
      console.log('centerevent')
      setContent(currentContentAsHTML);
    }
    setConvertedEditContent(currentContentAsHTML);
  };
  ///////
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  // Assignment
  const [convertedContent, setConvertedContent] = useState();
  const handleEditorChange = state => {
    convertContentToHTML(state);
    setEditorState(state);
  };
  const convertContentToHTML = state => {
    let currentContentAsHTML = convertToHTML(state.getCurrentContent());
    if (assignment) {
      setContent(currentContentAsHTML);
    }
    if (centerEvent) {
      setContent(currentContentAsHTML);
    }
    setConvertedContent(currentContentAsHTML);
  };

  const createMarkup = html => {
    return {
      __html: DOMPurify.sanitize(html)
    };
  };
  const [file, setFile] = useState();
  const [uploadFile, { data, loading }] = useMutation(UPLOAD, {
    onError: error => {
      console.log(error);
    },
    onCompleted: data => {
      console.log(data);
    }
  });
  const classes = useStyles();
  if (preview)
    return (
      <Box
        className="preview"
        dangerouslySetInnerHTML={createMarkup(raw)}
      ></Box>
    );
  return (
    <Box>
      <Box className={classes.container}>
        {edit ? (
          <Editor
            defaultEditorState={editorEditState}
            editorState={editorEditState}
            onEditorStateChange={handleEditorEditChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            textAlignment="right"
          />
        ) : (
          <Editor
            defaultEditorState={editorState}
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            textAlignment="right"
          />
        )}
      </Box>
      {assignment && edit ? (
        <Box className={classes.editPostContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              mutation({
                variables: {
                  id,
                  description: convertedEditContent,
                  attachments: data?.upload?.id
                }
              });
            }}
          >
            {l1.edit}
          </Button>
        </Box>
      ) : (
        ""
      )}
      {assignment || noUpload ? (
        ""
      ) : (
        <Box
          className={classes.postBottomContainer}
          display="flex"
          alignItems="center"
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (file)
                uploadFile({
                  variables: {
                    file
                  }
                });
            }}
            startIcon={loading ? <CircularProgress /> : <CgAttachment />}
            type="upload"
          >
            <input type="file" onChange={e => setFile(e.target.files[0])} />
            {l1.add}
          </Button>
          <Box style={{ flexGrow: 1 }}></Box>
          <Box display="flex">
            <Button onClick={reverse}>{l1.cancel}</Button>

            {edit ? (
              <Box className={classes.editPostContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    mutation({
                      variables: {
                        id,
                        content: convertedEditContent,
                        attachments: data?.upload?.id
                      }
                    });
                  }}
                >
                  {l1.edit}
                </Button>
              </Box>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  mutation({
                    variables: {
                      writer: user.id,
                      content: convertedContent,
                      class: id,
                      attachments: data?.upload?.id
                    }
                  });
                }}
              >
                {l1.post}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Drafter2;
