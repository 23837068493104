import { Avatar, Box, Button, Divider, Grid, Icon, Typography, makeStyles } from "@material-ui/core";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { MdOutlineCheckCircleOutline } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useStudentWorks } from "./context/studentWorks";

const useStyles = makeStyles((theme)=>({
  container: {
    width: '100%',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    background: 'white',
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
  },
  avatar: {
    margin: theme.spacing(0, 1),
  },
  nameText: {},
  nameName: {
    textTransform:'none'
  },
  menuItem: {
    paddingRight: theme.spacing(10),
  },
  iconPrimary:{
    stroke: theme.palette.primary.main
  },
  iconSecondary:{
    stroke: theme.palette.secondary.main
  }
}))

export default function EvaluationList({subjectKey}){
  const classes = useStyles();
  const {id, slug} = useParams();
  const studentWorks = useStudentWorks();
  const history = useHistory();
  console.log({studentWorks});
  if(!studentWorks.length) {
    return null;
  }
  return (<>
    <Divider style={{marginBlock: 20}}/>
    <Typography component="h1" variant="h3" color="primary">
      قائمة الطلاب
    </Typography>
    <Box my={4}></Box>
    <Grid container spacing={2} >
      { studentWorks.map( ({id: studentWorkId, student, submission}) => (
        <Grid item xs={12} sm={6} md={4} key={studentWorkId}>
          <Button className={classes.container} variant="outlined" onClick={()=>{history.push(`/class/${slug}/classwork/${id}/${subjectKey}-evaluation/${studentWorkId}`)}} color={submission?.[subjectKey] ? 'secondary': undefined} >
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Avatar className={classes.avatar} src={`${process.env.REACT_APP_STRAPI_URL}${student.avatar}`} />
              <Box className={classes.nameText} flexGrow={1} width={'100%'} textAlign="initial">
                <Typography className={classes.nameName}>{student.fullname}</Typography>
              </Box>
              <Box height={32} >
                { submission?.[subjectKey]
                  ? <MdOutlineCheckCircleOutline size={32} className={classes[submission?.[subjectKey] ? "iconSecondary": 'iconPrimary']}/>
                  : <IoArrowBackCircleOutline size={32} className={classes[submission?.[subjectKey] ? "iconSecondary": 'iconPrimary']}/>
                }
              </Box>
            </Box>
          </Button>
        </Grid>
      ))}
    </Grid>
    </>
  )
}