import { number, string } from "yup";

const DEFAULT_FORMAT = 'string';
function requireIf(required = false, schema){
  return required ? schema.required() : schema;
}

const validations = {
  inputNumber: ({min=0, max=Infinity, required}) => requireIf(required, number().integer().min(min).max(max)),
  radioNumber: ({min=0, max, required}) => requireIf(required, number().integer().min(min).max(max)),
  string: ({required}) => requireIf(required, string()),
}

export function getValidationRule(question){
  return question?.validation ?? validations[question?.format ?? DEFAULT_FORMAT](question);
}

export const initialValues = {
  inputNumber: 0,
  radioNumber: ({min, max})=> Math.round((min+max)/2),
  string: '',
}

export function getInitalValue(question){
  let value = initialValues[question?.format ?? DEFAULT_FORMAT];
  return question?.initialValue ?? (typeof value === 'function' ? value(question) : value);
}

