import React, { useContext, useState } from 'react';
import { makeStyles, Box, Typography, IconButton, CircularProgress } from '@material-ui/core';
import { FaTimes } from 'react-icons/fa';
import { MdAssignment } from 'react-icons/md';
import { l1 } from '../../../local.json';

import QuizSelector from './QuizSelector';
import { gql, useQuery, useMutation } from '@apollo/client';

import moment from 'moment';
import 'moment/locale/ar-dz';
import { Navigation } from '../../../../Context';
moment.locale('ar-dz');

const useStyles = makeStyles((theme) => ({
  quizCard: {
    border: 'solid 1px lightgray',
    borderRadius: theme.spacing(1),
    height: 100,
    gap: 8,
    overflow: 'hidden',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover h5': {
      color: theme.palette.primary,
    },
    '& > p': {
      fontSize: 14,
    },
  },
  quizIcon: {
    backgroundColor: 'gray',
    color: 'white',
    padding: theme.spacing(2),
    height: '100%',
  },
  quizCardInfo: {
    padding: theme.spacing(1),
  },
  quizCardInner: {
    padding: theme.spacing(1),
    gap: theme.spacing(1),
  },
}));

const GET_CENTER_QUIZZES = gql`
  query CenterQuizzes($centerId: ID!) {
    centersList(id: $centerId) {
      quizzes(sort: "createdAt:desc") {
        id
        title
        createdAt
        createdBy {
          username
        }
      }
    }
  }
`;
// TODO add createdBy user field
const NEW_QUIZ = gql`
  mutation NewQuiz($centerId: ID!) {
    createQuiz(
      input: {
        data: {
          title: "اختبار جديد"
          description: ""
          instructions: []
          questions: []
          correctAnswers: {}
          assignments: []
          center: $centerId
        }
      }
    ) {
      quiz {
        id
        title
        createdAt
        createdBy {
          username
        }
      }
    }
  }
`;

export default function QuizCard({ mode, ...props }) {
  const { centerId } = useContext(Navigation);
  const c = useStyles();
  const [quizzes, setQuizzes] = useState([]);
  const [selectedQuiz, selectQuiz] = useState('');

  const [errorMessage, setErrorMessage] = useState(null);
  const { loading: loadingQuizzes } = useQuery(GET_CENTER_QUIZZES, {
    onError: (error) => {
      setErrorMessage(error);
      console.log('QueryQuizzesError: ', error);
    },
    onCompleted: (data) => {
      console.log('QueryCenterQuizzesData: ', data);
      setQuizzes(data.centersList.quizzes);
      setQuizSelectorReady(true);
    },
    fetchPolicy: 'cache',
    variables: {
      centerId,
    },
  });

  const [quizSelectorReady, setQuizSelectorReady] = useState(false);

  const editQuiz = (id) => {
    let path = window.location.protocol + '//' + window.location.host + '/quiz/' + id;
    window.open(path, 'edit-quiz');
  };

  const [createNewQuiz, { loading: creatingNewQuiz }] = useMutation(NEW_QUIZ, {
    variables: {
      centerId,
    },
    onError: (error) => {
      console.warn('MutationNewQuiz error: ', error);
      setErrorMessage(error);
    },
    onCompleted: (data) => {
      console.log('MutationNewQuiz data: ', data);
      setQuizzes([data.createQuiz.quiz].concat(...quizzes));
      selectQuiz(data.createQuiz.quiz.id);
      props.setQuiz(data.createQuiz.quiz.id);
      setErrorMessage(null);

      editQuiz(data.createQuiz.quiz.id);
    },
  });

  const selectOrCreateQuiz = (event) => {
    if (event.target.value === '+') {
      createNewQuiz();
    } else {
      selectQuiz(event.target.value);
      props.setQuiz(event.target.value);
    }
  };
  const quizInfo = selectedQuiz.length > 0 ? quizzes.filter((quiz) => selectedQuiz === quiz.id)[0] : {};
  console.log(selectedQuiz, quizInfo);
  return mode === 'edit' ? (
    selectedQuiz.length > 1 ? (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={c.quizCard}
        onClick={(event) => {
          editQuiz(selectedQuiz);
        }}
      >
        <Box className={c.quizIcon + ' clickable'} display="flex" alignItems="center">
          <MdAssignment fontSize={48} />
        </Box>
        <Box display="flex" flexDirection="column" className={c.quizCardInfo}>
          <Typography variant="h5">{quizInfo.title}</Typography>
          <Typography component="p">
            {l1.createdBy} @{'moatezz' /*quiz.createdBy.username*/}
          </Typography>
          <Typography component="p"> {moment(quizInfo.createdAt).fromNow()}</Typography>
        </Box>
        <Box display="flex" alignItems="flex-start" height="100%">
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              selectQuiz('');
              props.setQuiz('');
            }}
          >
            <FaTimes fontSize={16} />
          </IconButton>
        </Box>
      </Box>
    ) : (
      <Box display="flex" alignItems="center" justifyContent="space-between" className={c.quizCard}>
        {creatingNewQuiz ? (
          <Box display="flex" alignItems="center" className={c.quizCardInner}>
            <CircularProgress />
            <Typography component="em">{l1.creatingQuiz}</Typography>
          </Box>
        ) : quizSelectorReady ? (
          <Box display="flex" alignItems="center" className={c.quizCardInner}>
            <QuizSelector
              quizzes={quizzes}
              selectedQuiz={props.quiz}
              onQuizSelected={selectOrCreateQuiz}
              newQuiz={createNewQuiz}
            />
          </Box>
        ) : loadingQuizzes ? (
          <Box display="flex" alignItems="center" className={c.quizCardInner}>
            <CircularProgress />
            <Typography component="em">{l1.loadingQuizList}</Typography>
          </Box>
        ) : errorMessage ? (
          l1.anErrorHappened + '. ' + l1.refreshToRetry
        ) : null}
      </Box>
    )
  ) : (
    'Mode not implemented yet'
  );
}
