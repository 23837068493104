import { Box, TextField as MuiTextField, Typography } from "@material-ui/core";

export default function TextField({
  // field properties
  name,
  type,
  label,
  edit,
  readOnly,
  required,
  // data properties
  touched,
  error,
  value,
  handleChange,
  handleBlur,
  inputProps,
  minRows = 3,
  ...other
}) {
  if (!edit) {
    return (
      <Box style={{ display: "flex", alignItems: "top", gap: 16 }}>
        {label && <Typography color="primary">{label}:</Typography>}
        <Typography style={{whiteSpace:"pre-wrap"}}>{value ?? "(غير محدد)"}</Typography>
      </Box>
    );
  } else {
    return (
      <MuiTextField
        style={{ marginBottom: 20 }}
        id={name}
        label={label}
        value={value ? value : type === "text" && readOnly ? "(غير محدد)" : ""}
        InputProps={{
          type,
          inputProps,
        }}
        disabled={readOnly}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        //InputLabelProps={{}}
        fullWidth
        error={touched && error}
        helperText={touched ? error : null}
        minRows={minRows}
        {...other}
      />
    );
  }
}
