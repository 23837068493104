import { Radio, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { useField, } from "formik";
import { useHistory, useParams } from "react-router-dom";
import EvaluationSubject from "./EvaluationSubject";
import {MdCheckCircle, MdCheckCircleOutline} from 'react-icons/md';

const radio = (props) => ({
  type: 'number',
  format: 'radioNumber',
  min: 1,
  max: 5,
  aggregation: 'count',
  ...props,
})

const longText= (props) => ({
  type: 'text',
  long: true,
  aggregation: 'listStrings',
  ...props,
})

const TrainingEvaluation = {
  key: 'training',
  questions:{
    q1: radio({ label: "ساعدني البرنامج على اكتساب معلومات ومهارات جديدة أستطيع تطبيقها في حياتي العملية" }),
    q2: radio({ label: "توافق البرنامج مع توقعاتي الشخصية" }),
    q3: radio({ label: "الأساليب التدريبية المستخدمة تميزت بالفعالية (تمارين،ورش..) خاصة لكل دورة" }),
    q4: radio({ label: "عمق وحداثة المادة العلمية للبرنامج" }),
    q5: radio({ label: "التقييم العام للبرنامج" }),
    q6: radio({ label: "قدرته على إدارة الحوار مع المشاركين" }),
    q7: radio({ label: "تمكنه من المادة العلمية" }),
    q8: radio({ label: "تمكنه من تغطية أهداف الدورة" }),
    q9: radio({ label: "تمكنه من تغطية محتوى الدورة بشكل كامل" }),
    q10: radio({ label: "تميز المدرب بمهارات العرض والتقديم" }),
    q11: radio({ label: "حافظ المدرب على حيوية البرنامج" }),
    q12: radio({ label: "كان المدرب موفقا في استخدام الأساليب التدريبية لتحقيق أهداف البرنامج (ورش-تمارين..)" }),
    q13: radio({ label: "إعطاؤه وقتا كافيا للأنشطة المساعدة (تمارين..)" }),
    q14: radio({ label: "تكيفه مع مستويات المشاركين" }),
    q15: radio({ label: "وُفِّق المدرب في إدارة وقت البرنامج" }),
    q16: radio({ label: "مدة البرنامج كانت كافية" }),
    q17: radio({ label: "مكان انعقاد البرنامج" }),
    q18: radio({ label: "القاعة التدريبية" }),
    q19: radio({ label: "تعاون منسق البرنامج والهيئة الادارية" }),
    q20: radio({ label: "توافر المستلزمات والقرطاسية والمطبوعات" }),
    q21: radio({ label: "خدمات الضيافة" }),
    pros:longText({ label: "أبرز الإيجابيات" }),
    cons:longText({ label: "أبرز السلبيات" }),
    comment:longText({ label:"ملاحظات عامة و اقتراحات للدورات القادمة" }),
  },

  layout: [
    {
      label: 'تقييم الجانب الفني في الدورة',
      questions: ['q1', 'q2', 'q3', 'q4', 'q5'],
    },
    {
      label: 'تقييم المدرب',
      questions: ['q6', 'q7', 'q8', 'q9', 'q10', 'q11', 'q12', 'q13', 'q14', 'q15'],
    },
    {
      label: 'تقييم الجانب الإداري',
      questions: ['q16', 'q17', 'q18', 'q19', 'q20', 'q21'],
    },
    {
      questions: ['pros', 'cons', 'comment'],
      table: false,},
  ],
  
  usePostSubmit(){
    const {id, slug} = useParams();
    const history = useHistory();
    return function postSubmitAction(){
      history.push(`/class/${slug}/classwork/${id}/details`);
    }
  },

  EvaluationTable: {
    Head(){
      return (
        <TableHead>
          <TableRow>
            <TableCell component="th"><Typography color="secondary">م</Typography></TableCell>
            <TableCell component="th"><Typography color="secondary">عنصر التقييم</Typography></TableCell>
            {['ممتاز','جيد جدا', 'جيد', 'مقبول', 'ضعيف'].map(label => 
              <TableCell component="th" key={label} width={80} style={{textAlign: "center"}}><Typography color="secondary">{label}</Typography></TableCell>
            )}
          </TableRow>
        </TableHead>
      )
    },
    Row({id, index, mode = 'fill'}){
      const {label} = this.questions[id];
      const [props, _meta, helpers] = useField(id);
      return <TableRow>
        <TableCell width={100}><Typography><b>{index}</b></Typography></TableCell>
        <TableCell><Typography>{label}</Typography></TableCell>
          {[5,4,3,2,1].map(possibleValue => (
            <TableCell component="th" key={possibleValue} width={60} style={{textAlign:"center", verticalAlign:"middle"}} >
              { mode === 'summary'
                ? <Typography variant={props.value?.[possibleValue] ? "h5" : "body1"} color={props.value?.[possibleValue] ? "secondary" : undefined}><b>
                  {props.value?.[possibleValue] ?? 0}
                </b>
                </Typography>
                : mode === 'fill'
                ? <Radio name={id} value={possibleValue} checked={possibleValue === props.value} readOnly={mode !== 'fill'} color="secondary" onClick={()=>{helpers.setValue(possibleValue)}} onFocus={()=>{helpers.setValue(possibleValue)}} />
                : props.value === possibleValue && <Typography color="secondary"><b><MdCheckCircleOutline fontSize={24} /></b></Typography>
              }
            </TableCell>
          ))}
      </TableRow>
    }
  }
}

export default new EvaluationSubject(TrainingEvaluation);