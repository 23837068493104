import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { Formik, useFormikContext } from "formik";
import { createContext, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Navigation } from "../../Context";
import { FloatingAlertContext } from "../../components/common/FloatingAlert";
import NotFound from "../../components/common/NotFound";
import { l1 } from "../../components/local.json";
import capitalizeFirstLetter from "../../helpers/capitalizeFirstLetter";

export const FormDefinition = createContext();
export const FormContext = createContext();

export function FormDefinitionProvider({ form, children }) {
  return (
    <FormDefinition.Provider value={form}>{children}</FormDefinition.Provider>
  );
}

export function CreateFormContextProvider({ options, children }) {
  const form = useContext(FormDefinition);
  const { centerId } = useContext(Navigation);
  const context = useContext(FormContext) ?? {};
  const history = useHistory();
  const floatingAlert = useContext(FloatingAlertContext);
  const MUTATION_CREATE = gql`
  mutation createItem($data: ${capitalizeFirstLetter(form.itemName)}Input) {
    create${capitalizeFirstLetter(form.itemName)}(input: { data: $data } ) {
        ${form.itemName} {
          ${form.getSingleQuerySelection()}
        }
      }
    }
  `;
  const [createItem, { loading: creating, error: creationError }] = useMutation(
    MUTATION_CREATE,

    {
      onCompleted: (data) => {
        floatingAlert({
          message: "تم إنشاء الاستمارة بنجاح!",
          severity: "success",
        });
        form.postMutation.created({ data, history });
      },
      onError: (error) => {
        floatingAlert({
          message:
            "حصل خطأ أثناء محاولة إنشاء الاستمارة. رجاءً أعد المحاولة لاحقًا",
          severity: "error",
        });
      },
      ...options,
    }
  );
  const create = (data) => {
    return createItem({
      variables: { data: { ...form.cleanedFormData(data, 'add'), center: centerId } },
    });
  }

  return (
    <FormContext.Provider
      value={{
        ...context,
        mutate: create,
        mutating: creating,
        mutationError: creationError,
      }}
    >
      <FormikProvider>
        <FormWrapper>{children}</FormWrapper>
      </FormikProvider>
    </FormContext.Provider>
  );
}

export function UpdateFormContextProvider({ options, children }) {
  const form = useContext(FormDefinition);
  const { id } = useParams();
  const context = useContext(FormContext) ?? {};
  const history = useHistory();
  const floatingAlert = useContext(FloatingAlertContext);

  // Mutation to update an existing form item
  const MUTATION_UPDATE = gql`
    mutation updateItem($data: edit${capitalizeFirstLetter(
      form.itemName
    )}Input) {
      update${capitalizeFirstLetter(
        form.itemName
      )}(input: { where: { id: "${id}" }, data: $data }) {
        ${form.itemName} {
          ${form.getSingleQuerySelection()}
        }
      }
    }
  `;
  const [updateItem, { loading: updating, error: updateError }] = useMutation(
    MUTATION_UPDATE,
    {
      onCompleted: (data) => {
        floatingAlert({
          message: "تم حفظ الاستمارة بنجاح!",
          severity: "success",
        });
        form.postMutation.updated({ data, history });
      },
      onError: (error) => {
        floatingAlert({
          message:
            "حصل خطأ أثناء محاولة حفظ الاستمارة. رجاءً أعد المحاولة لاحقًا",
          severity: "error",
        });
      },
      ...options,
    }
  );
  const update = (data) => {
    updateItem({ variables: { data: form.cleanedFormData(data, 'edit') } });
  };
  return (
    <FormContext.Provider
      value={{
        ...context,
        mutate: update,
        mutating: updating,
        mutationError: updateError,
      }}
    >
      <ViewFormContextProvider>
        <FormikProvider>
          <FormWrapper>{children}</FormWrapper>
        </FormikProvider>
      </ViewFormContextProvider>
    </FormContext.Provider>
  );
}

export function ViewFormContextProvider({ options, children }) {
  const form = useContext(FormDefinition);
  const { centerId } = useContext(Navigation);
  const { id } = useParams();
  const context = useContext(FormContext) ?? {};

  // Query to a single form item
  const QUERY_SINGLE = gql`
    query {
      ${
        form.collectionName
      }(where: { center: "${centerId}", id: "${id}" }, sort: "createdAt:desc") {
        id createdAt ${form.getSingleQuerySelection()}
      }
    }
  `;
  const { loading, error, data, refetch } = useQuery(QUERY_SINGLE, options);
  let values = data?.[form.collectionName]?.[0];

  if (loading) return <LinearProgress />;
  if (error) return <Typography color="secondary">Error loading </Typography>;
  if (!values) return <NotFound />;
  return (
    <FormContext.Provider value={{ ...context, values, initialValues: values }}>
      <FormikProvider>
        {children}
      </FormikProvider>
    </FormContext.Provider>
  );
}

const contextProviderMap = {
  add: CreateFormContextProvider,
  edit: UpdateFormContextProvider,
  view: ViewFormContextProvider,
};

export function FormContextProvider({ children, mode = "view" }) {
  const ModeContextProvider = contextProviderMap[mode];
  return (
    <FormContext.Provider value={{ mode }}>
      <ModeContextProvider>{children}</ModeContextProvider>
    </FormContext.Provider>
  );
}
export function FormikProvider({children}){
  const form = useContext(FormDefinition);
  const context = useContext(FormContext) ?? {};
  const { user } = useContext(Navigation);
  const initialValues = form.compiledValues(context?.values ?? {}, context.mode, { user });
  const formikConfig = {
    initialValues,
    validationSchema: Yup.object().shape(
      form.views[context.mode].validationSchema
    ),
    validateOnMount: true,
    onSubmit: (data) => {
      context.mutate(data);
    },
  };
  return <Formik {...formikConfig}>{children}</Formik>;
}
export function FormWrapper({ children }) {
  const context = useContext(FormContext) ?? {};
  const formik = useFormikContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    formik.handleSubmit();
  };
  const SubmitButton = () => {
    return (<Box my={3}>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={context.mutating || !formik.isValid || formik.isSubmitting}
        startIcon={context.mutating && <CircularProgress size={24} />}
      >
        {l1[context.mode === "edit" ? "saveChanges" : "create"]}
      </Button>
    </Box>)
  };
  return (
    <FormContext.Provider value={{ ...context, ...formik, SubmitButton }}>
      <form name={`context.mode`} onSubmit={handleSubmit}>
        {children}
      </form>
    </FormContext.Provider>
  );
}
