import React, { useContext, useRef, useState, useCallback } from "react";
import {
  Button,
  Slider,
  makeStyles,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Snackbar,
  CircularProgress
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./utils/cropImage";
import Cookies from "universal-cookie";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { Navigation } from "../../Context";
import { gql, useMutation } from "@apollo/client";
import { dataURLtoFile } from "./utils/dataURLtoFile";
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { l1 } from "../../components/local.json";

const cache = new InMemoryCache();
const cookies = new Cookies();
const token = cookies.get("token");
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_END_POINT
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    };
  }),
  client = new ApolloClient({
    cache,
    link: authLink.concat(uploadLink)
  });
const UPLOAD = gql`
  mutation($file: Upload!) {
    upload(file: $file) {
      name
      id
      url
    }
  }
`;
const UPDATE_USER = gql`
  mutation UpdateUser($data: editUserInput!, $id: ID!) {
    updateUser(input: { data: $data, where: { id: $id } }) {
      user {
        id
        avatar
      }
    }
  }
`;
const UPDATE_CENTER_INFO = gql`
  mutation updateCenterInfo($id: ID!, $data: editCenterInput!) {
    updateCenter(input: { where: { id: $id }, data: $data }) {
      center {
        id
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    height: "100vh",
    width: "100vw"
  },
  containerCropper: {
    height: "90%",
    padding: "10px"
  },
  cropper: {
    position: "relative",
    height: "90%",
    background: "#333"
  },
  slider: {
    height: "10%",
    display: "flex",
    alignItems: "center",
    width: "60%",
    margin: "auto"
  },
  containerButtons: {
    height: "10%",
    border: "1px solid #f5f5f5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  sliderLabel: {
    marginRight: "20px"
  }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CropeImage({
  destination = "userProfile",
  centerId = null,
  setEventImage = null,
  setEventImageId = null
}) {
  const { user } = useContext(Navigation);
  const classes = useStyles();
  const [updateCenterInfo] = useMutation(UPDATE_CENTER_INFO, {
    onError: err => {
      setAlertFailure(l1.failure);
      console.log("Error upload to logo", err);
    },
    onCompleted: () => {
      console.log("done");
      setAlertSuccess(l1.editsuccess);
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
      // setSaveLoading(false);
      // refetch();
    }
  });
  const handleClose = () => {
    setImage(null);
    inputRef.current.value = null;
  };

  const inputRef = useRef();
  const triggerFileSelect = () => {
    inputRef.current.click();
  };

  const [loading, setLoading] = useState(null);
  const [image, setImage] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
    // console.log(croppedAreaPercentage, croppedAreaPixels)
  };
  const onSelectFile = event => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
    }
  };
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFailure, setAlertFailure] = useState(false);
  const [updateUser, { data, status }] = useMutation(UPDATE_USER, {
    onCompleted: e => {
      console.log("updateUser completed", e);
      setAlertSuccess(l1.editsuccess);
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    },
    onError: err => {
      setAlertFailure(l1.failure);
      console.log("updateUser error", err);
    }
  });
  const uploadImage = croppedImage => {
    const file = dataURLtoFile(croppedImage, "image.jpeg");
    client
      .mutate({
        mutation: UPLOAD,
        variables: {
          file: file
        }
      })
      .then(res => {
        console.log("response appollo", res);
        if (destination === "userProfile") {
          updateUser({
            variables: { data: { avatar: res.data.upload.url }, id: user.id }
          });
        }
        if (destination === "centerLogo") {
          updateCenterInfo({
            variables: {
              id: centerId,
              data: { logo: res.data.upload.id }
            }
          });
        }
        if (destination === "eventImage") {
          console.log("the id of the uploaded image", res.data.upload.id);
          setAlertSuccess(l1.editsuccess);
          setEventImage(res.data.upload.url);
          setEventImageId(res.data.upload.id);
          handleClose();
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(null);
      });
  };
  const sendCroppedImageToUpload = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedArea);
      //   console.log("done", { croppedImage });
      uploadImage(croppedImage);
      //   setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e);
    }
  }, [image, croppedArea]);

  const onSubmit = () => {
    setLoading(true);
    sendCroppedImageToUpload(image, croppedArea);
  };
  return (
    <>
      {image ? (
        <Dialog
          fullScreen
          open={Boolean(image)}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h5"
                  component="div"
                >
                  {l1.changePicture}
                </Typography>
              </div>
              <Button
                autoFocus
                color="inherit"
                onClick={onSubmit}
                disabled={loading}
              >
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  {loading && <CircularProgress size={14} />}
                  {!loading && l1.submit}
                </Typography>
              </Button>
            </Toolbar>
          </AppBar>
          <div className={classes.container}>
            <div className={classes.containerCropper}>
              <div className={classes.cropper}>
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </div>
              <div className={classes.slider}>
                <Typography variant="h6" className={classes.sliderLabel}>
                  {l1.zoom}
                </Typography>
                <Slider
                  min={1}
                  max={3}
                  step={0.1}
                  value={zoom}
                  onChange={(e, zoom) => setZoom(zoom)}
                  aria-labelledby="Zoom"
                />
                {console.log("status", updateUser.status)}
                {updateUser.isLoading ? (
                  <Typography variant="h1">hello</Typography>
                ) : null}
              </div>
            </div>
            <div className={classes.containerButtons}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={onSubmit}
                disabled={loading}
              >
                {loading && <CircularProgress size={14} />}
                {!loading && l1.submit}
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={handleClose}
                color="secondary"
                style={{ marginRight: "10px" }}
              >
                {l1.close}
              </Button>
            </div>
          </div>
        </Dialog>
      ) : null}
      <div className={classes.choose}>
        <Button
          variant="contained"
          color="primary"
          onClick={triggerFileSelect}
          startIcon={<CloudUploadIcon />}
        >
          {l1.chooseFromComputer}
        </Button>
        <input
          type="file"
          accept="image/*"
          ref={inputRef}
          style={{ display: "none" }}
          onChange={onSelectFile}
        />
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={Boolean(alertSuccess)}
        onClose={() => {
          setAlertSuccess(false);
        }}
        autoHideDuration={3000}
      >
        <MuiAlert
          className={classes.muiAlert}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {alertSuccess}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={Boolean(alertFailure)}
        onClose={() => {
          setAlertFailure(false);
        }}
        autoHideDuration={3000}
      >
        <MuiAlert
          className={classes.muiAlert}
          elevation={6}
          variant="filled"
          severity="error"
        >
          {alertFailure}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
