import { createContext, useContext } from "react";
import Loading from "../../common/Loading";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { gql, useQuery } from "@apollo/client";

export const AssignmentContext = createContext();

const QUERY_ASSIGNMENT = gql`
  query QueryAssignment($id: ID!) {
    assignment(id: $id) {
      title
      type
      description
      evaluationsInfo{
        coach
        date
        location
      }
    }
  }
`;

export function AssignmentProvider({children}){
  const {id} = useParams();
  const {data, loading, error} = useQuery(QUERY_ASSIGNMENT, {variables: {id}});
  return (
    loading
    ? <Loading />
    : error || !data?.assignment
    ? 'ERROR'
    : <AssignmentContext.Provider value={data.assignment}>
      {children}
    </AssignmentContext.Provider>
  )
}

export function useAssignment(){
  console.log('context', useContext(AssignmentContext));
  return useContext(AssignmentContext);
}