import React, { useState, useEffect } from "react";
import {
  Avatar,
  Typography,
  Box,
  Dialog,
  DialogContent,
  LinearProgress,
  makeStyles,
  TextField,
  Checkbox,
  DialogActions,
  Button,
} from "@material-ui/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import { l1 } from "../local.json";

// const GET_CENTER_STUDENTS = gql`
//   query QueryCenterPeople($id: ID!) {
//     centersList(id: $id) {
//       students {
//         id
//         fullname
//         avatar
//       }
//     }
//   }
// `;
const GET_CENTER_MEMBERS = gql`
  query QueryCenterMembers($id: ID!) {
    centersLists(where: { id: $id }) {
      memberships(where: { status: "ACTIVE", role: "member" }) {
        id
        user {
          id
          fullname
          avatar
        }
        role
      }
    }
  }
`;

const ADD_ADMINS = gql`
  mutation AddAdmins($ids: [ID!]!) {
    updateMemberships(ids: $ids, data: { role: moderator })
  }
`;

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    margin: theme.spacing(2),
  },
  search: {
    margin: theme.spacing(2, 30),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  nameContainer: {
    marginBottom: theme.spacing(1),
    border: "1px solid lightgrey",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

function SearchMembers({ refetch, ready, reverseReady, centerId }) {
  const [search, setSearch] = useState("");
  const [result, setResult] = useState([]);
  const [selected, setSelected] = useState([]);

  const { loading, data } = useQuery(GET_CENTER_MEMBERS, {
    onError: (error) => console.error("QueryMembershipError: ", error),
    variables: {
      id: centerId,
    },
    onCompleted: () => {
      setResult(
        data?.centersLists[0]?.memberships?.filter(({ user, role }) =>
          user.fullname?.includes(search)
        )
      );
    },
  });

  const [addAdmins] = useMutation(ADD_ADMINS, {
    onCompleted: (data) => {
      reverseReady();
      setSelected([]);
      refetch();
    },
  });
  console.log({ selected });
  useEffect(() => {
    console.log("data", data?.centersLists[0]?.memberships);
    // data?.centersLists[0].memberships?.forEach((user) => {
    //   const foundIt = user?.memberships?.search(search) !== -1;
    //   if (search && foundIt) arr.push(user);
    //   return arr;
    // });
    // setResult(arr);
    setResult(
      data?.centersLists[0]?.memberships?.filter(({ user }) =>
        user.fullname?.includes(search)
      )
    );
  }, [search]);

  const classes = useStyles();
  return (
    <Dialog
      className={classes.dialog}
      open={ready}
      onClose={reverseReady}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.search}>
          {loading ? (
            <LinearProgress />
          ) : (
            <TextField
              fullWidth
              label="Search"
              value={search}
              variant="outlined"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          )}
        </Box>
        <Box
          style={{
            width: "100%",
            height: 1,
            background: "lightgrey",
            marginBottom: 32,
          }}
        ></Box>
        {!result?.length ? (
          <Box textAlign={"center"} p={4}>
            {l1.noMember}
          </Box>
        ) : (
          result?.map((i) => (
            <Box
              className={classes.nameContainer}
              display="flex"
              alignItems="center"
              key={i.id}
            >
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelected([...selected, i]);
                  } else {
                    setSelected(selected.filter((j) => j.id !== i.id));
                  }
                }}
              />
              <Avatar
                src={`${process.env.REACT_APP_STRAPI_URL}${i.user.avatar}`}
                className={classes.avatar}
              ></Avatar>
              <Typography className={classes.name}>
                {i.user.fullname}
              </Typography>
            </Box>
          ))
        )}
        {selected ? (
          <Box
            style={{
              width: "100%",
              height: 1,
              background: "lightgrey",
              marginBottom: 32,
            }}
          ></Box>
        ) : null}
        {selected.map((i) => (
          <Box
            className={classes.nameContainer}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            key={i.id}
          >
            <Box display="flex" alignItems="center">
              <Avatar
                src={`${process.env.REACT_APP_STRAPI_URL}${i.avatar}`}
                className={classes.avatar}
              ></Avatar>
              <Typography className={classes.name}>{i.fullname}</Typography>
            </Box>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => setSelected(selected.filter((j) => j.id !== i.id))}
            >
              {l1.delete}
            </Button>
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            addAdmins({
              variables: {
                ids: selected.map(({ id }) => id),
              },
            });
          }}
        >
          {l1.add}
        </Button>
        <Button onClick={reverseReady}>{l1.cancel}</Button>
      </DialogActions>
    </Dialog>
  );
}
export default SearchMembers;
