import React, { useState, useContext, useEffect } from "react";
import {
  Facebook,
  Instagram,
  YouTube,
  LinkedIn,
  Twitter
} from "@material-ui/icons/";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ActivityCard from "./rel/Landing/ActivityCard";
import {gql, useQuery } from "@apollo/client";
import Loading from "./common/Loading";
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { Navigation } from "../Context";
import logo from "./rel/Landing/assets/img/ONTWNC1-11.png";
import bg from "./rel/Landing/assets/img/image-slider.png";
import { Typography, Container, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
const theme = createTheme({
  palette: {
    primary: {
      main: "#002366"
    },
    secondary: {
      main: "#b23434"
    }
  },
  typography: {
    fontFamily: ['"Open Sans"', "Cairo", "Roboto"].join(","),
    h1: {
      fontSize: "40px"
    }
  }
});
const useStyles = makeStyles(theme => ({
  title: {
    fontSize: "50px",
    padding: theme.spacing(4, 0, 6)
    // fontFamily: "Helvetica"
  },
  buttonPrimary: {
    color: theme.primary
  },
  buttonSecondary: {
    color: theme.secondary
  },
  login: {
    padding: theme.spacing(2, 4, 2),
    fontSize: "18px;",
    color: theme.primary
  },
  item: {
    marginBottom: theme.spacing(3)
  },
  eventTitle: {
    margin: theme.spacing(4, 0),
    textAlign: "left"
  },
  eventsContainer: {
    marginBottom: theme.spacing(20)
  },
  date: {
    textAlign: "left"
  },
  eventImage: {
    width: "100%",
    height: "400px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    margin: theme.spacing(5, 0)
  },
  back: {
    marginTop: theme.spacing(5)
  },
  eventContent: {
    textAlign: "left"
  }
}));

const GET_EVENT = gql`
  query getEvent($id: ID!) {
    centerEvent(id: $id) {
      id
      title
      content
      excerpt
      createdAt
      image {
        url
      }
    }
  }
`;
const GET_INTERFACE = gql`
  query GetInterface($subdomain: String!) {
    centersLists(where: { subdomain: $subdomain }) {
      info {
        logo {
          url
        }
        name
      }
    }
  }
`;
export default function FrontEvent() {
  const { sub } = useContext(Navigation);
  const classes = useStyles(theme);
  const { slug } = useParams();
  const history = useHistory();
  const { loading: getEventLoading, data: eventData } = useQuery(GET_EVENT, {
    variables: { id: slug },
    onError: err => {
      console.log("error when getting the event", err);
    },
    onCompleted: data => {
    }
  });
  const { data } = useQuery(GET_INTERFACE, {
    variables: { subdomain: sub },
    onError: err => console.log("error getting the centers info"),
    onCompleted: () => console.log("done getting center info")
  });
  const center = data?.centersLists[0]?.info;
  const centerLogo = center?.logo
    ? `url(${process.env.REACT_APP_STRAPI_URL}${center?.logo?.url})`
    : `url(${logo})`;
  const centerEvent = eventData?.centerEvent;
  console.log('event', centerEvent)
  let newDate = new Date(centerEvent?.createdAt)
  let date = newDate.toLocaleDateString('ar-EG-u-nu-latn', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' });

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <main className="center-main">
          <div
            className="center-header"
            style={{
              backgroundImage: `linear-gradient(transparent 58%, white 100%),
     url(${bg})`
            }}
          ></div>
          <div className="center-content">
            <Container className={classes.eventsContainer}>
              <div
                className="logo-center"
                style={{ backgroundImage: centerLogo }}
              ></div>
              <Typography variant="h2" className={classes.title}>
                {center?.name}
              </Typography>

              <hr />
              <Typography variant="h5" className={classes.eventTitle}>
                {centerEvent?.title}
              </Typography>
              <Typography variant="body2" className={classes.date}>
                نشر بتاريخ {date}
              </Typography>
              <div className={classes.eventImage} style={{ backgroundImage: `url(${process.env.REACT_APP_STRAPI_URL}${centerEvent?.image?.url})` }}>
              </div>
              <Typography
                component="p"
                variant="p"
                dangerouslySetInnerHTML={{ __html: centerEvent?.content }}
                className={classes.eventContent}
              />
              <Button
                variant="outlined"
                className={classes.back}
                size="large"
                onClick={() => {
                  history.push('/events')
                }}
                endIcon={<ArrowBackIcon />}
              >
                العودة الى النشاطات
              </Button>
            </Container>
          </div>
        </main>
      </ThemeProvider>
    </>
  );
}
