import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  level1: {
    backgroundColor: "lightgrey",
  },
  level2: {
    textDecoration: "underline",
  },
});
export default function FormSection({ title, level, children }) {
  const classes = useStyles();
  return (
    <Box my={3}>
      <Typography
        variant={`h${level + 4}`}
        className={classes[`level${level}`]}
      >
        {title}
      </Typography>
      <Box my={2}>{children}</Box>
    </Box>
  );
}
