import Form from "./Form";

const TripOrMeetingReport = {
  slug: "trip-or-meeting-report",
  name: "نموذج كتابة تقرير الرحلات و اللقاءات",
  availableActions:{
    view: (item, {membershipRole, user}) => item.submittedAt && membershipRole === 'owner' || item.author.id === user.id,
    edit: (item, {user}) => item.author.id === user.id && !item.submittedAt,
    delete: (item, {membershipRole, user}) => item.submittedAt ? membershipRole === 'owner' : item.author.id === user.id,
  },
  fields: {
    name: {
      label: "العنوان",
      type: "string",
    },
    about: {
      label: "تعريف عن الرحلة أو اللقاء",
      type: "string",
      multiline: true,
    },
    team: {
      label: "فريق الرحلات و اللقاءت",
      type: "string",
      multiline: true,
    },
    met: {
      label: "بمن التقوا",
      type: "string",
      multiline: true,
    },
    topic: {
      label: "ماذا كان محور اللقاء؟",
      type: "string",
      multiline: true,
    },
    duration: {
      label: "كم استغرق من الوقت؟",
      type: "string",
      multiline: true,
    },
    outcomes: {
      label: "ماذا كانت مخرجات اللقاء؟",
      type: "string",
      multiline: true,
    },
    author: {
      label: "قام بإعداد التقرير",
      type: "user",
      permissions: 'autoFillFirst',
      valueFor: {
        view: (value, context) => value ?? context.user,
        edit: (value, context) => value ?? context.user,
      },
    },
    submittedAt: {
      label: "رفع التقرير للمسؤول",
      type: "timestamp",
      permissions: 'fillOnce',
    }
  },
  layout: [
    {
      type: "section",
      singleField: "name",
    },
    {
      type: "section",
      singleField: "about",
    },
    {
      type: "section",
      singleField: "team",
    },
    {
      type: "section",
      singleField: "met",
    },
    {
      type: "section",
      singleField: "topic",
    },
    {
      type: "section",
      singleField: "duration",
    },
    {
      type: "section",
      singleField: "outcomes",
    },
    {
      type: "section",
      label: "معلومات عن التقرير",
      children:[
        'author',
        'submittedAt',
      ]
    },
  ],

  views: {
    list: {
      title: "قائمة التقارير",
      requiredFields: ["name", "author", 'submittedAt'],
      columns: [
        {
          name: "عنوان التقرير",
          selector: ({ name }) => <span color="red">{name}</span>,
          searchable: true,
          sortable: true,
        },
        {
          name: "انشئ بتاريخ",
          selector: ({ createdAt }) => createdAt,
          searchable: true,
          sortable: true,
        },
        {
          name: "كاتب التقرير",
          selector: ({ author }) => author ? author?.fullname : '(غير محدد)' ,
          searchable: true,
          sortable: true,
        },
        {
          name: "تم رفعه بتاريخ",
          selector: ({ submittedAt }) => submittedAt ?? 'ليس بعد',
          searchable: true,
          sortable: true,
        },
      ],
    },
    add: {
      title: "تقرير جديد",
    },
    edit: {
      title: "تعديل تقرير",
    },
    view: {
      title: "تقرير رحلة أو لقاء",
    },
  },
};


export default new Form(TripOrMeetingReport);
