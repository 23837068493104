import React, { useContext, useReducer, useState } from "react";
import { l1 } from "../../local.json";
import {
  Button,
  Dialog,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  TextField,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  CircularProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Drafter2 from "../../common/Drafter2";
import CropeImage from "../../UserPages/ImageCrop";
function EditEvent({
  classes,
  create,
  reverseCreate,
  saveAllChanges,
  setEventImageId,
  eventImage,
  setEventImage,
  eventTitle,
  eventContent,
  eventExcerpt,
  setTitle,
  setContent,
  setExcerpt,
  saveLoading,
  edit
}) {
  console.log("eventContent", eventContent);
  return (
    <>
      {/* dialog for create event */}
      <Dialog fullScreen open={create} onClose={reverseCreate}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={reverseCreate}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {l1.eventDetails}
            </Typography>
          </Toolbar>
        </AppBar>
        <Container className={classes.createDialogContent}>
          <TextField
            variant="outlined"
            label={l1.title}
            style={{ margin: "20px 0" }}
            defaultValue={eventTitle}
            value={eventTitle}
            onChange={e => {
              setTitle(e.target.value);
            }}
          />
          <Drafter2
            noUpload={true}
            raw={eventContent}
            setContent={setContent}
            edit={edit}
            assignement={false}
            centerEvent={true}
          />
          <div style={{ margin: "20px 0" }}>
            <CropeImage
              destination="eventImage"
              setEventImage={setEventImage}
              setEventImageId={setEventImageId}
            />
            {eventImage ? (
              <img
                className={classes.eventImage}
                src={`${process.env.REACT_APP_STRAPI_URL}${eventImage}`}
              />
            ) : (
              ""
            )}
          </div>
          <TextField
            variant="outlined"
            label={l1.excerpt}
            style={{ marginTop: "20px", marginBottom: "120px" }}
            defaultValue={eventExcerpt}
            value={eventExcerpt}
            onChange={e => {
              setExcerpt(e.target.value);
            }}
          />
          <Paper
            style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
            elevation={3}
          >
            <BottomNavigation
              showLabels={false}
            // value={value}
            // onChange={(event, newValue) => {
            //   setValue(newValue);
            // }}
            >
              {/* <Button variant="contained" label={l1.saveAllChanges}>{l1.saveAllChanges}</Button> */}
              <BottomNavigationAction
                label={l1.saveChanges}
                icon={
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={saveAllChanges}
                    // style={{ color: 'white', cursor: 'pointer' }}
                    // disabled={saveLoading}
                    style={{ width: "100%" }}
                  >
                    {saveLoading && <CircularProgress size={20} />}
                    {!saveLoading && l1.saveChanges}
                  </Button>
                }
              />
              {/* <BottomNavigationAction label={l1.cancel} icon={<Button variant="contained" color="secondary" size="large">{l1.cancel}</Button>} /> */}
            </BottomNavigation>
          </Paper>
        </Container>
      </Dialog>
      {/* dialog for create event */}
    </>
  );
}

export default EditEvent;
