import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  TextField,
  Typography
} from "@material-ui/core";
import React, {
  useEffect,
  useReducer,
  useRef,
  useState,
  useContext
} from "react";
import { MdPeople } from "react-icons/md";
import { l1 } from "../../../local.json";
import { gql, useMutation } from "@apollo/client";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useParams } from "react-router-dom";
import Comment from "../../../common/Comment";
import CreateComment from "../../../common/CreateComment";
import MuiAlert from "@material-ui/lab/Alert";
import Loading from "../../../common/Loading";
import Drafter2 from "../../../common/Drafter2";
import moment from "moment";
import { RequireClassAccess } from "../../../../../src/Auth/gates";
import { Navigation } from "../../../../Context";

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  topSection: {
    margin: theme.spacing(1, 2, 2, 1)
  },
  avatar: {
    margin: theme.spacing(2)
  },
  name: {
    fontWeight: 600
  },
  date: {
    fontSize: theme.spacing(1.6),
    color: theme.palette.grey[600]
  },
  post: {
    marginLeft: theme.spacing(2),
    "&>*": {}
  },
  midSection: {
    //  marginBottom: theme.spacing(2),
  },
  stats: {
    margin: theme.spacing(1, 0)
  },
  statsIcon: {
    color: theme.palette.grey[700],
    fontSize: theme.spacing(3),
    margin: theme.spacing(0, 1)
  },
  statsText: {
    fontWeight: 600,
    color: theme.palette.grey[700]
  },
  midNameBox: {
    gap: theme.spacing(1.4)
  },
  inputContainer: {
    border: `1px solid lightgrey`,
    borderRadius: theme.spacing(4),
    marginRight: theme.spacing(4)
  },
  input: {
    marginLeft: theme.spacing(2)
  },
  inputIcon: {
    transform: "scaleX(-1)",
    margin: theme.spacing(0, 1),
    fontSize: theme.spacing(3),
    cursor: "pointer",
    color: theme.palette.grey[500]
  },
  options: {
    marginRight: theme.spacing(2)
  },
  menuItem: {
    paddingRight: theme.spacing(10)
  },
  announcement: {
    fontSize: theme.spacing(3),
    // margin: theme.spacing(2),
    cursor: "pointer",
    color: "grey"
  },
  editPostContainer: {
    margin: theme.spacing(0, 5)
  },
  editPostTextField: {
    background: theme.palette.grey[100],
    marginBottom: theme.spacing(2)
  },
  editPostButtons: {
    gap: theme.spacing(2)
  },
  muiAlert: {
    width: "max-content"
  }
}));

const EDIT_POST = gql`
  mutation EditPost($id: ID!, $content: String!) {
    updatePost(input: { where: { id: $id }, data: { content: $content } }) {
      post {
        content
      }
    }
  }
`;
const DELETE_POST = gql`
  mutation DeletePost($id: ID!) {
    deletePost(input: { where: { id: $id } }) {
      post {
        content
      }
    }
  }
`;

function Announcement({
  content,
  fullname,
  id,
  comments,
  failure,
  refetch,
  alert,
  createdAt,
  attachments,
  writerEmail
}) {
  //Apollo
  const [editPost, { loading: loadingEdit }] = useMutation(EDIT_POST, {
    onError: () => failure(l1.failure),
    onCompleted: () => {
      alert(l1.editsuccess);
      reverseEditPostState();
      refetch();
    }
  });
  const [deletePost, { loading: loadingDelete }] = useMutation(DELETE_POST, {
    onError: () => failure(l1.failure),
    onCompleted: () => {
      alert(l1.deletesuccess);
      refetch();
    }
  });

  // Edit State Indicator
  const [editPostState, reverseEditPostState] = useReducer(s => !s, false);
  // Upload Tracker
  const [uploadTracker, setUploadTracker] = useState();
  // Options Logic
  const [anchorPost, setAnchorPost] = useState(null);
  const openPostMenu = event => {
    setAnchorPost(event.currentTarget);
  };
  const closePostMenu = () => {
    setAnchorPost(null);
  };
  const [anchorComment, setAnchorComment] = useState(null);
  const openCommentMenu = event => {
    setAnchorComment(event.currentTarget);
  };
  const closeCommentMenu = () => {
    setAnchorComment(null);
  };
  const { user } = useContext(Navigation);

  // Confirmation Dialog Logic
  const [confirmationState, reverseConfirmationState] = useReducer(
    state => !state,
    false
  );
  const classes = useStyles();

  if (loadingEdit || loadingDelete) return <Loading />;
  return (
    <Paper className={classes.container} variant="outlined">
      <Box className={classes.topSection}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box className={classes.nameBox} display="flex" alignItems="center">
            <Avatar className={classes.avatar}></Avatar>
            <Box>
              <Typography className={classes.name}>{fullname}</Typography>
              <Typography className={classes.date}>
                {moment(createdAt).fromNow()}
              </Typography>
            </Box>
          </Box>
          {user.email === writerEmail ? (
            <BiDotsVerticalRounded
              className={classes.announcement}
              onClick={openPostMenu}
            />
          ) : null}

          <Menu
            open={Boolean(anchorPost)}
            onClose={closePostMenu}
            elevation={1}
            anchorEl={anchorPost}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <MenuItem
              className={classes.menuItem}
              onClick={reverseEditPostState}
            >
              <Typography>{l1.edit}</Typography>
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={reverseConfirmationState}
            >
              <Typography>{l1.delete}</Typography>
            </MenuItem>
          </Menu>
        </Box>
        <Box className={classes.post}>
          {editPostState ? (
            <Drafter2
              edit
              mutation={editPost}
              reverse={reverseEditPostState}
              id={id}
            />
          ) : (
            <Drafter2 preview raw={content} />
          )}
          {attachments ? (
            <Link
              href={process.env.REACT_APP_STRAPI_URL + attachments?.url}
              target="_blank"
            >
              {" "}
              تحميل المرفق{" "}
            </Link>
          ) : null}
        </Box>
      </Box>
      <Divider className={classes.divider} />
      <Box
        className={classes.midSection}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Box className={classes.stats} display="flex" alignItems="center">
          <MdPeople className={classes.statsIcon} />

          <Typography className={classes.statsText}>
            {comments.length} {l1.comment}
          </Typography>
        </Box>
        {comments.map(item => (
          <Comment
            fullname={item.writer.fullname}
            content={item.content}
            id={item.id}
            key={item.id}
            refetch={refetch}
            alert={alert}
            failure={failure}
            createdAt={item.createdAt}
            writerEmail={item.writer.email}
          />
        ))}
        <CreateComment
          refetch={refetch}
          alert={alert}
          failure={failure}
          id={id}
        />
      </Box>

      <Dialog open={confirmationState} onClose={reverseConfirmationState}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Typography style={{ fontWeight: "bold" }}>
            {l1.confirmation}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              deletePost({
                variables: {
                  id
                }
              });
            }}
          >
            {l1.confirm}
          </Button>
          <Button variant="contained" onClick={reverseConfirmationState}>
            {l1.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default Announcement;
