import React, { useContext } from "react";
import { useHistory } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie/es6";
import Loading from "../components/common/Loading";
import { Class, Navigation } from "../Context";

function logout() {
  let cookies = new Cookies();
  cookies.remove("token");
}

const accountsRedirect = (target = "login") => {
  let nextURL = encodeURIComponent(
    window.location.pathname === "/"
      ? window.location.origin + "/home"
      : window.location.href
  );
  return `${process.env.REACT_APP_ACCOUNTS_URL}/${
    target === "register" ? "register" : ""
  }?center&redirect_to=${nextURL}`;
};

export function BaseGate({
  authorize,
  rejectBehaviour = "hide",
  children,
  message = null,
  redirectTo = "login",
}) {
  const history = useHistory();
  if (authorize) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  // eslint-disable-next-line default-case
  switch (rejectBehaviour) {
    case "hide":
      return null;
    case "message":
      return message;
    case "redirect":
      if (["register", "login"].includes(redirectTo)) {
        window.location.href = accountsRedirect(redirectTo);
        return <Loading />;
      }
      return <Redirect to={redirectTo} />;

    case "logout":
      logout();
      return <Redirect to="/" />;
  }
}

export function RequireAuthentication({ children, ...gateProps }) {
  const { user } = useContext(Navigation);
  return (
    <BaseGate authorize={user?.confirmed && !user?.blocked} {...gateProps}>
      {children}
    </BaseGate>
  );
}

// membership.status == "ACTIVE"
export function RequireCenterMembership({
  children,
  status = "GUEST",
  ...gateProps
}) {
  const { membershipStatus, isCenterOwner } = useContext(Navigation);
  let authorize;
  switch (status) {
    case "REQUESTED":
      authorize = membershipStatus === "REQUESTED";
      break;
    case "ACTIVE":
      authorize = isCenterOwner || membershipStatus === "ACTIVE";
      break;
    case "BANNED":
      authorize = membershipStatus === "BANNED";
      break;
    case "GUEST":
    default:
      authorize = !membershipStatus;
  }
  // console.log('required = ', status, 'found = ', membershipStatus, "authorize = ²", authorize)

  return (
    <BaseGate authorize={authorize} {...gateProps}>
      {children}
    </BaseGate>
  );
}

export function RequireCenterAdmin({ children, only = ['moderator','assistant','owner'], ...gateProps }) {
  const { isCenterAdmin, membershipRole } = useContext(Navigation);
  const allowedRoles = Array.isArray(only) ? only : [only];
  return (
    <BaseGate authorize={isCenterAdmin && allowedRoles.includes(membershipRole) } {...gateProps}>
      {children}
    </BaseGate>
  );
}

export function RequireClassAccess({
  children,
  only = "any",
  slug = null,
  ...gateProps
}) {
  let { classSlug } = useContext(Class);
  classSlug = classSlug || slug;
  const { isCenterOwner, isCenterAdmin, joinedClasses, teachedClasses } =
    useContext(Navigation);
  let authorize;
  const studentAccess = joinedClasses?.some(({ slug }) => slug === classSlug);
  const teacherAccess =
    isCenterOwner ||
    isCenterAdmin ||
    teachedClasses?.some(({ slug }) => slug === classSlug);
  switch (only) {
    case "student":
      authorize = studentAccess;
      break;
    case "teacher":
      authorize = teacherAccess;
      break;
    case "any":
    default:
      authorize = studentAccess || teacherAccess;
  }

  return (
    <BaseGate authorize={authorize} {...gateProps}>
      {children}
    </BaseGate>
  );
}

export function RequireTeacherAccess({ children, ...gateProps }) {
  const { isCenterOwner, isCenterAdmin, teachedClasses } =
    useContext(Navigation);
  return (
    <BaseGate
      authorize={isCenterOwner || isCenterAdmin || teachedClasses?.length}
      {...gateProps}
    >
      {children}
    </BaseGate>
  );
}
