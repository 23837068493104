import { Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "80vh",
  },
}));

function NotFound() {
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h1">Wrong Place, Wrong Time! 404</Typography>
    </Box>
  );
}

export default NotFound;
