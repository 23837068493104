import Form from "./Form";

const TrainingProgram = {
  slug: "training-program",
  name: "نموذج توزيع المواد التدريبية والأنشطة",
  fields:{
    name:{
      label: 'اسم الدورة',
      type: 'string',
    },
    program:{
      label: "الجدول",
      type:'table',
      columns: [
        {
          label: 'اليوم',
          type: 'calculated',
          calculate: ({rowIndex}) => rowIndex + 1,
        },
        {
          label: 'التاريخ',
          type: 'date',
        },
        {
          label: 'صباحا',
          type: 'string',
          multiline: true,
          minRows: 1,
        },
        {
          label: 'عصرا',
          type: 'string',
          multiline: true,
          minRows: 1,
        },
        {
          label: 'ملاحظة',
          type: 'string',
          multiline: true,
          minRows: 1,
        },
      ],
      // aggregate: false if none of the columns has an aggregate function
    },
  },
  layout:[
    'name',
    'program',
  ],
  views: {
    list: {
      title: "قائمة الجداول",
      requiredFields: ["name",  'createdAt'],
      columns: [
        {
          name: "العنوان",
          selector: ({ name }) => <span color="red">{name}</span>,
          searchable: true,
          sortable: true,
        },
        {
          name: "أنشئ بتاريخ",
          selector: ({ createdAt }) => createdAt ,
          searchable: true,
          sortable: true,
        },
      ],
    },
    add: {
      title: "جدول توزيع جديد",
    },
    edit: {
      title: "تعديل جدول التوزيع",
    },
    view: {
      title: "توزيع المواد التدريبية والأنشطة",
    },
  },
};

export default new Form(TrainingProgram);