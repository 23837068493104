import React, { useRef, useState, useContext } from "react";

import {
  Avatar,
  Box,
  Button,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { gql, useMutation } from "@apollo/client";
import { l1 } from "../local.json";
import Loading from "./Loading";
import moment from "moment";
import { Navigation } from "../../Context";

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  topSection: {
    margin: theme.spacing(1, 2, 2, 1)
  },
  avatar: {
    margin: theme.spacing(2)
  },
  name: {
    fontWeight: 600
  },
  date: {
    fontSize: theme.spacing(1.6),
    color: theme.palette.grey[600]
  },
  post: {
    marginLeft: theme.spacing(2),
    "&>*": {}
  },
  midSection: {
    //  marginBottom: theme.spacing(2),
  },
  stats: {
    margin: theme.spacing(1, 0)
  },
  statsIcon: {
    color: theme.palette.grey[700],
    fontSize: theme.spacing(3),
    margin: theme.spacing(0, 1)
  },
  statsText: {
    fontWeight: 600,
    color: theme.palette.grey[700]
  },
  midNameBox: {
    gap: theme.spacing(1.4)
  },
  inputContainer: {
    border: `1px solid lightgrey`,
    borderRadius: theme.spacing(4),
    marginRight: theme.spacing(4)
  },
  input: {
    marginLeft: theme.spacing(2)
  },
  inputIcon: {
    transform: "scaleX(-1)",
    margin: theme.spacing(0, 1),
    fontSize: theme.spacing(3),
    cursor: "pointer",
    color: theme.palette.grey[500]
  },
  options: {
    marginRight: theme.spacing(2)
  },
  menuItem: {
    paddingRight: theme.spacing(10)
  },
  announcement: {
    fontSize: theme.spacing(3),
    // margin: theme.spacing(2),
    cursor: "pointer",
    color: "grey"
  },
  editPostContainer: {
    margin: theme.spacing(0, 5)
  },
  editPostTextField: {
    background: theme.palette.grey[100],
    marginBottom: theme.spacing(2)
  },
  editPostButtons: {
    gap: theme.spacing(2)
  }
}));

const EDIT_COMMENT = gql`
  mutation EditComment($id: ID!, $content: String!) {
    updateComment(input: { where: { id: $id }, data: { content: $content } }) {
      comment {
        content
      }
    }
  }
`;
const DELETE_COMMENT = gql`
  mutation DeleteComment($id: ID!) {
    deleteComment(input: { where: { id: $id } }) {
      comment {
        content
      }
    }
  }
`;

function Comment({
  id,
  content,
  fullname,
  alert,
  failure,
  refetch,
  createdAt,
  writerEmail
}) {
  const [editComment, { loading: loadingEditComment }] = useMutation(
    EDIT_COMMENT,
    {
      onError: error => {
        console.log("mutationEditComment error: ", error);
        failure(l1.failure);
      },
      onCompleted: () => {
        refetch();
        alert(l1.editsuccess);
        setEditCommentState(false);
      }
    }
  );
  const [deleteComment, { loading: loadingDeleteComment }] = useMutation(
    DELETE_COMMENT,
    {
      onError: error => {
        console.log("mutationDeleteComment error: ", error);
        failure(l1.failure);
      },
      onCompleted: () => {
        refetch();
        alert(l1.deletesuccess);
      }
    }
  );
  // Edit State Indicator
  const [editCommentState, setEditCommentState] = useState(false);
  // Inputs Logic
  const [comment, setComment] = useState(content);
  const commentRef = useRef();

  // Options Logic

  const [anchorComment, setAnchorComment] = useState(null);
  const openCommentMenu = event => {
    setAnchorComment(event.currentTarget);
  };
  const closeCommentMenu = () => {
    setAnchorComment(null);
  };
  const { user } = useContext(Navigation);
  console.log("user", user.email);
  console.log("userWriter", writerEmail);
  const classes = useStyles();
  return (
    <>
      {loadingDeleteComment || loadingEditComment ? (
        <Loading />
      ) : (
        <>
          <Box display="flex" alignItems="center" style={{ minWidth: "100%" }}>
            <Avatar className={classes.avatar}></Avatar>
            <Box
              display="flex"
              justifyContent="flex-start"
              flexDirection="column"
              className={classes.commentContainer}
              // alignItems="flex-start"
              flexGrow={1}
            >
              <Box
                className={classes.midNameBox}
                display="flex"
                alignItems="center"
              >
                <Typography className={classes.name}>{fullname}</Typography>
                <Typography className={classes.date}>
                  {moment(createdAt).fromNow()}
                </Typography>
              </Box>
              <Typography className={classes.commentContent}>
                {editCommentState ? (
                  <Box className={classes.editPostContainer}>
                    <TextField
                      className={classes.editPostTextField}
                      fullWidth
                      autoFocus
                      multiline
                      rows={3}
                      defaultValue={comment}
                      variant="filled"
                      inputRef={commentRef}
                      onChange={e => {
                        setComment(commentRef.current.value);
                      }}
                    />
                    <Box
                      className={classes.editPostButtons}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          editComment({
                            variables: {
                              id,
                              content: comment
                            }
                          });
                        }}
                      >
                        {l1.edit}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setEditCommentState(false);
                        }}
                      >
                        {l1.cancel}
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  content
                )}
              </Typography>
            </Box>
            <Box display="flex" className={classes.options}>
              {user.email === writerEmail ? (
                <BiDotsVerticalRounded
                  className={classes.announcement}
                  onClick={openCommentMenu}
                />
              ) : null}

              <Menu
                open={Boolean(anchorComment)}
                onClose={closeCommentMenu}
                elevation={1}
                anchorEl={anchorComment}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => {
                    setEditCommentState(true);
                  }}
                >
                  <Typography>{l1.edit}</Typography>
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => {
                    deleteComment({
                      variables: {
                        id
                      }
                    });
                  }}
                >
                  {l1.delete}
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default Comment;
