import React, { useContext, useReducer } from "react";
import { l1 } from "../../local.json";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import TopBar from "../../common/TopBar";
import { gql, useQuery, useMutation } from "@apollo/client";
import Loading from "../../common/Loading";
import { Navigation } from "../../../Context";
import { onError } from "apollo-link-error";

const useStyles = makeStyles(theme => ({
  highOrder: {
    margin: theme.spacing(10, 30)
  },
  headContainer: {
    //margin: theme.spacing(0, 10),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[100]}`,
    background: `${theme.palette.grey["50"]}30`,
    borderRadius: theme.spacing(2)
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  iconHead: {
    color: "#44566c",
    margin: theme.spacing(0, 1)
  },
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    background: "white",
    marginBottom: theme.spacing(1),
    cursor: "pointer"
  },
  avatar: {
    margin: theme.spacing(0, 1)
  },
  nameText: {},
  nameName: {
    color: "#44566c"
  },
  menuItem: {
    paddingRight: theme.spacing(10)
  },
  nameTitle: {
    color: "#44566c",
    fontSize: theme.spacing(1.4)
  },
  idId: {
    color: "#8697a8"
  },
  address: {
    color: "#44566c"
  },
  icon: {
    fontSize: theme.spacing(3),
    color: "#8697a8",
    marginLeft: theme.spacing(3)
  },
  title: {
    fontSize: theme.spacing(3),
    fontWeight: 600,
    color: theme.palette.grey[700]
  },
  label: {
    marginLeft: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(4)
  }
}));

const GET_WAITING_LIST = gql`
  query GetWaitingList($id: ID!) {
    centersList(id: $id) {
      memberships(sort: "createdAt:desc", where: { status: "REQUESTED" }) {
        id
        fullname
        school
        specialty
        email
        field
        afterGraduate
        homeTel
        mobileTel
        hobbies
        birthDate
        object
        college
        address
        user {
          id
          username
        }
        status
      }
    }
  }
`;

const REJECT = gql`
  mutation Reject($id: ID!) {
    deleteMembership(input: { where: { id: $id } }) {
      membership {
        id
      }
    }
  }
`;
const APPROVE = gql`
  mutation Approve($id: ID!) {
    updateMembership(input: { where: { id: $id }, data: { status: ACTIVE } }) {
      membership {
        id
        status
      }
    }
  }
`;

function Waiting() {
  //Apollo
  const { centerId, info } = useContext(Navigation);

  const { loading, data, refetch } = useQuery(GET_WAITING_LIST, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: centerId
    }
  });

  const [reject, { loading: loadingReject }] = useMutation(REJECT, {
    onCompleted: () => refetch()
  });
  const [approve, { loading: loadingApprove }] = useMutation(APPROVE, {
    onCompleted: () => {
      // reject({
      //   variables: {
      //     id: selected
      //   }
      // });
      console.log("it has been approved");
      refetch();
    },
    onError: err => {
      console.log(err);
    }
  });
  const [state, reverseState] = useReducer(s => !s, false);
  const [selected, setSelected] = React.useState(null);
  const classes = useStyles();
  if (loading || loadingReject || loadingApprove) return <Loading />;
  else {
    const membership = data.centersList.memberships;
    return (
      <Box>
        <TopBar type="admin" tab="waiting" />
        <Box className={classes.highOrder}>
          <Grid
            container
            className={classes.headContainer}
            display="flex"
            justifyContent="space-between"
          >
            <Grid item xs={4}>
              <Box display="flex" flexDirection="row">
                <Typography className={classes.text}>{l1.username}</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                display="flex"
                flexDirection="row"
                //style={{ right: -70, position: 'relative' }}
              >
                <Typography className={classes.text}>{l1.id}</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="row">
                <Typography className={classes.text}>{l1.email}</Typography>
              </Box>
            </Grid>

            <Grid item xs={1}></Grid>
          </Grid>
          {membership.map(
            ({
              id,
              fullname,
              school,
              specialty,
              email,
              field,
              afterGraduate,
              homeTel,
              mobileTel,
              hobbies,
              birthDate,
              object,
              college,
              address,
              user,
              status
            }) => (
              <>
                <Box>
                  <TableRow
                    name={fullname}
                    email={email}
                    id={id}
                    reverse={reverseState}
                    user={user}
                  />
                </Box>
                <UserInfo
                  state={state}
                  reverseState={reverseState}
                  fullname={fullname}
                  birthDate={birthDate}
                  school={school}
                  college={college}
                  specialty={specialty}
                  address={address}
                  homeTel={homeTel}
                  mobileTel={mobileTel}
                  email={email}
                  object={object}
                  field={field}
                  hobbies={hobbies}
                  afterGraduate={afterGraduate}
                  approve={approve}
                  id={id}
                />
              </>
            )
          )}
        </Box>
      </Box>
    );
  }

  function TableRow({ name, email, id, reverse, user }) {
    const classes = useStyles();
    return (
      <Box className={classes.container}>
        <Grid container>
          <Grid item xs={4} onClick={reverse}>
            <Box
              className={classes.nameContainer}
              display="flex"
              flexDirection="row"
            >
              <Avatar className={classes.avatar} onClick={reverse} />
              <Box className={classes.nameText}>
                <Typography className={classes.nameName}>{name}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3} onClick={reverse}>
            <Typography className={classes.idId}>{id}</Typography>
          </Grid>
          <Grid item xs={3} onClick={reverse}>
            <Typography className={classes.address}>{email}</Typography>
          </Grid>

          <Grid item xs={2}>
            <Box display="flex" flexDirection="row" gridGap="16px">
              <Button
                className={classes.unBanButton}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setSelected(id);
                  // const ids = [];
                  // const raw = info.students;

                  // raw.map(({ id }) => ids.push(id));
                  // console.log("raw is ", raw);
                  // const target = user.id;
                  // ids.push(target);
                  console.log("id is ", id);
                  approve({
                    variables: {
                      id: id
                    }
                  });
                }}
              >
                {l1.apporve}
              </Button>
              <Button
                className={classes.unBanButton}
                color="secondary"
                variant="outlined"
                onClick={() => {
                  reject({
                    variables: {
                      id
                    }
                  });
                }}
              >
                {l1.reject}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
export const UserInfo = ({
  state,
  reverseState,
  fullname,
  birthDate,
  school,
  college,
  specialty,
  address,
  homeTel,
  mobileTel,
  email,
  object,
  field,
  hobbies,
  afterGraduate,
  approve,
  id
}) => {
  const classes = useStyles();
  return (
    <Dialog maxWidth="md" open={state} onClose={reverseState}>
      <Paper className={classes.paper} variant="outlined">
        <Box display="flex" justifyContent="center">
          <Typography className={classes.title}>{l1.formTitle}</Typography>
        </Box>

        <TextField
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          label={l1.fullName}
          name="fullname"
          autoFocus
          value={fullname}
        />
        <TextField
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          label={l1.birthDate}
          name="birthDate"
          autoFocus
          value={birthDate}
          InputLabelProps={{ className: classes.label }}
        />
        <TextField
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          label={l1.school}
          name="school"
          autoFocus
          value={school}
        />
        <TextField
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          label={l1.college}
          name="college"
          autoFocus
          value={college}
        />
        <TextField
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          label={l1.specialities}
          name="specialty"
          autoFocus
          value={specialty}
        />
        <TextField
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          label={l1.address}
          name="address"
          autoFocus
          value={address}
        />
        <TextField
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          label={l1.homeTel}
          name="homeTel"
          autoFocus
          value={homeTel}
        />
        <TextField
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          label={l1.tel}
          name="mobileTel"
          autoFocus
          value={mobileTel}
        />
        <TextField
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          label={l1.email}
          name="email"
          autoFocus
          value={email}
        />
        <TextField
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          label={l1.q1}
          name="object"
          autoFocus
          rows={6}
          multiline
          value={object}
        />
        <TextField
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          label={l1.q2}
          name="field"
          autoFocus
          rows={6}
          multiline
          value={field}
        />
        <TextField
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          label={l1.q3}
          name="hobbies"
          autoFocus
          rows={6}
          multiline
          value={hobbies}
        />
        <TextField
          InputProps={{
            readOnly: true
          }}
          variant="outlined"
          margin="normal"
          fullWidth
          label={l1.q4}
          name="afterGraduate"
          autoFocus
          rows={6}
          multiline
          value={afterGraduate}
        />
        <Box display="flex" justifyContent="center" className={classes.button}>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() =>
              approve({
                variables: {
                  id
                }
              })
            }
          >
            {l1.approve}
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
};
export default Waiting;
