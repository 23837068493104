import { useMediaQuery } from '@material-ui/core';

function useLayout() {
  const desktop = useMediaQuery('(min-width: 768px)');
  const mobile = useMediaQuery('(max-width: 767px)');
  if (desktop) {
    return true;
  } else if (mobile) {
    return false;
  }
}

export default useLayout;
