import React, { useState, useEffect } from "react";
import {
  Avatar,
  Typography,
  Box,
  Dialog,
  DialogContent,
  LinearProgress,
  makeStyles,
  TextField,
  Checkbox,
  DialogActions,
  Button
} from "@material-ui/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import { l1 } from "../local.json";

// const GET_CENTER_STUDENTS = gql`
//   query QueryCenterPeople($id: ID!) {
//     centersList(id: $id) {
//       students {
//         id
//         fullname
//         avatar
//       }
//     }
//   }
// `;
const GET_CENTER_MEMBERS = gql`
  query QueryClassMembers($id: ID!, $classId: ID!) {
    centersLists(where: { id: $id }) {
      memberships(where: { status: "ACTIVE" }) {
        user {
          classes(where: { id: $classId }) {
            id
          }
          teachingClasses(where: { id: $classId }) {
            id
          }
          id
          fullname
          avatar
        }
      }
    }
  }
`;

const UPDATE_PEOPLE = gql`
  mutation UpdateInterface($data: editClassInput!, $id: ID!) {
    updateClass(input: { data: $data, where: { id: $id } }) {
      class {
        teachers {
          fullname
          id
        }
        students {
          fullname
          id
        }
      }
    }
  }
`;
const useStyles = makeStyles(theme => ({
  dialogContent: {
    margin: theme.spacing(2)
  },
  search: {
    margin: theme.spacing(2, 30),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  nameContainer: {
    marginBottom: theme.spacing(1),
    border: "1px solid lightgrey",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1)
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

function Search({
  refetch,
  ready,
  reverseReady,
  centerId,
  classID,
  teachers,
  students,
  studentsMode
}) {
  const [search, setSearch] = useState("");
  const [result, setResult] = useState([]);
  const [selected, setSelected] = useState([]);

  const { loading, data } = useQuery(GET_CENTER_MEMBERS, {
    onError: error => console.log("QueryStreamError: ", error),
    variables: {
      id: centerId,
      classId: classID
    },
    onCompleted: () => {
      setResult(
        data?.centersLists[0]?.memberships?.filter(({ user }) =>
          user.fullname?.includes(search)
        )
      );
    }
  });
  const [updatePeople] = useMutation(UPDATE_PEOPLE, {
    onCompleted: data => {
      reverseReady();
      setSelected([]);
      refetch();
    }
  });

  useEffect(() => {
    const arr = [];
    console.log("data", data?.centersLists[0]?.memberships);
    // data?.centersLists[0].memberships?.forEach((user) => {
    //   const foundIt = user?.memberships?.search(search) !== -1;
    //   if (search && foundIt) arr.push(user);
    //   return arr;
    // });
    // setResult(arr);
    setResult(
      data?.centersLists[0]?.memberships?.filter(({ user }) =>
        user.fullname?.includes(search)
      )
    );
  }, [search]);

  const classes = useStyles();
  return (
    <Dialog
      className={classes.dialog}
      open={ready}
      onClose={reverseReady}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.search}>
          {loading ? (
            <LinearProgress />
          ) : (
            <TextField
              fullWidth
              label="Search"
              value={search}
              variant="outlined"
              onChange={e => {
                setSearch(e.target.value);
              }}
            />
          )}
        </Box>
        <Box
          style={{
            width: "100%",
            height: 1,
            background: "lightgrey",
            marginBottom: 32
          }}
        ></Box>
        {result?.map(i => (
          <Box
            className={classes.nameContainer}
            display="flex"
            alignItems="center"
            key={i.user.id}
          >
            <Checkbox
              onChange={e => {
                if (e.target.checked) {
                  setSelected([...selected, i.user]);
                } else {
                  setSelected(selected.filter(j => j.id !== i.user.id));
                }
              }}
            />
            <Avatar
              src={`${process.env.REACT_APP_STRAPI_URL}${i.user.avatar}`}
              className={classes.avatar}
            ></Avatar>
            <Typography className={classes.name}>{i.user.fullname}</Typography>
            {console.log("studentsMode", studentsMode)}
            <Typography
              variant="body2"
              style={{ fontSize: "12px", marginRight: "10px" }}
            >
              {studentsMode
                ? i.user?.classes[0]?.id === classID
                  ? l1.alreadyAddedStudent
                  : null
                : i.user?.teachingClasses[0]?.id === classID
                ? l1.alreadyAddedTeacher
                : null}
            </Typography>
          </Box>
        ))}
        {selected ? (
          <Box
            style={{
              width: "100%",
              height: 1,
              background: "lightgrey",
              marginBottom: 32
            }}
          ></Box>
        ) : null}
        {selected.map(i => (
          <Box
            className={classes.nameContainer}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            key={i.id}
          >
            <Box display="flex" alignItems="center">
              <Avatar
                src={`${process.env.REACT_APP_STRAPI_URL}${i.avatar}`}
                className={classes.avatar}
              ></Avatar>
              <Typography className={classes.name}>{i.fullname}</Typography>
            </Box>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => setSelected(selected.filter(j => j.id !== i.id))}
            >
              {l1.delete}
            </Button>
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            const arr = [];
            if (!studentsMode) {
              teachers.forEach(i => arr.push(i.id));
              selected.forEach(i => {
                arr.push(i.id);
              });
              updatePeople({
                variables: {
                  data: {
                    teachers: arr
                  },
                  id: classID
                }
              });
            }
            if (studentsMode) {
              students.forEach(i => arr.push(i.id));
              selected.forEach(i => {
                arr.push(i.id);
              });
              updatePeople({
                variables: {
                  data: {
                    students: arr
                  },
                  id: classID
                }
              });
            }
          }}
        >
          {l1.add}
        </Button>
        <Button onClick={reverseReady}>{l1.cancel}</Button>
      </DialogActions>
    </Dialog>
  );
}
export default Search;
