import React from "react";
import { TwitterOutlined, InstagramOutlined } from "@ant-design/icons";
import { Avatar, Box, Paper } from "@material-ui/core";
import "./Styling.scss";
import HighlightOff from "@material-ui/icons/HighlightOff";
import { useHistory } from "react-router";
import defaultAvatar from "./assets/img/user.default.jpg";

export default function MemberCard({
  index,
  deleteMember,
  picture,
  name,
  role,
  twitter,
  instagram,
  facebook,
}) {
  const history = useHistory();

  // console.log(picture);
  return (
    <Paper
      variant="outlined"
      elevation={3}
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        margin: "15px",
        maxWidth: "600px",
      }}
    >
      {history.location.pathname === "/" ? null : (
        <HighlightOff
          style={{
            position: "absolute",
            top: "-12px",
            left: "-12px",
            cursor: "pointer",
          }}
          onClick={() => deleteMember(index)}
        />
      )}
      <Box
        style={{ margin: 20 }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        className="member-card"
      >
        {picture ? (
          <Avatar
            style={{ height: 150, width: 150 }}
            src={`${process.env.REACT_APP_STRAPI_URL}${picture?.url}`}
            alt=""
          />
        ) : (
          <Avatar src={defaultAvatar} style={{ height: 150, width: 150 }} />
        )}
        <strong>{name}</strong>
        <p className="gray">{role}</p>
        <div className="socials">
          {Boolean(instagram) &&
            <a href={instagram} className="social-btn white">
              <InstagramOutlined />
            </a>
          }
          { Boolean(facebook) &&
            <a href={facebook} className="social-btn white">
              <svg
                style={{ marginTop: 2 }}
                width="11"
                height="15"
                viewBox="0 0 10 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.98868 0.753641L6.7795 0.75C4.29755 0.75 2.69361 2.44041 2.69361 5.05677V7.04248H0.472373C0.280432 7.04248 0.125 7.20233 0.125 7.3995V10.2766C0.125 10.4737 0.280609 10.6334 0.472373 10.6334H2.69361V17.8932C2.69361 18.0903 2.84904 18.25 3.04098 18.25H5.93907C6.13101 18.25 6.28644 18.0902 6.28644 17.8932V10.6334H8.88359C9.07553 10.6334 9.23096 10.4737 9.23096 10.2766L9.23202 7.3995C9.23202 7.30483 9.19534 7.21417 9.13029 7.14717C9.06525 7.08017 8.97663 7.04248 8.88447 7.04248H6.28644V5.35917C6.28644 4.55011 6.47413 4.13938 7.50012 4.13938L8.98833 4.13884C9.18009 4.13884 9.33553 3.97899 9.33553 3.782V1.11048C9.33553 0.913671 9.18027 0.754005 8.98868 0.753641Z"
                  fill="white"
                />
              </svg>
            </a>
          }
          { Boolean(twitter) &&
            <a href={twitter} className="social-btn white">
              <TwitterOutlined />
            </a>
          }
        </div>
      </Box>
    </Paper>
  );
}
