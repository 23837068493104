import React, { useReducer } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { l1 } from "../local.json";
import { makeStyles } from "@material-ui/styles";

export function useConfirmationDialog() {
  const [dialogProps, dispatch] = useReducer(
    (current, action) => {
      let next = { ...current };
      Object.entries(action).forEach(([key, value]) => {
        next[key] = value;
      });
      return next;
    },
    {
      open: false,
      title: null,
      content: null,
      onConfirm: () => {},
      onClose: () => {
        dispatch({ open: false, title: null, content: null });
      },
    }
  );

  const confirmationDialog = ({ title, content, onConfirm }) => {
    dispatch({
      open: true,
      title,
      content,
      onConfirm,
    });
  };
  return { dialogProps, confirmationDialog };
}

const useStyles = makeStyles({
  dialog: {
    "MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  },
});
export default function ConfirmationDialog(props) {
  const classes = useStyles();
  const { onClose, onConfirm, open, title, content, ...dialogProps } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      className={classes.dialog}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      style={{ backgroundColor: "transparent" }}
      {...dialogProps}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {l1.cancel}
        </Button>
        <Button onClick={handleOk} color="primary">
          {l1.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.node,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
