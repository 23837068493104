import React, { useContext, useReducer } from "react";
import { l1 } from "../../local.json";
import {
  Avatar,
  Box,
  Button,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import TopBar from "../../common/TopBar";
import { gql, useQuery, useMutation } from "@apollo/client";
import Loading from "../../common/Loading";
import { Navigation } from "../../../Context";
import { RequireCenterAdmin } from "../../../Auth/gates";
import SearchMembers from "../../common/SearchMembers";
import EditableChoice from "../../common/EditableChoice";
import { useHistory } from "react-router-dom";
import ConfirmationDialog, {
  useConfirmationDialog,
} from "../../common/ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  highOrder: {
    margin: theme.spacing(10, 30),
  },
  headContainer: {
    //margin: theme.spacing(0, 10),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[100]}`,
    background: `${theme.palette.grey["50"]}30`,
    borderRadius: theme.spacing(2),
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  iconHead: {
    color: "#44566c",
    margin: theme.spacing(0, 1),
  },
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    background: "white",
    marginBottom: theme.spacing(1),
  },
  avatar: {
    margin: theme.spacing(0, 1),
  },
  nameText: {},
  nameName: {
    color: "#44566c",
  },
  menuItem: {
    paddingRight: theme.spacing(10),
  },
  nameTitle: {
    color: "#44566c",
    fontSize: theme.spacing(1.4),
  },
  idId: {
    color: "#8697a8",
  },
  address: {
    color: "#44566c",
  },
  icon: {
    fontSize: theme.spacing(3),
    color: "#8697a8",
    marginLeft: theme.spacing(3),
  },
  title: {
    fontSize: theme.spacing(3),
    fontWeight: 600,
    color: theme.palette.grey[700],
  },
  label: {
    marginLeft: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(4),
  },
}));

const GET_STAFF = gql`
  query GetStaff($id: ID!) {
    centersList(id: $id) {
      memberships(
        sort: "role:desc"
        where: { status: "ACTIVE", role_ne: "member" }
      ) {
        id
        fullname
        school
        specialty
        email
        field
        afterGraduate
        homeTel
        mobileTel
        hobbies
        birthDate
        object
        college
        address
        user {
          id
          username
        }
        status
        role
      }
    }
  }
`;

const UPDATE_CENTER_OWNER = gql`
  mutation updateCenterOwner($centerId: ID!, $ownerId: ID!) {
    updateCentersList(
      input: { where: { id: $centerId }, data: { owner: $ownerId } }
    ) {
      centersList {
        id
      }
    }
  }
`;

const UPDATE_ROLE = gql`
  mutation updateRole($id: ID!, $role: ENUM_MEMBERSHIP_ROLE!) {
    updateMembership(input: { where: { id: $id }, data: { role: $role } }) {
      membership {
        id
        role
      }
    }
  }
`;

function Staff() {
  //Apollo
  const { centerId } = useContext(Navigation);

  const { loading, data, refetch } = useQuery(GET_STAFF, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: centerId,
    },
  });

  const history = useHistory();
  const [updateRole, { loading: loadingUpdateRole }] = useMutation(
    UPDATE_ROLE,
    {
      onCompleted: () => {
        refetch();
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  const [updateCenterOwner, { loading: loadingUpdateOwner }] = useMutation(
    UPDATE_CENTER_OWNER,
    {
      onCompleted: () => {
        history.go(0);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
  const { dialogProps, confirmationDialog } = useConfirmationDialog();
  const [add, reverseAdd] = useReducer((current) => !current, false);
  const classes = useStyles();
  if (loading || loadingUpdateRole || loadingUpdateOwner) return <Loading />;
  else {
    const membership = data.centersList.memberships;
    return (
      <Box>
        <TopBar type="admin" tab="staff" />
        <Box className={classes.highOrder}>
          <RequireCenterAdmin only="owner">
            <Grid container>
              <Grid item xs={12} style={{ paddingBottom: 20 }}>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  className={classes.create}
                  onClick={reverseAdd}
                >
                  {l1.add}
                </Button>
                <SearchMembers
                  centerId={centerId}
                  ready={add}
                  reverseReady={reverseAdd}
                  refetch={refetch}
                />
              </Grid>
            </Grid>
          </RequireCenterAdmin>

          <Grid
            container
            className={classes.headContainer}
            display="flex"
            justifyContent="space-between"
          >
            <Grid item xs={3}>
              <Box display="flex">
                <Typography className={classes.text}>{l1.username}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                display="flex"
                justifyContent="center"
                //style={{ right: -70, position: 'relative' }}
              >
                <Typography className={classes.text}>{l1.id}</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" justifyContent="center">
                <Typography className={classes.text}>{l1.email}</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" justifyContent="center">
                <Typography className={classes.text}>{l1.role}</Typography>
              </Box>
            </Grid>

            <Grid item xs={2}></Grid>
          </Grid>
          {membership.map(({ id, fullname, email, user, role }) => (
            <React.Fragment key={id}>
              <Box>
                <TableRow
                  name={fullname}
                  email={email}
                  id={id}
                  user={user}
                  role={role}
                />
              </Box>
            </React.Fragment>
          ))}
        </Box>
        <ConfirmationDialog {...dialogProps} />
      </Box>
    );
  }

  function TableRow({ name, email, id, user, role }) {
    const classes = useStyles();
    return (
      <Box className={classes.container}>
        <Grid container>
          <Grid item xs={3}>
            <Box
              className={classes.nameContainer}
              display="flex"
              flexDirection="row"
            >
              <Avatar className={classes.avatar} />
              <Box className={classes.nameText}>
                <Typography className={classes.nameName}>{name}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.idId} align="center">
              {id}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.address} align="center">
              {email}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.address} align="center">
              <RequireCenterAdmin
                only="owner"
                rejectBehaviour="message"
                message={l1[role]}
              >
                {role === "owner" ? (
                  l1[role]
                ) : (
                  <EditableChoice
                    choices={["moderator", "assistant"].map((value) => ({
                      value,
                      label: l1[value],
                    }))}
                    initialChoice={role ?? 'moderator'}
                    onChange={(newRole) =>
                      updateRole({
                        variables: {
                          id,
                          role: newRole,
                        },
                      })
                    }
                  />
                )}
              </RequireCenterAdmin>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="row" gridGap="16px">
              <RequireCenterAdmin only="owner">
                {role !== "owner" && (
                  <>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        confirmationDialog({
                          title: l1.setAsOwner,
                          content: l1.confirmation,
                          onConfirm: () => {
                            updateCenterOwner({
                              variables: {
                                centerId: centerId,
                                ownerId: user.id,
                              },
                            });
                          },
                        });
                      }}
                    >
                      {l1.setAsOwner}
                    </Button>

                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        confirmationDialog({
                          title: l1.removeFromStaff,
                          content: l1.confirmation,
                          onConfirm: () => {
                            updateRole({
                              variables: {
                                id,
                                role: "member",
                              },
                            });
                          },
                        });
                      }}
                    >
                      {l1.removeFromStaff}
                    </Button>
                  </>
                )}
              </RequireCenterAdmin>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default Staff;
