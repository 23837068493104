import { Divider } from "antd";
import { useContext } from "react";
import { FormContext, FormDefinition } from "./context";
import FormField from "./FormField";
import FormSection from "./FormSection";

export default function FormLayout({ root = null, level = 0 }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const form = useContext(FormDefinition);
  const { mode } = useContext(FormContext);
  root = root ?? form.views[mode].layout;
  return root.map((element, index) => {
    if (element.type === "separator") {
      return <Divider key={index} />;
    } else if (element.type === "section") {
      return (
        <FormSection
          key={index}
          level={level + 1}
          title={element.singleField?.label || element.label}
        >
          {element.singleField ? (
            <FormField {...element.singleField} singleField />
          ) : (
            <>
              <FormLayout
                root={element.children}
                level={level + 1}
                key={index}
              />
            </>
          )}
        </FormSection>
      );
    } else {
      return <FormField {...element} key={index} />;
    }
  });
}
