import { Box, Button, Divider, Grid, Table, TableBody, Typography } from "@material-ui/core";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { object } from "yup";
import Loading from "../common/Loading";
import TopBar from "../common/TopBar";
import EvaluationResult from "./EvaluationResult";
import EvaluationStatistics from "./EvaluationStatistics";
import EvaluationTextField from "./TextField";
import { useAssignment } from "./context/assignment";
import { useStudentWork } from "./context/studentWork";
import { getInitalValue, getValidationRule } from "./questionFormats";
import useEvaluationSubject from "./useEvaluationSubject";
import { l1 } from "../local.json";
import { useStudentWorks } from "./context/studentWorks";
import aggregate from "./aggregate";
import EvaluationList from "./EvaluationList";
export default function Evaluation({subject}){
  const { studentWorkId } = useParams();
  const assignment = useAssignment();
  const {key: subjectKey, EvaluationTable, layout, questions, resultLabels, getPageTitle, useCheckPermissions, listStudents} = useEvaluationSubject(subject);
  const {studentWork, updateStudentWork} = useStudentWork();
  const evaluation = studentWork?.submission?.[subjectKey];

  const studentworks = useStudentWorks();
  const aggregatedData = aggregate(studentworks?.map((work) => work.submission?.[subjectKey]), questions);
  const mode = aggregatedData?.expected ? 'summary' : (evaluation ? 'view' : 'fill');
  useCheckPermissions(mode);
  
  const formikConfig = {
    initialValues: {
      ...Object.fromEntries(Object.keys(questions).map(questionId => ([questionId, mode === "summary" ? aggregatedData?.[questionId] : evaluation?.[questionId] ?? getInitalValue(questions[questionId])]))),
    },
    validationSchema: object().shape({
      ...Object.fromEntries(Object.entries(questions).map(([questionId, question]) => ([questionId, getValidationRule(question)]))),
    }),
    onSubmit(evaluation){
      updateStudentWork({
        variables: {
          studentWorkId,
          submission: {
            ...studentWork.submission,
            [subjectKey]: evaluation,
          }
        }
    })
    },
    enableReinitialize: true,
  }
  return <>
    <TopBar />
    <Formik {...formikConfig}>
    {({isSubmitting, handleSubmit, values, isValid, errors}) =>{
      const resultsBlock = resultLabels && <EvaluationResult
        total={Object.keys(questions).reduce((prev, cur) => prev + (questions[cur].type === 'number' ? values[cur] : 0), 0)}
        max={Object.keys(questions).reduce((prev, cur) => prev + (questions[cur].type === 'number' ? questions[cur].max : 0), 0)}
        labels={resultLabels}
      />
      return (
        isSubmitting
        ? <Loading/>
        : <Grid container style={{marginTop: 32, marginBottom: 60}}>
          <Grid item xs={1} md={3}></Grid>
          <Grid item xs={10} md={6}>
            <Typography variant="h4" color="primary">
              {assignment.title}
            </Typography>
            <Divider style={{marginBlock: 20}}/>
            <Box>
              <Typography variant="h6">
                <b>{l1.evaluationsCoach}:</b> {assignment?.evaluationsInfo?.coach}
              </Typography>
              <Typography variant="h6" >
                <b>{l1.evaluationsDate}:</b> {assignment?.evaluationsInfo?.date}
              </Typography>
              <Typography variant="h6">
                <b>{l1.evaluationsLocation}:</b> {assignment?.evaluationsInfo?.location}
              </Typography>
            </Box>


            {mode === 'summary' && listStudents && <EvaluationList subjectKey={subjectKey}/>}

            <Divider style={{marginBlock: 20}}/>
            
            <Typography component="h1" variant="h3" color="primary">
              {getPageTitle(mode)}
            </Typography>
            {mode !== 'fill' && <>
              {mode === "summary" && <EvaluationStatistics submitted={aggregatedData.submitted} expected={aggregatedData.expected} />}
              {resultsBlock}
            </>
            }
            <form onSubmit={(e)=>{e.preventDefault()}}>
              {console.log(layout)}
              {layout.map((section, index) => 
                <Box key={index} mt={5}>
                  { (section?.label ?? questions?.[section?.singleQuestion]?.label)?.length && <Typography variant="h4">{index + 1}. {section?.label ?? questions?.[section?.singleQuestion]?.label}</Typography> }
                  {
                    section?.table ?? true
                      ? <Table style={{marginTop: 20}}>
                          <EvaluationTable.Head />
                          <EvaluationTable.Footer section={section}/>
                          <TableBody>
                            {section.questions.map((id, index) => <EvaluationTable.Row id={id} index={index+1} key={id} mode={mode} />)}
                          </TableBody>
                        </Table>
                    : (section?.singleQuestion ? [section.singleQuestion] : section.questions).map((id) => (
                      <Box mt={3} key={id}>
                        
                        <EvaluationTextField id={id} label={section?.singleQuestion ? null : questions[id].label} mode={mode} long={questions[id]?.long}/>
                      </Box>
                    ))
                  }
                </Box>
              )}

              {mode === 'fill' && <>
                {isValid && resultsBlock}
                <Box mt={4}>
                  {!isValid && <Typography color="secondary">رجاءً راجع تقييمك أعلاه و قم بتصحيح الاخطاء التي فيه قبل المواصلة</Typography>}
                </Box>
                <Box mt={2}>
                  <Button disabled={!isValid} onClick={handleSubmit} color="primary" variant="contained">إنهاء التقييم</Button>
                </Box>
              </>}
            </form>
          
          </Grid>
          <Grid item xs={1} md={3}></Grid>
        </Grid>
      )
    }}
    </Formik>
  </>
}