import React , {useState} from 'react';
import Value from './Value'
import { Typography, TextField, Button } from "@material-ui/core";
import { l1 } from "../../../../components/local.json";



export default function Values(props){
//    const [values, setValues] = useState(props.values);
   const [name, setName] = useState(null);
   const [content, setContent] = useState(null);

   const addValue = ({name,content})=>{
       const newValue = {  name: name, content: content };
       props.setValues([...props.values, newValue]);
    }
    // const deleteValue = (id)=>{
    //     const remainingValues = props.values.filter(value => id !== value.id);
    //     props.setValues(remainingValues);
    // }
    const deleteValue = (index)=>{
        const remainingValues = props.values.filter((value,idx) => index !== idx);
        props.setValues(remainingValues);
    }
    const editValue = (index, {newName, newContent})=>{
        const editedValues = props.values.map((value,idx) => {
            // if this task has the same ID as the edited task
              if (index === idx) {
                //
                return {...value, name: newName , content: newContent}
              }
              return value;
            });
            props.setValues(editedValues);
    }
   const handleSubmit= (e) =>{
         e.preventDefault();
         addValue({name,content});
         setName("")
         setContent("")
       }
   const handleNameChange= (e)=>{
         setName(e.target.value);
     }
   const handleContentChange= (e)=>{
         setContent(e.target.value);
     }
    return(
        <>
        <form onSubmit={handleSubmit}>
            <Typography variant="h5" className="" htmlFor="new-value">
            هل تريد اضافة قيمة جديد؟
            </Typography>
            <div className="description">
                <TextField value={name || ''} placeholder={l1.value} onChange={handleNameChange} id="new-value" autoComplete="off" />
                <TextField value={content || ''} placeholder={l1.description} onChange={handleContentChange}  fullWidth id="new-content" autoComplete="off" />
            </div>
            <Button type="submit" variant="contained" size="large" color="primary">
                {l1.add}
            </Button>
        </form>
        {props?.values?.map((value,index) => (
            <Value key={index} index={index} name={value.name} content={value.content} deleteValue={deleteValue} editValue={editValue}/>
          ))}
        </>
        
    )
}