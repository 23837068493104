import { Radio, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { useField, } from "formik";
import { useHistory, useParams } from "react-router-dom";
import EvaluationSubject from "./EvaluationSubject";
import { MdOutlineCheckCircleOutline } from "react-icons/md";

const radio = (props) => ({
  type: 'number',
  format: 'radioNumber',
  min: 1,
  max: 5,
  aggregation: 'count',
  ...props,
})

const longText= (props) => ({
  type: 'text',
  long: true,
  aggregation: 'listStrings',
  ...props,
})

const TrainingEvaluation = {
  key: 'student',
  listStudents: true,
  permissions:{
    student: {
      view: ['own'],
    },
    teacher: {
      fill: ['own','others'],
      view: ['own','others','summary'],
    },
  },
  questions:{
    q1: radio({ label: "المواظبة على الحضور والمواعيد  المحاضرات" }),
    q2: radio({ label: "السلوك العام للمتدرب" }),
    q3: radio({ label: "المشاركة داخل قاعة التدريب" }),
    q4: radio({ label: "يلتزم بسياسات و إجراءات المركز" }),
    q5: radio({ label: "التحفيز الذاتي" }),
    q6: radio({ label: "المتدرب لطيف و إيجابي في عمله" }),
    q7: radio({ label: "المبادرة في تعلم الجديد" }),
    q8: radio({ label: "يقدم ملاحظات واقتراحات" }),
    q9: radio({ label: "يعمل بروح الفريق" }),
    q10: radio({ label: "مهارات الاتصال مع الآخرين" }),
    q11: radio({ label: "سرعة الفهم" }),
    q12: radio({ label: "استخدام للوقت" }),
    q13: radio({ label: "المبادرة" }),
    q14: radio({ label: "التكييف مع اسلوب التدريب" }),
    q15: radio({ label: "المظهر الخارجي اللائق" }),
    q16: radio({ label: "جودة العمل بشكل عام" }),
    q17: radio({ label: "يلتزم بتعليمات المدرب بإيجابية" }),
    strengthsAndWeaknesses:longText({ label:"مواطن القوة و الضعف لدى المتدرب من حيث ارتباطها بموضوع التدريب" }),
  },

  layout: [
    {
      label: 'المسؤوليات العامة للمتدرب',
      questions: ['q1', 'q2', 'q3', 'q4', 'q5', 'q6', 'q7', 'q8', 'q9'],
    },
    {
      label: 'تقييم المدرب',
      questions: ['q10', 'q11', 'q12', 'q13', 'q14', 'q15', 'q16', 'q17'],
    },
    {
      singleQuestion: 'strengthsAndWeaknesses',
      table: false,
    },
  ],
  
  usePostSubmit(){
    const {id, slug} = useParams();
    const history = useHistory();
    return function postSubmitAction(){
      history.push(`/class/${slug}/classwork/${id}/details`);
    }
  },

  EvaluationTable: {
    Head(){
      return (
        <TableHead>
          <TableRow>
            <TableCell component="th"><Typography color="secondary">م</Typography></TableCell>
            <TableCell component="th"><Typography color="secondary">عنصر التقييم</Typography></TableCell>
            {['ممتاز','جيد جدا', 'جيد', 'مقبول', 'ضعيف'].map(label => 
              <TableCell component="th" key={label} width={80} style={{textAlign: "center"}}><Typography color="secondary">{label}</Typography></TableCell>
            )}
          </TableRow>
        </TableHead>
      )
    },
    Row({id, index, mode = 'fill'}){
      const {label} = this.questions[id];
      const [props, _meta, helpers] = useField(id);
      return <TableRow>
        <TableCell width={100}><Typography><b>{index}</b></Typography></TableCell>
        <TableCell><Typography>{label}</Typography></TableCell>
          {[5,4,3,2,1].map(possibleValue => (
            <TableCell component="th" key={possibleValue} width={60} style={{textAlign:"center", verticalAlign:"middle"}} >
              { mode === 'summary'
                ? <Typography variant={props.value?.[possibleValue] ? "h5" : "body1"} color={props.value?.[possibleValue] ? "secondary" : undefined}><b>
                  {props.value?.[possibleValue] ?? 0}
                </b>
                </Typography>
                : mode === 'fill'
                ? <Radio name={id} value={possibleValue} checked={possibleValue === props.value} readOnly={mode !== 'fill'} color="secondary" onClick={()=>{helpers.setValue(possibleValue)}} onFocus={()=>{helpers.setValue(possibleValue)}} />
                : props.value === possibleValue && <Typography color="secondary"><b><MdOutlineCheckCircleOutline fontSize={24} /></b></Typography>
              }
            </TableCell>
          ))}
      </TableRow>
    }
  }
}

export default new EvaluationSubject(TrainingEvaluation);