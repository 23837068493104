import React, { useContext, useReducer, useState } from "react";
import { l1 } from "../../local.json";
import {
  Avatar,
  Box,
  Button,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import TopBar from "../../common/TopBar";
import { gql, useQuery, useMutation } from "@apollo/client";
import Loading from "../../common/Loading";
import { Navigation } from "../../../Context";
import { onError } from "apollo-link-error";

const useStyles = makeStyles(theme => ({
  highOrder: {
    margin: theme.spacing(10, 30)
  },
  headContainer: {
    //margin: theme.spacing(0, 10),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[100]}`,
    background: `${theme.palette.grey["50"]}30`,
    borderRadius: theme.spacing(2)
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  iconHead: {
    color: "#44566c",
    margin: theme.spacing(0, 1)
  },
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    background: "white",
    marginBottom: theme.spacing(1),
    cursor: "pointer"
  },
  avatar: {
    margin: theme.spacing(0, 1)
  },
  nameText: {},
  nameName: {
    color: "#44566c"
  },
  menuItem: {
    paddingRight: theme.spacing(10)
  },
  nameTitle: {
    color: "#44566c",
    fontSize: theme.spacing(1.4)
  },
  idId: {
    color: "#8697a8"
  },
  address: {
    color: "#44566c"
  },
  icon: {
    fontSize: theme.spacing(3),
    color: "#8697a8",
    marginLeft: theme.spacing(3)
  }
}));

const GET_STUDENTS = gql`
  query GetBannedList($id: ID!) {
    memberships(
      sort: "createdAt:desc"
      where: { status: "BANNED", center: $id }
    ) {
      id
      fullname
      email
    }
  }
`;

const UN_BAN_STUDENT = gql`
  mutation UnBanStudent($id: ID!) {
    updateMembership(input: { where: { id: $id }, data: { status: ACTIVE } }) {
      membership {
        id
        status
      }
    }
  }
`;

function Banned() {
  const { centerId } = useContext(Navigation);

  //Apollo
  const { loading, data, refetch } = useQuery(GET_STUDENTS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: centerId
    }
  });

  const [unBanStudent, { loading: loadingBan }] = useMutation(UN_BAN_STUDENT, {
    onCompleted: () => {
      console.log("user has been unbanned");
      refetch();
    },
    onError: err => {
      console.log(err);
    }
  });
  const classes = useStyles();
  if (loading || loadingBan) return <Loading />;
  else {
    const students = data?.memberships;
    return (
      <Box>
        <TopBar type="admin" tab="banned" />
        <Box className={classes.highOrder}>
          <Grid
            container
            className={classes.headContainer}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Box display="flex" alignItems="center" flexDirection="row">
                <Typography className={classes.text}>{l1.fullName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                //style={{ right: -70, position: 'relative' }}
              >
                <Typography className={classes.text}>{l1.id}</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" alignItems="center" flexDirection="row">
                <Typography className={classes.text}>{l1.email}</Typography>
              </Box>
            </Grid>

            <Grid item xs={1}></Grid>
          </Grid>
          {students.map(({ fullname, email, id }) => (
            <TableRow name={fullname} email={email} id={id} key={id} />
          ))}
        </Box>
      </Box>
    );
  }

  function TableRow({ name, email, id }) {
    // Options Logic
    const [anchorPost, setAnchorPost] = useState(null);
    const openPostMenu = event => {
      setAnchorPost(event.currentTarget);
    };
    const closePostMenu = () => {
      setAnchorPost(null);
    };
    // Confirmation Dialog Logic
    const [confirmationState, reverseConfirmationState] = useReducer(
      state => !state,
      false
    );
    const [confirmationBanState, reverseConfirmationBanState] = useReducer(
      state => !state,
      false
    );
    const classes = useStyles();
    return (
      <Box className={classes.container}>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Box
              className={classes.nameContainer}
              display="flex"
              alignItems="center"
              flexDirection="row"
            >
              <Avatar className={classes.avatar} />
              <Box className={classes.nameText}>
                <Typography className={classes.nameName}>{name}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.idId}>{id}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.address}>{email}</Typography>
          </Grid>

          <Grid item xs={1} alignItems="center" justifyContent="center">
            <Button
              className={classes.unBanButton}
              color="primary"
              variant="outlined"
              onClick={() => {
                unBanStudent({
                  variables: {
                    id
                  }
                });
              }}
            >
              {l1.unBan}
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
export default Banned;
