import Form from "./Form";

const Suggestion = {
  slug: "suggestion",
  name: "نموذج اقتراح / ملاحظة / شكوى",
  availableActions:{
    edit: () => false,
    view: (item, {user, membershipRole}) => membershipRole === 'owner' || item.author.id === user.id,
    delete: (_, {membershipRole}) => membershipRole === 'owner',
  },
  fields:{
    author:{
      label: 'صاحب الاقتراح / الملاحظة / الشكوى',
      type: 'user',
      permissions: 'autoFillFirst',
      valueFor: {
        edit: (_, context) => context.user,
        view: (value) => value,
      },
    },
    subject:{
      label: "الموضوع",
      type: "string",
      permissions: 'fillFirst'
    },
    to: {
      label: 'الجهة المعنية', 
      type: 'string',
      permissions: 'fillFirst'
    },
    details: {
      label: 'التفاصيل',
      type: "string",
      multiline: true,
      minRows: 5,
    }
  },
  layout:[
    {
      type: 'section',
      label: 'معلومات سريعة',
      children:[
        'author',
        'to',
        'subject',
      ]
    },
    {
      type: 'section',
      singleField: 'details'
    }
  ],
  views: {
    list: {
      title: "قائمة الرسائل",
      requiredFields: ["subject", "author", 'createdAt'],
      columns: [
        {
          name: "الموضوع",
          selector: ({ subject }) => <span color="red">{subject}</span>,
          searchable: true,
          sortable: true,
        },
        {
          name: "من طرف",
          selector: ({ author }) => author ? author?.fullname : '(غير محدد)',
          searchable: true,
          sortable: true,
        },
        {
          name: "بتاريخ",
          selector: ({ createdAt }) => createdAt ,
          searchable: true,
          sortable: true,
        },
      ],
    },
    add: {
      title: "رسالة جديدة",
    },
    view: {
      title: "اقتراح / ملاحظة / شكوى",
    },
  },

};

export default new Form(Suggestion);
