import { gql, useMutation, useQuery } from "@apollo/client";
import { Box, Button, Typography } from "@material-ui/core";
import { createContext, useContext, useState } from "react";
import DataTable from "react-data-table-component";
import { BiPlusCircle } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { Navigation } from "../../Context";
import ConfirmationDialog, {
  useConfirmationDialog,
} from "../../components/common/ConfirmationDialog";
import { FloatingAlertContext } from "../../components/common/FloatingAlert";
import Loading from "../../components/common/Loading";
import { l1 } from "../../components/local.json";
import capitalizeFirstLetter from "../../helpers/capitalizeFirstLetter";
import FormListPagination from "./FormListPagination";
import { FormDefinition } from "./context";
export const FormActionsContext = createContext();

function FormList() {
  // get form and center id from context
  const form = useContext(FormDefinition);
  const { centerId, user, membershipRole } = useContext(Navigation);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  // listing query
  const QUERY_LIST = gql`
    query {
      ${
        form.collectionName
      }(where: { center: "${centerId}"}, sort: "createdAt:desc") {
        id createdAt ${form.getSingleQuerySelection(form.views.list.requiredFields ?? [])}
      }
    }
  `;

  const { data, loading, error, refetch } = useQuery(QUERY_LIST);

  // delete form mutation
  const DELETE_MUTATION = gql`
    mutation deleteItem($id: ID!) {
      delete${capitalizeFirstLetter(
        form.itemName
      )}(input: { where: { id: $id } }) {
        ${form.itemName} {
          id
        }
      }
    }
  `;
  const [deleteItem, { loading: deleting, error: deleteError }] = useMutation(
    DELETE_MUTATION,
    {
      onCompleted(data) {
        form.postMutation.deleted(data);
        floatingAlert({
          message: "تم حذف الاستمارة بنجاح!",
          severity: "success",
        });
        refetch();
      },
      onError: (error) => {
        floatingAlert({
          message:
            "حصل خطأ أثناء محاولة حذف الاستمارة. رجاءً أعد المحاولة لاحقًا",
          severity: "error",
        });
      },
    }
  );
  const { dialogProps, confirmationDialog } = useConfirmationDialog();
  const floatingAlert = useContext(FloatingAlertContext);
  const history = useHistory();
  const availableItems = data?.[form.collectionName]?.filter((item)=> form.availableActions.view(item, {user, membershipRole})) ?? []
  return loading ? (
    <Loading />
  ) : (
    <Box my={3}>
      <Button
        color="primary"
        variant="contained"
        startIcon={<BiPlusCircle />}
        style={{ fontWeight: 700, marginBottom: 20 }}
        onClick={() => {
          history.push(form.routes.add.path);
        }}
      >
        {l1.add}
      </Button>
      <FormActionsContext.Provider
        value={{
          form,
          refetch,
          confirmationDialog,
          deleteItem,
          deleting,
          deleteError,
          data: availableItems,
        }}
      >
        <DataTable
          columns={form.views.list.columns}
          data={availableItems}
          keyField="id"
          pagination
          paginationPerPage={itemsPerPage}
          onChangeRowsPerPage={async (perPage) => {
            await setItemsPerPage(perPage);
          }}
          paginationComponent={FormListPagination}
          onChangePage={(page) => {
            setPage(page);
          }}
          striped
          highlightOnHover
          noDataComponent={
            <Typography align="center">لا توجد أي نتائج</Typography>
          }
          customStyles={{
            rows: {
              style: {
                minHeight: 64, // override the row height
              },
            },
            headCells: {
              style: {
                fontWeight: 700,
                fontSize: 18,
                paddingLeft: 8, // override the cell padding for head cells
                paddingRight: 8,
              },
            },
            cells: {
              style: {
                fontSize: 18,
                paddingLeft: 8, // override the cell padding for data cells
                paddingRight: 8,
              },
            },
          }}
        />
      </FormActionsContext.Provider>
      <ConfirmationDialog {...dialogProps} />
    </Box>
  );
}

FormList.propTypes = {};

export default FormList;
