import React, { useContext, useReducer } from "react";
import { l1 } from "../../local.json";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { gql, useMutation } from "@apollo/client";
import Loading from "../../common/Loading";
import { useHistory } from 'react-router-dom';
import { Navigation } from "../../../Context";

const useStyles = makeStyles(theme => ({
  container: {},
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(4)
  },
  title: {
    fontSize: theme.spacing(3),
    fontWeight: 600,
    color: theme.palette.grey[700]
  },
  label: {
    marginLeft: theme.spacing(4)
  },
  button: {
    margin: theme.spacing(2, 30)
  },
  text: {
    fontSize: theme.spacing(3),
    fontWeight: 600,
    color: theme.palette.primary.main
  }
}));

const SEND_TO_WAITING_LIST = gql`
  mutation SendToWaitingList(
    $fullname: String!
    $birthDate: Date!
    $school: String!
    $college: String!
    $specialty: String!
    $address: String!
    $homeTel: String!
    $mobileTel: String!
    $object: String!
    $field: String!
    $hobbies: String!
    $afterGraduate: String!
    $email: String!
    $center: ID!
    $user: ID!
  ) {
    createMembership(
      input: {
        data: {
          fullname: $fullname
          birthDate: $birthDate
          school: $school
          college: $college
          specialty: $specialty
          address: $address
          homeTel: $homeTel
          mobileTel: $mobileTel
          object: $object
          field: $field
          hobbies: $hobbies
          email: $email
          afterGraduate: $afterGraduate
          center: $center
          user: $user
          role: "member",
          status: REQUESTED
        }
      }
    ) {
      membership {
        id
      }
    }
  }
`;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(l1.validEmail)
    .required(l1.emailRequired),
  fullname: Yup.string().required(l1.fieldRequired),
  birthDate: Yup.string().required(l1.fieldRequired),
  school: Yup.string().required(l1.fieldRequired),
  college: Yup.string().required(l1.fieldRequired),
  specialty: Yup.string().required(l1.fieldRequired),
  address: Yup.string().required(l1.fieldRequired),
  homeTel: Yup.string().required(l1.fieldRequired),
  mobileTel: Yup.string().required(l1.fieldRequired),
  object: Yup.string().required(l1.fieldRequired),
  field: Yup.string().required(l1.fieldRequired),
  hobbies: Yup.string().required(l1.fieldRequired),
  afterGraduate: Yup.string().required(l1.fieldRequired)
});
function MembershipForm() {
  const { centerId, user } = useContext(Navigation);

  const history = useHistory();
  const [state, reverseState] = useReducer(s => !s, false);

  // Apollo
  const [SendToWaitingList, { loading }] = useMutation(SEND_TO_WAITING_LIST, {
    onError: err => console.log("SEND_TO_WAITING_LIST", err),
    onCompleted: reverseState
  });

  const classes = useStyles();
  if (loading) return <Loading />;
  return (
    <Grid container className={classes.container}>
      <Grid item md={3}></Grid>
      <Grid item md={6}>
        <Formik
          initialValues={{
            fullname: "",
            birthDate: "",
            school: "",
            college: "",
            specialty: "",
            address: "",
            homeTel: "",
            email: "",
            mobileTel: "",
            object: "",
            field: "",
            hobbies: "",
            afterGraduate: ""
          }}
          onSubmit={values => {
            console.log("val", values);
            SendToWaitingList({
              variables: {
                fullname: values.fullname,
                birthDate: values.birthDate,
                school: values.school,
                college: values.college,
                specialty: values.specialty,
                address: values.address,
                homeTel: values.homeTel,
                mobileTel: values.mobileTel,
                object: values.object,
                field: values.field,
                hobbies: values.hobbies,
                email: values.email,
                afterGraduate: values.afterGraduate,
                center: centerId,
                user: user.id
              }
            });
          }}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleBlur, values, handleChange, errors, handleSubmit }) => (
            <Paper className={classes.paper} variant="outlined">
              <Box display="flex" justifyContent="center">
                <Typography className={classes.title}>
                  {l1.formTitle}
                </Typography>
              </Box>
              <form
                className={classes.form}
                onSubmit={e => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={l1.fullName}
                  name="fullname"
                  autoFocus
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fullname}
                  // defaultValue={values.fullname}
                  error={Boolean(errors.fullname)}
                  helperText={errors.fullname}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={l1.birthDate}
                  name="birthDate"
                  autoFocus
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.birthDate}
                  // defaultValue={values.birthDate}
                  error={Boolean(errors.birthDate)}
                  helperText={errors.birthDate}
                  type="date"
                  InputLabelProps={{ className: classes.label }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={l1.school}
                  name="school"
                  autoFocus
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.school}
                  // defaultValue={values.school}
                  error={Boolean(errors.school)}
                  helperText={errors.school}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={l1.college}
                  name="college"
                  autoFocus
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.college}
                  // defaultValue={values.college}
                  error={Boolean(errors.college)}
                  helperText={errors.college}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={l1.specialities}
                  name="specialty"
                  autoFocus
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.specialty}
                  // defaultValue={values.specialty}
                  error={Boolean(errors.specialty)}
                  helperText={errors.specialty}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={l1.address}
                  name="address"
                  autoFocus
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  // defaultValue={values.address}
                  error={Boolean(errors.address)}
                  helperText={errors.address}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={l1.homeTel}
                  name="homeTel"
                  autoFocus
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.homeTel}
                  // defaultValue={values.homeTel}
                  error={Boolean(errors.homeTel)}
                  helperText={errors.homeTel}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={l1.tel}
                  name="mobileTel"
                  autoFocus
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mobileTel}
                  // defaultValue={values.mobileTel}
                  error={Boolean(errors.mobileTel)}
                  helperText={errors.mobileTel}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={l1.email}
                  name="email"
                  autoFocus
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  // defaultValue={values.email}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={l1.q1}
                  name="object"
                  autoFocus
                  rows={6}
                  multiline
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.object}
                  // defaultValue={values.object}
                  error={Boolean(errors.object)}
                  helperText={errors.object}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={l1.q2}
                  name="field"
                  autoFocus
                  rows={6}
                  multiline
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.field}
                  // defaultValue={values.field}
                  error={Boolean(errors.field)}
                  helperText={errors.field}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={l1.q3}
                  name="hobbies"
                  autoFocus
                  rows={6}
                  multiline
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.hobbies}
                  // defaultValue={values.hobbies}
                  error={Boolean(errors.hobbies)}
                  helperText={errors.hobbies}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={l1.q4}
                  name="afterGraduate"
                  autoFocus
                  rows={6}
                  multiline
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.afterGraduate}
                  // defaultValue={values.afterGraduate}
                  error={Boolean(errors.afterGraduate)}
                  helperText={errors.afterGraduate}
                />
                <Box
                  display="flex"
                  justifyContent="center"
                  className={classes.button}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    {l1.confirm}
                  </Button>
                </Box>
              </form>
            </Paper>
          )}
        </Formik>
      </Grid>
      <Grid item md={3}></Grid>
      <Dialog className={classes.dialog} open={state} onClose={reverseState}>
        <DialogContent>
          <Typography className={classes.text}>{l1.submitted}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.reload()
            }}
          >
            {l1.continue}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default MembershipForm;
