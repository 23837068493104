import React, { useContext, useReducer, useState } from 'react';
import { l1 } from '../../local.json';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import TopBar from '../../common/TopBar';
import { gql, useQuery, useMutation } from '@apollo/client';
import Loading from '../../common/Loading';
import { Navigation } from '../../../Context';
import TopicSection from '../class/components/TopicSection';

const useStyles = makeStyles((theme) => ({
  highOrder: {
    margin: theme.spacing(10, 30),
  },
  headContainer: {
    //margin: theme.spacing(0, 10),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[100]}`,
    background: `${theme.palette.grey['50']}30`,
    borderRadius: theme.spacing(2),
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  iconHead: {
    color: '#44566c',
    margin: theme.spacing(0, 1),
  },
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    background: 'white',
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
  },
  avatar: {
    margin: theme.spacing(0, 1),
  },
  nameText: {},
  nameName: {
    color: '#44566c',
  },
  menuItem: {
    paddingRight: theme.spacing(10),
  },
  nameTitle: {
    color: '#44566c',
    fontSize: theme.spacing(1.4),
  },
  idId: {
    color: '#8697a8',
  },
  address: {
    color: '#44566c',
  },
  icon: {
    fontSize: theme.spacing(3),
    color: '#8697a8',
    marginLeft: theme.spacing(3),
  },
}));

const GET_TEACHERS = gql`
  query GetTeachers($id: ID!) {
    centersList(id: $id) {
      classes(where: { isArchived: false }) {
        id
        name
        slug
        teachers {
          id
          fullname
          email
        }
      }
    }
  }
`;
const DELETE_TEACHER = gql`
  mutation DeleteTeacher($id: ID!, $data: editClassInput!) {
    updateClass(input: { data: $data, where: { id: $id } }) {
      class {
        id
      }
    }
  }
`;

function Teachers() {
  const { centerId } = useContext(Navigation);

  //Apollo
  const { loading, data, refetch } = useQuery(GET_TEACHERS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: centerId,
    },
  });
  const [deleteTeacher, { loading: loadingDelete }] = useMutation(DELETE_TEACHER, {
    onCompleted: () => refetch(),
  });
  const classes = useStyles();
  if (loading || loadingDelete) return <Loading />;
  else {
    const centerClasses = data?.centersList?.classes;
    return (
      <Box>
        <TopBar type="admin" tab="teachers" />
        <Box className={classes.highOrder}>
          {centerClasses.map((i) => (
            <TopicSection key={i.id} text={i.name}>
              <Grid container className={classes.headContainer} display="flex">
                <Grid item xs={4}>
                  <Box display="flex" alignItems="center" flexDirection="row">
                    <Typography className={classes.text}>{l1.username}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    //style={{ right: -70, position: 'relative' }}
                  >
                    <Typography className={classes.text}>{l1.id}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex" alignItems="center" flexDirection="row">
                    <Typography className={classes.text}>{l1.email}</Typography>
                  </Box>
                </Grid>

                <Grid item xs={1}></Grid>
              </Grid>
              {i.teachers.map(({ fullname, email, id }) => (
                <TableRow name={fullname} email={email} data={i} id={id} key={id} />
              ))}
            </TopicSection>
          ))}
        </Box>
      </Box>
    );
  }

  function TableRow({ name, email, id, data }) {
    // Options Logic

    // Confirmation Dialog Logic
    const [confirmationState, reverseConfirmationState] = useReducer((state) => !state, false);
    const [confirmationBanState, reverseConfirmationBanState] = useReducer((state) => !state, false);
    const classes = useStyles();
    return (
      <Box className={classes.container}>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <Box className={classes.nameContainer} display="flex" alignItems="center" flexDirection="row">
              <Avatar className={classes.avatar} />
              <Box className={classes.nameText}>
                <Typography className={classes.nameName}>{name}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.idId}>{id}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.address}>{email}</Typography>
          </Grid>

          <Grid item xs={1} alignItems="center" justifyContent="center">
            <Button color="secondary" variant="outlined" onClick={reverseConfirmationState}>
              {l1.delete}
            </Button>
          </Grid>
        </Grid>

        <Dialog open={confirmationState} onClose={reverseConfirmationState}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <Typography style={{ fontWeight: 'bold' }}>{l1.confirmation}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                const remove = data.teachers.filter((i) => i.id !== id);
                const arr = [];
                remove.forEach((i) => arr.push(i.id));

                deleteTeacher({
                  variables: {
                    id: data.id,
                    data: { teachers: arr },
                  },
                });
              }}
            >
              {l1.confirm}
            </Button>
            <Button variant="contained" onClick={reverseConfirmationState}>
              {l1.cancel}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}
export default Teachers;
