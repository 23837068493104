export const similarityScore = (reference, source) => {
  /**
   * compares properties of two objects, and returns a score of similarity between 0 and 1.
   */
  const keys = Object.keys(reference)
  const maxScore = keys.length
  const score = keys.reduce((prev, key) => prev + (JSON.stringify(reference[key]) === JSON.stringify(source[key]) ? 1 : 0), 0)
  return score / maxScore
}

export const staticUrl = path => process.env.REACT_APP_STRAPI_BACKEND_URL + path 
  