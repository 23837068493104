import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Typography,
  Container
} from "@material-ui/core";

import TopBar from "./common/TopBar";
import "moment/locale/ar-dz";
import Loading from "./common/Loading";
import { useQuery, gql } from "@apollo/client";
import { l1 } from "./local.json";
import { useHistory } from 'react-router-dom';
import { Navigation } from "../Context";
import { BiCalendarAlt } from "react-icons/bi";
import SchoolIcon from "@material-ui/icons/School";
import TopicRow from "./rel/class/components/TopicRow";
import TopicSection from "./rel/class/components/TopicSection";
const QUERY_CLASSWORK = gql`
  query teacherClassworks($id: ID!) {
    classes(where: { teachers: $id , isArchived:false}) {
      id
      slug
      assignments(where: { type_ne: "material" }) {
        id
        title
        description
        type
        studentWorks {
          status
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  Typography: {
    fontFamily: "Cairo"
  }
}));
function Review() {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(Navigation);
  const { loading, data } = useQuery(QUERY_CLASSWORK, {
    variables: { id: user.id },
    onComplete: response => {
      console.log("response", response);
    },
    onError: error => console.log("QueryClassworkError: ", error),
    notifyOnNetworkStatusChange: true
  });
  const groupStudentWorks = works => {
    const groups = {
      all: works,
      assigned: works.filter(work => work.status === "assigned"),
      submitted: works.filter(work => work.status === "submitted"),
      graded: works.filter(work => work.status === "graded")
    };
    return {
      statistics: {
        all: works.length,
        assigned: groups.assigned.length,
        submitted: groups.submitted.length,
        graded: groups.graded.length
      },
      groups
    };
  };

  console.log("data", data);
  if (loading) return <Loading />;
  else {
    const myClasses = data.classes
    // const { assignments, slug } = data.classes;
    return (
      <>
        <TopBar empty />
        <Container style={{ fontFamily: classes.Typography }}>
          <Grid container>
            <Grid item xs={12} sm={10} md={10}>
              <Box className={classes.mainContainer}>
                <Box className={classes.topSection} display="flex">
                  <Button
                    size="large"
                    className={classes.calendar}
                    endIcon={<BiCalendarAlt />}
                    onClick={() => history.push("/calendar")}
                  >
                    {l1.calendar}
                  </Button>
                  <Button
                    endIcon={<SchoolIcon />}
                    onClick={() => history.push("/home")}
                  >
                    الأقسام
                  </Button>
                </Box>
                {!myClasses.length ? (
                  <Box
                    className={classes.topicTitleContainer}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h2" style={{ color: "grey" }}>
                      {l1.empty}
                    </Typography>
                  </Box>
                ) : (
                  <TopicSection text="للمراجعة" scope="review">
                    <Typography variant="body1" component="span">
                      امتحانات واسئلة للتصحيح
                    </Typography>
                    {
                    myClasses.map(
                      ({assignments,slug})=>(
                      assignments.map(
                        ({
                          title,
                          description,
                          type,
                          id,
                          createdAt,
                          studentWorks
                        }) => {
                          let statistics = groupStudentWorks(studentWorks)
                            .statistics;
                          let link =
                            "/class/" + slug + "/classwork/" + id + "/answers";
  
                          return (
                            <TopicRow
                              title={title}
                              description={description}
                              type={type}
                              key={id}
                              id={id}
                              createdAt={createdAt}
                              statistics={statistics}
                              link={link}
                              slug={slug}
                              scope="review"
                            />
                          );
                        }
                      ))
                    )}

                  </TopicSection>
                )}
              </Box>
            </Grid>
            <Grid item sm={3}></Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

export default Review;
