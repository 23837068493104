export default function sparseUpdate(obj, ...updates) {
  updates.forEach((props) => {
    for (let prop in props) {
       if (typeof props[prop] === "object" && !Array.isArray(props[prop]) && props[prop] !== null) {
        if (!obj.hasOwnProperty(prop)) {
          obj[prop] = {};
        }
        sparseUpdate(obj[prop], props[prop]);
      } else {
        obj[prop] = props[prop];
      }
    }
  })
  return obj;
}
