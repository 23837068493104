import React, { useContext, useReducer, useRef, useState } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Divider,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Button
} from "@material-ui/core";

// Icons
import { MdTrendingUp, MdFolderOpen } from "react-icons/md";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Due from "./Due";
import { l1 } from "../local.json";
import { Link } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { ClassAnchor, Navigation } from "../../Context";
import Loading from "./Loading";
import { RequireClassAccess } from "../../../src/Auth/gates";
const useStyles = makeStyles(theme => ({
  root: {
    height: theme.spacing(40),
    backgroundColor: "#fff",
    border: "1px solid #dadce0",
    borderRadius: theme.spacing(1.5),
    margin: theme.spacing(0, 3, 6, 3)
  },
  backgroundContainer: {
    height: theme.spacing(13),
    borderTopLeftRadius: theme.spacing(1.5),
    borderTopRightRadius: theme.spacing(1.5),
    backgroundImage: 'url("https://gstatic.com/classroom/themes/Physics.jpg")',
    backgroundSize: "cover"
  },
  background: {
    height: "inherit"
  },
  optionContainer: {},
  optionIcon: {
    color: "white",
    fontSize: theme.spacing(3),
    margin: theme.spacing(2, 2, 1, 0.5),
    cursor: "pointer"
  },
  titleContainer: {
    width: "min-content",
    margin: theme.spacing(2),
    flex: 1
  },
  cardTitle: {
    textDecoration: "none",
    color: theme.palette.common.white
  },
  dueContainer: { flexGrow: 1, padding: 0 },
  bottom: {
    height: "min-content",
    fontSize: theme.spacing(3),
    margin: theme.spacing(1),
    "& > *": {
      margin: theme.spacing(0, 1.5),
      color: theme.palette.grey[700]
    }
  },
  options: {
    fontSize: theme.spacing(3.3),
    margin: theme.spacing(2),
    cursor: "pointer",
    color: "white"
  },
  menuItem: {
    paddingRight: theme.spacing(10)
  },
  muiAlert: {
    width: "max-content"
  }
}));

const EDIT_CLASS = gql`
  mutation EditClass(
    $name: String!
    $section: String
    $slug: String!
    $subject: String
    $room: String
    $id: ID!
  ) {
    updateClass(
      input: {
        where: { id: $id }
        data: {
          name: $name
          section: $section
          slug: $slug
          subject: $subject
          room: $room
        }
      }
    ) {
      class {
        name
        section
        slug
      }
    }
  }
`;

const CLONE_CLASS = gql`
  mutation CreateClass(
    $name: String!
    $section: String!
    $subject: String!
    $slug: String!
    $center: ID!
  ) {
    createClass(
      input: {
        data: {
          name: $name
          section: $section
          subject: $subject
          slug: $slug
          center: $center
        }
      }
    ) {
      class {
        name
        id
        section
        slug
        isArchived
        center {
          id
        }
      }
    }
  }
`;

const ARCHIVE_CLASS = gql`
  mutation ArchiveClass($id: ID!) {
    updateClass(input: { where: { id: $id }, data: { isArchived: true } }) {
      class {
        isArchived
      }
    }
  }
`;

const ClassCard = ({ classData, refetch, alert, failure }) => {
  const { centerId } = useContext(Navigation);

  // Apollo
  const [editClass, { loading: loadingEditClass }] = useMutation(EDIT_CLASS, {
    onError: () => failure(l1.failure),
    onCompleted: () => {
      refetch();
      alert(l1.editsuccess);
    }
  });
  const [cloneClass, { loading: loadingCloneClass }] = useMutation(
    CLONE_CLASS,
    {
      onError: () => failure(l1.failure),
      onCompleted: data => {
        console.log("class clone", data);
        refetch();
        alert(l1.clonesuccess);
      }
    }
  );
  const [archiveClass, { loading: loadingArchiveClass }] = useMutation(
    ARCHIVE_CLASS,
    {
      onError: () => failure(l1.failure),
      onCompleted: () => {
        refetch();
        alert(l1.archivesuccess);
      }
    }
  );
  // Edit Class Dialog Logic
  const [editClassState, reverseEditClassState] = useReducer(
    state => !state,
    false
  );
  // Clone Class Dialog Logic
  const [cloneClassState, reverseCloneClassState] = useReducer(
    state => !state,
    false
  );
  // Edit & clone Class Mutation Logic
  const [name, setName] = useState(classData.name);
  const nameRef = useRef();
  const [section, setSection] = useState(classData.section);
  const sectionRef = useRef();
  const [subject, setSubject] = useState(classData.subject);
  const subjectRef = useRef();

  const [slug, setSlug] = useState(classData.slug);
  const slugRef = useRef();

  // Options Menu Logic
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  // Confirmation Dialog Logic
  const [confirmationState, reverseConfirmationState] = useReducer(
    state => !state,
    false
  );

  const { setClassAnchor } = useContext(ClassAnchor);
  const classes = useStyles();
  return loadingEditClass || loadingCloneClass || loadingArchiveClass ? (
    <Loading />
  ) : (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
      onClick={() => setClassAnchor(classData.id)}
    >
      <Box
        className={classes.backgroundContainer}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box className={classes.titleContainer}>
          <Typography variant="h5" className={classes.cardTitle}>
            <Link
              to={`class/${classData.slug}`}
              style={{ textDecoration: "none", color: "white" }}
              params={{ id: classData.id }}
            >
              {classData.name}
            </Link>
          </Typography>

          <Typography
            variant="body2"
            style={{ color: "white", textAlign: "right" }}
          >
            {classData.section}
          </Typography>
        </Box>
        <Box className={classes.optionContainer}>
          <RequireClassAccess
            slug={classData.slug}
            only="teacher"
            rejectBehaviour="hide"
          >
            <BiDotsVerticalRounded
              className={classes.options}
              onClick={openMenu}
            />
          </RequireClassAccess>
          <Menu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={closeMenu}
            elevation={1}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <MenuItem
              className={classes.menuItem}
              onClick={reverseEditClassState}
            >
              <Typography>{l1.edit}</Typography>
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={reverseCloneClassState}
            >
              <Typography>{l1.clone}</Typography>
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={reverseConfirmationState}
            >
              <Typography>{l1.archive}</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box className={classes.dueContainer}>
        <Due id={classData?.id} slug={classData?.slug} />
      </Box>
      <Divider />
      <Box className={classes.bottom}>
        <MdTrendingUp />
        <MdFolderOpen />
      </Box>
      {/* //Edit Class //////////////////////////////////////////////////////// */}
      <Dialog
        className={classes.CreateClassContainer}
        open={editClassState}
        onClose={reverseEditClassState}
      >
        <DialogTitle>{`${l1.edit} ${name}`}</DialogTitle>
        <DialogContent className={classes.contentContainer}>
          <TextField
            className={classes.textField}
            fullWidth
            label={l1.name}
            required
            defaultValue={name}
            inputRef={nameRef}
            onChange={e => {
              setName(nameRef.current.value);
            }}
          />
          <TextField
            className={classes.textField}
            inputRef={slugRef}
            fullWidth
            defaultValue={slug}
            label={l1.slug}
            required
            onChange={e => {
              setSlug(slugRef.current.value);
              console.log("slug", slugRef.current.value);
            }}
          />
          <TextField
            className={classes.textField}
            fullWidth
            defaultValue={section}
            inputRef={sectionRef}
            label={l1.section}
            onChange={e => {
              setSection(sectionRef.current.value);
            }}
          />
          <TextField
            className={classes.textField}
            defaultValue={subject}
            inputRef={subjectRef}
            fullWidth
            label={l1.subject}
            onChange={e => {
              setSubject(subjectRef.current.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              editClass({
                variables: {
                  id: classData.id,
                  name,
                  section,
                  subject,
                  slug
                }
              });

              reverseEditClassState();
            }}
          >
            {l1.edit}
          </Button>
          <Button variant="contained" onClick={reverseEditClassState}>
            {l1.cancel}
          </Button>
        </DialogActions>
      </Dialog>

      {/* //Clone Class //////////////////////////////////////////////////////// */}
      <Dialog
        className={classes.CreateClassContainer}
        open={cloneClassState}
        onClose={reverseCloneClassState}
      >
        <DialogTitle>{`${l1.clone} ${name}`}</DialogTitle>
        <DialogContent className={classes.contentContainer}>
          <TextField
            className={classes.textField}
            fullWidth
            label={l1.name}
            required
            defaultValue={name}
            inputRef={nameRef}
            onChange={e => {
              setName(nameRef.current.value);
            }}
          />
          <TextField
            className={classes.textField}
            inputRef={slugRef}
            fullWidth
            defaultValue={slug}
            label={l1.slug}
            required
            onChange={e => {
              setSlug(slugRef.current.value);
            }}
          />
          <TextField
            className={classes.textField}
            fullWidth
            defaultValue={section}
            inputRef={sectionRef}
            label={l1.section}
            onChange={e => {
              setSection(sectionRef.current.value);
            }}
          />
          <TextField
            className={classes.textField}
            defaultValue={subject}
            inputRef={subjectRef}
            fullWidth
            label={l1.subject}
            onChange={e => {
              setSubject(subjectRef.current.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              cloneClass({
                variables: {
                  center: centerId,
                  name,
                  section,
                  subject,
                  slug
                }
              });

              reverseCloneClassState();
            }}
          >
            {l1.clone}
          </Button>
          <Button variant="contained" onClick={reverseCloneClassState}>
            {l1.cancel}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmationState} onClose={reverseConfirmationState}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Typography style={{ fontWeight: "bold" }}>
            {l1.confirmation}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              archiveClass({
                variables: {
                  id: classData.id
                }
              });
            }}
          >
            {l1.confirm}
          </Button>
          <Button variant="contained" onClick={reverseConfirmationState}>
            {l1.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ClassCard;
