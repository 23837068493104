import Form from "./Form";

const TripFinancialEstimation = {
  slug: "trip-financial-estimation",
  name: "نموذج تقدير مصاريف وإيرادات الرحلات",
  fields:{
    name:{
      label: 'الرحلة',
      type: 'string',
    },
    numberOfPeople:{
      label: "عدد الأشخاص",
      type: 'number',
    },
    expenses:{
      label: "المصاريف",
      type:'table',
      columns: [
        {
          label: 'البيان',
          type: 'string',
          multiline: true,
          minRows: 1,
          aggregate: ()=> "الإجمالي",
        },
        {
          label: 'تكلفة الفرد',
          type: 'number',
        },
        {
          label: 'العدد',
          type: 'number',
        },
        {
          label: '$',
          type: 'calculated',
          calculate: ({rows, rowIndex}) => {
            const [value,quantity] = rows[rowIndex].slice(1,3).map(Number);
            return ([value, quantity].includes(NaN) ? 0 : value * quantity)
          },
          aggregate: ({rows}) => rows.reduce((sum, row) => {
            const [value,quantity] = row.slice(1,3).map(Number);
            return sum + ([value, quantity].includes(NaN) ? 0 : value * quantity)
          }, 0),
        },
        {
          label: 'ملاحظات',
          type: 'string',
          multiline: true,
          minRows: 1,
        },
      ],
      // aggregate: false if none of the columns has an aggregate function
    },
    revenue:{
      label: "الإيرادات",
      type:'table',
      columns: [
        {
          label: 'البيان',
          type: 'string',
          multiline: true,
          minRows: 1,
          aggregate: ()=> "الإجمالي",
        },
        {
          label: 'تكلفة الفرد',
          type: 'number',
        },
        {
          label: 'العدد',
          type: 'number',
        },
        {
          label: '$',
          type: 'calculated',
          calculate: ({rows, rowIndex}) => {
            const [value,quantity] = rows[rowIndex].slice(1,3).map(Number);
            return ([value, quantity].includes(NaN) ? 0 : value * quantity)
          },
          aggregate: ({rows}) => rows.reduce((sum, row) => {
            const [value,quantity] = row.slice(1,3).map(Number);
            return sum + ([value, quantity].includes(NaN) ? 0 : value * quantity)
          }, 0),
        },
        {
          label: 'ملاحظات',
          type: 'string',
          multiline: true,
          minRows: 1,
        },
      ],
      // aggregate: false if none of the columns has an aggregate function
    },
    averageCostPerPerson: {
      label: 'متوسط التكلفة للشخص',
      type: 'calculated',
      requiredFields: ['expenses', 'numberOfPeople'],
      calculate: ({expenses, numberOfPeople}) => numberOfPeople > 0
        ? expenses.reduce((sum, row) => {
            const [value,quantity] = row.slice(1,3).map(Number);
            return sum + ([value, quantity].includes(NaN) ? 0 : value * quantity)
          }, 0) / numberOfPeople
        : 0,
      permissions: 'readOnly',
    },
    totalRevenue:{
      label: 'إجمالي الإيرادات',
      type: 'calculated',
      requiredFields: ['revenue'],
      calculate: ({revenue}) => revenue.reduce((sum, row) => {
        const [value,quantity] = row.slice(1,3).map(Number);
        return sum + ([value, quantity].includes(NaN) ? 0 : value * quantity)
      }, 0),
      permissions: 'readOnly',
    },
    totalExpenses: {
      label: 'إجمالي المصاريف',
      type: 'calculated',
      requiredFields: ['expenses'],
      calculate: ({expenses}) => expenses.reduce((sum, row) => {
        const [value,quantity] = row.slice(1,3).map(Number);
        return sum + ([value, quantity].includes(NaN) ? 0 : value * quantity)
      }, 0),
      permissions: 'readOnly',
    },
    remainingExpenses: {
      label: 'المصاريف المتبقية بعد خصم الإيرادات',
      type: 'calculated',
      requiredFields: ['revenue', 'expenses'],
      calculate: ({expenses, revenue}) => (
        expenses.reduce((sum, row) => {
          const [value,quantity] = row.slice(1,3).map(Number);
          return sum + ([value, quantity].includes(NaN) ? 0 : value * quantity)
        }, 0)
        - revenue.reduce((sum, row) => {
          const [value,quantity] = row.slice(1,3).map(Number);
          return sum + ([value, quantity].includes(NaN) ? 0 : value * quantity)
        }, 0)
      ),
      permissions: 'readOnly',
    },
  },
  layout:[
    'name',
    {
      type: 'section',
      label: 'المصاريف',
      children: [
        'expenses',
        'numberOfPeople',
        'averageCostPerPerson'
      ],
    },
    {
      type: 'section',
      singleField: 'revenue',
    },
    {
      type: 'section',
      label: 'النتيجة',
      children: [
        'totalRevenue',
        'totalExpenses',
        'remainingExpenses',
      ]
    }
  ],
  views: {
    list: {
      title: "قائمة الرحلات",
      requiredFields: ["name",  'createdAt'],
      columns: [
        {
          name: "الرحلة",
          selector: ({ name }) => <span color="red">{name}</span>,
          searchable: true,
          sortable: true,
        },
        {
          name: "أنشئ بتاريخ",
          selector: ({ createdAt }) => createdAt ,
          searchable: true,
          sortable: true,
        },
      ],
    },
    add: {
      title: "نموذج جديد",
    },
    edit: {
      title: "تعديل النموذج",
    },
    view: {
      title: "تقدير مصاريف و إيرادات الرحلة",
    },
  },
};

export default new Form(TripFinancialEstimation);
