import React, { useContext, useReducer } from "react";
import { l1 } from "./local.json";
import {
  Box,
  Button,
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import TopBar from "./common/TopBar";
import { Navigation } from "../Context";
import { useHistory } from "react-router-dom";
import ConfirmationDialog, {
  useConfirmationDialog,
} from "./common/ConfirmationDialog";
import { BallotOutlined, Group, GroupOutlined, Map, MoneyOutlined } from "@material-ui/icons";
import { BiDownload } from "react-icons/bi";
import { BusinessPlan, ComplaintAboutSuperior, EmployeeAbsence, EventPlan, MeetingSchedule, ProjectSheet, Suggestion, TrainingProgram, TripFinancialEstimation, TripOrMeetingReport, WarningLetter } from "../forms";
import { gql, useQuery } from "@apollo/client";
import Loading from "./common/Loading";
const useStyles = makeStyles((theme) => ({
  highOrder: {
    margin: theme.spacing(10, 30),
  },
  headContainer: {
    //margin: theme.spacing(0, 10),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[100]}`,
    background: `${theme.palette.grey["50"]}30`,
    borderRadius: theme.spacing(2),
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  iconHead: {
    color: "#44566c",
    margin: theme.spacing(0, 1),
  },
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    background: "white",
    marginBottom: theme.spacing(1),
  },
  avatar: {
    margin: theme.spacing(0, 1),
  },
  nameText: {},
  nameName: {
    color: "#44566c",
  },
  menuItem: {
    paddingRight: theme.spacing(10),
  },
  nameTitle: {
    color: "#44566c",
    fontSize: theme.spacing(1.4),
  },
  idId: {
    color: "#8697a8",
  },
  address: {
    color: "#44566c",
  },
  icon: {
    fontSize: theme.spacing(3),
    color: "#8697a8",
    marginLeft: theme.spacing(3),
  },
  title: {
    fontSize: theme.spacing(3),
    fontWeight: 600,
    color: theme.palette.grey[700],
  },
  label: {
    marginLeft: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(4),
  },
}));

const fileSections = [
  {
    name: "استمارات الترشح",
    roles: ["owner", "assistant"],
    Icon: BallotOutlined,
    files: [
      {
        name: "استمارة نموذج ترشيح العضوية مركز",
        type: "pdf",
        key: "memberNomination"
      },
      {
        name: "نموذج أسئلة و معايير الترشيح أثناء المقابلة",
        type: "pdf",
        key: "interviewQuestionsAndCriteria"
      },
    ],
  },
  {
    name: "المالية و التسويق",
    roles: ["owner", "assistant"],
    Icon: MoneyOutlined,
    files: [
      {
        name: "تقدير التكاليف المالية",
        type: "pdf",
        key: "costEstimation"
      },
      {
        name: "نموذج خطوات كتابة الخطة التسويقية",
        type: "pdf",
        key: "sampleMarketingPlan"
      },
    ],
  },
  {
    name: "ملفات الموظفين",
    Icon: GroupOutlined,
    files: [
      {
        name: "نموذج محضر اجتماع",
        type: "pdf",
        key: "meetingMinutes"
      },
      {
        name: "نموذج عقد عمل",
        type: "pdf",
        key: "employmentContract",
        roles: ['admin', 'assistant']
      },
      {
        name: "نموذج تقييم الاداء الوظيفي",
        type: "pdf",
        key: "jobPerformanceEvaluation"
      },
      {
        name: "ترشيح الموظف للدورة التدريبية",
        type: "pdf",
        key: "employeeNominationForTraining",
        roles: ['admin', 'assistant']
      },
      EmployeeAbsence,
      WarningLetter,
      Suggestion,
      ComplaintAboutSuperior,
    ],
  },
  {
    name: "الخطط",
    Icon: Map,
    files: [
      BusinessPlan,
      TrainingProgram,
      EventPlan,
      TripFinancialEstimation,
      MeetingSchedule,
      TripOrMeetingReport,
      ProjectSheet,
    ],
  },
];

const QUERY_FORMS = gql`
query{
  form{
    memberNomination {file {url}}
    interviewQuestionsAndCriteria{file {url}}
    costEstimation{file {url}}
    sampleMarketingPlan{file {url}}
    meetingMinutes{file {url}}
    employmentContract{file {url}}
    jobPerformanceEvaluation{file {url}}
    employeeNominationForTraining{file {url}}
  }
}
`
export default function Files() {
  const {loading, data, error} = useQuery(QUERY_FORMS,{});
  //Apollo
  return (
    error ? 'ERROR'
    : loading ? <Loading/>
    : <>
      <TopBar />

      <Container maxWidth="lg">
        <Box pt={4}>
          <Typography variant="h3" color="primary">
            الملفات
          </Typography>
          {fileSections.map((section, index) => (
            <FileSection key={index + 1} section={section} data={data} />
          ))}
        </Box>
      </Container>
    </>
  );
}

function FileSection({ section: { name, Icon, files}, data }) {
  const forms = data.form;
  const history = useHistory();
  return (
    <Box paddingY={4}>
      <Typography variant="h4" color="textSecondary">
        <Icon fontSize="large" />
        &nbsp;
        {name}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>اسم الملف</TableCell>
            <TableCell>النوع</TableCell>
            <TableCell>خيارات</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file, index) => (
            <TableRow key={index}>
              <TableCell>{file?.name}</TableCell>
              <TableCell>{l1[file.type] ?? file.type}</TableCell>
              <TableCell>
                {file.type !== "form" ? (
                  forms?.[file.key]?.file?.url
                  ? <Button
                      variant="contained"
                      color="primary"
                      href={`${process.env.REACT_APP_STRAPI_URL}${forms?.[file.key]?.file?.url ?? "#"}`}
                      target='_blank'
                    >
                      <BiDownload color="inherit" /> &nbsp;{l1.download}
                    </Button>
                  : <Typography color="secondary" variant="b">غير متوفر</Typography>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={()=>history.push(file.routes.list.path)}
                  >
                    تفاصيل
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
