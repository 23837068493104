import React, { useContext, useEffect, useState } from 'react';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import TopBar from './common/TopBar';
import moment from 'moment';
import 'moment/locale/ar-dz';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import Loading from './common/Loading';
import { useQuery, gql } from '@apollo/client';
import { l1 } from './local.json';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { Navigation } from '../Context';

const GET_DAY_ONE = gql`
  query GetDayOne($due: Date!, $centerId: ID!) {
    assignments(where: { center: $centerId, due: $due }) {
      title
      id
      type
      class {
        slug
      }
    }
  }
`;

const GET_DAY_TWO = gql`
  query GetDayTwo($due: Date!, $centerId: ID!) {
    assignments(where: { center: $centerId, due: $due }) {
      title
      id
      type
      class {
        slug
      }
    }
  }
`;
const GET_DAY_THREE = gql`
  query GetDayThree($due: Date!, $centerId: ID!) {
    assignments(where: { center: $centerId, due: $due }) {
      title
      id
      type
      class {
        slug
      }
    }
  }
`;
const GET_DAY_FOUR = gql`
  query GetDayFour($due: Date!, $centerId: ID!) {
    assignments(where: { center: $centerId, due: $due }) {
      title
      id
      type
      class {
        slug
      }
    }
  }
`;
const GET_DAY_FIVE = gql`
  query GetDayFive($due: Date!, $centerId: ID!) {
    assignments(where: { center: $centerId, due: $due }) {
      title
      id
      type
      class {
        slug
      }
    }
  }
`;
const GET_DAY_SIX = gql`
  query GetDaySix($due: Date!, $centerId: ID!) {
    assignments(where: { center: $centerId, due: $due }) {
      title
      id
      type
      class {
        slug
      }
    }
  }
`;
const GET_DAY_SEVEN = gql`
  query GetDaySeven($due: Date!, $centerId: ID!) {
    assignments(where: { center: $centerId, due: $due }) {
      title
      id
      type
      class {
        slug
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
  },
  calendarContainer: {
    width: 'min-content',
    minHeight: '80vh',
    border: `1px solid lightgrey`,
    borderRadius: theme.spacing(2),
    overflow: 'auto',
    justifyContent: 'center',
  },
  dayContainer: {
    '&>*': {},
    borderLeft: `1px solid lightgrey`,
    width: '12vw',
    padding: theme.spacing(1),
  },
  dayText: {
    color: theme.palette.grey[500],
    fontSize: theme.spacing(1.4),
  },
  dayNumber: {
    color: theme.palette.grey[600],
    fontSize: theme.spacing(3.5),
  },
  assignmentsContainer: {
    marginTop: theme.spacing(3),
    width: '10vw',
  },
  toggleWeek: {
    //marginRight: theme.spacing(30),
  },
  assignment: {
    marginBottom: theme.spacing(1),
    background: '#1967d2',
    padding: theme.spacing(1),
    width: 'inherit',
    cursor: 'pointer',
    borderRadius: '3px',
    '&>*': {
      color: 'white',
      fontWeight: '600',
      fontSize: theme.spacing(1.6),
    },
  },
  select: {
    width: '20%',
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(1, 0),
    borderRadius: theme.spacing(1),
    border: '1px solid lightgrey',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[50],
    },
    color: theme.palette.grey[700],
  },
}));

function Calendar() {
  const { centerId } = useContext(Navigation);
  const history = useHistory();
  moment.locale('ar-dz');
  const [startDate, setStartDate] = useState(moment().startOf('week'));
  const [dates, setDates] = useState([]);
  const [state, reverseState] = useState(true);
  useEffect(() => {
    const tempDates = [];
    for (let i = 0; i < 7; i++) {
      tempDates.push(moment(startDate).add(i, 'days'));
    }
    setDates(tempDates);
    reverseState(false);
  }, [startDate]);
  const preWeek = () => setStartDate(startDate.clone().subtract(7, 'days'));
  const postWeek = () => setStartDate(startDate.clone().add(7, 'days'));
  // console.log('dates', dates[0].format('YYYY-DD-MM'));
  const { loading: loadingDayOne, data: dataDayOne } = useQuery(GET_DAY_ONE, {
    skip: state,
    variables: {
      due: state ? null : dates[0].format('YYYY-MM-DD'),
      centerId,
    },
  });
  const { loading: loadingDayTwo, data: dataDayTwo } = useQuery(GET_DAY_TWO, {
    skip: state,
    variables: {
      due: state ? null : dates[1].format('YYYY-MM-DD'),
      centerId,
    },
  });
  const { loading: loadingDayThree, data: dataDayThree } = useQuery(GET_DAY_THREE, {
    skip: state,
    variables: {
      due: state ? null : dates[2].format('YYYY-MM-DD'),
      centerId,
    },
  });
  const { loading: loadingDayFour, data: dataDayFour } = useQuery(GET_DAY_FOUR, {
    skip: state,
    variables: {
      due: state ? null : dates[3].format('YYYY-MM-DD'),
      centerId,
    },
  });
  const { loading: loadingDayFive, data: dataDayFive } = useQuery(GET_DAY_FIVE, {
    skip: state,
    variables: {
      due: state ? null : dates[4].format('YYYY-MM-DD'),
      centerId,
    },
  });
  const { loading: loadingDaySix, data: dataDaySix } = useQuery(GET_DAY_SIX, {
    skip: state,
    variables: {
      due: state ? null : dates[5].format('YYYY-MM-DD'),
      centerId,
    },
  });
  const { loading: loadingDaySeven, data: dataDaySeven } = useQuery(GET_DAY_SEVEN, {
    skip: state,
    variables: {
      due: state ? null : dates[6].format('YYYY-MM-DD'),
      centerId,
    },
  });
  const arType = (type) => {
    switch (type) {
      case 'question':
        return l1.question;
      case 'material':
        return l1.material;
      default:
        return l1.assignment;
    }
  };
  const classes = useStyles();
  if (
    state ||
    loadingDayOne ||
    loadingDayTwo ||
    loadingDayThree ||
    loadingDayFour ||
    loadingDayFive ||
    loadingDaySix ||
    loadingDaySeven
  )
    return <Loading />;
  if (!state) {
    const dayOne = dataDayOne.assignments;
    const dayTwo = dataDayTwo.assignments;
    const dayThree = dataDayThree.assignments;
    const dayFour = dataDayFour.assignments;
    const dayFive = dataDayFive.assignments;
    const daySix = dataDaySix.assignments;
    const daySeven = dataDaySeven.assignments;

    return (
      <>
        <TopBar empty />

        <Box className={classes.container} display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" style={{ width: '84vw' }}>
            {/* <Box
              className={classes.select}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>{l1.allClasses}</Typography>
              <IoMdArrowDropdown />
            </Box> */}
            <Box className={classes.toggleWeek} display="flex" alignItems="center" flexGrow={1} justifyContent="center">
              <IconButton className={classes.arrowIcon} onClick={preWeek}>
                <FaAngleRight />
              </IconButton>
              <Typography className={classes.monthText}>{startDate.format('MMMM YYYY')}</Typography>
              <IconButton className={classes.arrowIcon} onClick={postWeek}>
                <FaAngleLeft />
              </IconButton>
            </Box>
          </Box>
          <Box className={classes.calendarContainer} display="flex">
            {/* DAY ONE */}
            <Box className={classes.dayContainer} display="flex" flexDirection="column" alignItems="center">
              <Typography className={classes.dayText}>
                {Boolean(dates[0].format('ddd')) ? dates[0].format('ddd') : 0}
              </Typography>
              <Typography className={classes.dayNumber}>
                {Boolean(dates[0].format('D')) ? dates[0].format('D') : 0}
              </Typography>
              <Box className={classes.assignmentsContainer} display="flex" flexDirection="column" alignItems="center">
                {dayOne.length === 0
                  ? ''
                  : dayOne.map((i) => (
                      <Box
                        className={classes.assignment}
                        display="flex"
                        justifyContent="flex-start"
                        onClick={() => history.push(`/class/${i.class.slug}/classwork/${i.id}/details`)}
                        key={i.id}
                      >
                        <Typography>
                          {arType(i.type)} : {i.title}
                        </Typography>
                      </Box>
                    ))}
              </Box>
            </Box>
            {/* DAY TWO */}
            <Box className={classes.dayContainer} display="flex" flexDirection="column" alignItems="center">
              <Typography className={classes.dayText}>
                {Boolean(dates[1].format('ddd')) ? dates[1].format('ddd') : 1}
              </Typography>
              <Typography className={classes.dayNumber}>
                {Boolean(dates[1].format('D')) ? dates[1].format('D') : 1}
              </Typography>
              <Box className={classes.assignmentsContainer} display="flex" flexDirection="column" alignItems="center">
                {dayTwo.length === 0
                  ? ''
                  : dayTwo.map((i) => (
                      <Box
                        className={classes.assignment}
                        display="flex"
                        justifyContent="flex-start"
                        onClick={() => history.push(`/class/${i.class.slug}/classwork/${i.id}/details`)}
                        key={i.id}
                      >
                        <Typography>
                          {arType(i.type)} : {i.title}
                        </Typography>
                      </Box>
                    ))}
              </Box>
            </Box>
            {/* DAY THREE */}
            <Box className={classes.dayContainer} display="flex" flexDirection="column" alignItems="center">
              <Typography className={classes.dayText}>
                {Boolean(dates[2].format('ddd')) ? dates[2].format('ddd') : 2}
              </Typography>
              <Typography className={classes.dayNumber}>
                {Boolean(dates[2].format('D')) ? dates[2].format('D') : 2}
              </Typography>
              <Box className={classes.assignmentsContainer} display="flex" flexDirection="column" alignItems="center">
                {dayThree.length === 0
                  ? ''
                  : dayThree.map((i) => (
                      <Box
                        className={classes.assignment}
                        display="flex"
                        justifyContent="flex-start"
                        onClick={() => history.push(`/class/${i.class.slug}/classwork/${i.id}/details`)}
                        key={i.id}
                      >
                        <Typography>
                          {arType(i.type)} : {i.title}
                        </Typography>
                      </Box>
                    ))}
              </Box>
            </Box>
            {/* DAY FOUR */}
            <Box className={classes.dayContainer} display="flex" flexDirection="column" alignItems="center">
              <Typography className={classes.dayText}>
                {Boolean(dates[3].format('ddd')) ? dates[3].format('ddd') : 3}
              </Typography>
              <Typography className={classes.dayNumber}>
                {Boolean(dates[3].format('D')) ? dates[3].format('D') : 3}
              </Typography>
              <Box className={classes.assignmentsContainer} display="flex" flexDirection="column" alignItems="center">
                {dayFour.length === 0
                  ? ''
                  : dayFour.map((i) => (
                      <Box
                        className={classes.assignment}
                        display="flex"
                        justifyContent="flex-start"
                        onClick={() => history.push(`/class/${i.class.slug}/classwork/${i.id}/details`)}
                        key={i.id}
                      >
                        <Typography>
                          {arType(i.type)} : {i.title}
                        </Typography>
                      </Box>
                    ))}
              </Box>
            </Box>
            {/* DAY FIVE */}
            <Box className={classes.dayContainer} display="flex" flexDirection="column" alignItems="center">
              <Typography className={classes.dayText}>
                {Boolean(dates[4].format('ddd')) ? dates[4].format('ddd') : 4}
              </Typography>
              <Typography className={classes.dayNumber}>
                {Boolean(dates[4].format('D')) ? dates[4].format('D') : 4}
              </Typography>
              <Box className={classes.assignmentsContainer} display="flex" flexDirection="column" alignItems="center">
                {dayFive.length === 0
                  ? ''
                  : dayFive.map((i) => (
                      <Box
                        className={classes.assignment}
                        display="flex"
                        justifyContent="flex-start"
                        onClick={() => history.push(`/class/${i.class.slug}/classwork/${i.id}/details`)}
                        key={i.id}
                      >
                        <Typography>
                          {arType(i.type)} : {i.title}
                        </Typography>
                      </Box>
                    ))}
              </Box>
            </Box>
            {/* DAY SIX */}
            <Box className={classes.dayContainer} display="flex" flexDirection="column" alignItems="center">
              <Typography className={classes.dayText}>
                {Boolean(dates[5].format('ddd')) ? dates[5].format('ddd') : 5}
              </Typography>
              <Typography className={classes.dayNumber}>
                {Boolean(dates[5].format('D')) ? dates[5].format('D') : 5}
              </Typography>
              <Box className={classes.assignmentsContainer} display="flex" flexDirection="column" alignItems="center">
                {daySix.length === 0
                  ? ''
                  : daySix.map((i) => (
                      <Box
                        className={classes.assignment}
                        display="flex"
                        justifyContent="flex-start"
                        onClick={() => history.push(`/class/${i.class.slug}/classwork/${i.id}/details`)}
                        key={i.id}
                      >
                        <Typography>
                          {arType(i.type)} : {i.title}
                        </Typography>
                      </Box>
                    ))}
              </Box>
            </Box>
            {/* DAY SEVEN */}
            <Box className={classes.dayContainer} display="flex" flexDirection="column" alignItems="center">
              <Typography className={classes.dayText}>
                {Boolean(dates[6].format('ddd')) ? dates[6].format('ddd') : 6}
              </Typography>
              <Typography className={classes.dayNumber}>
                {Boolean(dates[6].format('D')) ? dates[6].format('D') : 6}
              </Typography>
              <Box className={classes.assignmentsContainer} display="flex" flexDirection="column" alignItems="center">
                {daySeven.length === 0
                  ? ''
                  : daySeven.map((i) => (
                      <Box
                        className={classes.assignment}
                        display="flex"
                        justifyContent="flex-start"
                        onClick={() => history.push(`/class/${i.class.slug}/classwork/${i.id}/details`)}
                        key={i.id}
                      >
                        <Typography>
                          {arType(i.type)} : {i.title}
                        </Typography>
                      </Box>
                    ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
}

export default Calendar;
