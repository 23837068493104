import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import {
  RequireAuthentication,
  RequireCenterAdmin,
  RequireCenterMembership,
  RequireClassAccess,
  RequireTeacherAccess,
} from "./Auth/gates";
import { ClassWrapper } from "./Context";
import AdminPanel from "./components/AdminPanel";
import Answers from "./components/Answers";
import Assignment from "./components/Assignment";
import Calendar from "./components/Calendar";
import Files from "./components/Files";
import FrontEvent from "./components/FrontEvent";
import FrontEvents from "./components/FrontEvents";
import Home from "./components/Home";
import Landing from "./components/Landing";
import QuizEditor from "./components/QuizEditor";
import Review from "./components/Review";
import EditProfile from "./components/UserPages/EditProfile";
import CropeImage from "./components/UserPages/ImageCrop";
import Evaluation from "./components/evaluations/Evaluation";
import { AssignmentProvider } from "./components/evaluations/context/assignment";
import { StudentWorkProvider } from "./components/evaluations/context/studentWork";
import { StudentWorksProvider } from "./components/evaluations/context/studentWorks";
import Banned from "./components/rel/AdminPanel/Banned";
import EditLanding from "./components/rel/AdminPanel/EditLanding";
import Events from "./components/rel/AdminPanel/Events";
import Staff from "./components/rel/AdminPanel/Staff";
import Teachers from "./components/rel/AdminPanel/Teachers";
import Waiting from "./components/rel/AdminPanel/Waiting";
import MembershipForm from "./components/rel/RequestMembership/MembershipForm";
import Classwork from "./components/rel/class/Classwork";
import Marks from "./components/rel/class/Marks";
import People from "./components/rel/class/People";
import Stream from "./components/rel/class/Stream";
import forms from "./forms";

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route exact path="/events">
          <FrontEvents />
        </Route>
        <Route exact path="/event/:slug">
          <FrontEvent />
        </Route>
        <Route exact path="/image">
          <CropeImage />
        </Route>
        <RequireAuthentication rejectBehaviour="redirect" redirectTo="login">
          <Switch>
            {/* Only users not registered in center can request membership, others are redirected */}
            <Route exact path="/request-membership">
              <RequireCenterMembership
                /* status="GUEST" */ rejectBehaviour="redirect"
                redirectTo="/home"
              >
                <MembershipForm />
              </RequireCenterMembership>
            </Route>

            {/* Users not registered in center are redirected to request membership form */}
            <RequireCenterMembership
              /* status="GUEST" */ rejectBehaviour="message"
              message={
                <Switch>
                  {/* /home also handles banned & requested memberships */}
                  <Route exact path="/home">
                    <RequireCenterMembership status="REQUESTED">
                      طلب انضمامك للمركز قيد المراجعة، يرجى العودة لاحقًا.
                    </RequireCenterMembership>
                    <RequireCenterMembership status="BANNED">
                      لقد تم تجميد عضويتك في هذا المركز بسبب مخالفتك لشروط
                      الخدمة.
                    </RequireCenterMembership>
                    <RequireCenterMembership status="ACTIVE">
                      <Home />
                    </RequireCenterMembership>
                  </Route>

                  {/* Only active members of center can access these routes */}
                  <RequireCenterMembership
                    status="ACTIVE"
                    rejectBehaviour="redirect"
                    redirectTo="/"
                  >
                    <Switch>
                      <Route exact path="/calendar">
                        <Calendar />
                      </Route>
                      <Route exact path="/profile/edit">
                        <EditProfile />
                      </Route>
                      <Route path="/class/:slug">
                        <ClassWrapper>
                          <RequireClassAccess
                            rejectBehaviour="message"
                            message={<h1>لا يمكنك الدخول إلى هذا القسم</h1>}
                          >
                            <Switch>
                              <Route exact path="/class/:slug">
                                <Stream />
                              </Route>
                              <Route exact path="/class/:slug/classwork">
                                <Classwork />
                              </Route>
                              <Route
                                exact
                                path="/class/:slug/classwork/:id/details"
                              >
                                <Assignment />
                              </Route>
                              <Route
                                exact
                                path="/class/:slug/classwork/:id/student-evaluation"
                              >
                                <AssignmentProvider>
                                  <StudentWorksProvider>
                                      <Evaluation subject="student"/>
                                  </StudentWorksProvider>
                                </AssignmentProvider>
                              </Route>
                              <Route
                                exact
                                path="/class/:slug/classwork/:id/student-evaluation/:studentWorkId"
                              >
                                <AssignmentProvider>
                                  <StudentWorkProvider subject='student'>
                                      <Evaluation subject="student"/>
                                  </StudentWorkProvider>
                                </AssignmentProvider>
                              </Route>
                              <Route
                                exact
                                path="/class/:slug/classwork/:id/training-evaluation"
                              >
                                <AssignmentProvider>
                                  <StudentWorksProvider>
                                      <Evaluation subject="training"/>
                                  </StudentWorksProvider>
                                </AssignmentProvider>
                              </Route>
                              <Route
                                exact
                                path="/class/:slug/classwork/:id/training-evaluation/:studentWorkId"
                              >
                                <AssignmentProvider>
                                  <StudentWorkProvider subject='student'>
                                      <Evaluation subject="training"/>
                                  </StudentWorkProvider>
                                </AssignmentProvider>
                              </Route>
                              <Route
                                exact
                                path="/class/:slug/classwork/:id/coach-evaluation"
                              >
                                <AssignmentProvider>
                                  <StudentWorksProvider>
                                    <Evaluation subject="coach"/>
                                  </StudentWorksProvider>
                                </AssignmentProvider>
                              </Route>
                              <Route
                                exact
                                path="/class/:slug/classwork/:id/coach-evaluation/:studentWorkId"
                              >
                                <AssignmentProvider>
                                  <StudentWorkProvider subject='student'>
                                      <Evaluation subject="coach"/>
                                  </StudentWorkProvider>
                                </AssignmentProvider>
                              </Route>
                              <Route exact path="/class/:slug/people">
                                <People />
                              </Route>
                              <RequireClassAccess
                                only="teacher"
                                rejectBehaviour="redirect"
                                redirectTo="/home"
                              >
                                <Switch>
                                  <Route exact path="/class/:slug/Marks">
                                    <Marks />
                                  </Route>
                                  <Route
                                    exact
                                    path="/class/:slug/classwork/:id/answers"
                                  >
                                    <Answers />
                                  </Route>
                                </Switch>
                              </RequireClassAccess>
                            </Switch>
                          </RequireClassAccess>
                        </ClassWrapper>
                      </Route>
                      <Route exact path="/quiz/:id">
                        <RequireTeacherAccess
                          rejectBehaviour="redirect"
                          redirectTo="/home"
                        >
                          <QuizEditor />
                        </RequireTeacherAccess>
                      </Route>
                      <Route exact path="/review">
                        <RequireTeacherAccess
                          rejectBehaviour="redirect"
                          redirectTo="/home"
                        >
                          <ClassWrapper>
                            <Review />
                          </ClassWrapper>
                        </RequireTeacherAccess>
                      </Route>
                      <RequireCenterAdmin
                        rejectBehaviour="redirect"
                        redirectTo="/home"
                      >
                        <Switch>
                          <Route exact path="/admin-panel">
                            <AdminPanel />
                          </Route>
                          <Route exact path="/admin-panel/banned">
                            <Banned />
                          </Route>
                          <Route exact path="/admin-panel/events">
                            <Events />
                          </Route>
                          <Route exact path="/admin-panel/teachers">
                            <Teachers />
                          </Route>
                          <Route exact path="/admin-panel/waiting">
                            <Waiting />
                          </Route>
                          <Route exact path="/admin-panel/staff">
                            <Staff />
                          </Route>
                          <Route exact path="/admin-panel/center-info">
                            <EditLanding />
                          </Route>
                          <Route exact path="/admin-panel/admins">
                            <EditLanding />
                          </Route>
                          <Route exact path="/files">
                            <Files />
                          </Route>
                          {/* Load form routes from form list */}
                          {Object.values(forms).map(({ routes, roles }) =>
                            Object.values(routes).map(({ path, Component }) => (
                              <Route key={path} exact path={path}>
                                <RequireCenterAdmin
                                  only={roles}
                                  rejectBehaviour="redirect"
                                  redirectTo="/"
                                >
                                  <Component />
                                </RequireCenterAdmin>
                              </Route>
                            ))
                          )}
                        </Switch>
                      </RequireCenterAdmin>
                    </Switch>
                  </RequireCenterMembership>
                </Switch>
              }
            >
              <Redirect to="/request-membership" />
            </RequireCenterMembership>
          </Switch>
        </RequireAuthentication>
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
