import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";

export default function CheckBoxField({
  // field properties
  name,
  type,
  label,
  // data properties
  edit,
  touched,
  error,
  value,
  handleChange,
  handleBlur,
  inputProps,
  emptyLabel,
  ...other
}) {
  if (!edit) {
    return (
      <Box style={{ display: "flex", alignItems: "top", gap: 16 }}>
        {label && <Typography color="primary">{label}:</Typography>}
        <Typography style={{whiteSpace:"pre-wrap"}}>{value && value?.length ? value : emptyLabel}</Typography>
      </Box>
    );
  } else {
    return (
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            disabled={!edit}
            value={Boolean(value)}
            onChange={handleChange}
            color="primary"
            //{...inputProps}
            {...other}
          />
        }
        label={label}
      />
    );
  }
}
